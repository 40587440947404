<template>
    <div class="p-6">
        <div class="mb-8 flex justify-between space-x-3">
            <h3 v-if="isEdit">Edit Press Release</h3>
            <h3 v-else>Create Press Release</h3>
        </div>

        <div v-if="isEdit && loading">
            <div class="flex items-center space-x-5">
                <Loader class="w-10 h-10 text-green"/>
                <p>Loading press release...</p>
            </div>
        </div>

        <div v-else-if="!isEdit || gotPressRelease" class="grid gap-y-4 relative">
            <!-- Delete | Archive Buttons -->
            <div v-if="canPerformActions" class="justify-self-end">
                <button v-if="pressReleaseStatus === 'draft'" class="btn white px-6" @click="confirmDelete">Delete</button>
                <button v-else-if="pressReleaseStatus === 'published'" class="btn white px-6" @click="confirmArchive">Archive</button>
            </div>

            <!-- Banner -->
            <div class="relative">
                <label for="press-release-banner" class="block text-xs text-gray-600 uppercase mb-2">Banner* <span class="normal-case">&nbsp;(max file size 2 MB)</span></label>

                <!-- Banner Image File Input -->
                <input id="press-release-banner" type="file" class="hidden" ref="photo" @change="updateBannerPreview" accept=".jpg, .jpeg">

                <div class="flex justify-center w-full bg-gray-100" :class="{'cp-input-error': $v.form.banner.$error}">
                    <div :style="`background-image: url('${bannerUrl}')`" class="w-[100vw] sm:w-full h-[55vw] xs:h-[45vw] sm:h-[35vw] md:h-[30vw] xl:h-[40vw] max-h-[400px] max-w-[1024px] rounded-[9px] bg-cover bg-no-repeat bg-center"></div>
                </div>
                <template v-if="$v.form.banner.$error">
                    <p v-if="!$v.form.banner.required" class="text-red text-xs ml-1">Banner image is required</p>
                    <p v-else-if="!$v.form.banner.maxImageSize" class="text-red text-xs ml-1">Banner image must be no larger than 2 MB</p>
                </template>

                <div class="flex items-center justify-center absolute top-[48%] left-0 right-0">
                    <button @click.prevent="selectNewPhoto" class="inline-flex items-center px-6 py-2 bg-blue-600 rounded-md font-semibold text-white text-sm hover:bg-blue-700 transition mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                        Select Banner Image
                    </button>
                    <button v-show="frontBannerPreview || form.banner" @click.prevent="removeBannerImage" class="inline-flex items-center px-6 py-2 bg-white border border-gray-500 rounded-md font-semibold text-gray-700 hover:text-gray-600 hover:border-gray-400 text-sm transition">Remove Image</button>
                </div>
            </div>

            <!-- Title -->
            <div class="relative">
                <label for="press-release-title" class="block text-xs text-gray-600 uppercase mb-2">Title*</label>
                <input id="press-release-title" class="w-full lg:w-6/12" v-model="$v.form.title.$model" :class="{'cp-input-error': $v.form.title.$error}">
                <template v-if="$v.form.title.$error">
                    <p v-if="!$v.form.title.required" class="text-red text-xs ml-1">Title is required</p>
                </template>
            </div>

            <!-- Content -->
            <div>
                <label for="press-release-content" class="block text-xs text-gray-600 uppercase mb-2">Content*</label>
                <VueTrix
                    id="press-release-content"
                    placeholder="Start writing..."
                    class="w-full h-[500px]"
                    v-model.lazy="$v.form.content.$model"
                    :config="trixConfig"
                    :disabledEditor="disabledEditor"
                />
                <template v-if="$v.form.content.$error">
                    <p v-if="!$v.form.content.required" class="text-red text-xs ml-1">Content is required</p>
                </template>
            </div>

            <!-- Status -->
            <div class="relative flex flex-wrap">
                <div class="w-full lg:w-3/12 mb-6 mr-2">
                    <label for="press-release-status" class="block text-xs text-gray-600 uppercase mb-2">Status*</label>
                    <select v-model="$v.form.status.$model"
                            id="press-release-status"
                            name="status"
                            class="rounded-md border border-gray bg-white w-full h-[40px]"
                            :class="{'cp-input-error': $v.form.status.$error}"
                            :disabled="pressReleaseStatus === 'archived'"
                    >
                        <option v-for="(status, index) in statuses"
                                :value="status"
                                :selected="status === pressReleaseStatus"
                                :key="`${index}-${status}`"
                        >
                            {{ status[0].toUpperCase() + status.slice(1) }}
                        </option>
                    </select>
                    <template v-if="$v.form.status.$error">
                    </template>
                </div>

                <!-- Publish Date/Time -->
                <div v-if="showPublishDate" class="w-full lg:w-3/12 mb-6">
                    <label for="press-release-publish" class="block text-xs text-gray-600 uppercase mb-2">Publish Date/Time*</label>
                    <input id="press-release-publish" v-model="$v.form.published_at.$model" @change="$v.form.published_at.$touch" class="w-full" :class="{'cp-input-error': $v.form.published_at.$error}" type="datetime-local">
                    <template v-if="$v.form.published_at.$error">
                        <p v-if="!$v.form.published_at.requiredIf" class="text-red text-xs ml-1">Publish date/time is required</p>
                        <!-- <p v-if="!$v.form.published_at.minValue" class="text-red text-xs ml-1">Publish date/time must be greater than current date/time</p> -->
                    </template>
                </div>
                <!-- <div v-else-if="isPublished(pressReleaseStatus, pressReleasePublishDate)" class="mb-6">
                    <label class="block text-xs text-gray-600 uppercase mb-2">Published Date/Time</label>
                    <div class="lg:grid lg:h-[40px] lg:items-center">
                        <Date :date="pressReleasePublishDate" preset="DATETIME_FULL"/>
                    </div>
                </div> -->
            </div>

            <div class="mb-6 flex gap-x-2">
                <!-- Cancel Button -->
                <router-link class="btn white px-6" :to="`/newsroom/press-releases`">Cancel</router-link>

                <!-- Create | Save Buttons -->
                <button class="btn button-green px-6" v-if="!isEdit" @click="save()" :disabled="!canCreatePressRelease || pressReleaseStatus === 'archived' || saving">
                    <div v-if="saving" class="flex items-center space-x-1"><Loader class="w-5 h-5"/>
                        <span>Saving</span>
                    </div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Saved!</span>
                    <span v-else>Create</span>
                </button>
                <button class="btn button-green px-6" v-else @click="update()" :disabled="!canEditPressRelease || pressReleaseStatus === 'archived' || saving">
                    <div v-if="saving" class="flex items-center space-x-1"><Loader class="w-5 h-5"/>
                        <span>Updating</span>
                    </div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Saved!</span>
                    <span v-else>Save</span>
                </button>
            </div>

            <!-- Modals -->
            <ConfirmArchiveModal
                :newsItemId="pressReleaseId"
                :newsType="'pressRelease'"
                :visible="showConfirmArchiveModal"
                @close="showConfirmArchiveModal = false"
                @success="$router.push('/newsroom/press-releases')"
            />

            <ConfirmDeleteModal
                :newsItemId="pressReleaseId"
                :newsType="'pressRelease'"
                :visible="showConfirmDeleteModal"
                @close="showConfirmDeleteModal = false"
                @success="$router.push('/newsroom/press-releases')"
            />
        </div>

        <div v-else>
            <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
        </div>

        <!-- Render server errors if any on save -->
        <div v-if="errors" class="mt-6">
            <ul class="ml-1">
                <li v-for="(error, i) in errors" :key="`create-press-release-error-${i}`" class="text-red text-sm">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import ConfirmDeleteModal from '@components/newsroom/modals/ConfirmDelete'
import ConfirmArchiveModal from '@components/newsroom/modals/ConfirmArchive'
import Date from '@components/global/dates/Date'
import Loader from '@components/global/Loader'
import VueTrix from "vue-trix"
import { required, requiredIf } from 'vuelidate/lib/validators'
import { DateTime } from 'luxon'

export default {
    name: 'PressRelease',

    components: {
        ConfirmDeleteModal,
        ConfirmArchiveModal,
        Date,
        Loader,
        VueTrix
    },

    metaInfo() {
        return  {
            title: this.form.title ? 'Edit Press Release' : 'Create Press Release'
        }
    },

    data() {
        return {
            form: {
                banner: null,
                title: null,
                content: null,
                status: 'draft',
                published_at: null,
            },
            frontBannerPreview: null,
            bannerUrl: null,
            pressReleaseId: null,
            pressReleaseSlug: null,
            pressReleaseStatus: 'draft',
            pressReleasePublishDate: null,
            loading: false,
            saving: false,
            success: false,
            errors: null,
            isEdit: false,
            gotPressRelease: false,
            showConfirmArchiveModal: false,
            showConfirmDeleteModal: false,
            trixConfig: {
                blockAttributes: {
                    heading1: {
                        tagName: 'h5'
                    },
                },
                textAttributes: {
                    bold: {
                        parser: (element) => {
                            return false
                        }
                    },
                    italic: {
                        parser: (element) => {
                            return false
                        }
                    },
                }
            },
            disabledEditor: false
        }
    },
    computed: {
        canCreatePressRelease() {
            return this.isValid && this.$app.services.auth.hasPermission('newsroom.pressreleases.create')
        },
        canEditPressRelease() {
            return this.isValid && this.$app.services.auth.hasPermission('newsroom.pressreleases.update')
        },
        canDeletePressRelease() {
            return this.$app.services.auth.hasPermission('newsroom.pressreleases.delete')
        },
        canPerformActions() {
            return this.isEdit && this.pressReleaseStatus !== 'archived' && this.canDeletePressRelease
        },
        isValid() {
            return !this.$v.form.$invalid
        },
        statuses() {
            const statuses = []

            switch (this.pressReleaseStatus) {
                case 'draft': statuses.push('draft', 'schedule', 'publish')
                    break
                case 'published':
                    if (this.isScheduled(this.pressReleaseStatus, this.pressReleasePublishDate))
                        statuses.push('draft', 'scheduled')
                    else statuses.push('draft', 'published')
                    break
                case 'archived': statuses.push('archived')
                    break
            }

            return statuses
        },
        showPublishDate() {
            return this.form.status === 'schedule' || this.form.status === 'scheduled' || this.form.status === 'published'
        },
        today() {
            return DateTime.now().toFormat("yyyy'-'LL'-'dd'T'T")
        },
    },
    methods: {
        getPressRelease() {
            // default params
            const params = {
                relations: 'article|status',
                admin: true,
            }

            this.loading = true
            this.$app.api.newsroom.getPressReleaseBySlug(this.$route.params.slug, params)
                .then(({ data }) => {
                    this.form = {
                        banner: data.article.banner,
                        title: data.article.title,
                        content: data.article.content,
                        status: this.isScheduled(data.status.key, data.published_at) ? 'scheduled' : data.status.key,
                        published_at: this.parseDate(data.published_at),
                    }
                    this.pressReleaseId = data.id
                    this.pressReleaseSlug = data.article.slug
                    this.pressReleaseStatus = data.status.key
                    this.pressReleasePublishDate = this.form.published_at
                    this.setBannerUrl()
                    this.contentEditable()
                    this.gotPressRelease = true
                })
                .catch(error => console.log(error))
                .finally(() => this.loading = false)
        },
        save() {
            this.errors = null
            this.saving = true

            let formData = new FormData()
            formData.append('banner', this.$refs.photo.files[0])
            formData.append('title', this.form.title)
            formData.append('content', this.form.content)
            formData.append('status', this.form.status === 'schedule' || this.form.status === 'publish' || this.form.status === 'scheduled' ? 'published' : this.form.status)
            if (this.form.published_at && this.form.status === 'schedule' || this.form.status === 'scheduled') formData.append('published_at', DateTime.fromISO(this.form.published_at).toUTC())

            this.$app.api.newsroom.storePressRelease(formData)
                .then(res => {
                    this.success = true

                    setTimeout(() => this.$router.push(`/newsroom/press-releases/${res.data.article.slug}`), 2000)
                })
                .catch(error => {
                    if (error.response?.data?.errors) this.errors = Object.values(error.response.data.errors).flat()
                    else if (error.response?.data?.message) this.errors = [error.response.data.message]
                    else this.errors = ['Whoops... something went wrong!']
                    console.error(error)
                })
                .finally(() => this.saving = false)
        },
        update() {
            this.errors = null
            this.saving = true

            let formData = new FormData()
            if (this.frontBannerPreview) formData.append('banner', this.$refs.photo.files[0])
            formData.append('_method', 'put')
            formData.append('title', this.form.title)
            formData.append('content', this.form.content)
            formData.append('status', this.form.status === 'schedule' || this.form.status === 'publish' || this.form.status === 'scheduled' ? 'published' : this.form.status)
            if (this.form.published_at && this.form.status === 'schedule' || this.form.status === 'scheduled' || this.form.status === 'published') formData.append('published_at', DateTime.fromISO(this.form.published_at).toUTC())

            this.$app.api.newsroom.updatePressRelease(this.pressReleaseId, formData)
                .then(res => {
                    this.success = true

                    setTimeout(() => {
                        if (res.data.article.slug === this.pressReleaseSlug) {
                            this.success = false
                            this.saving = false
                            this.frontBannerPreview = null

                            this.getPressRelease()
                        }
                        else this.$router.push(`/newsroom/press-releases/${res.data.article.slug}`)
                    }, 2000)
                })
                .catch(error => {
                    if (error.response?.data?.errors) this.errors = Object.values(error.response.data.errors).flat()
                    else if (error.response?.data?.message) this.errors = [error.response.data.message]
                    else this.errors = ['Whoops... something went wrong!']
                    console.error(error)
                })
                .finally(() => this.saving = false)
        },
        setBannerUrl() {
            this.bannerUrl = this.frontBannerPreview
                ? this.frontBannerPreview
                : (this.form.banner
                    ? `https://www.citruspear.com/${process.env.VUE_APP_PRESS_RELEASE_IMAGE_PATH}${this.form.banner}-lg.jpg`
                    // ? `https://www.citruspear.local/${process.env.VUE_APP_PRESS_RELEASE_IMAGE_PATH}${this.form.banner}-lg.jpg`
                    : null
                )
        },
        selectNewPhoto() {
            this.$refs.photo.click()
        },
        updateBannerPreview() {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.frontBannerPreview = e.target.result
                this.setBannerUrl()
            }
            this.form.banner = this.$refs.photo.files[0]
            this.$v.form.banner.$touch()
            reader.readAsDataURL(this.$refs.photo.files[0])
        },
        removeBannerImage() {
            this.frontBannerPreview = null
            this.form.banner = null
            this.setBannerUrl()
            this.$v.form.banner.$touch()
        },
        isScheduled(status, date) {
            return status === 'published' && DateTime.now() < DateTime.fromISO(date)
        },
        isPublished(status, date) {
            return status === 'published' && DateTime.now() >= DateTime.fromISO(date)
        },
        parseDate(date) {
            return date ? DateTime.fromISO(date, { zone: 'local' }).toFormat("yyyy'-'LL'-'dd'T'T") : null
        },
        confirmDelete() {
            if (this.pressReleaseStatus !== 'draft') return
            this.showConfirmDeleteModal = true
        },
        confirmArchive() {
            this.showConfirmArchiveModal = true
        },
        contentEditable() {
            this.disabledEditor = this.pressReleaseStatus === 'archived'
        }
    },

    created() {
        if (this.$route.params.slug) {
            this.isEdit = true
            this.getPressRelease()
        }
    },

    validations: {
        form: {
            banner: {
                required,
                maxImageSize: function(val) {
                    if (!val || typeof val === 'string') return true
                    return val.size <= 2097152
                },
            },
            title: { required },
            content: { required },
            status: {},
            published_at: {
                requiredIf: requiredIf(function() {
                    return this.form.status === 'schedule' || this.form.status === 'scheduled' || this.form.status === 'published'
                }),
                // minValue: function(val) {
                //     if (this.form.status === 'schedule' || this.form.status === 'scheduled') {
                //         const now = DateTime.now().toFormat("yyyy'-'LL'-'dd'T'T")
                //         const pub = val ? DateTime.fromISO(val).toFormat("yyyy'-'LL'-'dd'T'T") : null
                //         // console.log('now:', now)
                //         // console.log('pub:', pub)
                //         // console.log('pub >= now:', pub >= now)
                //         return pub >= now
                //     } else return true
                // },
            },
        },
    }
}
</script>

<style lang="scss">
.trix-content {
    height: 500px;
    overflow-y: auto;
    font-size: 1rem;
    @media (min-width: 998px) {
        font-size: 1.125rem;
    }
    @media (min-width: 1382px) {
        font-size: 1.25rem;
    }
}
.trix-content {
    blockquote, ol, ul {
        margin: 0;
        padding: 0;
    }
    blockquote {
        border: none;
        margin-left: 1rem;
    }
    div {
        letter-spacing: 0em;
    }
    h5 {
        color: #183d1d;
        font-family: inherit;
        font-size: inherit;
        font-weight: 700;
        text-transform: uppercase;
    }
    a {
        font-size: inherit;
        font-weight: 500;
        color: #183d1d;
    }
    ul {
        list-style-type: disc;
        margin-left: 1rem;
        li {
            margin-left: 1rem;
        }
    }
    ol {
        list-style-type: none;
        li {
            margin-left: 1rem;
        }
    }
}
.trix-button--icon-attach, .trix-button--icon-code {
    display: none;
}
</style>
