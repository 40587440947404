<template>
    <div v-if="mealSelectorMeals && mealSelectorSelections" class="mb-4">
        <MealsSelect :meals="mealSelectorMeals" :selections="mealSelectorSelections" :portionQuantity="portionQuantity"
            @meals="handleMeals" class="mb-6" />
    </div>
</template>

<script>
import { DateTime } from "luxon";
import Loader from '@components/global/Loader'
import MealsSelect from '@components/registrations/update/selections/MealsSelect.vue'
import SelectionListView from '@components/registrations/selections/ListView'
import Accordion from '@components/global/Accordion'

export default {
    name: "Selections",

    components: {
        Accordion,
        Loader,
        MealsSelect,
        SelectionListView
    },

    props: {
        registration: {
            type: Object,
            required: true,
        },
        meals: {
            type: Array,
            required: true,
        },
        portionQuantity: {
            type: Number,
            required: true,
        }
    },

    data() {
        return {
            errors: [],
            newSelections: null,
            originalSelectionsSet: null,
            newSelectionsSet: null,
        }
    },

    computed: {
        canSave() {
            if (!this.newSelectionsSet) return false
            return this.hasSymmetricDifference(this.originalSelectionsSet, this.newSelectionsSet) && this.validSelections
        },
        different() {
            return this.hasSymmetricDifference(this.originalSelectionsSet, this.newSelectionsSet)
        },
        newSelectionsTotalMeals() {
            let count = 0
            this.newSelections.forEach((selection) => { count += selection.quantity })

            return count
        },
        validSelections() {
            return this.newSelectionsTotalMeals === this.portionQuantity
            // return this.$app.services.registration.validateSelections(this.newSelections, this.portionQuantity);
        },
        mealSelectorMeals() {
            if (this.meals) return this.sortMeals(this.meals)
            return this.sortMeals(this.registration.class.meals)
        },
        mealSelectorSelections() {
            if (this.selectionChangeRequired && this.different) return []
            else if (this.newSelections) return this.sortMeals(this.newSelections)
            else return this.sortMeals(this.registration.selections)
        },
        selectionChangeRequired() {
            return this.$app.store.state.registration.update.review.selections
        },
    },

    methods: {
        createSelectionSet(selections) {
            return new Set(selections.map((selection) => { return `${selection.meal_id}` }))
        },
        handleMeals(selections) {
            this.newSelections = selections.filter((selection) => { return selection.quantity })
            this.newSelectionsSet = this.createSelectionSet(this.newSelections)

            if (this.validSelections) this.updateSelectionState()
            this.$emit('isValid', this.validSelections)
        },
        hasSymmetricDifference(original, change) {
            if (original.size === change.size) return true

            let _difference = new Set(original)
            for (let item of change) {
                if (_difference.has(item)) {
                    _difference.delete(item)
                } else {
                    _difference.add(item)
                }
            }
            return !!_difference.size
        },
        updateSelectionState() {
            this.$app.store.commit('registration/setUpdateData', { selections: this.newSelections.filter((item) => { return item.quantity > 0 }) })
        },
        sortMeals(meals) {
            // TODO: remove the sorting once the meal model has sorting by default
            return meals.sort((a, b) => {
                if (a.meal.name < b.meal.name) return -1
                if (a.meal.name > b.meal.name) return 1
                return 0
            })
        }
    },

    watch: {},

    created() {
        this.originalSelectionsSet = this.createSelectionSet(this.registration.selections)
    },
}
</script>

<style lang="scss" scoped></style>
