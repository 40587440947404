<template>
  <ListView :headers="headers" @changeSort="changeSort">
    <template #list>
      <slot>
        <div v-for="classEvent in classes" :key="classEvent.id" class="mb-5 xl:mb-0">
          <ClassCard :classEvent="classEvent" />
        </div>
      </slot>
    </template>
  </ListView>
</template>

<script>
import ListView from '@components/global/ListView'
import ClassCard from '@components/classes/ClassCard'

export default {
  props: {
    classes: {
      type: Array,
      default: function () {
        return []
      },
    },
    headerNames: {
      type: Array,
      default: function () {
        return ['SKU', 'Status', 'Date', 'Location', 'Lead', 'Capacity']
      },
    },
    headersWithSort: {
      type: Array,
      default: function () {
        return []
      },
    },
  },

  components: {
    ListView,
    ClassCard,
  },

  computed: {
    headers() {
      return this.headerNames.map((header) => {
        const matchingHeader = this.headersWithSort.find((h) => h.name === header)
        if (matchingHeader) {
          return matchingHeader
        } else {
          return header
        }
      })
    },
  },

  methods: {
    changeSort(sort) {
      this.$emit('changeSort', sort)
    },
  },
}
</script>

<style lang="scss" scoped></style>
