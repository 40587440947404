const state = () => {
    return {
        statuses: {
            calendar: [],
            event: [],
        }
    }
}

const getters = {}

const mutations = {
    setStatuses(state, data) {
        for(const [key, value] of Object.entries(data)) {
            if(value != null || typeof value !== 'undefined') state.statuses[key] = value
        }
    },
}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}
