const routes = [
  {
    path: "/meals",
    name: "meals",
    component: () => import("@views/meals/Meals.vue"),
    meta: {
      requiresAuth: true,
      roles: [],
      permissions: ["meals.view"]
    }
  },
  {
    path: '/meals/create',
    name: 'meal.create',
    component: () => import('@views/meals/CreateMeal.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      roles: [],
      permissions: ['meals.create']
    }
  },
  {
    path: "/meals/:id",
    name: "meal",
    component: () => import("@views/meals/Meal.vue"),
    meta: {
      requiresAuth: true,
      roles: [],
      permissions: ["meals.view"]
    }
  }
];

export default routes;
