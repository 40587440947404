import DefaultApp from '@shared/app'
import Auth from './services/auth'
import User from '@shared/app/services/user'
import Promotion from './services/promotion'
import Registration from '@shared/app/services/registration'
import Location from '@shared/app/services/location'

export default class App extends DefaultApp {

    constructor(router, store){
        super(router, store)

        this.services = {
            auth: new Auth(this),
            user: new User(this),
            promotion: new Promotion(this),
            registration: new Registration(this),
            location: new Location(this),
        }
    }

}
