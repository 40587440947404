<template>
  <div>
    <form v-on:submit.prevent="search">
      <div class="relative">
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <button type="submit" class="p-1 focus:outline-none focus:shadow-outline">
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-green"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </button>
        </span>
        <div class="flex">
          <input
            id="search-input"
            :value="value"
            @input="handleInput"
            type="text"
            class="w-64 font-display pl-10 py-2 bg-gray-100 border border-gray-200 rounded-tr-none rounded-br-none"
            :class="inputClass"
            :placeholder="placeholder"
          />
          <button
            type="submit"
            class="btn bg-white text-gray-800 font-normal border border-gray-200 border-l-0 rounded-tl-none rounded-bl-none capitalize"
          >
            <span>Search</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    inputClass: {
      type: String,
      required: false,
    },
  },

  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value)
    },
    search() {
      this.$emit('search')
    },
  },
}
</script>

<style lang="scss" scoped>
.search-button {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: white;
  border-color: var(--gray-200);
  border-width: 1px;
  border-left: none;
  color: black;
}
</style>
