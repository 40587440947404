<template>
    <ListView :headers="headers">
        <template #list>
            <slot>
                <div v-for="payment in payments" :key="payment.id" class="mb-5 xl:mb-0">
                    <PaymentsCard :payment="payment" :totals="totals" @select="handleSelect"/>
                </div>
            </slot>
        </template>
    </ListView>
</template>

<script>
import ListView from '@components/global/ListView'
import PaymentsCard from '@components/registrations/update/payments/PaymentsCard';

export default {
    props: {
        payments: {
            type: Array,
            default: function () {
                return []
            },
        },
        totals: {
            type: Object,
            default: function () {
                return {}
            },
        },
        headers: {
            type: Array,
            default: function () {
                return [
                    'ID',
                    'Service',
                    'Method',
                    'Amount',
                    'Status',
                    'Action'
                ]
            },
        }
    },

    components: {
        ListView,
        PaymentsCard,
    },

    methods: {
        handleSelect(event) {
            this.$emit('select', event)
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
