<template>
    <div>
        <TextBox class="mb-4" :value="instructions" @input="handleInput" label="Additional Instructions:" placeholder="No onion, mild spice, etc..." />
    </div>
</template>

<script>
import debounce from 'lodash.debounce'
import TextBox from '@/components/global/forms/Textbox'

export default {
    name: "Instructions",

    components: {
        TextBox,
    },

    props: {
        open: {
            type: Boolean,
            default: false,
        },
        instructions: {
            type: String,
        },
    },

    data() {
        return {
            newInstructions: null,
        }
    },

    computed: {},

    methods: {
        handleInput(value) {
            this.newInstructions = value
            this.debounceUpdate()
        },
        updateInstructionsData() {
            this.$app.store.commit('registration/setUpdateData', {instructions: this.newInstructions})
        },
    },

    watch: {
        newInstructions: {
            handler() {
                this.debounceUpdate()
            }
        },
    },

    created() {
        this.debounceUpdate = debounce(this.updateInstructionsData, 400)

        if(this.instructions) this.newInstructions = this.instructions
    },

    mounted() {},
}
</script>

<style lang="scss" scoped>
</style>
