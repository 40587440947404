export class calendarStatus {
    static get ENABLED() { return 1 }
    static get DISABLED() { return 2 }
}

export class calendarEventStatus {
    static get ENABLED() { return 1 }
    static get DISABLED() { return 2 }
    static get PENDING() { return 3 }
    static get DENIED() { return 4 }
    static get CANCELED() { return 5 }
}
