import Resource from './resource'

export default class Tracking extends Resource {

    trackCart(data) {
        return this.axios.post('events/cart', data)
    }

    trackContact(data) {
        return this.axios.post('events/contact', data)
    }

    trackOrder(data) {
        return this.axios.post('events/order', data)
    }

}
