<template>
  <div v-if="showDetails">
    <div v-for="registration in registrations" :key="registration.id" class="bg-gray-200 mb-5 rounded-md p-5">
      <div class="flex">
        <!-- Participant info -->
        <div class="bg-gray-100 rounded-md p-5 flex-1 mr-5">
          <div class="grid grid-cols-1 xs:grid-cols-2 gap-2 gap-y-4">
            <div class="xs:col-span-2">
              <h6 class="label">Participant</h6>
              <p class="font-medium">{{ registration.guest.name | ucwords }}</p>
              <p class="break-words">
                <a :href="`mailto:${registration.guest.email}`" class="lowercase mr-2 hover:underline">
                  {{ registration.guest.email }}
                </a>
                <Tooltip v-if="registration.prep_instructions"
                  :message="'Prep instructions have been written for this customer'">
                  <template v-slot:default>
                    <i class="fas fa-check-circle text-green"></i>
                  </template>
                </Tooltip>
                <Tooltip v-else-if="hasSpecialInstructions(registration)"
                  :message="'This customer has special instructions'">
                  <template v-slot:default>
                    <i class="fas fa-exclamation-circle text-green"></i>
                  </template>
                </Tooltip>
                <Tooltip v-if="registration.class.fulfillment_id == 4 && !registration.arrival_date"
                  :message="'This customer has a delivery address issue.'">
                  <template v-slot:default>
                    <i class="fas fa-exclamation-triangle text-red"></i>
                  </template>
                </Tooltip>
                <Tooltip v-if="registration.shippo_orders && registration.shippo_orders.length > 0"
                  :message="'A shippo order has been created for this customer'">
                  <template v-slot:default>
                    <i class="fas fa-shipping-fast text-green"></i>
                  </template>
                </Tooltip>
              </p>
              <p>{{ registration.guest.phone }}</p>
            </div>
            <div>
              <h6 class="label">ID</h6>
              <router-link :to="`/registrations/${registration.id}`" class="hover:underline">
                <p>{{ registration.id }}</p>
              </router-link>
            </div>
            <div>
              <h6 class="label">Order ID</h6>
              <router-link v-if="registration.order_id" :to="`/orders/${registration.order_id}`"
                class="hover:underline">
                <p>{{ registration.order_id }}</p>
              </router-link>
              <p v-else>-</p>
            </div>
            <div>
              <h6 class="label">Class</h6>
              <router-link :to="`/classes/${registration.class_event_id}`" class="hover:underline">
                <p>{{ registration.class.sku }}</p>
              </router-link>
            </div>
            <div>
              <h6 class="label">Meal Size</h6>
              <p>{{ registration.portion_size.key | ucwords }}</p>
            </div>
            <div>
              <h6 class="label">Meal Qty</h6>
              <p>{{ registration.portion_quantity.value }}</p>
            </div>
            <div>
              <h6 class="label">Cost</h6>
              <p class="font-medium">{{ registration.total | currency }}</p>
            </div>
            <div>
              <h6 class="label">Status</h6>
              <StatusBadge class="text-xs" :registration="registration" />
            </div>
          </div>
        </div>
        <!-- Participant meal info -->
        <div class="bg-gray-100 rounded-md p-5 flex-1">
          <div v-for="selection in registration.selections" :key="selection.id">
            <div class="grid grid-cols-2 gap-2">
              <div>
                <p class="text-xs text-gray-400">Meal</p>
                <div class="flex items-center">
                  <p>{{ selection.meal.name }}</p>
                </div>
              </div>
              <div>
                <p class="text-xs text-gray-400">Quantity</p>
                <p>{{ selection.quantity }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Notes -->
      <div class="bg-gray-100 mt-5 rounded-md p-5">
        <div class="grid grid-cols-2 gap-2">
          <div>
            <p class="text-xs text-gray-400">Customer Instructions</p>
            <p v-if="registration.instructions">{{ registration.instructions }}</p>
            <p v-else>None</p>
          </div>
          <div>
            <p class="text-xs text-gray-400">Prep Instructions</p>
            <p v-if="registration.prep_instructions">
              {{ registration.prep_instructions }}
            </p>
            <p v-else>None</p>
          </div>
        </div>
        <div v-if="registration.user && registration.user.allergies.length > 0">
          <p class="text-xs text-gray-400 mt-2">User Allergies</p>
          <div class="flex items-center">
            <ul>
              <li v-for="allergy in registration.user.allergies" :key="allergy.id">
                <p>{{ allergy.name }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="hidden xl:grid grid-cols-10 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
      <h6 class="col-span-3 label">Participant</h6>
      <h6 class="label">ID</h6>
      <h6 class="label">Order ID</h6>
      <h6 class="label">SKU</h6>
      <h6 class="label">Meal Size</h6>
      <h6 class="label">Meal Qty</h6>
      <h6 class="label">Cost</h6>
      <h6 class="label">Status</h6>
    </div>
    <div class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md">
      <div v-for="registration in registrations" :key="registration.id"
        class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
        <!-- Change to radio? -->
        <Checkbox class="ml-2 mt-2 mb-2" v-if="bulkTransfer && registration.status_id == 2"
          @input="handleTransfer(registration.id, $event)" />
        <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-10 gap-2 gap-y-4 xl:px-2">
          <div class="xs:col-span-2 xl:col-span-3">
            <h6 class="label xl:hidden">Participant</h6>
            <p class="font-medium">{{ registration.guest.name | ucwords }}</p>
            <p class="break-words">
              <a :href="`mailto:${registration.guest.email}`" class="lowercase mr-2 hover:underline">
                {{ registration.guest.email }}
              </a>
              <Tooltip v-if="registration.prep_instructions"
                :message="'Prep instructions have been written for this customer'" class="mr-1">
                <template v-slot:default>
                  <i class="fas fa-check-circle text-green"></i>
                </template>
              </Tooltip>
              <Tooltip v-else-if="hasSpecialInstructions(registration)"
                :message="'This customer has special instructions'" class="mr-1">
                <template v-slot:default>
                  <i class="fas fa-exclamation-circle text-green"></i>
                </template>
              </Tooltip>
              <Tooltip v-if="registration.class.fulfillment_id == 4 && !registration.arrival_date"
                :message="'This customer has a delivery address issue.'">
                <template v-slot:default>
                  <i class="fas fa-exclamation-triangle text-red"></i>
                </template>
              </Tooltip>
              <Tooltip v-if="registration.shippo_orders && registration.shippo_orders.length > 0"
                :message="'A shippo order has been created for this customer'">
                <template v-slot:default>
                  <i class="fas fa-shipping-fast text-green"></i>
                </template>
              </Tooltip>
              <Tooltip v-if="!registration.selections_confirmed" :message="'This customer has unconfirmed selections.'">
                <template v-slot:default>
                  <i class="fas fa-exclamation-circle text-red"></i>
                </template>
              </Tooltip>
            </p>
            <p>{{ registration.guest.phone }}</p>
          </div>
          <div>
            <h6 class="label xl:hidden">ID</h6>
            <router-link :to="`/registrations/${registration.id}`" class="hover:underline">
              <p>{{ registration.id }}</p>
            </router-link>
          </div>
          <div>
            <h6 class="label xl:hidden">Order ID</h6>
            <router-link v-if="registration.order_id" :to="`/orders/${registration.order_id}`" class="hover:underline">
              <p>{{ registration.order_id }}</p>
            </router-link>
            <p v-else>-</p>
          </div>
          <div v-if="registration.class_event_id">
            <h6 class="label xl:hidden">SKU</h6>
            <router-link :to="`/classes/${registration.class_event_id}`" class="hover:underline">
              <p>{{ registration.class.sku }}</p>
            </router-link>
          </div>
          <div v-else>
            <div></div>
          </div>
          <div>
            <h6 class="label xl:hidden">Meal Size</h6>
            <p>{{ registration.portion_size.key | ucwords }}</p>
          </div>
          <div>
            <h6 class="label xl:hidden">Meal Qty</h6>
            <p>{{ registration.portion_quantity.value }}</p>
          </div>
          <div>
            <h6 class="label xl:hidden">Cost</h6>
            <p class="font-medium">{{ registration.total | currency }}</p>
          </div>
          <div>
            <h6 class="label xl:hidden">Status</h6>
            <StatusBadge class="text-xs" :registration="registration" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from '@components/registrations/StatusBadge'
import Tooltip from '@components/global/Tooltip'
import Checkbox from '@components/global/forms/Checkbox'

export default {
  props: {
    registrations: {
      type: Array,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    bulkTransfer: {
      type: Boolean,
      default: false,
    },
    showTracking: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    StatusBadge,
    Tooltip,
    Checkbox,
  },

  data() {
    return {
      selected: [],
    }
  },

  computed: {
    canUpdateRegistrations() {
      return this.$app.services.auth.hasPermissions(['registrations.update'])
    },
    allSelected() {
      return this.selected.length == this.registrations.length
    },
    subsetSelected() {
      return this.selected.length > 0 && this.selected.length != this.registrations.length
    },
  },

  methods: {
    checkRegistration(event) {
      const id = parseInt(event.target.value)
      const idx = this.selected.findIndex((existing) => existing == id)
      if (idx >= 0) this.selected.splice(idx, 1)
      else this.selected.push(id)
    },
    checkAll(event) {
      if (this.allSelected) this.selected = []
      else this.selected = this.registrations.map((reg) => reg.id)
    },
    hasSpecialInstructions(registration) {
      return (
        registration.user?.allergies?.length > 0 ||
        registration.user?.spice_tolerance === 'mild' ||
        registration.user?.allergy_notes ||
        registration.instructions
      )
    },
    handleTransfer(registrationId, isChecked) {
      this.$emit('transfer', registrationId, isChecked)
    },
  },
}
</script>

<style></style>
