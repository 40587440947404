<template>
    <span class="badge" :class="{
            'green': status === 'Published',
            'mint-green': status === 'Scheduled',
            'border border-gray bg-white': status === 'Archived'
        }"
    >
        {{ status }}
    </span>
</template>

<script>
export default {
    props: {
        blogStatus: {
            type: Object,
            required: true
        }
    },

    computed: {
        status(){
            return this.blogStatus.name
        }
    }
}
</script>

<style>

</style>
