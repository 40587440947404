<template>
  <div>
    <div v-if="loading.user" class="flex items-center space-x-5">
      <Loader class="w-10 h-10 text-green" />
      <p>Loading user details...</p>
    </div>
    <div v-else-if="user">
      <ConfirmDeleteModal
        :visible="modals.confirmDelete"
        :user-id="user.id"
        @close="modals.confirmDelete = false"
        @success="$router.back()"
      />

      <!-- Heading -->
      <div class="mb-14 flex justify-between">
        <div>
          <h2 class="mb-2">User #{{ user.id }}</h2>
          <h3>{{ user.first_name }} {{ user.last_name }}</h3>
        </div>
        <div>
          <button
            class="btn white mr-2"
            @click="
              $router.push({
                name: 'user.edit',
                params: { id: user.id }
              })
            "
          >
            <p>Edit</p>
          </button>
          <button
            class="btn red"
            @click="
              modals.confirmDelete = true;
              hide();
            "
          >
            <p><i class="fas fa-fw fa-trash mr-2"></i>Delete</p>
          </button>
        </div>
      </div>

      <!-- Details -->
      <div class="grid grid-cols-1 xs:grid-cols-2 gap-x-4 gap-y-6 mb-14">
        <div>
          <h6 class="label">Created</h6>
          <p><Date :date="user.created_at" preset="DATE_FULL" /></p>
          <p>
            <Date
              :date="user.created_at"
              preset="TIME_SIMPLE_WITH_SHORT_OFFSET"
            />
          </p>
        </div>
        <div>
          <h6 class="label">Updated</h6>
          <p><Date :date="user.updated_at" preset="DATE_FULL" /></p>
          <p>
            <Date
              :date="user.updated_at"
              preset="TIME_SIMPLE_WITH_SHORT_OFFSET"
            />
          </p>
        </div>
        <div class="col-span-2">
          <h6 class="label">Email</h6>
          <p>{{ user.email }}</p>
        </div>
        <div class="col-span-2">
          <h6 class="label">Phone</h6>
          <p>{{ user.phone }}</p>
        </div>
        <div class="col-span-2">
          <h6 class="label">Role</h6>
          <div class="flex items-center">
            <multiselect
              class="max-w-xs"
              v-model="selectedRole"
              :options="roles"
              :loading="loading.roles"
              :disabled="loading.roles"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              label="display_name"
              track-by="role_id"
              placeholder="Select role"
            >
              <template slot="placeholder">
                <span class="text-gray-400">Select role</span>
              </template>
              <template #caret="{toggle}">
                <div
                  class="multiselect__select"
                  @mousedown.prevent.stop="toggle"
                >
                  <span class="absolute right-3 top-3 select-none text-green">
                    <i class="fas fa-chevron-down"></i>
                  </span>
                </div>
              </template>
            </multiselect>
            <button
              v-if="selectedRole && Object.keys(selectedRole).length > 0"
              class="btn white ml-2"
              :disabled="!selectedRole || selectedRole.length === 0"
              @click="clearRole"
            >
              Clear
            </button>
            <button class="btn ml-2" :disabled="!roleChanged" @click="saveRole">
              Save&nbsp;Role
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>
        <i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i
        >Whoops... something went wrong!
      </p>
    </div>
  </div>
</template>

<script>
import Loader from "@components/global/Loader";
import Date from "@components/global/dates/Date";
import Multiselect from "vue-multiselect";

import ConfirmDeleteModal from "@components/users/modals/ConfirmDelete";

export default {
  components: {
    Loader,
    Date,
    ConfirmDeleteModal,
    Multiselect
  },

  metaInfo() {
    return {
      title: this.user?.id ? `User #${this.user?.id}` : "User Details"
    };
  },

  data() {
    return {
      loading: {
        user: false,
        roles: false
      },
      modals: {
        confirmDelete: false
      },
      user: null,
      roles: [],
      selectedRole: null,
      roleChanged: false
    };
  },
  watch: {
    selectedRole(newRole, oldRole) {
      this.roleChanged = newRole?.role_id !== this.user.roles[0]?.role_id;
    }
  },
  computed: {
    canDeleteUser() {
      return this.$app.services.auth.hasPermission("users.delete");
    }
  },

  methods: {
    async getRoles() {
      this.loading.roles = true;
      return this.$app.api.roles
        .getRoles()
        .then(res => {
          this.roles = res.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.loading.roles = false));
    },

    async getUser() {
      this.loading.user = true;
      return this.$app.api.users
        .getUser(this.$route.params.id)
        .then(res => {
          this.user = res.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.loading.user = false));
    },

    async saveRole() {
      if (this.roleChanged) {
        let data = {
          roles: this.selectedRole.role_id
            ? [{ role_id: this.selectedRole.role_id }]
            : []
        };
        return this.$app.api.users
          .updateUser(this.$route.params.id, data)
          .then(res => {
            this.user = res.data;
            this.user.roles = this.roles.filter(role => {
              return this.user.roles.includes(role.name);
            });
            this.roleChanged = false;
          })
          .catch(err => {
            console.error(err);
          });
      }
    },

    clearRole() {
      this.selectedRole = [];
    }
  },

  async created() {
    await Promise.all([this.getRoles(), this.getUser()]);
    this.user.roles = this.roles.filter(role => {
      return this.user.roles.includes(role.name);
    });
    this.selectedRole = this.user.roles[0];
  }
};
</script>

<style></style>
