<template>
    <div>
        <div v-if="promotion.loading">
            <div class="flex items-center space-x-5">
                <Loader class="w-10 h-10 text-green"/>
                <p>Loading promotion...</p>
            </div>
        </div>
        <div v-else-if="promotion.data">
            <!-- Create Codes -->
            <CreateCodesModal
                :visible="modals.createCodes"
                @close="modals.createCodes = false"
                @update="updateCodes"
            />

            <!-- Edit Code -->
            <EditCodeModal
                v-if="selectedCode"
                :visible="modals.editCode"
                :value="selectedCode"
                @close="modals.editCode = false; selectedCode = null;"
                @update="updateCodes"
            />

            <!-- Confirm Delete Code -->
            <ConfirmDeleteCodeModal
                :visible="modals.deleteCode"
                :code="selectedCode"
                @close="modals.deleteCode = false; selectedCode = null;"
                @update="updateCodes"
            />

            <!-- Confirm Delete Promotion -->
            <ConfirmDeletePromotionModal
                :visible="modals.deletePromotion"
                :promotion="promotion.data"
                @close="modals.deletePromotion = false"
                @update="deleteSuccess"
            />

            <!-- Promotion -->
            <div class="mb-14 flex justify-between">
                <div>
                    <h2 class="mb-2">{{ promotion.data.name }}</h2>
                    <p><StatusBadge :promotion="promotion.data"/></p>
                </div>
                <div v-if="!promotion.editing && canPerformActions">
                    <button v-if="canPerformEdit" class="btn white px-6 mr-2" @click="editPromotion">Edit</button>
                    <button v-if="canPerformDelete" class="btn gray px-6" :disabled="!canDeletePromotion" @click="deletePromotion(promotion)">Delete</button>
                </div>
            </div>
            <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-6 mb-14">
                <div>
                    <h6 class="label">Display Name:</h6>
                    <p>{{ promotion.data.display_name }}</p>
                </div>
                <div>
                    <h6 class="label">Description:</h6>
                    <p>{{ promotion.data.description }}</p>
                </div>
                <div>
                    <h6 class="label">Terms:</h6>
                    <p>{{ promotion.data.terms }}</p>
                </div>
                <div>
                    <h6 class="label">Discount:</h6>
                    <p>{{ discount }}</p>
                </div>
                <div v-if="promotion.data.start">
                    <h6 class="label">Start Date:</h6>
                    <template v-if="promotion.data.start">
                        <p><Date :date="promotion.data.start" preset="DATE_FULL"/></p>
                        <p><Date :date="promotion.data.start" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
                    </template>
                </div>
                <div v-if="promotion.data.end">
                    <h6 class="label">End Date:</h6>
                    <template v-if="promotion.data.start">
                        <p><Date :date="promotion.data.end" preset="DATE_FULL"/></p>
                        <p><Date :date="promotion.data.end" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
                    </template>
                </div>
                <div>
                    <h6 class="label">Use Count:</h6>
                    <p>{{ promotion.data.uses }}</p>
                </div>
                <div v-if="promotion.data.max_uses">
                    <h6 class="label">Max Uses:</h6>
                    <p>{{ promotion.data.max_uses }}</p>
                </div>
                <div v-if="promotion.data.min_total">
                    <h6 class="label">Min Total:</h6>
                    <p>{{ promotion.data.min_total }}</p>
                </div>
                <div v-if="maxDiscount">
                    <h6 class="label">Max Discount:</h6>
                    <p>{{ promotion.data.max_discount | currency}}</p>
                </div>
            </div>

            <!-- Codes -->
            <div class="mb-14">
                <div class="flex justify-between items-center mb-6">
                    <h3><i class="fas fa-fw fa-tags mr-2"></i>Codes</h3>
                    <button v-if="canCreateCodes" class="btn small white" :disabled="!canCreateCodes" @click="createCodes"><i class="fas fa-fw fa-plus"></i></button>
                </div>

                <!-- Unlimited Use Codes -->
                <div class="mb-6">
                    <div class="flex justify-between items-center mb-2">
                        <h5>Unlimited Use</h5>
                    </div>
                    <template v-if="unlimitedCodes.loading">
                        <div class="flex items-center space-x-5">
                            <Loader class="w-10 h-10 text-green"/>
                            <p>Loading codes...</p>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="unlimitedCodes.data && unlimitedCodes.data.length">
                            <CodesListView :codes="unlimitedCodes.data" @edit="editCode" @delete="deleteCode" class="mb-2"/>
                            <Pagination
                                v-if="unlimitedTotal > unlimitedCodes.perPage"
                                :page="unlimitedCodes.page" :per-page="unlimitedCodes.perPage"
                                :total-items="unlimitedTotal" @change="unlimitedUpdated"
                                :pageQueryString="unlimitedCodes.pageQueryString"
                                :perPageQueryString="unlimitedCodes.perPageQueryString"
                            />
                        </div>
                        <div v-else>
                            <p class="text-gray-400">No unlimited use promotion codes found.</p>
                        </div>
                    </template>
                </div>

                <!-- Limited Use Codes -->
                <div class="mb-6">
                    <div class="flex justify-between items-center mb-2">
                        <h5>Limited Use</h5>
                    </div>
                    <template v-if="limitedCodes.loading">
                        <div class="flex items-center space-x-5">
                            <Loader class="w-10 h-10 text-green"/>
                            <p>Loading codes...</p>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="limitedCodes.data && limitedCodes.data.length">
                            <CodesListView :codes="limitedCodes.data" @edit="editCode" @delete="deleteCode" class="mb-2"/>
                            <Pagination
                                v-if="limitedTotal > limitedCodes.perPage"
                                :page="limitedCodes.page" :per-page="limitedCodes.perPage"
                                :total-items="limitedTotal" @change="limitedUpdated"
                                :pageQueryString="limitedCodes.pageQueryString"
                                :perPageQueryString="limitedCodes.perPageQueryString"
                            />
                        </div>
                        <div v-else>
                            <p class="text-gray-400">No limited use promotion codes found.</p>
                        </div>
                    </template>
                </div>
            </div>

            <!-- Orders -->
            <div class="mb-14">
                <div class="flex justify-between items-center mb-6">
                    <h3><i class="fas fa-fw fa-file-invoice-dollar mr-2"></i>Orders</h3>
                </div>
                <template v-if="ordersCodes.loading">
                    <div class="flex items-center space-x-5">
                        <Loader class="w-10 h-10 text-green"/>
                        <p>Loading orders...</p>
                    </div>
                </template>
                <template v-else>
                    <div v-if="ordersCodes.data && ordersCodes.data.length">
                        <OrdersCodesListView  :discounts="ordersCodes.data"/>
                        <Pagination
                            v-if="ordersTotal > ordersCodes.perPage"
                            :page="ordersCodes.page" :per-page="ordersCodes.perPage"
                            :total-items="ordersTotal" @change="ordersUpdated"
                            :pageQueryString="ordersCodes.pageQueryString"
                            :perPageQueryString="ordersCodes.perPageQueryString"
                        />
                    </div>
                    <div v-else>
                        <p class="text-gray-400">No promotion code usage found.</p>
                    </div>
                </template>
            </div>
        </div>
        <div v-else>
            <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/global/Loader'
import Date from '@components/global/dates/Date'
import StatusBadge from '@components/promotions/StatusBadge'
import ActionsMenu from '@components/promotions/ActionsMenu'
import CodesListView from '@components/promotions/codes/ListView'
import OrdersCodesListView from '@components/promotions/orders/ListView'
import Pagination from '@/components/global/Pagination'
import ConfirmDeleteCodeModal from '@/components/promotions/codes/modals/ConfirmDeleteCodeModal'
import ConfirmDeletePromotionModal from '@/components/promotions/modals/ConfirmDeletePromotionModal'
import CreateCodesModal from '@/components/promotions/codes/modals/CreateCodesModal'
import EditCodeModal from '@/components/promotions/codes/modals/EditCodeModal'
import PromotionForm from '@/components/promotions/PromotionForm';

export default {
    name: "Promotion",

    props: {},

    components: {
        PromotionForm,
        ConfirmDeleteCodeModal,
        ConfirmDeletePromotionModal,
        EditCodeModal,
        CreateCodesModal,
        Pagination,
        Loader,
        StatusBadge,
        ActionsMenu,
        CodesListView,
        OrdersCodesListView,
        Date,
    },

    metaInfo() {},

    data() {
        return {
            promotion: {
                loading: false,
                data: {}
            },
            selectedCode: null,
            limitedCodes: {
                loading: false,
                data: [],
                meta: {},
                page: parseInt(this.$route.query.l_page) || 1,
                perPage: parseInt(this.$route.query.l_per_page) || 5,
                pageQueryString: 'l_page',
                perPageQueryString: 'l_per_page',
            },
            unlimitedCodes: {
                loading: false,
                data: [],
                meta: {},
                page: parseInt(this.$route.query.u_page) || 1,
                perPage: parseInt(this.$route.query.u_per_page) || 5,
                pageQueryString: 'u_page',
                perPageQueryString: 'u_per_page',
            },
            ordersCodes: {
                loading: false,
                data: [],
                meta: {},
                page: parseInt(this.$route.query.o_page) || 1,
                perPage: parseInt(this.$route.query.o_per_page) || 10,
                pageQueryString: 'o_page',
                perPageQueryString: 'o_per_page',
            },
            modals: {
                createCodes: false,
                editCode: false,
                deleteCode: false,
                deletePromotion: false,
            },
        }
    },

    computed: {
        canCreateCodes() {
            return this.$app.services.auth.hasOneOfPermissions(['promotions.codes.create'])
        },
        canPerformEdit() {
            return this.$app.services.auth.hasOneOfPermissions(['promotions.update'])
        },
        canPerformDelete() {
            return this.$app.services.auth.hasOneOfPermissions(['promotions.delete'])
        },
        canPerformActions() {
            return this.$app.services.auth.hasOneOfPermissions(['promotions.update', 'promotions.delete'])
        },
        canDeletePromotion() {
            return !this.unlimitedCodes.loading && !this.limitedCodes.loading && this.canPerformDelete && this.unlimitedTotal === 0 && this.limitedTotal === 0
        },
        limitedTotal() {
            return this.limitedCodes.meta?.total ?? 0
        },
        unlimitedTotal() {
            return this.unlimitedCodes.meta?.total ?? 0
        },
        ordersTotal() {
            return this.ordersCodes.meta?.total ?? 0
        },
        discount() {
            if(!this.promotion.data.discount_percent || this.promotion.data?.discount_percent === '0.00') return `$${this.promotion.data.discount_amount}`
            else return `${parseInt(this.promotion.data.discount_percent)}%`
        },
        maxDiscount() {
            return this.promotion.data.discount_percent && this.promotion.data?.discount_percent !== '0.00';
        },
        promotionId() {
            return parseInt(this.$route.params.id)
        },
    },

    methods: {
        limitedUpdated(page) {
            this.limitedCodes.page = page
            this.getLimitedCodes()
        },
        unlimitedUpdated(page) {
            this.unlimitedCodes.page = page
            this.getUnlimitedCodes()
        },
        ordersUpdated(page) {
            this.ordersCodes.page = page
            this.getOrdersCodes()
        },
        getPromotion() {
            this.promotion.loading = true

            this.$app.api.promotions.getPromotion(this.promotionId)
                .then((response) => {
                    this.promotion.data = response.data
                    this.getLimitedCodes()
                    this.getUnlimitedCodes()
                    this.getOrdersCodes()
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.promotion.loading = false
                })
        },
        getUnlimitedCodes() {
            let params = {
                type: 'unlimited',
                page: this.$route.query.u_page || this.unlimitedCodes.page,
                per_page: this.$route.query.u_per_page || this.unlimitedCodes.perPage,
            }

            this.unlimitedCodes.loading = true
            this.$app.api.promotions.getCodes(this.promotionId, params)
                .then((response) => {
                    this.unlimitedCodes.data = response.data.data
                    this.unlimitedCodes.meta = response.data.meta
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.unlimitedCodes.loading = false
                })
        },
        getLimitedCodes() {
            let params = {
                type: 'limited',
                page: this.$route.query.l_page || this.limitedCodes.page,
                per_page: this.$route.query.l_per_page || this.limitedCodes.perPage,
            }

            this.limitedCodes.loading = true
            this.$app.api.promotions.getCodes(this.promotionId, params)
                .then((response) => {
                    this.limitedCodes.data = response.data.data
                    this.limitedCodes.meta = response.data.meta
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.limitedCodes.loading = false
                })
        },
        getOrdersCodes() {
            let params = {
                page: this.$route.query.o_page || this.ordersCodes.page,
                per_page: this.$route.query.o_per_page || this.ordersCodes.perPage,
            }

            this.ordersCodes.loading = true
            this.$app.api.promotions.getOrders(this.promotionId, params)
                .then((response) => {
                    this.ordersCodes.data = response.data.data
                    this.ordersCodes.meta = response.data.meta
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.ordersCodes.loading = false
                })
        },
        createCodes() {
            this.modals.createCodes = true
        },
        editCode(code) {
            this.selectedCode = code
            this.modals.editCode = true
        },
        deleteCode(code) {
            this.selectedCode = code
            this.modals.deleteCode = true
        },
        deletePromotion() {
            this.modals.deletePromotion = true
        },
        deleteSuccess() {
            this.$router.push('/promotions')
        },
        updateCodes(code) {
            // this.limitedCodes.data = this.limitedCodes.data.map((item) => {
            //     if(item.deleted) return {...item}
            //     else if(item.id === code.id) return {...item, deleted: true}
            //     return { ...item }
            // })
            //
            // this.unlimitedCodes.data = this.unlimitedCodes.data.map((item) => {
            //     if(item.deleted) return {...item}
            //     else if(item.id === code.id) return {...item, deleted: true}
            //     return { ...item }
            // })

            // TODO: rework code updates
            this.getLimitedCodes()
            this.getUnlimitedCodes()
        },
        editPromotion() {
            this.$router.push(`/promotions/${this.promotionId}/edit`)
        },
    },

    created() {
        this.getPromotion()
    }
}
</script>

<style scoped>

</style>
