<template>
    <div>
        <div class="hidden lg:grid grid-cols-3 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <p class="text-xs text-gray-400 font-medium">Name</p>
            <p class="text-xs text-gray-400 font-medium">Email</p>
            <p class="text-xs text-gray-400 font-medium">Phone</p>
        </div>
        <div class="lg:divide-y lg:divide-gray-200 lg:border lg:border-t-0 border-gray-200 lg:border-gray-200 rounded-b-md">
            <div v-for="guest in guests" :key="guest.id" class="bg-gray-100 px-5 py-3 lg:mb-0 rounded-md lg:rounded-none lg:bg-transparent p-5 lg:px-0 lg:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-3 gap-2 lg:px-2">
                    <div>
                        <p class="text-xs text-gray-400 lg:hidden">Name</p>
                        <p>{{ guest.first_name }} {{ guest.last_name }}</p>
                    </div>
                    <div>
                        <p class="text-xs text-gray-400 lg:hidden">Email</p>
                        <p class="lowercase">{{ guest.email }}</p>
                    </div>
                    <div >
                        <p class="text-xs text-gray-400 lg:hidden">Phone</p>
                        <p>{{ guest.phone }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        guests: {
            type: Array,
            required: true
        }
    },

    data(){
        return {
        }
    },

    computed: {
        canUpdateRegistrations(){
            return this.$app.services.auth.hasPermissions([
                'registrations.update'
            ])
        },
    },
}
</script>

<style>

</style>
