const routes = [
    {
        path: '/classes',
        name: 'classes',
        component: () => import('@views/classes/Classes.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['classes.view']
        }
    },
    {
        path: '/classes/create',
        name: 'class.create',
        component: () => import('@views/classes/CreateClass.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['classes.create']
        }
    },
    {
        path: '/classes/:sku/edit',
        name: 'class.edit',
        component: () => import('@views/classes/CreateClass.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['classes.update']
        }
    },
    {
        path: '/classes/:sku',
        name: 'class',
        component: () => import('@views/classes/Class.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['classes.view']
        }
    },
]

export default routes
