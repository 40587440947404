// helpers.js

export function generateSKU(date, locationCode, skuFormat = "YY-MMDD-LC") {
  if (!date || !locationCode) {
    return "Error";
  }

  let formattedDate;
  if (date.format) {
    formattedDate = date.format("YY-MMDD");
  } else {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear().toString().slice(-2);
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    formattedDate = `${year}-${month}${day}`;
  }

  return skuFormat
    .replace(/YY-MMDD/, formattedDate)
    .replace(/LC/, locationCode);
}

export function formatDateToMonthDay(date) {
  const options = { month: "long", day: "numeric" };
  let formattedDate = new Date(date).toLocaleDateString(undefined, options);

  // Add the ordinal suffix to the day
  const day = new Date(date).getDate();
  if (day % 10 === 1 && day !== 11) {
    formattedDate = formattedDate.replace(day, `${day}st`);
  } else if (day % 10 === 2 && day !== 12) {
    formattedDate = formattedDate.replace(day, `${day}nd`);
  } else if (day % 10 === 3 && day !== 13) {
    formattedDate = formattedDate.replace(day, `${day}rd`);
  } else {
    formattedDate = formattedDate.replace(day, `${day}th`);
  }

  return formattedDate;
}
