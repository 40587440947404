import Resource from './resource'

export default class Allergies extends Resource {

    /**
     *
     * @param {object} params
     */
    getAllergies(params = {}) {
        return this.axios.get(`allergies`, { params })
    }

}
