const routes = [
    {
        path: '/gift-cards',
        name: 'gc-list',
        component: () => import('@views/giftcards/GiftCards.vue'),
    },
    {
        path: '/gift-cards/create',
        name: 'gift-card-create',
        component: () => import('@views/giftcards/CreateGiftCard.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['giftcards.create']
        }
    },
    {
        path: '/gift-cards/:id',
        name: 'gc-detail',
        component: () => import('@views/giftcards/GiftCard.vue'),
    },
]

export default routes
