<template>
    <div>
        <div class="mb-5">
            <h3>Refund Payment</h3>
        </div>
        <div v-if="loading.payment" class="flex items-center space-x-5">
            <Loader class="w-10 h-10 text-green"/>
            <p>Loading payment details...</p>
        </div>
        <div v-else-if="payment">
            <div class="mb-10 grid xs:grid-cols-2 lg:grid-cols-4 max-w-screen-lg gap-5 p-5 bg-gray-100 rounded-md">
                <div>
                    <h6 class="label">Refundable Amount</h6>
                    <p class="font-semibold">{{ payment.refundable | currency }}</p>
                </div>
                <div>
                    <h6 class="label">Payment Total</h6>
                    <p>{{ payment.amount | currency }}</p>
                </div>
                <div>
                    <h6 class="label">Service</h6>
                    <p>{{ payment.service.name }}</p>
                </div>
                <div>
                    <h6 class="label">Method</h6>
                    <p>{{ payment.type.name }}</p>
                </div>
            </div>
            <div class="mb-14" v-if="payment.refunds && payment.refunds.length">
                <div class="flex space-x-2 items-center mb-2">
                    <h4><i class="fas fa-fw fa-hand-holding-usd mr-2"></i>Refunds</h4>
                    <p class="text-sm font-semibold rounded-2xl bg-mint px-3">{{ payment.refunds.length }}</p>
                </div>
                <RefundsListView :refunds="payment.refunds"/>
            </div>
            <template v-if="refundableAmount.toUnit() > 0">
                <div class="mb-5">
                    <h6 class="label mb-1">Refund Method</h6>
                    <Radio value="original" v-model="refundMethod" label="Original Payment Method"/>
                    <Radio v-if="canRefundToGiftCard" value="gift-card" v-model="refundMethod" label="Gift Card"/>
                    <Radio value="external" v-model="refundMethod" label="External"/>
                </div>
                <template v-if="refundMethod == 'external'">
                    <div class="mb-5">
                        <h6 class="label">External Transaction ID</h6>
                        <input class="w-full max-w-xs" v-model="$v.externalTransactionId.$model" :class="{error: $v.externalTransactionId.$invalid}" placeholder="Enter external transaction ID"/>
                        <p v-if="$v.externalTransactionId.$invalid" class="text-red text-xs">An external transaction ID is required.</p>
                    </div>
                    <div class="mb-5">
                        <h6 class="label">Refund Service</h6>
                        <multiselect
                            class="max-w-xs"
                            v-model="$v.service.$model"
                            :options="services"
                            :loading="loading.services"
                            :disabled="loading.services"
                            :searchable="false"
                            :close-on-select="true"
                            :show-labels="false"
                            :allow-empty="false"
                            label="name"
                            track-by="id"
                            placeholder="Select a service"
                        >
                            <template slot="placeholder"><span class="text-gray-400">Select a service</span></template>
                            <template #caret="{toggle}">
                                <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                    <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                    <div class="mb-5">
                        <h6 class="label">Refund Method</h6>
                        <multiselect
                            class="max-w-xs"
                            v-model="$v.type.$model"
                            :options="types"
                            :loading="loading.types"
                            :disabled="loading.types"
                            :searchable="false"
                            :close-on-select="true"
                            :show-labels="false"
                            :allow-empty="false"
                            label="name"
                            track-by="id"
                            placeholder="Select a type"
                        >
                            <template slot="placeholder"><span class="text-gray-400">Select a method</span></template>
                            <template #caret="{toggle}">
                                <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                    <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                </template>
                <div class="mb-5">
                    <h6 class="label">Refund Amount</h6>
                    <money class="px-3" :class="{'error': !validAmount}" v-model="amount" v-bind="money"/>
                    <p v-if="!validAmount" class="text-red text-xs">Invalid amount!</p>
                </div>
                <div class="mb-5">
                    <h6 class="label">Reason</h6>
                    <multiselect
                        class="max-w-xs"
                        v-model="$v.reason.$model"
                        :options="reasons"
                        :loading="loading.reasons"
                        :disabled="loading.reasons"
                        :searchable="false"
                        :close-on-select="true"
                        :show-labels="false"
                        :allow-empty="false"
                        label="name"
                        track-by="id"
                        placeholder="Select a reason"
                    >
                        <template slot="placeholder"><span class="text-gray-400">Select a reason</span></template>
                        <template #caret="{toggle}">
                            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
                            </div>
                        </template>
                    </multiselect>
                </div>
                <div class="mb-10">
                    <h6 class="label">Notes</h6>
                    <textarea class="px-3 w-full text-sm max-w-xs" rows="4" v-model="description" placeholder="Enter any additional details..."/>
                </div>
                <div class="flex space-x-5">
                    <button class="btn white px-6" @click="$router.back()">Cancel</button>
                    <button class="btn px-6" @click="processRefund" :disabled="loading.refund || !canRefund">
                        <div v-if="loading.refund" class="flex items-center"><Loader class="w-5 mr-1"/><span>Processing</span></div>
                        <span v-else>Refund</span>
                    </button>
                </div>
            </template>
            <div v-else>
                <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>This payment has already been refunded.</p>
            </div>
        </div>
        <div v-else>
            <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
        </div>
    </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import currency from '@shared/utils/currency'
import Loader from '@components/global/Loader'
import Radio from '@components/global/forms/Radio'
import RefundsListView from '@components/payments/refunds/ListView'

export default {
    components: {
        Loader,
        Radio,
        RefundsListView,
    },

    metaInfo(){
        return {
            title: this.payment?.id ? `Refund Payment: #${this.payment.id}` : 'Refund Payment'
        }
    },

    data(){
        return {
            loading: {
                payment: false,
                refund: false,
                services: false,
                types: false,
                reasons: false
            },
            payment: null,
            success: false,
            refundMethod: 'original',
            externalTransactionId: '',
            amount: 0,
            description: '',
            reason: null,
            service: null,
            type: null,
            services: [],
            types: [],
            reasons: [],
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false
            }
        }
    },

    computed: {
        permissionToRefund(){
            return this.$app.services.auth.hasPermission('payments.refund')
        },
        canRefund(){
            return this.permissionToRefund &&
                this.validAmount &&
                this.refundableAmount.toUnit() > 0 &&
                !this.$v.$invalid
        },
        canRefundToGiftCard(){
            return this.payment?.service?.key != 'gift-card'
        },
        validAmount(){
            let inputAmount = currency(`${this.amount}`)
            return inputAmount.greaterThan(currency(0)) && inputAmount.lessThanOrEqual(currency(this.payment.amount))
        },
        refundableAmount(){
            return currency(this.payment.refundable)
        }
    },

    methods: {
        getPayment(){
            this.loading.payment = true
            return this.$app.api.payments.getPayment(this.$route.params.id).then(res => {
                this.payment = res.data
                this.amount = this.payment.refundable
                this.service = this.payment.service
                this.type = this.payment.type
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading.payment = false)
        },
        getPaymentServices(){
            this.loading.services = true
            return this.$app.api.payments.getPaymentServices().then(res => {
                this.services = res.data
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading.services = false)
        },
        getPaymentTypes(){
            this.loading.types = true
            return this.$app.api.payments.getPaymentTypes().then(res => {
                this.types = res.data
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading.types = false)
        },
        getReasons(){
            this.loading.reasons = true
            return this.$app.api.payments.getRefundReasons().then(res => {
                this.reasons = res.data
                this.reason = this.reasons?.[0] ?? null
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading.reasons = false)
        },
        processRefund(){
            let data = {
                reason_id: this.reason.id,
                refund_method: this.refundMethod,
                amount: this.amount,
                description: this.description
            }

            if(this.refundMethod == 'external'){
                data.external_transaction_id = this.externalTransactionId,
                data.service_id = this.service.id
                data.type_id = this.type.id
            }

            this.loading.refund = true
            return this.$app.api.payments.refund(this.payment.id, data).then(res => {
                this.success = true
                this.payment = res.data
                this.amount = this.payment.refundable
                this.$router.back()
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading.refund = false)
        },
    },

    created(){
        this.getPayment()
        this.getPaymentServices()
        this.getPaymentTypes()
        this.getReasons()
    },

    validations: {
        amount: { required },
        reason: { required },
        service: { required: requiredIf(function(){
            return this.refundMethod == 'external'
        })},
        type: { required: requiredIf(function(){
            return this.refundMethod == 'external'
        })},
        externalTransactionId: { required: requiredIf(function(){
            return this.refundMethod == 'external'
        })}
    }
}
</script>

<style lang="scss" scoped>
</style>
