export default {
    methods: {
        downloadCsv(title, data) {
            const blob = new Blob([data], { type: 'text/csv' });
            const link = document.createElement('a');

            link.setAttribute('download', title + '.csv');
            link.href = window.URL.createObjectURL(blob);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
};
