<template>
    <div class="flex flex-col p-6 w-full">
        <div class="mb-8 flex justify-between space-x-3">
            <h3>Employee Links</h3>
        </div>
        <div class="flex flex-col">
            <input v-model="name" type="text" placeholder="Name" class="p-2 border
                        border-gray-300 rounded-md w-3/4 mb-2" />
            <input v-model="link" type="text" placeholder="URL" class="p-2 border
                        border-gray-300 rounded-md w-3/4 mb-2" />
            <button class="btn btn-green text-sm mb-8 w-20" @click="handleSave">Save</button>
        </div>
        <div class="flex flex-col items-center md:items-start pb-5 bg-white">
            <div class="md:w-[50%] w-full">
                <div v-if="links.length" class="flex flex-col gap-5">
                    <div v-for="link in links" :key="link.id" class="ml-2 flex flex-col gap-2">
                        <div class="flex flex-row items-center justify-between">
                            <div class="flex flex-row items-center gap-2">
                                <!-- add a delete button -->
                                <i class="fas fa-trash text-red cursor-pointer" @click="deleteLink(link.id)"></i>
                                <i class="fas fa-link text-green-800"></i>
                                <a :href="link.link" target="_blank" class="text-green-800">{{ link.name }}</a>
                            </div>
                        </div>
                        <p>{{ link.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmployeeLinks",

    data() {
        return {
            name: '',
            link: '',
            links: [],
        }
    },

    methods: {
        getLinks() {
            this.$app.api.accounts.getEmployeeLinks()
                .then((response) => {
                    this.links = response.data
                })
        },
        deleteLink(id) {
            this.$app.api.accounts.deleteEmployeeLink(id)
                .then(() => {
                    this.getLinks()
                })
        },
        handleSave() {
            this.$app.api.accounts.createEmployeeLink({
                name: this.name,
                link: this.link
            }).then(() => {
                this.name = ''
                this.link = ''
                this.getLinks()
            })
        }
    },

    created() {
        this.getLinks()
    }
}
</script>