const routes = [
    {
        path: '/locations',
        name: 'locations',
        component: () => import('@views/locations/Locations.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['locations.view']
        }
    },
    {
        path: '/locations/create',
        name: 'location-create',
        component: () => import('@views/locations/CreateLocation.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['locations.create']
        }
    },
    {
        path: '/locations/:id',
        name: 'location',
        component: () => import('@views/locations/Location.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['locations.view']
        }
    },
    {
        path: '/locations/:id/edit',
        name: 'location-edit',
        component: () => import('@views/locations/EditLocation.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['locations.update']
        }
    },
    {
        path: '/vendors',
        name: 'vendors',
        component: () => import('@views/locations/Vendors.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['locations.create']
        }
    },
]

export default routes
