<template>
    <div v-if="classes">
        <ClassListView :headers="headers">
            <div v-for="classEvent in classes" :key="classEvent.id" class="rounded-md xl:rounded-none mb-5 xl:mb-0" :class="[isSelected(classEvent) ? 'ring-2 ring-green xl:ring-inset' : 'ring-0']">
                <ClassCard :classEvent="classEvent" :links="false" @select="selectClass(classEvent)"/>
            </div>
        </ClassListView>
    </div>
</template>

<script>
import StatusBadge from '@components/classes/StatusBadge'
import ProgressRing from '@components/global/ProgressRing'
import Date from '@components/global/dates/Date'
import ClassListView from '@components/classes/ListView'
import ClassCard from '@components/registrations/update/classes/ClassCard'

export default {
    components: {
        StatusBadge,
        ProgressRing,
        Date,
        ClassListView,
        ClassCard,
    },

    props: {
        classes: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            selected: null,
            headers: [
                'SKU',
                'Date',
                'Location',
                'Lead',
                'Type',
                'Capacity',
                'Status',
                ''
            ]
        }
    },

    computed: {},

    methods: {
        selectClass(value) {
            this.selected = value
            this.$emit('selected', this.selected)
        },
        isSelected(value) {
            if(this.selected) return value.id === this.selected.id
            return false
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
