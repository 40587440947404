<template>
  <div>
    <div class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
      <h6 v-for="column in visibleColumns" :key="column" v-if="isColumnVisible(column)"
        :class="`xl:col-span-${colSpanValue} label`">
        {{ column }}
      </h6>
    </div>
    <div class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md">
      <div v-for="order in orders" :key="order.id"
        class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
        <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
          <div v-if="isColumnVisible('Location')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Location</h6>
            <p v-if="order.registrations && order.registrations.length">
              {{ order.registrations[0].class.location.name }},
              {{
                order.registrations[0].class.location.address
                  .administrative_area
              }}
            </p>
            <p v-else>None</p>
          </div>

          <div v-if="isColumnVisible('ID')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">ID</h6>
            <router-link class="hover:underline block mr-2" :to="`/orders/${order.id}`">
              <p class="font-medium">{{ order.id }}</p>
            </router-link>
          </div>

          <div v-if="isColumnVisible('SKU')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">SKU</h6>
            <p v-if="order.registrations && order.registrations.length">
              {{ order.registrations[0].class.sku }}
            </p>
            <p v-else>None</p>
          </div>

          <div v-if="isColumnVisible('Customer')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Customer</h6>
            <p>{{ order.customer && order.customer.first_name }} {{ order.customer && order.customer.last_name }}</p>
          </div>

          <div v-if="isColumnVisible('Contact')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Contact</h6>
            <p class="break-words">{{ order.customer && order.customer.email }}</p>
            <p>{{ order.customer && order.customer.phone }}</p>
          </div>

          <div v-if="isColumnVisible('Date')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Date</h6>
            <p>
              <Date :date="order.created_at" preset="DATE_FULL" />
            </p>
            <p>
              <Date :date="order.created_at" preset="TIME_SIMPLE_WITH_SHORT_OFFSET" />
            </p>
          </div>

          <div v-if="isColumnVisible('Updated')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Updated</h6>
            <p>
              <Date :date="order.updated_at" preset="DATE_FULL" />
            </p>
            <p>
              <Date :date="order.updated_at" preset="TIME_SIMPLE_WITH_SHORT_OFFSET" />
            </p>
          </div>

          <div v-if="isColumnVisible('Payment Method')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Payment Method</h6>

            <div v-for="payment in order.payments" :key="payment.id">
              <p>{{ payment.type.name }}</p>
            </div>
          </div>

          <div v-if="isColumnVisible('Tax')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Tax</h6>
            <p>{{ order.totals.taxes | currency }}</p>
          </div>

          <div v-if="isColumnVisible('Discount')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Discount</h6>
            <p>{{ order.totals.discounts | currency }}</p>
          </div>

          <div v-if="isColumnVisible('Subtotal')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Sobtotal</h6>
            <p>{{ order.totals.subtotal | currency }}</p>
          </div>

          <div v-if="isColumnVisible('Total')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Total</h6>
            <p>{{ order.totals.total | currency }}</p>
          </div>

          <div v-if="isColumnVisible('Refunded')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Refunded</h6>
            <p>{{ order.totals.refunds | currency }}</p>
          </div>

          <div v-if="isColumnVisible('Status')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Status</h6>
            <p>
              <StatusBadge class="text-xs" :status="order.status" />
            </p>
          </div>

          <div v-if="isColumnVisible('Coupon Code')" :class="`xl:col-span-${colSpanValue}`">
            <h6 class="label xl:hidden">Coupon Code</h6>

            <div v-for="discount in order.discounts" :key="discount.promotion.id">
              <router-link class="hover:underline block" :to="`/promotions/${discount.promotion.id}`">
                <p>
                  <span class="badge medium">
                    {{ discount.promotion_code.key }}
                  </span>
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@components/orders/StatusBadge";
import Date from "@components/global/dates/Date";

export default {
  props: {
    orders: {
      type: Array,
      required: true
    },
    visibleColumns: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  components: {
    StatusBadge,
    Date
  },

  methods: {
    isColumnVisible(columnKey) {
      return this.visibleColumns.includes(columnKey);
    }
  },

  computed: {
    totalVisibleColumns() {
      return this.visibleColumns.length;
    },

    colSpanValue() {
      return Math.floor(12 / this.totalVisibleColumns);
    }
  }
};
</script>

<style></style>
