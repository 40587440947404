<template>
    <div>
        <div class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="col-span-3 label">Name</h6>
            <h6 v-for="variant in variants" :key="variant.id" class="col-span-3 label">{{ variant.size.name }}</h6>
        </div>
        <div class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md">
            <div v-for="ingredient in variants[0].ingredients" :key="ingredient.id"
                class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Name</h6>
                        <a class="hover:underline block mr-2"
                            :href="`https://app.galleysolutions.com/ingredients/${ingredient.ingredient.galley_ingredient_id}`"
                            target="_blank">
                            {{ ingredient.ingredient.name }}</a>
                    </div>
                    <div v-for="variant in variants" :key="variant.id" class="xl:col-span-3">
                        <div v-for="variantIngredient in variant.ingredients" :key="variantIngredient.id">
                            <div v-if="variantIngredient.ingredient.id === ingredient.ingredient.id">
                                <h6 class="label xl:hidden">Type</h6>
                                <p>{{ variantIngredient.usage_quantity }} {{ variantIngredient.usage_unit }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import StatusBadge from '@components/meals/StatusBadge'

export default {
    props: {
        variants: {
            type: Array,
            required: true
        }
    },
    components: {
        StatusBadge,
    },

    created() { }
};
</script>
  
<style></style>
  