<template>
  <div>
    <AdminBaseModal
      :isOpen="isOpen"
      @close="closeModal"
      :title="title"
      :hasFooter="false"
    >
      <div v-if="shownScreen === 'main'">
        <!-- Body -->
        <div class="pb-6">
          <div class="larger-modal-text text-center text-green-500">
            Sign in with your mobile number.
          </div>
        </div>
        <AdminFloatingInput
          v-model="mobileNumber"
          label="Mobile Number"
          placeholder="(xxx) xxx-xxxx"
          type="tel"
          autocomplete="tel-national"
          id="phone"
          @enterPressed="onSendTextCodeClick"
        />
        <p v-if="mobileError" class="text-danger-600 text-sm ml-1 leading-4 -mt-2 mb-4">
          {{ mobileError }}
        </p>
        <div class="flex justify-center">
          <CPButton
            @click="onSendTextCodeClick"
            class="btn btn-green mb-4 mt-1 w-full"
            :disabled="anyLoading"
            :loading="loading.mobile"
          >
            TEXT ME A CODE
          </CPButton>
          <div id="recaptcha-verifier"></div>
        </div>
        <div class="text-center mb-4">
          <p
            @click="openEmailField"
            class="larger-modal-text underline text-green-500 cursor-pointer"
            style="display: inline-block"
          >
            Email me a sign-in link instead
          </p>
        </div>
        <div class="larger-modal-text text-center mb-4" style="font-size: 16px">
          Or log in with
        </div>
        <div class="flex flex-row space-x-4 mb-4">
          <!-- <CPButton class="btn border flex border-green-500 text-green-500 rounded-md w-full justify-center items-center" :disabled="anyLoading" :loading="loading.apple">
            <img src="img/login-apple-green.svg" />
          </CPButton> -->
          <CPButton
            @click="onGoogleSignInClick"
            class="btn border flex border-green-500 text-green-500 rounded-md w-full justify-center items-center bg-white"
            :disabled="anyLoading"
            :loading="loading.google"
          >
            <img src="/img/login-google-green.svg" width="20" />
          </CPButton>
          <!-- <CPButton class="btn border flex border-green-500 text-green-500 rounded-md w-full justify-center items-center" :disabled="anyLoading" :loading="loading.facebook">
            <img src="img/login-facebook-green.svg" />
          </CPButton> -->
        </div>
        <p
          v-if="loginProviderError"
          class="text-danger-600 text-sm ml-1 leading-4 -mt-2 mb-4"
        >
          {{ loginProviderError }}
        </p>
        <div class="text-center" style="font-size: 12px">
          By signing in you agree to our
          <router-link to="/privacy-policy"> Privacy Policy </router-link>
          and
          <router-link to="/terms-and-conditions"> Terms &amp; Conditions </router-link>
        </div>
        <div class="text-center mt-1" style="font-size: 10px">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy"> Privacy Policy </a>
          and
          <a href="https://policies.google.com/terms"> Terms of Service </a> apply
        </div>
      </div>

      <div v-if="shownScreen === 'email'" class="flex flex-col items-center">
        <AdminFloatingInput
          v-model="email"
          ref="emailInputRef"
          label="Email"
          placeholder="Type your email here..."
          type="email"
          :error="!!emailError"
          @enterPressed="checkAndSendSignInLinkEmail"
        />
        <p v-if="emailError" class="text-danger-600 text-sm ml-1 leading-4 -mt-2 mb-4">
          {{ emailError }}
        </p>
        <div class="flex justify-center w-full">
          <CPButton
            @click="checkAndSendSignInLinkEmail"
            class="btn btn-green mt-2 w-full"
            :disabled="anyLoading"
            :loading="loading.email"
          >
            EMAIL ME A LINK
          </CPButton>
        </div>
      </div>

      <div v-if="shownScreen === 'mobileSent'" class="flex flex-col items-center">
        <div class="larger-modal-text text-center text-green-500 pb-6 max-w-lg">
          Enter the code sent to {{ parsedMobileNumber }}.
        </div>
        <AdminUnspacedNumberInput class="mb-5" @submit="handleTextCodeSubmit" />
        <p v-if="mobileError" class="text-danger-600 text-sm ml-1 leading-4 -mt-2 mb-4">
          {{ mobileError }}
        </p>
        <CPButton
          @click="sendSignInText"
          class="btn btn-green w-full"
          variant="secondary"
          :disabled="anyLoading"
          :loading="loading.email"
        >
          Send text again
        </CPButton>
      </div>

      <div v-if="shownScreen === 'emailSent'" class="flex flex-col items-center">
        <div class="larger-modal-text text-center text-green-500 pb-6 max-w-lg">
          A sign-in link has been emailed to {{ email }}. If you have an account with that
          email, clicking the link will automatically log you in.
        </div>
        <CPButton
          @click="sendSignInLinkEmail"
          class="btn btn-green w-full"
          variant="secondary"
          :disabled="anyLoading"
          :loading="loading.email"
        >
          Send link again
        </CPButton>
      </div>
      <!-- Footer -->
      <!-- Use this when the sign in modal is automatically shown to the user as a result of a logically unrelated action (such as trying to edit allergies without being signed in) -->
      <template #footer>
        <div v-if="false" class="flex justify-center py-2">
          <CPButton
            @click="closeModal"
            class="btn btn-green w-full"
            :disabled="anyLoading"
          >
            SKIP
          </CPButton>
        </div>
      </template>
    </AdminBaseModal>
    <div id="recaptcha" class="g-recaptcha"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import parsePhoneNumber from 'libphonenumber-js'
import AdminBaseModal from './AdminBaseModal.vue'
import AdminFloatingInput from '../AdminFloatingInput.vue'
import AdminUnspacedNumberInput from '../AdminUnspacedNumberInput.vue'
import AdminCPButton from '../AdminCPButton.vue'
import AdminLoadingButton from '../AdminLoadingButton.vue'
import axios from 'axios'

export default {
  name: 'AdminLoginModal',
  components: {
    AdminBaseModal,
    AdminFloatingInput,
    AdminUnspacedNumberInput,
    CPButton: AdminCPButton,
    LoadingButton: AdminLoadingButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: 'Sign In',
      loading: {
        mobile: false,
        email: false,
        apple: false,
        google: false,
        facebook: false,
        allergies: false,
      },
      mobileNumber: null,
      parsedMobileNumber: null,
      recaptchaConfirmationResult: null,
      mobileError: null,
      email: null,
      emailInputRef: null,
      emailError: null,
      loginProviderError: null,
      route: this.$route,
      domain: window.location.origin,
      path: '/dashboard', // Use route.path to go back to the same page
      url: window.location.origin + '/dashboard',
      yourInfoFormRef: null,
      allergiesFormRef: null,
      registrationError: '',
      subscribe: false, // not exactly sure if this is ever supposed to change

      authService: this.$app.services.auth,
      router: this.$router,
      recaptchaVerifier: null,

      shownScreen: 'main',
    }
  },
  computed: {
    anyLoading() {
      return Object.values(this.loading).some((value) => value === true)
    },
  },
  methods: {
    async sendSignInLinkEmail() {
      try {
        this.loading.email = true
        await this.authService.sendPasswordlessSignInEmail(this.email, this.url)
        this.shownScreen = 'emailSent'
      } catch (error) {
        this.emailError = error.message
      }
      this.loading.email = false
    },
    async checkAndSendSignInLinkEmail() {
      if (this.email) {
        this.sendSignInLinkEmail()
      } else {
        this.emailError = 'Please enter an email address'
      }
    },
    async sendSignInText() {
      try {
        this.loading.mobile = true
        this.recaptchaConfirmationResult = await this.authService.sendSignInCodeText(
          this.parsedMobileNumber,
          window.recaptchaWidgetId,
          this.recaptchaVerifier,
          window.grecaptcha
        )
        this.mobileError = null
        this.shownScreen = 'mobileSent'
      } catch (error) {
        this.mobileError =
          error.code === 'auth/too-many-requests'
            ? "You've sent too many requests in too short a time, try a different login method"
            : 'There was an unexpected error sending you a text, please try again'
      }
      this.loading.mobile = false
    },
    async handleTextCodeSubmit(code) {
      if (this.recaptchaConfirmationResult) {
        let result = null
        try {
          this.loading.mobile = true
          this.mobileError = null
          result = await this.recaptchaConfirmationResult.confirm(code)
          const response2 = await this.authService.login()
          if (!this.authService.hasPermission('websites.admin.access')) {
            return this.authService.logout().then(() => {
              this.mobileError =
                "Your account doesn't have admin privileges. Please contact an administrator for access"
            })
          }
          this.$router.push('/dashboard')
        } catch (error) {
          if (error.response?.status === 401 && !!result) {
            // Correct code, but invalid firebase_uid
            // New user, tell them to create an account on the main site
            this.mobileError =
              'This phone number is not associated with a Citrus Pear account. Please create an account on the main site'
          } else if (error.code.includes('invalid-verification-code')) {
            this.mobileError = 'Incorrect code. Please try again'
          } else {
            console.error(error)
          }
        }
        this.loading.mobile = false
      }
    },
    async onSendTextCodeClick() {
      this.loading.mobile = true
      if (!this.mobileNumber) {
        this.mobileError = 'Please enter a phone number'
        this.loading.mobile = false
        return
      }
      this.$app.api.users
        .syncFirebaseNumber(this.mobileNumber)
        .then((response) => {
          const parsedPhoneNumberObject = parsePhoneNumber(this.mobileNumber, 'US')

          if (parsedPhoneNumberObject?.isValid()) {
            this.parsedMobileNumber = parsedPhoneNumberObject.number
            window.grecaptcha.execute()
          } else {
            this.mobileError = 'Please enter a valid mobile phone number'
          }
        })
        .catch((error) => {
          this.mobileError = error.message
        })
        .finally(() => {
          this.loading.mobile = false
        })
    },
    openEmailField() {
      this.shownScreen = 'email'
      Vue.nextTick(() => {
        if (this.emailInputRef) {
          this.emailInputRef.focus()
        }
      })
    },
    async onGoogleSignInClick() {
      try {
        this.loginProviderError = null
        this.loading.google = true
        const result = await this.authService.loginWithGoogleSignIn()
        switch (result) {
          case 'success':
            this.router.push(this.path)
            this.closeModal()
            break
          case 'auth/account-exists-with-different-credential':
            this.loginProviderError =
              "The email associated with your Google account is already being used for an existing Citrus Pear account. Sign in with your email above, and we'll link your Google account to your Citrus Pear account"
            break
          case 're-login':
            loginProviderError.value =
              'Your Google Account has been linked to your existing account successfully! Please sign in again using any method.'
            break
          case 'error':
          default:
            this.loginProviderError =
              'Something went wrong while signing in with Google. Please try again'
            break
        }
      } catch (error) {
        this.loginProviderError = error.message
        console.error(error)
      }
      this.loading.google = false
    },
    closeModal() {
      this.$emit('close')
      this.shownScreen = 'main'
    },
    async getYourInfoData() {
      if (this.yourInfoFormRef) {
        return await this.yourInfoFormRef.getData()
      }
    },
    getAllergyData() {
      if (this.allergiesFormRef) {
        return this.allergiesFormRef.getData()
      }
    },
    loadRecaptchaScript() {
      const script = document.createElement('script')
      script.src = 'https://www.google.com/recaptcha/api.js?render=explicit'
      script.async = true
      script.defer = true
      script.onload = this.initRecaptcha()
      document.head.appendChild(script)
    },
    initRecaptcha() {
      Vue.nextTick(() => {
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-verifier',
          {
            size: 'invisible',
            callback: (response) => {
              // reCAPTCHA solved - execute callback function
              this.sendSignInText()
            },
            'expired-callback': () => {
              // Handle expired reCAPTCHA
            },
          },
          this.authService.getFirebaseApp()
        )

        this.recaptchaVerifier.render().then(function (widgetId) {
          window.recaptchaWidgetId = widgetId
        })
      })
    },
  },
  mounted() {
    window.onTextSubmit = (token) => {
      // callback from recaptcha
      this.sendSignInText()
    }
    this.loadRecaptchaScript()
  },
}
</script>

<style scoped lang="scss">
.larger-modal-text {
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.18px;
}

a {
  color: #183d1d;
  font-size: 12px;
}

// Hides the reCAPTCHA badge from appearing. Any login via text needs to include reCAPTCHA's terms and privacy policy
.grecaptcha-badge {
  visibility: hidden !important;
}
</style>
