import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import Vue from 'vue'

/** import modules here */
import user from '@shared/store/user'
import registration from './modules/registration'
import calendars from './modules/calendars'
import locations from '@shared/store/locations'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        registration,
        calendars,
        locations,
    },
    plugins: [createPersistedState({
        key: 'citruspear-admin'
    })],
})
