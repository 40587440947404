<template>
    <Modal :visible="visible" @close="close" :background-close="false">
        <div class="flex flex-col space-y-5 w-full sm:max-w-screen-xs max-h-full rounded-md shadow-sm bg-white border-gray-200 p-5">
            <div class="flex justify-between items-center">
                <h3>Unauthorized</h3>
                <p class="px-2 text-2xl cursor-pointer" @click="close"><i class="fas fa-times"></i></p>
            </div>
            <div>
                <p>Whoops, it looks like you need additional permissions to perform that action.</p>
            </div>
            <div class="text-right">
                <button class="btn" @click="close">Ok</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'

export default {
    components: {
        Modal
    },

    data(){
        return {
            visible: false,
            permissions: []
        }
    },

    methods: {
        close(){
            this.visible = false
        },
        onUnauthorizedEvent(permissions){
            this.visible = true
            this.permissions = permissions
        }
    },

    created(){
        this.$app.events.$on('unauthorized', this.onUnauthorizedEvent)
    },

    beforeDestroy(){
        this.$app.events.$off('unauthorized', this.onUnauthorizedEvent)
    }
}
</script>

<style>

</style>
