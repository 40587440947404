<template>
    <div>
        <div v-for="announcement in announcements" :key="announcement.id" class="bg-gray-100 mb-5 rounded-md p-5">

            <h4 class="label">{{ announcement.title }}</h4>
            <div v-if="announcement.user">
                <h6 class="label">{{ announcement.user.first_name }} {{ announcement.user.last_name }}</h6>
            </div>
            <div class="mt-4 bg-gray-50 p-4 rounded-md">
                <p v-html="formatContent(announcement.content)"></p>
            </div>
            <div v-if="permissionToDelete" class="flex justify-right mt-4">
                <button class="fas fa-fw fa-trash" @click="deleteAnnouncement(announcement.id)">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnnouncementsGridView',

    props: {
        announcements: {
            type: Array,
            required: true
        }
    },

    computed: {
        permissionToDelete() {
            return this.$app.services.auth.hasPermission('announcements.delete')
        }
    },

    methods: {
        deleteAnnouncement(id) {
            this.$emit('deleteAnnouncement', id)
        },
        formatContent(content) {
            return content.replace(/\n/g, '<br>');
        }
    }
}
</script>