<template>
  <div>
    <div class="flex flex-row mb-4">
      <DateRangePicker
        class="mr-4"
        :value="dateRange"
        :default="defaultDateRange"
        @input="dateRangeChanged"
      />
      <button class="btn" :disabled="!canSubmit" @click.prevent="getManagementPayroll">
        Generate
      </button>
    </div>
    <template v-if="!loading && orders.data.data">
      <ListView class="mb-10" :orders="orders.data.data" />
      <Pagination
        :page="orders.page"
        :total-items="totalOrders"
        :per-page="orders.perPage"
        @change="pageUpdated"
      />
    </template>
  </div>
</template>

<script>
import DateRangePicker from '@components/global/dates/DateRangePicker'
import ListView from '@components/reports/payroll/ListView'
import Pagination from '@components/global/Pagination'

export default {
  name: 'ManagementPayrollReport',

  components: {
    DateRangePicker,
    ListView,
    Pagination,
  },

  data() {
    return {
      loading: false,
      dateRange: this.getDefaultDateRange(),
      defaultDateRange: this.getDefaultDateRange(),
      orders: {
        page: parseInt(this.$route.query.page) || 1,
        perPage: parseInt(this.$route.query.per_page) || 10,
        loading: false,
        data: [],
      },
      success: false,
    }
  },

  computed: {
    canSubmit() {
      return !!this.dateRange?.start && !!this.dateRange?.end
    },
    totalOrders() {
      return this.orders.data.meta.total
    },
  },

  methods: {
    pageUpdated(page) {
      this.orders.page = page
      this.getManagementPayroll()
    },
    getDefaultDateRange() {
      return {
        start: null,
        end: null,
      }
    },
    dateRangeChanged(input) {
      const range = input.range
      this.dateRange.start = range.start
      this.dateRange.end = range.end
    },
    getManagementPayroll() {
      let params = {
        from_date: this.dateRange.start.toUTC().toJSDate(),
        to_date: this.dateRange.end.toUTC().toJSDate(),
        page: this.orders.page,
        per_page: this.orders.perPage,
      }

      this.loading = true
      this.$app.api.reports
        .getManagementPayroll(params)
        .then((response) => {
          this.success = true
          this.orders.data = response.data
          setTimeout(() => {
            this.success = false
          }, 1000)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {},

  mounted() {},
}
</script>

<style scoped></style>
