<template>
    <div class="flex flex-col p-6 w-full">
        <div class="mb-8 flex justify-between space-x-3">
            <h3>Create Location</h3>
        </div>

        <LocationForm v-model="location" @input="handleChange"/>

        <div class="flex space-x-5 mt-6">
            <router-link class="btn white px-6" :to="`/locations`">Cancel</router-link>
            <button class="btn button-green px-6" @click="handleSave" :disabled="!canCreateLocation || saving">Save</button>
        </div>

        <!-- Render server errors if any on save -->
        <div v-if="errors" class="mt-6">
            <ul class="ml-1">
                <li v-for="(error, i) in errors" :key="`create-location-error-${i}`" class="text-red text-sm">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import LocationForm from '@components/locations/LocationForm'

export default {
    name: 'CreateLocation',

    components: {
        LocationForm,
    },

    data() {
        return {
            saving: false,
            location: null,
            errors: null,
            isValid: false,
            saveData: null,
        }
    },

    methods: {
        handleChange({ location, isValid }) {
            this.saveData = location
            this.isValid = isValid
        },
        handleSave() {
            this.saving = true
            this.errors = null
            this.saveData.location_vendor_id = this.saveData.location_vendor.id

            this.$app.api.locations.createLocation(this.saveData)
                .then((response) => {
                    this.$router.push(`/locations/${response.data.id}`)
                })
                .catch((error) => {
                    if (error.response?.data?.errors) this.errors = Object.values(error.response.data.errors).flat()
                    else if (error.response?.data?.message) this.errors = [error.response.data.message]
                    else this.errors = ['Whoops... something went wrong!']
                    console.error(error)
                })
                .finally(() => {
                    this.saving = false
                })
        },
    },

    computed: {
        canCreateLocation() {
            return this.location && this.isValid && this.$app.services.auth.hasPermission('locations.create')
        }
    },

}
</script>

<style lang="scss" scoped>
</style>
