import Resource from './resource'

export default class Classes extends Resource {

    /**
     * 
     * @param {object} data
     */
    createMeal(data) {
        return this.axios.post('meals', data);
    }

    /**
     * 
     * @param {object} params 
     */
    getMeals(params = {}) {
        return this.axios.get(`meals`, { params })
    }

    /**
     * 
     * @param {string|int} mealID meal ID
     * @param {object} params query params
     */
    getMeal(mealId, params = {}) {
        return this.axios.get(`meals/${mealId}`, { params })
    }

    /**
     * 
     * @param {array} mealIds array of meal IDs
     */
    getMealsByIds(mealIds) {
        return this.axios.post(`meals/ids`, {
            meal_ids: mealIds
        })
    }

    /**
     * 
     * @param {string|int} mealId 
     * @param {object} data 
     * @returns 
     */
    updateMeal(mealId, data) {
        return this.axios.put(`meals/${mealId}`, data)
    }

    updateMealVariantCosts(mealId, data) {
        return this.axios.put(`meals/${mealId}/variant-costs`, data)
    }


    // Galley endpoints

    /**
     * 
     * @param {string|int} mealId 
     */
    syncMeal(mealId, data) {
        return this.axios.post(`meals/galley/${mealId}/sync`, data)
    }

    updateMealVariant(mealId, data) {
        return this.axios.put(`meals/${mealId}/variant`, data);
    }

    fetchUnsyncedGalleyRecipes() {
        return this.axios.get(`meals/galley/unsynced`)
    }

    checkGalleyRecipe(mealId) {
        return this.axios.get(`meals/galley/${mealId}/check`)
    }
}
