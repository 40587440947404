<template>
    <div class="w-full rounded-md p-2 bg-white shadow-sm border border-gray-200">
        <!-- cancel -->
        <button class="menu-item red" :disabled="!canCancelOrder" @click="$emit('cancel')">
            <p><i class="fas fa-fw fa-ban mr-2"></i>Cancel</p>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            required: true
        }
    },

    computed: {
        permissionToUpdate(){
            return this.$app.services.auth.hasPermission('orders.update')
        },
        canCancelOrder(){
            return this.permissionToUpdate && [2,5,6].includes(this.order.status_id)
        },
    }
}
</script>

<style>
</style>
