<template>
    <div>
        <div class="mb-10">
            <h2 class="mb-10">Menus</h2>
            <div class="flex justify-between">
                <SearchInput class="mr-2" v-model="menus.search" placeholder="Search by name" @search="runSearch" />
                <router-link v-if="canCreateMenu()" class="btn green border border-green px-3 py-2" :to="`/menus/create`">
                    <span><i class="fas fa-plus mr-2"></i>Create Menu</span>
                </router-link>
            </div>
        </div>
        <div>
            <template v-if="menus.loading">
                <div class="flex items-center space-x-5">
                    <Loader class="w-10 h-10 text-green" />
                    <p>Loading menus...</p>
                </div>
            </template>
            <template v-else-if="menus.data.data && menus.data.data.length">
                <MenusListView class="mb-10" :menus="menus.data.data" />
                <Pagination :page="menus.page" :total-items="totalMenus" :per-page="menus.perPage" @change="pageUpdated" />
            </template>
            <template v-else>
                <p>
                    <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No
                    menus to show. Please refine your critera.
                </p>
            </template>
        </div>
    </div>
</template>

<script>
import Loader from "@components/global/Loader";
import MenusListView from "@components/menus/ListView";
import SearchInput from "@components/global/SearchInput";
import Pagination from "@components/global/Pagination";

export default {
    components: {
        Loader,
        MenusListView,
        SearchInput,
        Pagination,
    },

    data() {
        return {
            menus: {
                data: {},
                current: [],
                loading: false,
                search: this.$route.query.search ?? "",
                page: parseInt(this.$route.query.page) || 1,
                perPage: parseInt(this.$route.query.per_page) || 10,
                loading: false,
            },
            filters: {
                showFilters: false,
                includeDeleted: false,
            },
            saving: false,
            error: null,
            success: null
        };
    },

    computed: {
        totalMenus() {
            return this.menus.data.meta?.total || 0;
        },
        routePath() {
            return this.$route.path;
        },
        routeName() {
            return this.$route.name;
        }
    },

    watch: {
        $route: function (route) {
            this.menus.page = parseInt(route.query.page) || this.menus.page;
            this.menus.perPage = parseInt(route.query.per_page) || this.menus.perPage;
            this.menus.search = route.query.search ?? this.menus.search;
            this.getMenus();
        }
    },

    methods: {
        pageUpdated(page) {
            this.menus.page = page;
            this.getMenus();
        },
        runSearch() {
            this.menus.page = 1;

            let query = { ...this.$route.query, search: this.menus.search };
            delete query.page;

            if (!this.menus.search) delete query.search;
            this.$router.replace({ path: this.$route.path, query });
            this.getMenus();
        },
        getMenus() {
            let params = {
                page: this.$route.query.page || this.menus.page,
                per_page: this.$route.query.per_page || this.menus.perPage,
                custom: false,
            }

            let search = this.$route.query.search || this.menus.search;
            if (search) params.search = search;

            this.menus.loading = true;
            return this.$app.api.menus.getMenus(params)
                .then((response) => {
                    this.menus.data = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => this.menus.loading = false)
        },
        getActiveMenus() {
            this.menus.loading = true;
            return this.$app.api.menus.getMenus({
                active: true,
            })
                .then((response) => {
                    this.menus.current = response.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => this.menus.loading = false)
        },
        canCreateMenu() {
            return this.$app.services.auth.hasPermission("menus.create")
        },
    },

    created() {
        this.getMenus();
        this.getActiveMenus();
    },
}

</script>
<style></style>
