import Resource from "./resource";

export default class Accounts extends Resource {
  /**
   * @param {object} params request query parameters
   */
  getDeliveryAddresses(params = {}) {
    return this.axios.get(`reports/classes/deliveryAddresses`, { params });
  }

  /**
   * @param {object} params request query parameters
   */
  getGiftCardOutstandingBalance(params = {}) {
    return this.axios.get(`reports/gift-cards/outstandingBalance`, { params });
  }

  /**
   * @param {object} params request query parameters
   */
  getManagementPayroll(params = {}) {
    return this.axios.get(`reports/managementPayroll`, { params });
  }
}
