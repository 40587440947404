<template>
  <div>
    <template v-if="label">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">{{ label }}</label>
    </template>
    <div class="flex flex-row">
      <button class="btn bg-white border-gray-200 text-gray-800 font-normal border border-r-0 rounded-r-none capitalize"
        @click="buttonClicked('Past')">
        <i class="fas fa-chevron-left"></i>
      </button>
      <Popover>
        <template #button="{ toggle, visible }">
          <div @click="toggle" class="border border-gray bg-white px-3 py-2 cursor-pointer" :class="{
            'bg-green-600 border-green-600 text-white': visible || rangeSelected,
          }">
            <p v-if="rangeSelected">
              {{ value.start | date('DATE_SHORT') }}
              <span v-if="!rangeIsSingleDay">
                <i class="fas fa-long-arrow-alt-right px-1"></i>
                {{ value.end | date('DATE_SHORT') }}
              </span>
              <span v-if="!rangeIsDefault" @click.stop="reset"><i class="fas fa-times-circle px-1"></i></span>
            </p>
            <p v-else>{{ placeholder }}</p>
          </div>
        </template>
        <template #default="{ hide }">
          <v-date-picker :value="jsDateRange" :attributes="attributes" @input="(range) => {
              changed(range)
              if (!time) hide()
            }
            " is-range :mode="mode" color="green" class="shadow-sm border-gray" />
        </template>
      </Popover>
      <button class="btn bg-white border-gray-200 text-gray-800 font-normal border border-l-0 rounded-l-none capitalize"
        @click="buttonClicked('Future')">
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Popover from '@components/global/Popover'
import { DateTime } from 'luxon'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    default: {
      type: Object,
      default: function () {
        return { start: null, end: null }
      },
    },
    placeholder: {
      type: String,
      default: 'Select dates',
    },
    highlightToday: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: null,
    },
    time: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
      // If the page allows for sorting by Date, set this to true, and make sure to handle the changed @input return value in the parent
    },
  },

  components: {
    Popover,
  },

  data() {
    return {
      attributes: [],
    }
  },

  computed: {
    rangeSelected() {
      return this.value.start !== null && this.value.end !== null
    },
    rangeIsSingleDay() {
      return this.value.start.hasSame(this.value.end, 'day')
    },
    rangeIsDefault() {
      return (
        +this.default.start === +this.value.start && +this.default.end === +this.value.end
      )
    },
    jsDateRange() {
      return {
        start: this.value.start ? this.value.start.toJSDate() : null,
        end: this.value.end ? this.value.end.toJSDate() : null,
      }
    },
    mode() {
      if (this.time) {
        return 'dateTime'
      }

      return 'date'
    },
  },

  methods: {
    changed(range) {
      range.start = DateTime.fromJSDate(range.start)
      range.end = DateTime.fromJSDate(range.end)

      if (!this.time) {
        range.start = range.start.startOf('day')
        range.end = range.end.endOf('day')
      }

      this.$emit('input', { range: range })
    },
    reset() {
      this.$emit('input', { range: this.default })
    },
    buttonClicked(name) {
      if (name === 'Past') {
        const range = {
          start: DateTime.now().minus({ years: 1 }).startOf('day'),
          end: DateTime.now().minus({ days: 1 }).endOf('day'),
        }
        if (this.sortable) {
          this.$emit('input', { range, sort: { name: 'Date', orderBy: 'desc' } })
        } else {
          this.$emit('input', { range })
        }
      } else if (name === 'Future') {
        const range = {
          start: DateTime.now().startOf('day'),
          end: DateTime.now().plus({ years: 1 }).endOf('day'),
        }
        if (this.sortable) {
          this.$emit('input', { range, sort: { name: 'Date', orderBy: 'asc' } })
        } else {
          this.$emit('input', { range })
        }
      }
    },
  },

  created() {
    if (this.highlightToday) {
      this.attributes.push({
        highlight: {
          color: 'gray',
          fillMode: 'light',
        },
        dates: new Date(),
      })
    }
  },
}
</script>

<style></style>
