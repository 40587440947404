<template>
    <div class="py-2 px-3 border-b border-gray-100 select-none"
        :class="{
            'bg-green-400': selected,
            'hover:bg-green hover:text-white' : canSelect,
            'bg-gray-100 cursor-not-allowed text-gray': disabled
        }"
        @click="clicked"
    >
        <slot :value="value" :displayValue="displayValue">
            {{ displayValue || value }}
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            default: null
        },
        displayValue: {
            default: null
        },
        selected: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    data(){
        return {}
    },

    computed: {
        canSelect(){
            return !this.selected && !this.disabled
        }
    },

    methods: {
        clicked(){
            if(this.canSelect) this.$emit('click', this.value)
        }
    }
}
</script>

<style>

</style>
