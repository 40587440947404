<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xl overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Transfer to Shipping</h3>
                <button class="px-2 text-xl text-green" @click="close" :disabled="isProcessing"><i
                        class="fas fa-times"></i></button>
            </div>
            <div v-if="!selectedGroup" class="mb-6">
                <p class="mb-4">Select a shipping group to transfer the registrations to:</p>
                <ListView class="mb-10" :groups="groups.data.data" :transfer="true" @select="groupSelected"
                    :numberOfTransfers="registrationsArray.length" />
            </div>
            <div v-else class="mb-6">
                <p class="mb-4">You are transferring the registrations to this shipping group:</p>
                <ListView class="mb-10" :groups="groups.data.data" />
            </div>
            <div class="flex space-x-5">
                <button class="btn px-6" :disabled="isProcessing || !selectedGroup"
                    @click="transferRegistrations">Transfer</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'
import ListView from '@components/shipping/ListView'

export default {
    name: "SmartForecastModal",

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        registrationsArray: {
            type: Array,
            default: () => [],
        },
        menuId: {
            type: Number,
            required: true,
        },
    },

    components: {
        Modal,
        Loader,
        ListView,
    },

    data() {
        return {
            isProcessing: false,
            error: null,
            groups: {
                data: [],
            },
            selectedGroup: null,
        };
    },

    mounted() {
        this.getGroups();
    },

    methods: {
        close() {
            this.$emit('close')
        },
        getGroups() {
            let params = {
                relations: 'reservationSize',
                orderBy: 'date',
                orderDirection: 'desc',
                includeArchived: false,
                fulfillment_ids: '4',
                completed: false,
                menu_id: this.menuId,
            }

            this.isProcessing = true
            return this.$app.api.classes
                .getClasses(params)
                .then((response) => {
                    this.groups.data = response
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.isProcessing = false
                })
        },
        groupSelected(group) {
            this.selectedGroup = group
            this.groups.data.data = this.groups.data.data.filter((g) => g.id === group.id)
        },
        async transferRegistrations() {
            this.isProcessing = true;
            let params = {
                class_event_id: this.selectedGroup.id,
            };

            let updatePromises = this.registrationsArray.map((registrationId) => {
                return this.$app.api.registrations
                    .updateRegistration(registrationId, params)
                    .then(() => { })
                    .catch((error) => {
                        console.error(error);
                    });
            });

            await Promise.all(updatePromises);

            this.isProcessing = false;
            this.close();
        }

    },
}
</script>

<style scoped></style>
