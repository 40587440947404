const routes = [
    {
        path: '/employee-links',
        name: "employee-links",
        component: () => import("@views/links/EmployeeLinks.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['employee-links.create']
        }
    },
]

export default routes
