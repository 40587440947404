import Resource from './resource'

export default class GiftCards extends Resource {

    getCards(params) {
        return this.axios.get('giftcards', { params })
    }

    getCard(id) {
        return this.axios.get(`giftcards/${id}`)
    }

    /**
     * @param {object} data gift card data
     */
    createGiftCard(data) {
        return this.axios.post('giftcards', data)
    }

    /**
     * @param {object} data gift cards data
     */
    createGiftCards(data) {
        return this.axios.post('giftcards/bulk', data);
    }

    updateStatus(id, data) {
        return this.axios.put(`giftcards/${id}/status`, data)
    }

    /**
     * @param {object} data gift card data
     */
    getBalance(data) {
        return this.axios.post('giftcards/balance', data)
    }

    /**
     * @param {object} data checkout data
     */
    checkout(data) {
        return this.axios.post('giftcards/checkout', data)
    }

}
