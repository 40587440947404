<template>
    <div class="">
        <div @click="toggleAccordion"
             class="py-2 px-4 cursor-pointer space-x-2 flex items-center justify-between"
             :aria-expanded="isOpen"
             :aria-controls="`collapse${_uid}`"
        >
            <slot name="title">
                <p>{{ title }}</p>
            </slot>
            <div class="w-6 h-6">
                <div v-if="!isOpen">
                    <slot name="openIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"/>
                        </svg>
                    </slot>
                </div>
                <div v-else>
                    <slot name="closeIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"/>
                        </svg>
                    </slot>
                </div>
            </div>
        </div>
        <div v-show="isOpen" :id="`collapse${_uid}`">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Accordion',

    props: {
        open: {
            type: Boolean|null,
            default: null,
        },
        canToggle: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            localOpen: false,
        };
    },

    computed: {
        isOpen() {
            if(!this.canToggle && this.open !== null) return this.open
            return this.localOpen
        },
    },

    methods: {
        toggleAccordion() {
            if(this.canToggle) this.localOpen = !this.localOpen;
        },
    },
};
</script>

<style scoped lang="scss"></style>
