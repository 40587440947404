const routes = [
    {
        path: '/newsroom/in-the-news',
        name: 'newsLink-list',
        component: () => import('@views/newsroom/NewsLinks.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['newsroom.newslinks.view']
        }
    },
    {
        path: '/newsroom/in-the-news/create',
        name: 'newsLink-create',
        component: () => import('@views/newsroom/NewsLink.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['newsroom.newslinks.create']
        }
    },
    {
        path: '/newsroom/in-the-news/:id',
        name: 'newsLink-edit',
        component: () => import('@views/newsroom/NewsLink.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['newsroom.newslinks.update']
        }
    },
    {
        path: '/newsroom/press-releases',
        name: 'pressRelease-list',
        component: () => import('@views/newsroom/PressReleases.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['newsroom.pressreleases.view']
        }
    },
    {
        path: '/newsroom/press-releases/create',
        name: 'pressRelease-create',
        component: () => import('@views/newsroom/PressRelease.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['newsroom.pressreleases.create']
        }
    },
    {
        path: '/newsroom/press-releases/:slug',
        name: 'pressRelease-edit',
        component: () => import('@views/newsroom/PressRelease.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['newsroom.pressreleases.update']
        }
    },
    {
        path: '/newsroom',
        name: 'newsroom',
        // component: () => import('@views/newsroom/Newsroom.vue'),
        redirect: { name: 'pressRelease-list' },
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: []
        }
    },
]

export default routes
