<template>
    <span class="badge" :class="{
            'mint-green': status === 'Scheduled',
            'green': status === 'Enabled',
            'red': status === 'Expired',
        }"
    >
        {{ status }}
    </span>
</template>

<script>
import {DateTime} from "luxon";

export default {
    name: "CodesStatusBadge",

    props: {
        code: {
            type: Object,
            required: true
        }
    },

    computed: {
        status(){
            if(this.isScheduled) return 'Scheduled'
            else if(this.isExpired) return 'Expired'
            else if(this.code?.max_uses !== null && this.code?.uses >= this.code?.max_uses) return 'Disabled'
            else if(this.code.enabled) return 'Enabled'
            else if(!this.code.enabled) return 'Disabled'
            else return 'Other'
        },
        isExpired() {
            return DateTime.now() > DateTime.fromISO(this.code.end)
        },
        isScheduled() {
            return DateTime.now() < DateTime.fromISO(this.code.start)
        }
    }
}
</script>

<style scoped>

</style>
