const routes = [
  {
    path: "/reports",
    name: "reports",
    component: () => import("@views/reports/Reports.vue"),
    meta: {
      requiresAuth: true,
      roles: [],
      permissions: []
    },
    children: [
      {
        path: "/reports/menu-costs",
        name: "Projected Menu Costs",
        component: () => import("@views/reports/MenuCostsReport.vue"),
        meta: {
          requiresAuth: true,
          roles: ["admin"],
          permissions: []
        }
      },
      {
        path: "/reports/delivery-addresses",
        name: "Delivery Addresses",
        component: () => import("@views/reports/DeliveryAddressesReport.vue"),
        meta: {
          requiresAuth: true,
          roles: ["admin", "regional_manager", "location_manager"],
          permissions: []
        }
      },
      {
        path: "/reports/gift-cards",
        name: "Gift Cards",
        component: () => import("@views/reports/GiftCardReport.vue"),
        meta: {
          requiresAuth: true,
          roles: ["admin"],
          permissions: []
        }
      },
      {
        path: "/reports/management-payroll",
        name: "Management Payroll",
        component: () => import("@views/reports/ManagementPayrollReport.vue"),
        meta: {
          requiresAuth: true,
          roles: ["admin"],
          permissions: []
        }
      }
    ]
  }
];

export default routes;
