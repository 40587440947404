import { render, staticRenderFns } from "./PromotionForm.vue?vue&type=template&id=2fe4f270&scoped=true&"
import script from "./PromotionForm.vue?vue&type=script&lang=js&"
export * from "./PromotionForm.vue?vue&type=script&lang=js&"
import style0 from "./PromotionForm.vue?vue&type=style&index=0&id=2fe4f270&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fe4f270",
  null
  
)

export default component.exports