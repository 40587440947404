<template>
    <div>
        <div class="mb-10">
            <h2 class="mb-5">Meals</h2>
            <div class="flex justify-between">
                <div class="flex flex-wrap">
                    <SearchInput class="mr-2" v-model="meals.search" placeholder="Search by name" @search="runSearch" />
                    <router-link class="btn green border border-green px-3 py-2" :to="`/meals/create`">
                        <span><i class="fas fa-plus mr-2"></i>New Meal</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="mb-10">
            <template v-if="meals.loading">
                <div class="flex items-center space-x-5">
                    <Loader class="w-10 h-10 text-green" />
                    <p>Loading meals...</p>
                </div>
            </template>
            <template v-else-if="meals.data.data && meals.data.data.length">
                <MealsListView class="mb-10" :meals="meals.data.data" />
                <Pagination :page="meals.page" :total-items="totalMeals" :per-page="meals.perPage" @change="pageUpdated" />
            </template>
            <template v-else>
                <p>
                    <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No
                    meals to show. Please refine your critera.
                </p>
            </template>
        </div>
    </div>
</template>

<script>
import Loader from "@components/global/Loader";
import MealsListView from "@components/meals/ListView";
import SearchInput from "@components/global/SearchInput";
import Pagination from "@components/global/Pagination";

export default {
    components: {
        Loader,
        MealsListView,
        SearchInput,
        Pagination,
    },

    data() {
        return {
            modals: {
                galleySync: false,
            },
            meals: {
                data: {
                    data: [],
                },
                search: this.$route.query.search ?? "",
                page: parseInt(this.$route.query.page) || 1,
                perPage: parseInt(this.$route.query.per_page) || 10,
                loading: false,
                syncing: false,
            },
            filters: {
                showFilters: false,
                includeDeleted: false,
            },
            saving: false,
            error: null,
            success: null
        };
    },

    computed: {
        totalMeals() {
            return this.meals.data.meta?.total || 0;
        },
        routePath() {
            return this.$route.path;
        },
        routeName() {
            return this.$route.name;
        },
        hasPermission() {
            return this.$app.services.auth.hasPermission('classes')
        },
    },

    watch: {
        $route: function (route) {
            this.meals.page = parseInt(route.query.page) || this.meals.page;
            this.meals.perPage = parseInt(route.query.per_page) || this.meals.perPage;
            this.meals.search = route.query.search ?? this.meals.search;
            this.getMeals();
        }
    },

    methods: {
        pageUpdated(page) {
            this.meals.page = page;
            this.getMeals();
        },
        runSearch() {
            this.meals.page = 1;

            let query = { ...this.$route.query, search: this.meals.search };
            delete query.page;

            if (!this.meals.search) delete query.search;
            this.$router.replace({ path: this.$route.path, query });
            this.getMeals();
        },
        getMeals() {
            let params = {
                page: this.$route.query.page || this.meals.page,
                per_page: this.$route.query.per_page || this.meals.perPage,
            }

            let search = this.$route.query.search || this.meals.search;
            if (search) params.search = search;

            this.meals.loading = true;
            return this.$app.api.meals.getMeals(params)
                .then((response) => {
                    this.meals.data = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => this.meals.loading = false)
        },
    },

    created() {
        this.getMeals();
    },
}

</script>
<style></style>
