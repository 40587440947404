import { DateTime } from 'luxon'

const n = "numeric", s = "short", l = "long"
const additionalPresets = {
    TIME_SIMPLE_WITH_SHORT_OFFSET: {
        hour: n,
        minute: n,
        timeZoneName: s,
    },
    WEEKDAY_MONTH_DAY: {
        weekday: s,
        month: s,
        day: n,
    },
    DAY_MONTH_YEAR: {
        day: '2-digit',
        month: l,
        year: n,
    },
}

function date(date, preset = 'DATE_FULL', options){
    if(typeof date === 'string' || date instanceof String) date = DateTime.fromISO(date)
    if(options?.timezone) date = date.setZone(options.timezone)

    let formatting = DateTime[preset] ?? additionalPresets[preset] ?? DateTime['DATE_FULL']
    return date.toLocaleString({...formatting, ...options})
}

function customDate(date, options){
    if(typeof date === 'string' || date instanceof String) date = DateTime.fromISO(date)
    return date.toLocaleString(options)
}

export default Vue => {
    Vue.filter('date', date)
    Vue.filter('customDate', customDate)
}
