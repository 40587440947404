<template>
  <img
    src="/img/icons/loading-spinner-green.svg"
    alt="loading"
    class="animate-spin mx-auto"
    style="width: 22px; height: 22px"
  />
</template>

<script>
export default {
  name: 'AdminLoadingButton',
  props: ['mode', 'size'],
}
</script>
