import Resource from "./resource";

export default class Blogs extends Resource {

    /**
     * 
     * @param {object} params 
     */
    getBlogs(params = {}) 
    {
        return this.axios.get(`blogs`, { params });
    }

    /**
     * 
     * @param {string|int} blogId blog post ID
     * @param {object} params query params
     */
    getBlog(blogId, params = {})
    {
        return this.axios.get(`blogs/${blogId}`, { params })
    }

    /**
     * 
     * @param {object} data post data
     */
    storeBlog(data = {}) 
    {
        return this.axios.post(`blogs`, 
            data, 
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
    }

    /**
     * 
     * @param {string|int} blogId blog post ID
     * @param {object} data put data
     */
    updateBlog(blogId, data = {}) 
    {
        return this.axios.post(`blogs/${blogId}`, 
            data, 
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
    }

    /**
     * 
     * @param {string|int} blogId blog post ID
     */
    deleteBlog(blogId) 
    {
        return this.axios.delete(`blogs/${blogId}`);
    }

    /**
     * 
     * @param {string|int} blogId blog post ID
     */
    archiveBlog(blogId) 
    {
        return this.axios.put(`blogs/${blogId}/archive`);
    }

    /**
     * 
     * @param {string} blogSlug blog post slug
     * @param {object} params query params
     */
    getBlogBySlug(blogSlug, params = {}) 
    {
        return this.axios.get(`blogs/${blogSlug}`, { params });
    }
}