import Resource from './resource'

export default class Marketing extends Resource {

    /**
     * @param {object} data newsletter subscription data
     */
    subscribe(data){
        return this.axios.post('marketing/subscribe', data)
    }

    /**
     * @param {object} data custom class request data
     */
    sendCustomClassRequest(data) {
        return this.axios.post('classes/custom', data)
    }

}
