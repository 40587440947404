<template>
    <span class="badge" :class="[color]">
        <template v-if="active">Active</template>
        <template v-else>Inactive</template>
    </span>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        color() {
            if (this.active) return 'mint-green'
            else return 'gray'
        }
    }
}
</script>

<style lang="scss" scoped></style>
