<template>
    <div>
        <div class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="col-span-8 label">Location</h6>
            <h6 class="col-span-4 label">Cost</h6>
        </div>
        <div
            class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md">
            <div v-for="info in locationInfo"
                class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-8">
                        <p class="text-xs text-gray-400 xl:hidden">Location</p>
                        <template v-if="links && info.location_id">
                            <p class="font-semibold">{{ info.name }}</p>
                        </template>
                        <template v-else>
                            <p class="font-semibold">{{ info.name }}</p>
                        </template>
                    </div>
                    <div class="xl:col-span-4">
                        <p class="text-xs text-gray-400 xl:hidden">Cost</p>
                        <p>{{ formatCurrency(info.medium) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selectedMeals: {
            type: Array,
            default: function () {
                return [];
            }
        },
        fulfillment: {
            type: String,
            default: 'in-person'
        },
        links: {
            type: Boolean,
            default: true
        },
    },

    created() {
        this.getLocations();
        this.setLocationInfo();
    },

    data() {
        return {
            locationInfo: Array(),
            locations: Array(),
        }
    },

    watch: {
        selectedMeals: {
            handler: function () {
                this.setLocationInfo();
            },
            deep: true
        },
        locations: {
            handler: function () {
                this.setLocationInfo();
            },
            deep: true
        }
    },

    methods: {
        setLocationInfo() {
            let locationInfo = this.locations.map(location => ({
                location_vendor_id: location.id,
                name: location.name,
                medium: 0,
            }));

            this.selectedMeals.forEach(meal => {
                meal.meal_variants[this.fulfillment].variants.forEach(variant => {
                    variant.vendor_prices.forEach(location_price => {
                        const location = locationInfo.find(loc => loc.location_vendor_id === location_price.location_vendor_id);

                        if (location && variant.portion_size_id == 1) {
                            const cost = Number(location_price.price);
                            location.medium = parseFloat((location.medium + cost).toFixed(2));
                        }
                    });
                });
            });

            this.locationInfo = locationInfo;
        },
        getLocations() {
            this.$app.api.locations.getVendors()
                .then((response) => {
                    this.locations = response.data;
                });
        },
        formatCurrency(value) {
            return `$${value.toFixed(2)}`;
        },
    }
};
</script>

<style lang="scss" scoped></style>