const routes = [
    {
        path: '/registrations/:id/edit',
        name: 'registration edit',
        component:  () => import('@views/registrations/update/Edit.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['registrations.update']
        }
    },
    {
        path: '/registrations/:id',
        name: 'registration',
        component:  () => import('@views/registrations/Registration.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['registrations.view']
        }
    },
]

export default routes
