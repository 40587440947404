<template>
    <div>
        <div v-if="mealSelections" class="cp-choose-meals text-gray-dark">
            <p class="text-base font-display text-green mb-[0.375rem]">Choose # of Each Meal</p>
            <p v-if="portionQuantity" class="text-xs font-body mb-[1.375rem]">
                You can choose up to 2 meals to opt out of and double the number of up to 2 meals to replace them. Total
                #
                of
                meals should equal {{ portionQuantity }}.
            </p>
            <div class="cp-meals-cover">
                <div class="flex items-center mb-3.5 w-full" v-for="(meal, index) in selectedMeals" :key="index">
                    <div
                        class="flex justify-around items-center meal-select-border rounded-[0.4375rem] h-[2.1875rem] mr-4">
                        <button class="flex justify-center items-center h-[2.1875rem] w-[2.1875rem]"
                            :class="[disableMinus(index) ? 'grayscale' : '']" v-on:click="handleDecrement(index)"
                            :disabled="disableMinus(index)">
                            <img class="w-[14px]" src="/img/icons/minus.svg" alt="minus">
                        </button>
                        <div class="p-1 text-center">{{ meal.quantity }}</div>
                        <button class="flex justify-center items-center h-[2.1875rem] w-[2.1875rem]"
                            :class="[disablePlus(index) ? 'grayscale' : '']" v-on:click="handleIncrement(index)"
                            :disabled="disablePlus(index)">
                            <img class="w-[14px]" src="/img/icons/plus.svg" alt="plus">
                        </button>
                    </div>
                    <p class="font-body inline-block text-sm">{{ meal.name }}</p>
                </div>
            </div>

            <div v-if="portionQuantity"
                :class="[quantity === portionQuantity ? 'text-green' : 'cp-error-message', 'mb-6']">
                <div>{{ quantity }}/{{ portionQuantity }} Total meals selected</div>
                <div v-if="quantity !== portionQuantity">Total meals must equal {{ portionQuantity }}</div>
            </div>
        </div>
        <div v-if="deprecatedSelections.length != 0" class="cp-choose-meals text-gray-dark">
            <p class="text-base font-display text-green mb-[1.375rem]">Old Selections</p>
            <div class="cp-meals-cover">
                <div class="flex items-center mb-3.5 w-full" v-for="(meal, index) in deprecatedSelections" :key="index">
                    <div
                        class="flex justify-around items-center meal-select-border rounded-[0.4375rem] h-[2.1875rem] mr-4">
                        <div class="p-6 text-center">{{ meal.quantity }}</div>
                    </div>
                    <p class="font-body inline-block text-sm">{{ meal.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MealsSelect',
    props: {
        meals: {
            type: [Array, Object],
        },
        portionQuantity: {
            type: Number,
        },
        selections: {
            type: Array,
            default: function () { return [] },
        },
    },

    data() {
        return {
            mealsMap: null,
            selectionsMap: null,
            mealSelections: [],
            deprecatedSelections: [],
            // doubled: 0,
            // removed: 0,
        }
    },

    computed: {
        defaultQuantity() {
            return this.portionQuantity / 10
        },
        selectedMeals() {
            return this.mealSelections.map((selectedMeal) => {
                return { ...selectedMeal, quantity: selectedMeal.quantity }
            })
        },
        quantity() {
            return this.selectedMeals.map((selectedMeal) => { return selectedMeal.quantity }).reduce((accumulator, currentValue) => { return accumulator + currentValue })
        },
    },

    methods: {
        handleDecrement(index) {
            this.decrement(index);

            // if (this.quantity > 1 && this.mealSelections[index].quantity !== 2) {
            //     this.decrement(index);
            // }
            // else if (this.removed < 2 && this.mealSelections[index].quantity !== 2) {
            //     this.decrement(index);
            //
            //     this.removed += 1;
            // }
            // else if (this.doubled > 0 && this.mealSelections[index].quantity === 2) {
            //     this.decrement(index);
            //
            //     this.doubled -= 1;
            // }
        },
        handleIncrement(index) {
            this.increment(index);

            // if (this.removed === 0 && this.mealSelections[index].quantity === 0) {
            //     this.increment(index);
            // }
            // else if (this.doubled < 2 && this.mealSelections[index].quantity === 1) {
            //     this.increment(index);
            //
            //     this.doubled += 1
            // }
            // else if (this.removed > 0 && this.mealSelections[index].quantity !== 2) {
            //     this.increment(index);
            //
            //     this.removed -= 1
            // }
        },
        disablePlus(index) {
            return false
            // return this.mealSelections[index].quantity === 2 || (this.doubled === 2 && this.mealSelections[index].quantity !== 0)
        },
        disableMinus(index) {
            return false
            // return this.mealSelections[index].quantity === 0 || (this.removed === 2 && this.mealSelections[index].quantity !== 2)
        },
        emitSelectedMeals() {
            this.$emit('meals', this.selectedMeals.map((selectedMeal) => { return { meal_id: selectedMeal.meal_id, quantity: selectedMeal.quantity, meal: selectedMeal } }));
        },
        increment(index) {
            this.mealSelections[index].quantity += 1
            // this.mealSelections[index].quantity = this.mealSelections[index].quantity < 2 ? this.mealSelections[index].quantity + 1 : this.mealSelections[index].quantity;
        },
        decrement(index) {
            if (this.mealSelections[index].quantity >= 1) {
                this.mealSelections[index].quantity -= 1
            }
            // this.mealSelections[index].quantity = this.mealSelections[index].quantity > 0 ? this.mealSelections[index].quantity - 1 : this.mealSelections[index].quantity;
        },
        getMealSelections() {
            if (this.selections.length > 0) {
                return this.meals.map((meal) => {
                    let quantity = 0
                    if (this.selectionsMap.has(meal.meal_id)) {
                        quantity = this.selectionsMap.get(meal.meal_id).quantity 
                    }
                    return { meal_id: meal.meal_id, name: meal.meal.name, quantity: quantity }
                });
            }
            else {
                return this.meals.map((meal, index) => {
                    if (index < 10) {
                        return { meal_id: meal.meal_id, name: meal.meal.name, quantity: this.defaultQuantity }
                    }
                    else {
                        return { meal_id: meal.meal_id, name: meal.meal.name, quantity: 0 }
                    }
                });
            }
        },
        getDeprecatedSelections() {
            return this.selections
                .filter((selection) => !this.mealsMap.has(selection.meal_id))
                .map((deprecatedSelection) => {
                    return {
                        meal_id: deprecatedSelection.meal_id,
                        name: deprecatedSelection.meal.name,
                        quantity: deprecatedSelection.quantity
                    };
                });
        },
        buildMaps() {
            this.mealsMap = new Map()
            this.meals.forEach((meal) => { this.mealsMap.set(meal.meal_id, meal) })

            this.selectionsMap = new Map()
            this.selections.forEach((selection) => { this.selectionsMap.set(selection.meal_id, selection) })

            this.mealSelections = this.getMealSelections();
            this.deprecatedSelections = this.getDeprecatedSelections();
        }
    },

    watch: {
        selectedMeals: function (newSelectedMeals, oldSelectedMeals) {
            this.emitSelectedMeals()
        },
        meals: {
            handler(newValue, oldValue) {
                this.buildMaps()
                // this.removed = 0
                // this.doubled = 0
            },
        }
    },

    mounted() { },

    created() {
        this.buildMaps()
    },
}
</script>

<style lang="scss" scoped>
.meal-select-border {
    border: 1px solid #BFC5B7;
}
</style>
