import Resource from './resource'

export default class Users extends Resource {

    /**
     * @param {object} data user request data
     */
    createUser(data){
        return this.axios.post(`users`, data)
    }

    /**
     * @param {object} data registration data
     */
    register(data){
        return this.axios.post('users/register', data)
    }

    /**
     * Logs in the user using firebase creds
     */
    login(){
        return this.axios.get(`users/login`)
    }

  /**
   * @param {string|int} userId user ID
   * @param {object} params query params
   */
  getUser(userId, params = {}) {
    return this.axios.get(`users/${userId}`, { params });
  }

  /**
   * Get users
   * @param {object} params query string params
   */
  getUsers(params = {}) {
    return this.axios.get(`users`, { params });
  }

    /**
     * @param {string|int} userId user ID
     * @param {object} data update data
     */
    updateUser(userId, data){
        return this.axios.put(`users/${userId}`, data)
    }

    /**
     * @param {string|int} userId user ID
     * @param {object} data request data
     */
    deleteUser(userId, data = {}){
        return this.axios.delete(`users/${userId}`, { data })
    }

    /**
     * @param {string|int} userId user ID
     * @param {object} data request data
     */
    getOrders(userId, data= {}) {
        return this.axios.get(`users/${userId}/orders`, data)
    }

    /**
     * @param {string|int} userId user ID
     * @param {object} params request data
     */
    getRegistrations(userId, params = {}) {
        return this.axios.get(`users/${userId}/registrations`, { params })
    }

    /**
     * @param {string} email
     * @param {string|null} current
     */
    isEmailUnique(email) {
        let params = { email: email }
        return this.axios.get(`users/unique`, { params })
    }

    isPhoneUnique(phone) {
        let params = { phone: phone }
        return this.axios.get(`users/unique`, { params })
    }

    syncFirebaseNumber(phoneNumber) {
        return this.axios.post(`users/firebase/number`, { phoneNumber })
    }

    syncFirebaseEmail(email) {
        return this.axios.post(`users/firebase/email`, { email })
    }

    sendFirebaseSignInEmail(email, actionCodeSettings) {
        return this.axios.post(`users/firebase/send-email-sign-in`, { email, actionCodeSettings })
    }

    getUidFromMatchingAccount(params = {}) {
        return this.axios.get(`users/uid`, { params })
    }

    linkNewUidToExistingUid(newUid, existingUid) {
        return this.axios.post(`users/firebase/link`, { newUid, existingUid })
    }

}
