<template>
  <div>
    <div class="mb-10">
      <h2 class="mb-5">Fulfillments</h2>
    </div>
    <div>
      <template v-if="fulfillments.loading">
        <div class="flex items-center space-x-5">
          <Loader class="w-10 h-10 text-green" />
          <p>Loading fulfillments...</p>
        </div>
      </template>
      <template v-else-if="fulfillments.data && fulfillments.data.length">
        <div class="flex flex-col">

            <ListView
            class="mb-10"
            :fulfillments="fulfillments.data"
            @update="updateFulfillments"
            />
            <p class="text-right self-end xl:w-1/3 mb-4" style="text-wrap: pretty;">* The single meal limit indicates a limit for a 10 meal order. This number will be doubled for a 20 meal order.</p>
            <p class="text-right self-end xl:w-1/3" style="text-wrap: pretty;">** The trade limit indicates how many meals can be increased/decreased for a single order.</p>
        </div>
      </template>
      <template v-else>
        <p></p>
        <p>
          <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No fulfillments to
          show. Please refine your critera.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import Loader from '@components/global/Loader'
import ListView from '@components/fulfillments/ListView'

export default {
  components: {
    Loader,
    ListView,
  },

  metaInfo() {
    return {
      title: this.title,
    }
  },

  data() {
    return {
      modals: {
      },
      fulfillments: {
        loading: true,
        data: [],
      },
    }
  },

  computed: {
    title() {
      return 'Fulfillments'
    },
  },

  methods: {
    getFulfillments() {
        this.$app.api.fulfillments.getFulfillments().then((response) => {
            this.fulfillments.loading = false
            this.fulfillments.data = response.data
        })
    },
    updateFulfillments() {
        this.getFulfillments()
    },
  },

  created() {
    this.getFulfillments()
  },
}
</script>

<style>
</style>
