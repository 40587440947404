<template>
    <div>
        <DeleteAnnouncementModal v-if="deleteAnnouncementId" :visible="modals.deleteAnnouncement"
            :announcementId="deleteAnnouncementId" @close="modals.deleteAnnouncement = false" />
        <h1 class="mb-5">Dashboard</h1>
        <div class="mb-4">
            <div v-if="announcements.loading">
                <p>Loading announcements...</p>
            </div>
            <div v-else>
                <div class="items-center flex justify-between mb-4">
                    <h4>Announcements</h4>
                    <router-link v-if="permissionToCreate"
                        class="h-[42px] btn flex items-center green border border-green px-3 py-2 mr-2"
                        :to="`/announcements/create`">
                        <span class="whitespace-nowrap"><i class="fas fa-plus mr-2"></i>New Announcement</span>
                    </router-link>
                </div>
                <p v-if="announcements.data.length === 0">There are no announcements.</p>
                <div v-else>
                    <AnnouncementsGridView :announcements="announcements.data"
                        @deleteAnnouncement="deleteAnnouncement" />
                </div>
            </div>
        </div>
        <div v-if="locations">
            <div class="mb-4">
                <div v-if="customClassRequests.loading">
                    <p>Loading custom class requests...</p>
                </div>
                <div v-else>
                    <h4 class="mb-4">Custom Class Requests</h4>
                    <p v-if="customClassRequests.data.length === 0">There are no custom class requests.</p>
                    <div v-else>
                        <CustomClassGridView :customClassRequests="customClassRequests.data" />
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <div v-if="unconfirmedRegistrations.loading">
                    <p>Loading unconfirmed registrations...</p>
                </div>
                <div v-else>
                    <h4 class="mb-4">Unconfirmed Registrations</h4>
                    <p v-if="unconfirmedRegistrations.data.length === 0">All registration selections are confirmed.</p>
                    <div v-else>
                        <p class="mb-4">The following customers have unconfirmed selections</p>
                        <UnconfirmedRegistrationsGridView :unconfirmedRegistrations="unconfirmedRegistrations.data" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomClassGridView from '@components/dashboard/CustomClassGridView'
import UnconfirmedRegistrationsGridView from '@components/dashboard/UnconfirmedRegistrationsGridView'
import AnnouncementsGridView from '@components/dashboard/AnnouncementsGridView'
import DeleteAnnouncementModal from '@components/dashboard/modals/DeleteAnnouncementModal'

export default {
    name: 'Dashboard',

    components: {
        CustomClassGridView,
        UnconfirmedRegistrationsGridView,
        AnnouncementsGridView,
        DeleteAnnouncementModal
    },

    data() {
        return {
            customClassRequests: {
                data: [],
                loading: false,
            },
            unconfirmedRegistrations: {
                data: [],
                loading: false,
            },
            announcements: {
                data: [],
                loading: false,
            },
            modals: {
                deleteAnnouncement: false
            },
            deleteAnnouncementId: null
        }
    },

    computed: {
        locations() {
            return this.$app.services.auth.locationManagerVerification()
        },
        permissionToCreate() {
            return this.$app.services.auth.hasPermission('announcements.create')
        }
    },

    methods: {
        getAnnouncements() {
            this.announcements.loading = true

            const userRoles = this.$app.services.auth.getRoles().join('|')

            let params = {
                roles: userRoles
            }

            this.$app.api.announcements.getAnnouncements(params)
                .then(response => {
                    this.announcements.data = response.data
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.announcements.loading = false
                })
        },
        deleteAnnouncement(id) {
            this.modals.deleteAnnouncement = true
            this.deleteAnnouncementId = id
        },
        getCustomClassRequests() {
            this.customClassRequests.loading = true
            const locationIds = this.locations.map(location => location).join('|')

            let params = {
                locations: locationIds
            }

            this.$app.api.classes.getCustomClassRequests(params)
                .then(response => {
                    this.customClassRequests.data = response.data
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.customClassRequests.loading = false
                })
        },
        getUnconfirmedRegistrations() {
            this.unconfirmedRegistrations.loading = true
            const locationIds = this.locations.map(location => location).join('|')

            let params = {
                locations: locationIds
            }

            this.$app.api.registrations.getUnconfirmedRegistrations(params)
                .then(response => {
                    this.unconfirmedRegistrations.data = response.data
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.unconfirmedRegistrations.loading = false
                })
        }

    },

    mounted() {
        this.getAnnouncements()
        if (this.locations) {
            this.getCustomClassRequests()
            this.getUnconfirmedRegistrations()
        }
    }
}
</script>

<style></style>
