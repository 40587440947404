<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="flex justify-between space-x-3" :class="[isPastClass ? 'mb-4' : 'mb-8']">
                <h3>Send Confirmation</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div v-if="isPastClass" class="mb-5 font-bold text-red">{{ registration.class.sku }} is in the past.<br> Are you sure you want to resend the confirmation?</div>
            <div>
                <div class="mb-10">
                    <p>Email will be sent to:</p>
                    <p class="font-bold">{{ registration.guest.name }} ({{ registration.guest.email }})</p>
                </div>
            </div>
            <div class="flex space-x-5">
                <button class="btn white px-6" @click="close">Cancel</button>
                <button class="btn px-6" @click="sendConfirmation" :disabled="loading || success || $v.$anyError">
                    <div v-if="loading" class="flex items-center"><Loader class="w-5 mr-1"/><span>Sending</span></div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Sent!</span>
                    <span v-else>Send</span>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'

export default {
    props: {
        registration: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },

    components: {
        Modal,
        Loader
    },

    data(){
        return {
            loading: false,
            success: false,
            email: '',
            bccEmail: '',
            shouldBcc: true
        }
    },

    computed: {
        isPastClass() {
            return this.registration.class_event_id && Date.now() > Date.parse(this.registration.class.date);
        }
    },

    methods: {
        close(){
            this.resetFields()
            this.$emit('close')
        },
        resetFields(){
            this.email = this.registration?.guest?.email ?? ''
            this.bccEmail = this.registration.class?.location?.lead?.email ?? ''
            this.shouldBcc = true
        },
        sendConfirmation(){
            let params = {
                email: this.email,
                should_bcc: this.shouldBcc,
                bcc_email: this.bccEmail
            }

            this.loading = true
            return this.$app.api.registrations.sendConfirmation(this.registration.id, params).then(res => {
                this.success = true
                setTimeout(() => {
                    this.success = false
                    this.close()
                }, 2000);
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading = false)
        },
    },

    created(){
        this.resetFields()
    },

    validations: {
        email: {required, email},
        bccEmail: {email}
    }
}
</script>

<style>

</style>
