<template>
    <div class="flex h-screen justify-center items-center bg-cream">
        <div>
            <img src="/img/logos/logo.svg" class="mx-auto w-60 animate-pulse">
        </div>
    </div>
</template>

<script>
export default {
    name: 'DefaultLayout',
    metaInfo() {
        return {
            title: 'Citrus Pear',
            titleTemplate: '%s',
        }
    },
}
</script>

<style>

</style>
