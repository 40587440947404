const state = () => {
    return {
        original: {
            id: null,
            guest: null,
            status: null,
        },
        update: {
            data: {
                classEvent: null,
                portion: null,
                variation: null,
                selections: null,
                instructions: null,
                prepInstructions: null,
                shipping: null,
                contact: null,
            },
            review: {
                classEvent: false,
                portion: false,
                selections: false,
                shipping: false,
                payment: false,
                refund: false,
            }
        },
    }
}

const getters = {}

const mutations = {
    setRegistration(state, data) {
        for (const [key, value] of Object.entries(data)) {
            if (value != null || typeof value !== 'undefined') state.original[key] = value
        }
    },
    setUpdateData(state, data) {
        for (const [key, value] of Object.entries(data)) {
            if (value != null || typeof value !== 'undefined') state.update.data[key] = value
        }
    },
    setUpdateReview(state, data) {
        for (const [key, value] of Object.entries(data)) {
            if (value != null || typeof value !== 'undefined') state.update.review[key] = value
        }
    },
    resetUpdateReview(state, data) {
        data.forEach((item) => {
            state.state.update.review[item] = false
        })
    },
    reset(storeState) {
        Object.assign(storeState, state())
    }
}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}
