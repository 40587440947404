// base class for all services

export default class Service {

    constructor(app){
        this.router = app.router
        this.store = app.store
        this.api = app.api
        this.events = app.events
        this.services = app.services
    }

}
