import Resource from "./resource";

export default class Fulfillments extends Resource {
    /**
     *
     * @param {object} params
     */
    getFulfillments(params = {}) {
        return this.axios.get(`fulfillments`, { params });
    }

    /**
     * @param {string} id
     */
    updateFulfillment(id, data) {
        return this.axios.put(`fulfillments/${id}`, data);
    }
}
