<template>
    <div class="flex flex-col p-6 w-full">
        <div class="mb-8 flex justify-between space-x-3">
            <h3>Edit Location</h3>
        </div>

        <div v-if="loading">
            <div class="flex items-center space-x-5">
                <Loader class="w-10 h-10 text-green" />
                <p>Loading location...</p>
            </div>
        </div>
        <div v-else-if="location">
            <LocationForm v-model="location" @input="handleChange" />

            <div class="flex space-x-5 mt-6">
                <router-link class="btn white px-6" :to="`/locations/${locationId}`">Cancel</router-link>
                <button class="btn button-green px-6" @click="updateLocation" :disabled="!canEditLocation || saving">
                    <div v-if="saving" class="flex items-center space-x-1">
                        <Loader class="w-5 h-5" />
                        <span>Saving</span>
                    </div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Saved!</span>
                    <span v-else>Save</span>
                </button>
            </div>
        </div>
        <div v-else>
            <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
        </div>

        <!-- Render server errors if any on update -->
        <div v-if="errors" class="mt-6">
            <ul class="ml-1">
                <li v-for="(error, i) in errors" :key="`create-location-error-${i}`" class="text-red text-sm">{{ error
                    }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import Loader from '@components/global/Loader'
import LocationForm from '@components/locations/LocationForm'

export default {
    name: 'EditLocation',

    components: {
        Loader,
        LocationForm,
    },

    data() {
        return {
            loading: false,
            saving: false,
            success: false,
            isValid: false,
            location: null,
            updateData: null,
            errors: null,
        }
    },

    methods: {
        handleChange({ location, isValid, deliveryZipcodesText }) {
            this.updateData = location
            if (this.updateData.location_vendor) {
                this.updateData.location_vendor_id = this.updateData.location_vendor.id
            }
            if (deliveryZipcodesText) {
                this.updateData.delivery_zipcodes = deliveryZipcodesText.split(',').map(zipcode => zipcode.trim())
            }
            this.isValid = isValid
        },
        getLocation() {
            // default params
            const params = {
                relations: [
                    'address',
                    'zipcodes',
                    'zipcodes.zipcode',
                    'locationVendor',
                ].join('|')
            }

            this.loading = true
            this.$app.api.locations.getLocation(this.locationId, params)
                .then(({ data }) => {
                    this.location = {
                        name: data.name,
                        store_name: data.store_name,
                        street_1: data.address.street_1,
                        street_2: data.address.street_2,
                        city: data.address.locality,
                        state: data.address.administrative_area,
                        postal_code: data.address.postal_code,
                        country: data.address.country,
                        active: data.active,
                        price_modifier: data.price_modifier,
                        surcharge: data.surcharge,
                        default_class_size: data.default_class_size,
                        couple_class_size: data.couple_class_size,
                        pickup_class_size: data.pickup_class_size,
                        code: data.code,
                        timezone: data.timezone,
                        lead_name: data.lead.name,
                        lead_email: data.lead.email,
                        regional_name: data.regional.name,
                        regional_email: data.regional.email,
                        google_drive_folder: data.google_drive_folder,
                        grocery_etrog_note: data.grocery_etrog_note,
                        meat_etrog_note: data.meat_etrog_note,
                        lat: data.lat,
                        long: data.long,
                        allow_custom_class: data.allow_custom_class,
                        delivery_zipcodes: data.zipcodes
                            .filter(zipcode => zipcode.distance === null)
                            .sort((a, b) => a.zipcode.zipcode.localeCompare(b.zipcode.zipcode))
                            .map(zipcode => zipcode.zipcode),
                        location_vendor: data.location_vendor,
                        location_vendor_id: data.location_vendor_id,
                        shipping: data.shipping,
                    }
                })
                .catch(error => console.error(error))
                .finally(() => this.loading = false)
        },
        updateLocation() {
            this.saving = true
            this.errors = null
            this.updateData.location_vendor_id = this.updateData.location_vendor.id

            this.$app.api.locations.editLocation(this.locationId, this.updateData)
                .then(response => {
                    this.success = true
                    setTimeout(() => {
                        this.success = false
                        this.$router.push(`/locations/${this.locationId}`)
                    }, 2000)
                })
                .catch(error => {
                    if (error.response?.data?.errors) this.errors = Object.values(error.response.data.errors).flat()
                    else if (error.response?.data?.message) this.errors = [error.response.data.message]
                    else this.errors = ['Whoops... something went wrong!']
                    console.error(error)
                })
                .finally(() => this.saving = false)
        },
    },

    computed: {
        canEditLocation() {
            return this.location && this.isValid && this.$app.services.auth.hasPermission('locations.update')
        },
        locationId() {
            return this.$route.params.id
        },
    },

    created() {
        this.getLocation()
    }

}
</script>

<style lang="scss" scoped></style>
