<template>
    <div class="md:grid grid-cols-1 gap-4">

        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">How many codes would you like?</label>
            <input v-model="$v.giftCard.numCards.$model" type="number" class="cp-input mb-2" min="1"
                :class="{ 'cp-input-error': $v.giftCard.numCards.$error }">
        </div>

        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Amount*:</label>
            <input v-model="$v.giftCard.amount.$model" type="number" class="cp-input mb-2" min="1"
                :class="{ 'cp-input-error': $v.giftCard.amount.$error }">
            <template v-if="$v.giftCard.amount.$error">
                <p v-if="!$v.giftCard.amount.required" class="text-red text-xs ml-1">Amount is required</p>
            </template>
        </div>

        <div class="col-span-1">
            <Checkbox v-model="$v.giftCard.enabled.$model" class="mb-2 ml-1" label="Enabled" />
            <Checkbox v-model="$v.giftCard.csv.$model" class="mb-2 ml-1" label="Generate CSV" />
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
import { required } from 'vuelidate/lib/validators'
import Textbox from '@/components/global/forms/Textbox'
import Checkbox from '@components/global/forms/Checkbox'

export default {
    name: "GiftCardForm",
    components: {
        Checkbox,
        Textbox,
    },

    props: {
        value: {
            default: null
        }
    },

    data() {
        return {
            giftCard: {
                numCards: 1,
                amount: null,
                enabled: true,
                csv: false,
            },
        }
    },

    methods: {
        emitGiftCard() {
            if (this.isValid) this.$emit('input', this.giftCard)
        }
    },

    computed: {
        isValid() {
            return !this.$v.giftCard.$invalid
        },
    },

    watch: {
        giftCard: {
            handler(value) {
                this.debouncedEmit()
            },
            deep: true
        }
    },

    created() {
        this.debouncedEmit = _.debounce(this.emitGiftCard, 500)

        if (this.value) {
            this.giftCard = JSON.parse(JSON.stringify(this.value))
        }
    },

    validations: {
        giftCard: {
            numCards: {},
            amount: { required },
            enabled: {},
            csv: {},
        },
    }
}
</script>

<style lang="scss" scoped></style>
