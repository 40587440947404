<template>
    <div>
        <div class="hidden lg:grid grid-cols-4 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="col-span-2 label">Promotion</h6>
            <h6 class="label">Code</h6>
            <h6 class="label">Amount</h6>
        </div>
        <div class="lg:divide-y lg:divide-gray-200 lg:border lg:border-t-0 border-gray-200 lg:border-gray-200 rounded-b-md">
            <div v-for="discount in discounts" :key="discount.id" class="bg-gray-100 p-5 py-3 lg:mb-0 rounded-md lg:rounded-none lg:bg-transparent lg:px-0">
                <div class="grid grid-cols-1 lg:grid-cols-4 gap-2 gap-y-4 lg:px-2">
                    <div class="lg:col-span-2">
                        <h6 class="label lg:hidden">Promotion</h6>
                        <p class="font-medium">{{ discount.promotion.name }}</p>
                        <p v-if="discount.promotion.display_name" class="text-sm">{{ discount.promotion.display_name }}</p>
                    </div>
                    <div>
                        <h6 class="label lg:hidden mb-1">Code</h6>
                        <p v-if="discount.promotion_code.key" class="font-mono text-sm font-semibold uppercase">
                            <span class="bg-white lg:bg-gray-100 px-3 py-1 rounded">{{ discount.promotion_code.key }}</span>
                        </p>
                        <p v-else>---</p>
                    </div>
                    <div>
                        <h6 class="label lg:hidden">Amount</h6>
                        <p class="font-medium">{{ discount.amount | currency }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        discounts: {
            type: Array,
            required: true
        }
    },

    data(){
        return {
        }
    },
}
</script>

<style>

</style>
