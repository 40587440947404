import Service from "./service"

export default class User extends Service {

    /**
     * return the user object from the store
     */
    getUser(){
        return this.store.state.user.user
    }

}
