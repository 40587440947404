import Resource from './resource'

export default class Payments extends Resource {

    /**
     * get a payment
     * @param {int|string} id
     * @param {object} params
     */
    getPayment(id, params = {}){
        return this.axios.get(`payments/${id}`, { params })
    }

    /**
     * refund a payment
     * @param {object} data post data
     */
    refund(id, data){
        return this.axios.post(`payments/${id}/refund`, data)
    }

    /**
     * get all refund reasons
     */
    getRefundReasons(){
        return this.axios.get(`refundReasons`)
    }

    /**
     * get all payment services
     */
    getPaymentServices(){
        return this.axios.get(`paymentServices`)
    }

    /**
     * get all payment types
     */
    getPaymentTypes(){
        return this.axios.get(`paymentTypes`)
    }
}
