import Resource from "./resource";

export default class Roles extends Resource {
  /**
   * Get roles
   * @param {object} params query string params
   */
  getRoles() {
    return this.axios.get(`roles`, {});
  }
}
