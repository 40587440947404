<template>
    <div>
        <div v-if="loading.giftCard" class="flex items-center space-x-5">
            <Loader class="w-10 h-10 text-green"/>
            <p>Loading gift card details...</p>
        </div>
        <div v-else-if="giftCard">
            <!-- Heading -->
            <div class="mb-14 flex justify-between">
                <div>
                    <h2 class="mb-2">Gift Card ID# {{ giftCard.id }}</h2>
                    <p><StatusBadge :enabled="giftCard.enabled"/></p>
                </div>
                <div>
                    <multiselect
                            class="max-w-xs"
                            v-model="status"
                            :options="statuses"
                            :loading="loading.updateStatus"
                            :disabled="loading.updateStatus"
                            :searchable="false"
                            :close-on-select="true"
                            :show-labels="false"
                            :allow-empty="false"
                            label="name"
                            track-by="id"
                            placeholder="Select status"
                            @input="updateStatus"
                    >
                        <template slot="placeholder"><span class="text-gray-400">Select status</span></template>
                        <template #caret="{toggle}">
                            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>

            <!-- Details -->
            <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-6 mb-14">

                <!-- Card Number -->
                <div v-if="giftCard.card_number">
                    <h6 class="label">Card Number</h6>
                    <p>{{ giftCard.card_number }}</p>
                </div>

                <!-- Card Code -->
                <div v-if="giftCard.card_code">
                    <h6 class="label">Card Code</h6>
                    <p>{{ giftCard.card_code }}</p>
                </div>

                <!-- Balance -->
                <div>
                    <h6 class="label">Current Balance</h6>
                    <p>{{ giftCard.balance | currency }}</p>
                </div>

                <!-- Recipient -->
                <div v-if="giftCard.recipient">
                    <h6 class="label">Recipient</h6>
                    <p>{{ giftCard.recipient.first_name }} {{ giftCard.recipient.last_name }}</p>
                    <p>{{ giftCard.recipient.email }}</p>
                </div>

                <!-- Purchaser -->
                <div v-if="giftCard.purchaser">
                    <h6 class="label">Purchaser</h6>
                    <p>{{ giftCard.purchaser.first_name }} {{ giftCard.purchaser.last_name }}</p>
                    <p>{{ giftCard.purchaser.email }}</p>
                </div>

                <!-- Digital Delivery Sent -->
                <div>
                    <h6 class="label">Email Sent</h6>
                    <template v-if="giftCard.order_item && giftCard.order_item.digital_sent_at">
                        <p><Date :date="giftCard.order_item.digital_sent_at" preset="DATE_FULL"/></p>
                        <p><Date :date="giftCard.order_item.digital_sent_at" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
                    </template>
                    <template v-else>
                        <p>No</p>
                    </template>
                </div>

                <!-- Created At -->
                <div>
                    <h6 class="label">Created At</h6>
                    <p><Date :date="giftCard.created_at" preset="DATE_FULL"/></p>
                    <p><Date :date="giftCard.created_at" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
                </div>

                <!-- Expires At -->
                <div v-if="giftCard.expiration">
                    <h6 class="label">Created At</h6>
                    <p><Date :date="giftCard.expiration" preset="DATE_FULL"/></p>
                    <p><Date :date="giftCard.expiration" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
                </div>
            </div>

            <!-- Transactions -->
            <div class="mb-14" v-if="giftCard.transactions && giftCard.transactions.length">
                <div class="flex space-x-2 items-center mb-2">
                    <h4><i class="fas fa-fw fa-address-card mr-2"></i>Transactions</h4>
                    <p v-if="giftCard.transactions" class="text-sm font-semibold rounded-2xl bg-mint px-3">{{ giftCard.transactions.length }}</p>
                </div>
                <TransactionListView v-if="giftCard.transactions && giftCard.transactions.length" :transactions="giftCard.transactions" class="mb-2"/>
                <div v-else>
                    <p class="text-sm text-gray-400">No transactions.</p>
                </div>
                <!-- <button class="btn small white mt-2"><i class="fas fa-fw fa-plus"></i> Add Contact</button> -->
            </div>

        </div>
        <div v-else>
            <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
        </div>
    </div>
</template>

<script>
import Date from '@components/global/dates/Date'
import Loader from '@components/global/Loader'
import StatusBadge from '@components/giftcards/StatusBadge'
import TransactionListView from '@components/giftcards/transactions/ListView'

export default {
    name: "GiftCard",

    components: {
        Date,
        Loader,
        StatusBadge,
        TransactionListView,
    },

    data() {
        return {
            loading: {
                giftCard: false,
                updateStatus: false,
            },
            giftCard: null,
            statuses: [{id: 1, name: 'Enabled', key: 'enabled', enabled: true}, {id: 2, name: 'Disabled', key: 'disabled', enabled: false}],
            status: null,
        }
    },

    computed: {
        giftCardId() {
            return this.$route.params.id
        },
    },

    methods: {
        updateStatus() {
            if(this.status == null) {
                this.setStatus()
                return
            }
            if(this.status.enabled === this.giftCard.enabled) return

            let data = {
                enabled: this.status.enabled
            }

            this.loading.updateStatus = true
            return this.$app.api.giftCards.updateStatus(this.giftCard.id, data)
                .then((response) => {
                    this.giftCard = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => { this.loading.updateStatus = false })
        },
        getCard(){
            this.loading.giftCard = true
            return this.$app.api.giftCards.getCard(this.giftCardId)
                .then((response) => {
                    this.giftCard = response.data

                    this.setStatus()
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading.giftCard = false
                })
        },
        setStatus() {
            this.status = this.statuses.filter((status) => { return status.enabled === this.giftCard?.enabled})
        }
    },

    created() {
        this.getCard()
    },

    mounted() {}
}
</script>

<style lang="scss" scoped>
</style>
