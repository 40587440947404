

import Vue from 'vue'
import AppView from './App.vue'
import router from './router'
import store from './store'

// Application logic
import App from './app/index'

// Packages
import VueMeta from "vue-meta"
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import Vuelidate from 'vuelidate'
import VCalendar from 'v-calendar';
import Multiselect from 'vue-multiselect'
import vClickOutside from 'v-click-outside'

// Filters
import * as Filters from '@shared/filters'

// Styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/styles/styles.scss"

//console.log('process.env.VUE_APP_MAINTENANCE_MODE', process.env.VUE_APP_MAINTENANCE_MODE)

if (process.env.VUE_APP_MAINTENANCE_MODE === 'true') {
    new Vue({
        render: h => h('h1', 'Admin is currently down for maintenance.'),
    }).$mount('#app');
}

// Create and assign global $app instance variable
Vue.prototype.$app = new App(router, store)

// Packages
Vue.use(VueMeta)
Vue.use(VueTheMask)
Vue.use(money, {precision: 2})
Vue.use(Vuelidate)
Vue.use(VCalendar)
Vue.use(vClickOutside)

// Components
Vue.component('multiselect', Multiselect)

// Import all global filters
Object.values(Filters).forEach(filter => {
    Vue.use(filter)
})

// Disable production tip in console
Vue.config.productionTip = false

// Instantiate + mount Vue app
new Vue({
    router,
    store,
    render: h => h(AppView)
}).$mount('#app')
