<template>
    <form>
        <div class="mb-4">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">First Name*</label>
            <input v-model="$v.contact.first_name.$model" type="text" class="w-full mb-0" :class="{'border-red': $v.contact.first_name.$error}" placeholder="">
            <template v-if="$v.contact.first_name.$error">
                <p v-if="!$v.contact.first_name.required" class="text-red text-xs ml-1">First name is required</p>
            </template>
        </div>
        <div class="mb-4">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">Last Name*</label>
            <input v-model="$v.contact.last_name.$model" type="text" class="w-full mb-0" :class="{'border-red': $v.contact.last_name.$error}" placeholder="">
            <template v-if="$v.contact.last_name.$error">
                <p v-if="!$v.contact.last_name.required" class="text-red text-xs ml-1">Last name is required</p>
            </template>
        </div>
        <div class="mb-4">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">Email*</label>
            <input v-model="$v.contact.email.$model" type="text" class="w-full mb-0" :class="{'border-red': $v.contact.email.$error}" placeholder="">
            <template v-if="$v.contact.email.$error">
                <p v-if="!$v.contact.email.required" class="text-red text-xs ml-1">Email is required</p>
                <p v-if="!$v.contact.email.email" class="text-red text-xs ml-1">A valid email address is required.</p>
            </template>
        </div>
        <div class="mb-4">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">Phone*</label>
            <the-mask mask="(###) ###-####" v-model="$v.contact.phone.$model" @blur.native="$v.contact.phone.$touch" type="tel" class="w-full mb-0" :class="{'border-red': $v.contact.phone.$error}" placeholder="(###) ###-####" />
            <template v-if="$v.contact.phone.$error">
                <p v-if="!$v.contact.phone.required" class="text-red text-xs ml-1">Phone is required</p>
            </template>
        </div>
    </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
    name: "ContactForm",

    props: {
        value: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            contact: {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
            }
        }
    },

    watch: {
        contact: {
            handler(newValue, oldValue) {
                this.emitInput()
            },
            deep: true,
        }
    },

    computed: {},

    methods: {
        emitInput() {
            this.$emit('input', this.$v.contact);
        },
    },

    created() {
        if(this.value) this.contact = this.value
    },

    validations: {
        contact: {
            first_name: { required },
            last_name: { required },
            email: { required, email },
            phone: { required },
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
