import Resource from "./resource";

export default class Jobs extends Resource {
    getClassEventJobId(classEventId, params = {}) {
        return this.axios.get(`jobs/classEvent/${classEventId}/job`, { params });
    }

    getMenuJobId(menuId, params = {}) {
        return this.axios.get(`jobs/menu/${menuId}/job`, { params });
    }

    getMealJobId(mealId, params = {}) {
        return this.axios.get(`jobs/meal/${mealId}/job`, { params });
    }

    getJob(params = {}) {
        return this.axios.get(`jobs/job`, { params });
    }

    checkFailedJob(uuid) {
        return this.axios.get(`jobs/failed/${uuid}`);
    }

    checkJobResult(params = {}) {
        return this.axios.get(`jobs/result`, { params });
    }
}