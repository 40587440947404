<template>
    <div>
        <!-- Modals -->
        <ConfirmDeleteLocationModal
            :visible="modals.deleteLocation"
            :locationId="selectedLocationId"
            @close="modals.deleteLocation = false"
            @update="updateLocations"
        />

        <!-- Locations -->
        <div class="mb-10">
            <h2 class="mb-5">Locations</h2>
            <div class="flex flex-wrap">
                <SearchInput class="mr-2 mb-2" v-model="locations.search" placeholder="Search by name or code" @search="runSearch"/>

                <button class="rounded-md border border-gray bg-white mr-2 mb-2 px-3 py-2 cursor-pointer" @click="clearSearch">Clear</button>

                <router-link v-if="canCreateLocation" class="btn green border border-green mr-2 mb-2 px-3 py-2" :to="`/locations/create`" :disabled="!canCreateLocation">
                    <span><i class="fas fa-fw fa-plus"></i></span>
                </router-link>
            </div>
        </div>
        <div>
            <template v-if="locations.loading">
                <div class="flex items-center space-x-5">
                    <Loader class="w-10 h-10 text-green"/>
                    <p>Loading locations...</p>
                </div>
            </template>
             <template v-else-if="locations.data.data && locations.data.data.length">
                <ListView class="mb-10" :locations="locations.data.data" @delete="deleteLocation"/>
                <Pagination v-if="totalLocations > 10" :page="locations.page" :total-items="totalLocations" :per-page="locations.perPage" @change="pageUpdated"/>
            </template>
            <template v-else>
                <p></p>
                <p><i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No locations to show. Please refine your critera.</p>
            </template>
        </div>
    </div>
</template>

<script>
import Loader from '@components/global/Loader'
import Checkbox from '@components/global/forms/Checkbox'
import SearchInput from '@components/global/SearchInput'
import Pagination from '@components/global/Pagination'
import ListView from '@components/locations/ListView'
import ConfirmDeleteLocationModal from '@components/locations/modals/ConfirmDeleteLocationModal'

export default {
    components: {
        Loader,
        Checkbox,
        SearchInput,
        Pagination,
        ListView,
        ConfirmDeleteLocationModal,
    },

    metaInfo(){
        return  {
            title: this.title,
        }
    },

    data(){
        return {
            locations: {
                page: parseInt(this.$route.query.page) || 1,
                perPage: parseInt(this.$route.query.per_page) || 10,
                loading: false,
                search: this.$route.query.search || '',
                inactiveSelected: false,
                customSelected: false,
                data: {},
                fulfillmentId: '',
                classPublic: false,
                classFuture: false
            },
            selectedLocationId: null,
            modals: {
                deleteLocation: false,
            },
        }
    },

    computed: {
        title(){
            if(this.$route.query.search) return `Search: ${this.$route.query.search} | Locations`
            return 'Locations'
        },
        totalLocations(){
            return this.locations.data.meta?.total ?? 0
        },
        canCreateLocation(){
            return this.$app.services.auth.hasPermission('locations.create')
        },
        canViewAllLocations(){
            return this.$app.services.auth.hasPermission('locations.view')
        },
    },

    methods: {
        pageUpdated(page){
            this.locations.page = page
            this.getLocations()
        },
        unfilterInactive(){
            if(!this.canViewAllLocations) return

            let search = []
            if(this.locations.search) search = this.locations.search.split(',').map(i => i.trim())
            if(this.locations.inactiveSelected) search = search.filter(i => i !== 'inactive')
            else search.push('inactive')
            this.locations.search = search.join(',')
            this.locations.inactiveSelected = ! this.locations.inactiveSelected

            this.runSearch()
        },
        runSearch(){
            this.locations.page = 1

            let query = { ...this.$route.query, search: this.locations.search.split(',').map(i => i.trim()).join(',') }
            delete query.page

            if(!this.locations.search) delete query.search
            else if(!this.canViewAllLocations) query.search.split(',').filter(i => i !== 'inactive').join(',')
            this.$router.push({ path: this.$route.path, query })

            this.getLocations()
        },
        clearSearch(){
            this.locations.inactiveSelected = false
            this.locations.customSelected = false
            this.locations.fulfillmentId = ''
            this.locations.classPublic = false
            this.locations.classFuture = false
            this.locations.search = ''
            this.locations.page = 1

            let query = { ...this.$route.query }
            delete query.page
            delete query.search
            this.$router.push({ path: this.$route.path, query })

            this.getLocations()
        },
        getLocations(){
            // default params
            let params = {
                relations: 'address',
                page: this.$route.query.page || this.locations.page,
                per_page: this.$route.query.per_page || this.locations.perPage,
                active: true,
                order_by: 'name',
            }

            // optional params
            if(this.$route.query.search) {
                this.locations.inactiveSelected = false
                const search = this.$route.query.search.split(',')

                // inactive
                if(this.canViewAllLocations && search.filter(i => i === 'inactive').length > 0) {
                    this.locations.inactiveSelected = true
                    delete params.active
                } else this.locations.inactiveSelected = false

                // custom_class
                if(search.filter(i => i === 'custom').length > 0) {
                    this.locations.customSelected = true
                    params.custom_class = true
                } else this.locations.customSelected = false

                // location_ids
                const location_ids = search.filter(i => Number.isInteger(+i))
                if(location_ids.length > 0) params.location_ids = location_ids.join('|')

                // fulfillment_id
                const fulfillment_id = search.filter(i => i.includes('fulfillment_id_'))[0]
                if(fulfillment_id) {
                    const id = fulfillment_id.replace('fulfillment_id_', '')
                    this.locations.fulfillmentId = id
                    params.fulfillment_id = id

                    // class_public
                    if(search.filter(i => i === 'public')[0]) {
                        this.locations.classPublic = true
                        params.class_public = true
                    } else this.locations.classPublic = false

                    // class_future
                    if(search.filter(i => i === 'future')[0]) {
                        this.locations.classFuture = true
                        params.class_future = true
                    } else this.locations.classFuture = false
                } else {
                    this.locations.classPublic = false
                    this.locations.classFuture = false

                    this.locations.search = this.locations.search.split(',').map(i => i.trim()).filter(i => i !== 'public' && i !== 'future').join(',')
                }

                // location name
                const location_name = search.filter(i => i !== 'inactive' && i !== 'custom' && i !== 'public' && i !== 'future' &&! i.includes('fulfillment_id_') && ! Number.isInteger(+i))
                if(location_name.length > 0) params.location_name = location_name.join(' ')
            }

            this.locations.loading = true
            this.$app.api.locations.getLocations(params).then(response => {
                this.locations.data = response.data
            }).catch(err => {
                console.error(err)
            }).finally(() => this.locations.loading = false)
        },
        updateLocations(){
            this.getLocations()
        },
        deleteLocation(locationId){
            this.selectedLocationId = locationId
            this.modals.deleteLocation = true
        },
    },

    created(){
        this.getLocations()
    }
}
</script>

<style>

</style>
