<template>
    <div class="bg-gray-100 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
        <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-12 gap-2 xl:px-3">
            <div class="xl:col-span-2">
                <p class="text-xs text-gray-400 xl:hidden">SKU</p>
                <template v-if="links">
                    <router-link class="hover:underline block" :to="`/classes/${classEvent.id}`">
                        <p class="font-semibold">{{ classEvent.sku }}</p>
                    </router-link>
                </template>
                <template v-else>
                    <p class="font-semibold">{{ classEvent.sku }}</p>
                </template>
                <div class="flex items-center">
                    <FulfillmentBadge :fulfillmentKey="classEvent.fulfillment.key" :small="true" class="mr-2" />
                    <p v-if="classEvent.private_menu" class="mr-2"><span class="badge small">Private</span></p>
                    <p v-else-if="classEvent.reservation_size.key != 'individual'" class="mr-2"><span class="badge small">{{
                        classEvent.reservation_size.name }}</span></p>
                    <p v-if="isVegetarian" class="mr-2"><span class="badge small">Vegetarian</span></p>
                    <p v-if="isSoup" class="mr-2"><span class="badge small">Soup</span></p>
                    <p v-if="classEvent.class_is_public"><i class="fas fa-fw fa-eye mr-2"></i></p>
                    <p v-else><i class="fas fa-fw fa-eye-slash mr-2"></i></p>
                </div>
            </div>
            <div class="xl:col-span-2">
                <p class="text-xs text-gray-400 xl:hidden">Status</p>
                <div>
                    <StatusBadge :classEvent="classEvent" />
                </div>
            </div>
            <div class="xl:col-span-2">
                <p class="text-xs text-gray-400 xl:hidden">Date</p>
                <p>
                    <Date :date="classEvent.date" :timezone="classEvent.location.timezone" preset="DATE_FULL" />
                </p>
                <p>
                    <Date :date="classEvent.date" :timezone="classEvent.location.timezone"
                        preset="TIME_SIMPLE_WITH_SHORT_OFFSET" />
                </p>
            </div>
            <div class="xl:col-span-2">
                <p class="text-xs text-gray-400 xl:hidden">Location</p>
                <p>{{ classEvent.location.name }}</p>
            </div>
            <div class="xl:col-span-2">
                <p class="text-xs text-gray-400 xl:hidden">Lead</p>
                <p>{{ classEvent.location.lead.name }}</p>
            </div>
            <div class="xl:col-span-2">
                <p class="text-xs text-gray-400 xl:hidden">Capacity</p>
                <div class="flex items-center space-x-0.5">
                    <ProgressRing class="flex-shrink-0" :radius="13" :stroke="3"
                        :progress="Math.floor((classEvent.registrations_count / classEvent.capacity) * 100)" />
                    <p>{{ classEvent.registrations_count }}/{{ classEvent.capacity }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from '@components/classes/StatusBadge'
import FulfillmentBadge from '@components/meals/FulfillmentBadge'

import ProgressRing from '@components/global/ProgressRing'
import Date from '@components/global/dates/Date'

export default {
    name: "EventCard",

    props: {
        classEvent: {
            type: Object,
            required: true,
        },
        links: {
            type: Boolean,
            default: true,
        }
    },

    components: {
        StatusBadge,
        ProgressRing,
        Date,
        FulfillmentBadge
    },

    computed: {
        isVegetarian() {
            return this.classEvent.menu && this.classEvent.menu.type && this.classEvent.menu.type.key === 'vegetarian';
        },
        isSoup() {
            return this.classEvent.menu && this.classEvent.menu.type && this.classEvent.menu.type.key === 'soup';
        },
    },

    methods: {},
}
</script>

<style lang="scss" scoped></style>
