const state = () => {
    return {
        user: {}
    }
}
const getters = {}
const mutations = {
    set(state, user){
        state.user = Object.assign({}, state.user, user)
    },
    delete(state){
        state.user = {}
    },
}
const actions = {
    resetState({commit}) {
        return new Promise((resolve, reject) => {
            commit('delete')
            resolve()
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}
