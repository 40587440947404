<template>
    <div>
        <div class="hidden lg:grid grid-cols-3 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <p class="text-xs text-gray-400 font-medium">Shippo</p>
            <p class="text-xs text-gray-400 font-medium">Tracking</p>
            <p class="text-xs text-gray-400 font-medium">Label</p>
        </div>
        <div
            class="lg:divide-y lg:divide-gray-200 lg:border lg:border-t-0 border-gray-200 lg:border-gray-200 rounded-b-md">
            <div v-for="shipment in shipments" :key="shipment.id"
                class="bg-gray-100 px-5 py-3 lg:mb-0 rounded-md lg:rounded-none lg:bg-transparent p-5 lg:px-0 lg:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-3 gap-2 lg:px-2">
                    <div>
                        <p class="text-xs text-gray-400 lg:hidden">Shippo</p>
                        <a v-if="shipment.shippo_order_id"
                            :href="'https://apps.goshippo.com/orders/' + shipment.shippo_order_id" target="_blank"
                            class="btn xs gray">
                            Shippo Order
                        </a>
                    </div>
                    <div>
                        <p class="text-xs text-gray-400 lg:hidden">Tracking</p>
                        <a v-if="shipment.tracking_number"
                            :href="'https://www.fedex.com/apps/fedextrack/?tracknumbers=' + shipment.tracking_number"
                            target="_blank" class="btn xs mint">{{ shipment.tracking_number }}</a>
                    </div>
                    <div>
                        <p class="text-xs text-gray-400 lg:hidden">Label</p>
                        <a v-if="shipment.label_url" :href="shipment.label_url" target="_blank"
                            class="btn xs green">Download</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        shipments: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
        }
    },
}
</script>

<style></style>
