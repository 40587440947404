<template>
    <div class="w-full rounded-md p-2 bg-white shadow-sm border border-gray-200">
        <!-- edit -->
        <button class="menu-item green" :disabled="!canEdit" @click="$emit('edit')">
            <p><i class="fas fa-fw fa-exchange-alt mr-2"></i>Edit</p>
        </button>

        <!-- divider -->
        <div class="px-2 py-2">
            <hr>
        </div>

        <!-- send/resend confirmation -->
        <button class="menu-item green" :disabled="!canSendConfirmation" @click="$emit('sendConfirmation')">
            <p><i class="fas fa-fw fa-file-invoice-dollar mr-2"></i>{{ confirmationHasBeenSent ? 'Resend' : 'Send' }}
                Confirmation</p>
        </button>

        <!-- send/resend reminder -->
        <!-- <button class="menu-item green" :disabled="!canSendReminder" @click="$emit('sendReminder')">
            <p><i class="fas fa-fw fa-clock mr-2"></i>{{ reminderHasBeenSent ? 'Resend' : 'Send' }} Reminder</p>
        </button> -->

        <!-- divider -->
        <!-- <div class="px-2 py-2"><hr></div> -->

        <!-- cancel -->
        <!-- <button class="menu-item red" :disabled="!canCancelRegistration" @click="$emit('cancel')">
            <p><i class="fas fa-fw fa-ban mr-2"></i>Cancel</p>
        </button> -->
    </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
    props: {
        registration: {
            type: Object,
            required: true
        }
    },

    computed: {
        confirmationHasBeenSent() {
            return this.registration.confirmation_sent_at != null
        },
        reminderHasBeenSent() {
            return this.registration.reminder_sent_at != null
        },
        permissionToUpdate() {
            return this.$app.services.auth.hasPermission('registrations.update')
        },
        pastClassDate() {
            return DateTime.fromISO(this.registration.class.date) < DateTime.now()
        },
        canEdit() {
            return (this.$app.services.auth.hasRole('admin') || this.$app.services.auth.hasRole('regional_manager')) || (this.permissionToUpdate && this.notExchanged)
        },
        canExchange() {
            return this.canEdit
        },
        canChangePortions() {
            return this.canEdit
        },
        canChangeSelections() {
            return this.canEdit
        },
        canSendConfirmation() {
            return this.permissionToUpdate && this.notExchanged
        },
        canSendReminder() {
            return this.canEdit && this.notExchanged
        },
        canCancelRegistration() {
            return this.permissionToUpdate && this.registration.status_id == 2
        },
        notExchanged() {
            return this.registration?.status?.key != 3
        },
    }
}
</script>

<style></style>
