<template>
    <transition name="fade">
        <div v-if="visible" @click.self="onClickOutside" class="fixed flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0 overflow-auto bg-black bg-opacity-30 p-5">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        backgroundClose: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        onClickOutside(){
            if(this.backgroundClose) this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
