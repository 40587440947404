import Resource from './resource'

export default class Carts extends Resource {

    /**
     *
     * @param {object} data post data
     */
    createCart(data = {}){
        return this.axios.post(`carts`, data)
    }

    /**
     *
     * @param {string} uuid cart UUID
     */
    deleteCart(uuid) {
        return this.axios.delete(`carts/${uuid}`)
    }

    /**
     *
     * @param {string} uuid cart UUID
     * @param {object} params query params for the request
     */
    getCart(uuid, params = {}){
        return this.axios.get(`carts/${uuid}`, {
            params
        })
    }

    /**
     * @param {string} uuid
     * @param {object} data
     */
    updateCart(uuid, data = {}){
        return this.axios.put(`carts/${uuid}`, data)
    }

    /**
     *
     * @param {string} uuid cart UUID
     * @param {object} data post data
     */
    addClass(uuid, data){
        return this.axios.post(`carts/${uuid}/classes`, data)
    }

    /**
     *
     * @param {string} uuid cart UUID
     * @param {string|int} cartClassId cart class ID
     * @param {object} data request data
     */
    removeClass(uuid, cartClassId, data = {}){
        return this.axios.delete(`carts/${uuid}/classes/${cartClassId}`, { data })
    }

    /**
     *
     * @param {string} uuid cart UUID
     * @param {object} data post data
     */
    applyGiftCard(uuid, data){
        return this.axios.post(`carts/${uuid}/giftCards`, data)
    }

    /**
     *
     * @param {string} uuid cart UUID
     * @param {string|int} giftCardId gift card ID
     * @param {object} data request data
     */
    removeGiftCard(uuid, giftCardId, data = {}){
        return this.axios.delete(`carts/${uuid}/giftCards/${giftCardId}`, { data })
    }

    /**
     *
     * @param {string} uuid cart UUID
     * @param {object} data post data
     */
    applyPromotion(uuid, data){
        return this.axios.post(`carts/${uuid}/promotions`, data)
    }

    /**
     *
     * @param {string} uuid cart UUID
     * @param {string|int} cartPromotionId cart promotion ID
     * @param {object} data request data
     */
    removePromotion(uuid, cartPromotionId, data = {}){
        return this.axios.delete(`carts/${uuid}/promotions/${cartPromotionId}`, { data })
    }

    /**
     * @param {string} uuid cart UUID
     * @param {object} data request data
     */
    setAddress(uuid, data){
        return this.axios.post(`carts/${uuid}/address`, data)
    }

    /**
     * @param {string} uuid cart UUID
     * @param {string|int} addressId address ID
     * @param {*} data request data
     */
    removeAddress(uuid, addressId, data = {}){
        return this.axios.delete(`carts/${uuid}/address/${addressId}`, { data })
    }

    /**
     *
     * @param {*} uuid cart UUID
     * @param {*} data checkout data
     */
    checkout(uuid, data){
        return this.axios.post(`carts/${uuid}/checkout`, data)
    }
}
