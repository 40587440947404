<template>
    <ListView :headers="headers">
        <template #list>
            <slot>
                <div v-for="vendor in vendors" :key="vendor.id" class="mb-5 xl:mb-0">
                    <div class="bg-gray-100 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                        <div class="grid grid-cols-4 gap-2 xl:px-3">
                            <div>
                                <p class="text-xs text-gray-400 xl:hidden">Galley ID</p>
                                <a class="hover:underline block mr-2" target="_blank"
                                    :href="`https://app.galleysolutions.com/locations/${vendor.galley_location_id}`">
                                    <h5>{{ vendor.galley_location_id }}</h5>
                                </a>
                            </div>
                            <div></div>
                            <div>
                                <p class="text-xs text-gray-400 xl:hidden">Name</p>
                                <p>{{ vendor.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </slot>
        </template>
    </ListView>
</template>

<script>
import ListView from '@components/global/ListView'

export default {
    props: {
        vendors: {
            type: Array,
            default: function () {
                return []
            },
        },
        headers: {
            type: Array,
            default: function () {
                return [
                    'Galley ID',
                    '',
                    'Name',
                    '',
                ]
            },
        }
    },

    components: {
        ListView,
    },
}
</script>

<style lang="scss" scoped></style>
