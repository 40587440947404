<template>
    <div>
        <div class="hidden lg:grid grid-cols-7 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="label">ID</h6>
            <h6 class="label">Service</h6>
            <h6 class="label">Method</h6>
            <h6 class="col-span-2 label">Transaction ID</h6>
            <h6 class="label">Amount</h6>
            <h6 class="label">Status</h6>
        </div>
        <div class="lg:divide-y lg:divide-gray-200 lg:border lg:border-t-0 border-gray-200 lg:border-gray-200 rounded-b-md">
            <div v-for="payment in payments" :key="payment.id" class="bg-gray-100 p-5 py-3 mb-5 lg:mb-0 rounded-md lg:rounded-none lg:bg-transparent lg:px-0 lg:py-3">
                <div class="grid grid-cols-1 lg:grid-cols-7 gap-2 gap-y-4 lg:px-2">
                    <div>
                        <h6 class="label lg:hidden">ID</h6>
                        <div class="flex flex-wrap">
                            <p class="font-medium mr-2">{{ payment.id }}</p>
                            <router-link v-if="!isGiftCard && canRefund(payment)" class="block" :to="`/payments/${payment.id}/refund`">
                                <button class="btn xs white rounded normal-case"><i class="fas fa-undo-alt mr-1"></i>Refund</button>
                            </router-link>
                        </div>
                    </div>
                    <div>
                        <h6 class="label lg:hidden">Service</h6>
                        <p>{{ payment.service.name }}</p>
                        <p v-if="payment.gift_card_transaction && payment.gift_card_transaction.card" class="break-words text-sm font-mono font-medium">
                            <span class="bg-white lg:bg-gray-100 rounded px-2 py-1 decoration-clone">
                                {{ payment.gift_card_transaction.card.card_number }}
                            </span>
                        </p>
                    </div>
                    <div>
                        <h6 class="label lg:hidden">Method</h6>
                        <p>{{ payment.type.name }}</p>
                    </div>
                    <div class="lg:col-span-2">
                        <h6 class="label lg:hidden">Transaction ID</h6>
                        <p class="break-words text-sm font-mono font-medium">
                            <span class="bg-white lg:bg-gray-100 rounded px-2 py-1 decoration-clone">{{ payment.transaction_id }}</span>
                        </p>
                    </div>
                    <div>
                        <h6 class="label lg:hidden">Amount</h6>
                        <p class="font-medium">{{ payment.amount | currency }}</p>
                        <p v-if="payment.amount_applied" class="text-xs">Applied: {{ payment.amount_applied | currency }}</p>
                    </div>
                    <div>
                        <h6 class="label lg:hidden">Status</h6>
                        <StatusBadge class="small" :status="payment.status"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from '@components/payments/StatusBadge'
import currency from '@shared/utils/currency'

export default {
    props: {
        payments: {
            type: Array,
            required: true
        },
        isGiftCard: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        StatusBadge
    },

    data(){
        return {
        }
    },

    computed: {
        permissionToRefund(){
            return this.$app.services.auth.hasPermission('payments.refund')
        }
    },

    methods: {
        canRefund(payment){
            return this.permissionToRefund && [3,6].includes(payment.status_id) && currency(payment.refundable).toUnit() > 0
        }
    }
}
</script>

<style>

</style>
