<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Archive News Item</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="mb-10">
                <p>Are you sure you want to archive this news item? This will immediately remove it from the public site!</p>
            </div>
            <div class="flex space-x-5">
                <button class="btn white px-6" @click="close">Cancel</button>
                <button class="btn button-green px-6" @click="archiveNewsItem" :disabled="loading || success">
                    <div v-if="loading" class="flex items-center space-x-1"><Loader class="w-5 h-5"/><span>Archiving...</span></div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Archived!</span>
                    <span v-else>Archive</span>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'

export default {
    props: {
        newsItemId: {
            type: Number|String,
            required: true
        },
        newsType: {
            type: String,
            required: true,
            validator: function(type) {
                return ['pressRelease', 'newsLink'].includes(type)
            },
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Modal,
        Loader
    },
    data(){
        return {
            loading: false,
            success: false,
        }
    },
    computed: {
        resource() {
            return this.newsType === 'pressRelease' ? 'archivePressRelease' : 'archiveNewsLink'
        },
    },
    methods: {
        close(){
            this.$emit('close')
        },
        archiveNewsItem(){
            this.loading = true

            return this.$app.api.newsroom[this.resource](this.newsItemId).then(res => {
                this.success = true
                setTimeout(() => {
                    this.success = false
                    this.close()
                    this.$emit('success')
                }, 2000)
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading = false)
        }
    }
}
</script>

<style>
</style>