<template>
  <form @submit.prevent="() => {}" class="flex">
    <input
      class="border-green-400 focus:border-gray-400 h-10 border text-md rounded-md text-center"
      type="text"
      inputmode="numeric"
      autocomplete="one-time-code"
      v-model="code"
      :maxlength="digits"
      ref="inputRef"
    />
  </form>
</template>

<script>
export default {
  name: 'AdminUnspacedNumberInput',
  props: {
    digits: {
      type: Number,
      default: 6,
    },
  },
  emits: ['submit'],
  data() {
    return {
      code: '',
    }
  },
  methods: {},
  mounted() {
    this.$refs.inputRef.focus()
  },
  watch: {
    code(newVal, oldVal) {
      if (newVal.length === this.digits) {
        this.$emit('submit', this.code)
      }
    },
  },
}
</script>

<style scoped></style>
