<template>
    <div class="absolute z-10 top-20 md:top-24 right-5 w-full max-w-xs bg-transparent">
        <transition-group name="alert-list" tag="div">
            <div v-for="alert in alerts" :key="alert.timestamp" class="relative w-full transition-all duration-300 bg-white mb-5 p-5 rounded-md shadow-sm border border-gray-200">
                <div class="flex justify-between items-center mb-2 space-x-4">
                    <h5>{{ alert.title || 'Alert' }}</h5>
                    <p @click="removeAlert(alert.timestamp)" class="flex-shrink-0 text-lg hover:text-red cursor-pointer px-1"><i class="fas fa-times"></i></p>
                </div>
                <p v-if="alert.message">{{ alert.message }}</p>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
    props: {
        duration: {
            type: Number,
            default: 5000
        }
    },

    data(){
        return {
            alerts: []
        }
    },

    methods: {
        addAlert(alert){
            alert.timestamp = DateTime.now().toMillis()
            this.alerts.unshift(alert)
            setTimeout(() => { this.removeAlert(alert.timestamp) }, alert.duration || this.duration)
        },
        removeAlert(timestamp){
            const index = this.alerts.findIndex(alert => alert.timestamp == timestamp)
            if(index > -1) this.alerts.splice(index, 1)
        }
    },

    created(){
        this.$app.events.$on('alert', this.addAlert)
    },

    beforeDestroy(){
        this.$app.events.$off('alert', this.addAlert)
    }
}
</script>

<style lang="scss" scoped>
.alert-list-leave-active {
    position: absolute;
    transition: all ease-in .3s;
}
.alert-list-enter, .alert-list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
