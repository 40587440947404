<template>
    <div>
        <p class="text-xs text-gray-400">Portion Size</p>
        <div class="flex mb-4">
            <button v-for="size in classEvent.portion_sizes"
                class="flex-grow py-2 border border-green border-r-0 first:rounded-l-md last:rounded-r-md last:border-r"
                :class="[isSelectedSize(size) ? 'bg-green text-white' : '']" @click="handleSize(size)">{{
                    size.description
                }}</button>
        </div>
        <p class="text-xs text-gray-400">Portion Quantity</p>
        <div v-if="portion.size" class="flex">
            <button v-for="quantity in validPortionQuantities"
                class="flex-grow py-2 border border-green border-r-0 first:rounded-l-md last:rounded-r-md last:border-r"
                :class="[isSelectedQuantity(quantity) ? 'bg-green text-white' : '']"
                @click="handleQuantity(quantity)">{{
                    quantity.value }}</button>
        </div>
        <div v-else>
            <p>Please select a portion size.</p>
        </div>
        <div v-if="portion.size && portion.size.id == 2" class="mt-2">
            <p class="text-red">(Discontinued)</p>
        </div>
        <div v-if="portion.size && portion.size.id == 1 && portion.quantity && portion.quantity.id == 3" class="mt-2">
            <p class="text-red">(Discontinued)</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "PortionSelector",

    props: {
        value: {
            type: Object,
        },
        classEvent: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            errors: [],
            portion: {
                size: null,
                quantity: null,
            },
        }
    },

    computed: {
        validVariations() {
            return this.classEvent.portion_variations.filter((variation) => {
                if (this.portion.size && this.portion.size.id !== variation.portion_size_id) return false
                return true
            }).sort((a, b) => {
                if (a.portion_quantity.value < b.portion_quantity.value) return -1
                if (a.portion_quantity.value > b.portion_quantity.value) return 1
                return 0
            })
        },
        validPortionQuantities() {
            return this.validVariations
                .map((variation) => { return variation.portion_quantity })
                .filter((element, index, self) => {
                    if (!element) return false
                    return self.findIndex(el => el.id === element.id) >= 0
                })
        },
        quantityValid() {
            return this.isQuantityValid(this.portion.quantity)
        },
    },

    methods: {
        isQuantityValid(quantity) {
            return !!this.validPortionQuantities.find((validQuantity) => {
                return quantity?.id === validQuantity.id;
            })
        },
        isSelectedSize(size) {
            return size?.id === this.portion.size?.id
        },
        isSelectedQuantity(quantity) {
            return quantity?.id === this.portion.quantity?.id
        },
        handleSize(size) {
            this.portion.size = size

            if (this.portion.quantity && !this.quantityValid) {
                this.portion.quantity = this.validPortionQuantities[0] || null
            }

            this.emitSelectedPortion()
        },
        handleQuantity(quantity) {
            this.portion.quantity = quantity
            this.emitSelectedPortion()
        },
        emitSelectedPortion() {
            this.$emit('input', { size: this.portion.size, quantity: this.portion.quantity })
        },
        setSelectedPortion() {
            if (this.value?.size) this.portion.size = this.value.size
            if (this.value?.size && this.value?.quantity) this.portion.quantity = this.value.quantity

            if (this.portion?.quantity && !this.isQuantityValid(this.value.quantity)) {
                this.portion.quantity = this.validPortionQuantities[0] || null
            }
        },
    },

    watch: {
        value: {
            handler(oldValue, newValue) {
                this.setSelectedPortion()
            },
            deep: true
        },
        classEvent: {
            handler(oldValue, newValue) {
                this.setSelectedPortion()
            },
        },
    },

    created() {
        this.setSelectedPortion()
    },
}
</script>

<style lang="scss" scoped></style>
