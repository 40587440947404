<template>
    <div>
        <div v-if="loading.order" class="flex items-center space-x-5">
            <Loader class="w-10 h-10 text-green" />
            <p>Loading order details...</p>
        </div>
        <div v-else-if="order">
            <!-- Heading -->
            <div class="mb-14 flex justify-between">
                <div>
                    <h2 class="mb-2">Order #{{ order.id }}</h2>
                    <p>
                        <StatusBadge :status="order.status" />
                    </p>
                </div>
                <!-- <div v-if="canPerformActions">
                    <Popover align="right">
                        <template #default>
                            <ActionsMenu class="w-64" :order="order"
                                @cancel="$router.push(`orders/${order.id}/cancel`)"
                            />
                        </template>
                    </Popover>
                </div> -->
                <div>
                    <multiselect class="max-w-xs" v-model="status" :options="statuses"
                        :loading="loading.statuses || loading.updateStatus"
                        :disabled="loading.statuses || loading.updateStatus" :searchable="false" :close-on-select="true"
                        :show-labels="false" :allow-empty="false" label="name" track-by="id" placeholder="Select status"
                        @input="updateStatus">
                        <template slot="placeholder"><span class="text-gray-400">Select status</span></template>
                        <template #caret="{ toggle }">
                            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                <span class="absolute right-3 top-3 select-none text-green"><i
                                        class="fas fa-chevron-down"></i></span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>

            <!-- Details -->
            <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-6 mb-4">
                <div>
                    <h6 class="label">Order Date</h6>
                    <p>
                        <Date :date="order.created_at" preset="DATE_FULL" />
                    </p>
                    <p>
                        <Date :date="order.created_at" preset="TIME_SIMPLE_WITH_SHORT_OFFSET" />
                    </p>
                </div>
                <div v-if="order.user">
                    <h6 class="label">Ordered By</h6>
                    <p>{{ order.user.first_name }} {{ order.user.last_name }}</p>
                    <p>{{ order.user.email }}</p>
                    <p>{{ order.user.phone | phone }}</p>
                </div>
                <div v-if="order.billing_address">
                    <h6 class="label">Billing Address</h6>
                    <p>{{ order.billing_address.first_name }} {{ order.billing_address.last_name }}</p>
                    <p>{{ order.billing_address.street_1 }}</p>
                    <p v-if="order.billing_address.street_2">{{ order.billing_address.street_2 }}</p>
                    <p>{{ order.billing_address.locality }}, {{ order.billing_address.administrative_area }} {{
                        order.billing_address.postal_code }}</p>
                </div>
                <div v-if="order.shipping_address">
                    <h6 class="label">Shipping Address</h6>
                    <p>{{ order.shipping_address.first_name }} {{ order.shipping_address.last_name }}</p>
                    <p>{{ order.shipping_address.street_1 }}</p>
                    <p v-if="order.shipping_address.street_2">{{ order.shipping_address.street_2 }}</p>
                    <p>{{ order.shipping_address.locality }}, {{ order.shipping_address.administrative_area }} {{
                        order.shipping_address.postal_code }}</p>
                </div>
            </div>

            <TextBox class="mb-2" v-model="editableOrderNotes" label="Order Notes:"
                placeholder="Leave at the front porch..." />
            <div class="flex space-x-2 mb-14">
                <button class="btn white text-sm" @click="resetOrderNotes">Cancel</button>
                <button class="btn cp-btn cp-btn-green-border text-sm" :disabled="isSaveDisabled" @click="saveOrderNotes">
                    Save
                </button>
            </div>

            <!-- Contacts -->
            <div class="mb-14" v-if="order.contacts && order.contacts.length">
                <div class="flex space-x-2 items-center mb-2">
                    <h4><i class="fas fa-fw fa-address-card mr-2"></i>Contacts</h4>
                    <p v-if="order.contacts" class="text-sm font-semibold rounded-2xl bg-mint px-3">{{ order.contacts.length
                    }}</p>
                </div>
                <ContactsListView v-if="order.contacts && order.contacts.length" :contacts="order.contacts" class="mb-2" />
                <div v-else>
                    <p class="text-sm text-gray-400">No additional order contacts.</p>
                </div>
                <!-- <button class="btn small white mt-2"><i class="fas fa-fw fa-plus"></i> Add Contact</button> -->
            </div>

            <!-- Registrations -->
            <div class="mb-14" v-if="order.registrations.length > 0">
                <div class="flex space-x-2 items-center mb-2">
                    <h4><i class="fas fa-fw fa-calendar-check mr-2"></i>Registrations</h4>
                    <p v-if="order.registrations" class="text-sm font-semibold rounded-2xl bg-mint px-3">{{
                        order.registrations.length }}</p>
                </div>
                <RegistrationsListView v-if="order.registrations && order.registrations.length"
                    :registrations="order.registrations" />
            </div>

            <!-- Gift Cards -->
            <div class="mb-14" v-if="order.order_item_gift_cards.length > 0">
                <div class="flex space-x-2 items-center mb-2">
                    <h4><i class="fas fa-gift mr-2"></i>Gift Cards</h4>
                    <p v-if="order.order_item_gift_cards" class="text-sm font-semibold rounded-2xl bg-mint px-3">{{
                        order.order_item_gift_cards.length }}</p>
                </div>
                <GCItemsListView v-if="order.order_item_gift_cards && order.order_item_gift_cards.length"
                    :gift_cards="giftCards" />
            </div>

            <!-- Discounts -->
            <div class="mb-14" v-if="order.discounts && order.discounts.length">
                <div class="flex space-x-2 items-center mb-2">
                    <h4><i class="fas fa-fw fa-tags mr-2"></i>Discounts</h4>
                    <p class="text-sm font-semibold rounded-2xl bg-mint px-3">{{ order.discounts.length }}</p>
                </div>
                <DiscountsListView :discounts="order.discounts" />
            </div>

            <!-- Payments -->
            <div class="mb-14">
                <div class="flex space-x-2 items-center mb-2">
                    <h4><i class="fas fa-fw fa-credit-card mr-2"></i>Payments</h4>
                    <p v-if="order.payments" class="text-sm font-semibold rounded-2xl bg-mint px-3">{{ order.payments.length
                    }}</p>
                </div>
                <PaymentsListView v-if="order.payments && order.payments.length" :payments="order.payments"
                    :isGiftCard="isGiftCard" class="mb-2" />
                <!-- <div>
                    <button class="btn small white"><i class="fas fa-fw fa-plus"></i> Add Payment</button>
                </div> -->
            </div>

            <!-- Refunds -->
            <div class="mb-14" v-if="refunds && refunds.length">
                <div class="flex space-x-2 items-center mb-2">
                    <h4><i class="fas fa-fw fa-hand-holding-usd mr-2"></i>Refunds</h4>
                    <p class="text-sm font-semibold rounded-2xl bg-mint px-3">{{ refunds.length }}</p>
                </div>
                <RefundsListView :refunds="refunds" />
            </div>

            <!-- Totals -->
            <div class="mb-14 flex justify-end">
                <div class="w-full max-w-screen-xs text-right">
                    <div class="grid grid-cols-2">
                        <h6>Subtotal</h6>
                        <p>{{ order.totals.subtotal | currency }}</p>
                    </div>
                    <div class="grid grid-cols-2">
                        <h6>Discounts</h6>
                        <p>-{{ order.totals.discounts | currency }}</p>
                    </div>
                    <div class="grid grid-cols-2">
                        <h6>Taxes</h6>
                        <p>{{ order.totals.taxes | currency }}</p>
                    </div>
                    <div class="grid grid-cols-2">
                        <h6>Fees</h6>
                        <p>{{ order.totals.fees | currency }}</p>
                    </div>
                    <div class="grid grid-cols-2">
                        <h6>Charity</h6>
                        <p>{{ order.totals.charity | currency }}</p>
                    </div>
                    <div class="grid grid-cols-2 mb-5">
                        <h6>Total</h6>
                        <p>{{ order.totals.total | currency }}</p>
                    </div>
                    <div class="grid grid-cols-2">
                        <h6>Payments</h6>
                        <p>-{{ order.totals.payments | currency }}</p>
                    </div>
                    <div class="grid grid-cols-2">
                        <h6>Refunds</h6>
                        <p>{{ order.totals.refunds | currency }}</p>
                    </div>
                    <div class="grid grid-cols-2">
                        <h5>Balance</h5>
                        <h5 class="font-semibold" :class="{ 'text-red': balance != 0 }">{{ order.totals.balance | currency
                        }}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
        </div>
    </div>
</template>

<script>
import currency from '@shared/utils/currency'
import Loader from '@components/global/Loader'
import StatusBadge from '@components/orders/StatusBadge'
import Popover from '@components/global/Popover'
import ActionsMenu from '@components/orders/ActionsMenu'
import Date from '@components/global/dates/Date'
import TextBox from '@/components/global/forms/Textbox'
import ContactsListView from '@components/orders/contacts/ListView'
import RegistrationsListView from '@components/registrations/ListView'
import DiscountsListView from '@components/orders/discounts/ListView'
import PaymentsListView from '@components/payments/ListView'
import RefundsListView from '@components/payments/refunds/ListView'
import GCItemsListView from '@components/giftcards/ListView'

export default {
    components: {
        Loader,
        StatusBadge,
        Popover,
        ActionsMenu,
        Date,
        ContactsListView,
        RegistrationsListView,
        DiscountsListView,
        PaymentsListView,
        RefundsListView,
        GCItemsListView,
        TextBox,
    },

    metaInfo() {
        return {
            title: this.order?.id ? `Order #${this.order?.id}` : 'Order Details',
        }
    },

    data() {
        return {
            loading: {
                order: false,
                statuses: false,
                updateStatus: false,
            },
            editableOrderNotes: null,
            order: null,
            status: null,
            statuses: [],
        }
    },

    computed: {
        refunds() {
            return this.order?.payments?.map(payment => payment.refunds).flat()
        },
        balance() {
            return currency(this.order.totals.balance).toUnit()
        },
        isGiftCard() {
            return this.order?.order_item_gift_cards?.length > 0
        },
        giftCards() {
            let cards = []
            if (this.order.order_item_gift_cards) {
                this.order.order_item_gift_cards.forEach((item) => {
                    cards.push(item.gift_card)
                })
            }
            return cards
        },
        isSaveDisabled() {
            return this.editableOrderNotes === this.order.notes;
        },
    },

    methods: {
        getStatuses() {
            this.loading.statuses = true
            return this.$app.api.orders.getStatuses().then(res => {
                this.statuses = res.data
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading.statuses = false)
        },
        updateStatus() {
            let data = {
                status_id: this.status.id
            }

            this.loading.updateStatus = true
            return this.$app.api.orders.updateOrder(this.order.id, data).then(res => {
                this.order = { ...this.order, ...res.data }
                this.status = this.order.status
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading.updateStatus = false)
        },
        getOrder() {
            this.loading.order = true
            return this.$app.api.orders.getOrder(this.$route.params.id).then(res => {
                this.order = res.data
                this.status = this.order.status
                this.resetOrderNotes()
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading.order = false)
        },
        resetOrderNotes() {
            this.editableOrderNotes = this.order.notes
        },
        saveOrderNotes() {
            let data = {
                notes: this.editableOrderNotes
            }

            this.loading.updateStatus = true
            return this.$app.api.orders.updateOrder(this.order.id, data).then(res => {
                this.order = { ...this.order, ...res.data }
                this.status = this.order.status
                this.resetOrderNotes()
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading.updateStatus = false)
        },
    },

    created() {
        this.getOrder()
        this.getStatuses()
    }
}
</script>

<style></style>
