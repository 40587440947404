<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-6 flex justify-between space-x-3">
                <h3>Block Dates</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="mb-6">
                <div v-if="errors.length" class="flex space-x-5 mb-4">
                    <p class="text-red">{{ errors }}</p>
                </div>
                <div class="mb-4">
                    <p>This will block off the following dates from your calendar:</p>
                    <div>
                        <div v-for="(day, index) in days">
                            <Date class="mr-12" :date="day.date.toISOString()" preset="DATE_SHORT" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex space-x-2">
                <button class="btn green" @click="blockDates" :disabled="!canBlockDates || saving || success">
                    <div v-if="saving" class="flex items-center">
                        <Loader class="w-5 mr-1" /><span>Saving</span>
                    </div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Saved!</span>
                    <span v-else>Save</span>
                </button>
                <button class="btn white" @click="close">Cancel</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import { DateTime } from "luxon";
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'
import Textbox from '@components/global/forms/Textbox'
import Checkbox from '@components/global/forms/Checkbox'
import Date from '@components/global/dates/Date'

export default {
    name: "BlockEventModal",

    components: {
        Modal,
        Loader,
        Textbox,
        Checkbox,
        Date,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        calendarId: {
            type: Number,
            required: true,
        },
        days: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            saving: false,
            success: false,
            errors: [],
        }
    },

    computed: {
        displayDate() {
            return DateTime.fromJSDate(this.day.date).toISO()
        },
        canBlockDates() {
            return this.hasPermission
        },
        hasPermission() {
            return this.$app.services.auth.hasPermission('calendars.events.create')
        },
    },

    methods: {
        close() {
            this.reset()
            this.$emit('close')
        },
        reset() {
            this.errors = []
            this.saving = false
            this.success = false
        },
        blockDates() {
            this.saving = true
            this.days.forEach((day) => {
                this.$app.api.calendars.createEvent(this.calendarId, {
                    key: 'blocked ' + day.date.toISOString(),
                    name: 'Blocked',
                    description: 'Blocked',
                    all_day: true,
                    start_time: DateTime.fromJSDate(day.date).startOf('day').toUTC(),
                    end_time: DateTime.fromJSDate(day.date).endOf('day').toUTC(),
                    status_id: 1,
                })
                    .then((response) => {
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            })
            this.success = true
            setTimeout(() => {
                this.close()
                this.$emit('update', this.calendarId)
            }, 250)
        }
    },

    created() { },
}
</script>

<style lang="scss" scoped></style>
