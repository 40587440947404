<template>
    <div class="flex flex-col p-6 w-full">
        <div class="mb-8 flex justify-between space-x-3">
            <h3>Create Announcement</h3>
        </div>

        <AnnouncementForm v-model="announcement" />

        <div v-if="error" class="flex space-x-5 mb-4">
            <p class="text-red">{{ error }}</p>
        </div>

        <div class="flex space-x-4 mt-6">
            <router-link class="btn white px-6" to="/dashboard">Cancel</router-link>
            <button class="btn button-green px-6 flex items-center" @click="handleSave"
                :disabled="!canCreateAnnouncement || saving">
                <span v-if="saving" class="mr-2">
                    <Loader class="w-5 h-5 text-white" />
                </span>
                <span>Save</span>
            </button>
        </div>
    </div>
</template>

<script>
import AnnouncementForm from '@components/dashboard/AnnouncementForm'
import Loader from '@components/global/Loader'

export default {
    name: "CreateAnnouncement",

    components: {
        AnnouncementForm,
        Loader
    },

    props: {
        id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            saving: false,
            announcement: null,
            error: null,
        }
    },

    methods: {
        createAnnouncement() {
            let data = {
                title: this.announcement.title,
                content: this.announcement.content,
                active: this.announcement.active,
                roles: this.announcement.roles.map(role => role.role_id),
            };

            this.saving = true
            this.$app.api.announcements.createAnnouncement(data)
                .then((response) => {
                    this.$router.push('/dashboard')
                })
                .catch((error) => {
                    console.error(error)
                    this.error = error.response && error.response.data && error.response.data.message
                        ? error.response.data.message
                        : 'Unknown error.';
                })
                .finally(() => {
                    this.saving = false
                })
        },
        handleSave() {
            this.createAnnouncement()
        },
    },

    computed: {
        canCreateAnnouncement() {
            return !!this.announcement && (this.$app.services.auth.hasPermission('announcements.create'))
        }
    },

}
</script>