<template>
    <div class="bg-gray-100 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
        <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-2 xl:px-3">
            <div>
                <h6 class="text-xs text-gray-400 xl:hidden">ID</h6>
                <p>{{ payment.id }}</p>
            </div>
            <div>
                <h6 class="text-xs text-gray-400 xl:hidden">Service</h6>
                <p>{{ payment.service.name }}</p>
            </div>
            <div>
                <h6 class="text-xs text-gray-400 xl:hidden">Method</h6>
                <p>{{ payment.type.name }}</p>
            </div>
            <div>
                <h6 class="text-xs text-gray-400 xl:hidden">Amount</h6>
                <p class="font-medium">{{ payment.amount | currency }}</p>
                <p v-if="payment.amount_applied" class="text-xs">Applied: {{ payment.amount_applied | currency }}</p>
            </div>
            <div>
                <h6 class="text-xs text-gray-400 xl:hidden">Status</h6>
                <StatusBadge class="small" :status="payment.status"/>
            </div>
            <div v-if="canRefundPayment">
                <h6 class="text-xs text-gray-400 xl:hidden">Action</h6>
                <button class="btn green xs" @click="$emit('select', payment)">Select Payment</button>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from '@components/payments/StatusBadge'
import ProgressRing from '@components/global/ProgressRing'
import Date from '@components/global/dates/Date'
import currency from '@shared/utils/currency';

export default {
    name: "PaymentsCard",

    props: {
        payment: {
            type: Object,
            required: true,
        },
        totals: {
            type: Object,
            required: true,
        },
        links: {
            type: Boolean,
            default: true,
        }
    },

    components: {
        StatusBadge,
        ProgressRing,
        Date,
    },

    computed: {
        canRefundPayment() {
            if(this.totals?.amount_due && this.payment?.amount_applied) {
                let refund = currency(0).subtract(currency(this.totals.amount_due)) // flip the sign
                let applied = currency(this.payment.amount_applied)

                return refund.lessThan(applied)
            }
            else return false
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
