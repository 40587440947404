<template>
    <span>
        {{ date | date(preset, options) }}
    </span>
</template>

<script>
export default {
    props: {
        date: {
            type: String,
            required: true
        },
        preset: {
            type: String,
            default: 'DATETIME_FULL'
        },
        timezone: {
            type: String,
            default: null
        }
    },

    computed: {
        options(){
            let options = {}
            if(this.timezone) options.timezone = this.timezone
            return options
        }
    }
}
</script>

<style>

</style>
