<template>
    <div class="sticky top-0 bg-white z-30 border-b border-gray-200">
        <div class="flex justify-between items-center w-full max-w-screen-2xl px-5 py-2 md:px-8">
            <div class="flex items-center">
                <div class="flex space-x-5 items-center flex-nowrap md:hidden">
                    <div>
                        <button class="text-green w-10 h-10 relative focus:outline-none active:bg-white bg-white" @click="toggleMenu">
                            <span class="sr-only">Menu</span>
                            <div class="block w-6 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <span aria-hidden="true" class="block absolute rounded h-0.5 w-5 bg-green transform -translate-y-2"></span>
                                <span aria-hidden="true" class="block absolute rounded h-0.5 w-7 bg-green"></span>
                                <span aria-hidden="true" class="block absolute rounded h-0.5 w-5 bg-green transform translate-y-2"></span>
                            </div>
                        </button>
                    </div>
                    <div>
                        <img src="/img/logos/logo.svg" class="w-36">
                    </div>
                </div>
                <div></div>
                <!-- <div class="py-2 w-60 hidden md:block">
                    <SearchInput v-model="searchValue" @search="search" placeholder="Search everything..."/>
                </div> -->
            </div>
            <div v-if="user.id" class="flex">
                <Popover align="right">
                    <template #button="{toggle, visible}">
                        <div class="relative w-10 h-10 rounded-full text-center content-center cursor-pointer transition-colors"
                            :class="{
                                'bg-green': visible,
                                'bg-mint' : !visible
                            }"
                            @click="toggle"
                        >
                            <h6 class="text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" :class="{'text-white':visible}">{{ initials }}</h6>
                        </div>
                    </template>
                    <template #default="{hide}">
                        <div class="bg-white rounded-md border border-gray-200 shadow-sm w-40 p-3">
                            <p class="transition-colors px-3 py-1 cursor-pointer rounded hover:bg-green hover:text-white" @click="() => {hide(); logout()}"><i class="fas fa-sign-out-alt pr-2"></i> Logout</p>
                        </div>
                    </template>
                </Popover>
            </div>
        </div>
    </div>
</template>

<script>
import SearchInput from '@components/global/SearchInput'
import Popover from '@components/global/Popover'

export default {
    components: {
        SearchInput,
        Popover
    },

    data(){
        return {
            searchValue: ''
        }
    },

    computed: {
        user(){
            return this.$app.services.user.getUser()
        },
        initials(){
            return (this.user.first_name[0] + this.user.last_name[0]).toUpperCase()
        }
    },

    methods: {
        toggleMenu(){
            this.$emit('toggle-menu')
        },
        search(){
            console.log(this.searchValue)
        },
        logout(){
            return this.$app.services.auth.logout().then(() => {
                this.$router.push('/login')
            })
        }
    }
}
</script>

<style>

</style>
