import Service from "@shared/app/services/service"

export default class Registration extends Service {

    validateSelections(selections, portionQuantity) {
        if (selections.length <= 0) return false;

        let total = 0;
        let mealsValid = true;
        let defaultSelection = portionQuantity / 10;
        let doubleSelection = (portionQuantity / 10) * 2;
        let removedSelection = 0;

        for (const selection of selections) {
            if (selection.quantity !== removedSelection && selection.quantity !== defaultSelection && selection.quantity !== doubleSelection) mealsValid = false;
            total += selection.quantity;
        }

        return mealsValid && total === portionQuantity;
    }

    selectionChangeRequired(originalClassEvent, changeClassEvent) {
        if (originalClassEvent.menu_id !== changeClassEvent.menu_id) return true
        return false
    }

    portionChangeRequired(portion, classEvent) {
        return !this.getVariation(portion.size.id, portion.quantity.id, classEvent)
    }

    getVariation(sizeId, quantityId, classEvent) {
        return classEvent.portion_variations.find((variation) => {
            return variation.portion_quantity_id === quantityId && variation.portion_size_id === sizeId
        })
    }

    hasPriceDifference(originalClassEvent, changeClassEvent, originalVariation, changeVariation) {
        return changeClassEvent.variation_prices[changeVariation.id] !== originalClassEvent.variation_prices[originalVariation.id]
    }

    _difference(original, change) {
        let _difference = new Set(original)
        for (let item of change) {
            if (_difference.has(item)) {
                _difference.delete(item)
            } else {
                _difference.add(item)
            }
        }
        return !!_difference.size
    }
}
