<template>
  <div>
    <div
      class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200"
    >
      <h6 class="col-span-2 label">Name</h6>
      <h6 class="col-span-3 label">Email</h6>
      <h6 class="col-span-2 label">Phone</h6>
      <h6 class="col-span-2 label">Created</h6>
      <h6 class="col-span-2 label">Updated</h6>
    </div>
    <div
      class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md"
    >
      <div
        v-for="user in users"
        :key="user.id"
        class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2"
      >
        <div
          class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3"
        >
          <div class="xl:col-span-2">
            <h6 class="label xl:hidden">Name</h6>
            <router-link
              class="hover:underline block mr-2"
              :to="`/users/${user.id}`"
            >
              <p>{{ user.first_name }} {{ user.last_name }}</p>
            </router-link>
          </div>
          <div class="xl:col-span-3">
            <h6 class="label xl:hidden">Email</h6>
            <p>{{ user.email }}</p>
          </div>
          <div class="xl:col-span-2">
            <h6 class="label xl:hidden">Phone</h6>
            <p>{{ user.phone | phone }}</p>
          </div>
          <div class="xl:col-span-2">
            <h6 class="label xl:hidden">Created</h6>
            <p>{{ user.created_at | date }}</p>
          </div>
          <div class="xl:col-span-2">
            <h6 class="label xl:hidden">Updated</h6>
            <p>{{ user.updated_at | date }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  components: {}
};
</script>

<style></style>
