<template>
    <div class="inline-block relative">
        <span @mouseenter="open = !open" @mouseleave="open = !open">
            <slot name="default"></slot>
        </span>
        <transition name="tooltip">
            <div v-show="open" class="absolute top-full left-1/2 -translate-x-1/2 mt-2 py-2 px-3 duration-150 bg-white border border-gray-400 rounded-md z-50 text-xs whitespace-nowrap shadow-md">
                {{ message }}
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Tooltip",

    props: {
        message: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            open: false,
        }
    },
}
</script>

<style lang="scss" scoped>
.tooltip-enter-active,
.tooltip-leave-active {
  //transform: translateY(0);
  transition: all .15s ease-in-out;
}
.tooltip-enter,
.tooltip-leave-to {
  opacity: 0;
  //transform: translateY(-20%);
}
</style>
