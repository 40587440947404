<template>
    <div>
        <div v-if="viewMap">
            <div class="mb-5">
                <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Store Address:</label>
                <p>{{ address }}</p>
            </div>
        </div>
        <div v-else class="mb-6">
            <div class="mb-5">
                <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Locate Store:</label>
                <input class="w-full lg:w-5/12" type="textbox" @input="updateAddress($event.target.value)" :value="address" @keyup.enter="searchPlaces">
            </div>
            <div>
                <button class="btn button-green px-6" @click="searchPlaces" type="button">Search</button>
            </div>
        </div>

        <div class="goog-map-wrap" :style="{height: (viewMap ? mapHeight / 2 : mapHeight)+ 'px'}">
            <div class="results-list" v-if="resultList.length > 0 && !viewMap">
                <div class="bg-white my-2 hover:cursor-pointer" v-for="address in resultList" :key="address.id" :class="selectedLocation && (selectedLocation.place_id == address.place_id) ? 'selected' : ''" @click="selectAddress(address)">
                    <div class="grid grid-cols-12 items-center justify-center p-4">
                        <div class="col-span-2 justify-center px-4">
                            <span class="text-2xl" :class="address.business_status ? 'fas fa-building' : 'fas fa-map-marker-alt'"></span>
                        </div>
                        <div class="col-span-10 justify-center px-3">
                            <h6 class="font-semibold" v-if="address.business_status">{{ address.business_name }}</h6>
                            <span>{{ address.formatted_address }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="results-list" v-else-if="noResults && !viewMap">
                <div class="my-2 bg-white">
                    <div class="grid grid-cols-12 items-center justify-center p-4">
                        <div class="col-span-2 justify-center px-4">
                            <span class="text-2xl fas fa-times"></span>
                        </div>
                        <div class="col-span-10 justify-center px-3">
                            <h6 class="font-bold">No Results</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div id="goog-map"></div>
        </div>
    </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"

export default {
    props: {
        viewMap: {
            type: Boolean
        },
        location: {
            type: Object
        },
        updateLocation: {
            type: Function
        }
    },

    data(){
        return {
            apiKey: null,
            loader: null,
            address: '',
            latlng: null,
            initialLoad: true,
            noResults: false,
            preload: false,
            geocoder: null,
            map: null,
            service: null,
            marker: null,
            resultList: [],
            selectedLocation: null,
            mapHeight: 550
        }
    },

    watch: {
        resultList: function(resultList) {
            this.noResults = resultList.length === 0 ? true : false
        }
    },

    created(){
        this.getAddress()
        this.getApiKey()
        this.mapInit()
    },

    methods: {
        getAddress(){
            if(this.address) {
                return this.address
            } else if(this.location.address && this.location.address.street_1 && this.initialLoad) {
                this.initialLoad = false

                let address = this.location.address.street_1
                address += this.location.address.street_2 ? ' ' + this.location.address.street_2 : ''
                address += this.location.address.locality ? ', ' + this.location.address.locality : ''
                address += this.location.address.administrative_area ? ', ' + this.location.address.administrative_area : ''
                address += this.location.address.postal_code ? ' ' + this.location.address.postal_code : ''

                this.updateAddress(address)
                return address
            } else if(this.location.street_1 && this.initialLoad) {
                this.initialLoad = false

                let address = this.location.street_1
                address += this.location.street_2 ? ' ' + this.location.street_2 : ''
                address += this.location.city ? ', ' + this.location.city : ''
                address += this.location.state ? ', ' + this.location.state : ''
                address += this.location.postal_code ? ' ' + this.location.postal_code : ''

                this.updateAddress(address)
                return address
            }
        },
        updateAddress(address){
            this.address = address
        },
        getApiKey(){
            this.apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
        },
        mapInit(){
            this.latlng = {
                lat: this.location.lat ?? 41.679936,
                lng: this.location.long ?? -111.869681
            }

            this.loader = new Loader({
                apiKey: this.apiKey,
                version: "weekly",
                libraries: ['places', 'localContext', 'geometry']
            })

            if(this.viewMap) this.loadViewMap(this.latlng)
            else this.loadMap(this.latlng)
        },
        loadMap(position){
            this.loader.load().then(() => {
                this.geocoder = new google.maps.Geocoder()
                this.preload = this.address ? true : false

                const mapOptions = {
                    zoom: 15,
                    center: this.latlng,
                    gestureHandling: "cooperative",
                    mapTypeControl: true,
                    navigationControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                }

                this.map = new google.maps.Map(document.getElementById("goog-map"), mapOptions)
                this.map.setOptions({ draggableCursor:'crosshair' })

                this.service = new google.maps.places.PlacesService(this.map)

                // Add click event listener
                this.map.addListener("click", event => {
                    this.resultList = []

                    this.latlng = event.latLng.toJSON()
                    this.setMarker(this.latlng)

                    if(event.placeId) this.reverseGeocodeByID(event.placeId)
                    else this.reverseGeocode(this.latlng)
                })

                if(this.preload) {
                    if(!position.lat || !position.lng) {
                        this.geocode()
                            .then(res => position = this.latlng)
                            .catch(err => console.warn(err))
                    }

                    this.setMarker(position)
                }
            })
        },
        loadViewMap(position){
            this.loader.load().then(() => {
                this.geocoder = new google.maps.Geocoder()
                if(!position.lat || !position.lng) {
                    this.geocode()
                        .then(res => position = this.latlng)
                        .catch(err => console.warn(err))
                }

                const mapOptions = {
                    zoom: 18,
                    center: position,
                    gestureHandling: "cooperative",
                    mapTypeControl: true,
                    navigationControl: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                }

                this.map = new google.maps.Map(document.getElementById("goog-map"), mapOptions)

                this.service = new google.maps.places.PlacesService(this.map)

                this.marker = new google.maps.Marker({
                    position,
                    map: this.map,
                    draggable: false
                })
            })
        },
        setMarker(position, title = ''){
            if (this.marker) {
                this.marker.setMap(null)
                this.marker = null
            }

            this.latlng = position
            this.map.setCenter(position)
            this.map.setZoom(18)

            this.marker = new google.maps.Marker({
                position: position,
                map: this.map,
                draggable: true,
                title: title ? title : ''
            });
        },
        searchPlaces(){
            this.resultList = []

            if(this.address) this.geocode().then(res => {
                if(!this.location.store_name) return

                const request = {
                    query: `${this.location.store_name} ${this.address}`,
                    fields: ['business_status', 'formatted_address', 'place_id', 'name', 'geometry'],
                }

                this.service.findPlaceFromQuery(request, (results, status) => {
                    if(status === google.maps.places.PlacesServiceStatus.OK) {
                        results.forEach(place => {
                            place.business_name = place.name
                            this.getBusinessStatus(place)
                        })

                        this.setMarker(results[0].geometry.location)
                    }
                })
            }).catch(err => {
                this.resultList = []
                console.warn(err)
            })
        },
        geocode(){
            this.resultList = []

            return this.geocoder.geocode({ 'address': this.address }).then(result => {
                const { results } = result

                if(results.length > 0) {
                    results.forEach(address => {
                        if(address.address_components.length >= 7) {
                            if(address.place_id) this.reverseGeocodeByID(address.place_id)
                            else this.reverseGeocode(address.geometry.location)
                        }
                    })

                    this.setMarker(results[0].geometry.location)
                }
            }).catch(err => {
                this.resultList = []
                console.warn(err)
            })
        },
        reverseGeocode(latlng){
            return this.geocoder.geocode({ 'location': latlng }).then(response => {
                response.results.forEach(address => {
                    if(address.address_components.length >= 7) {
                        this.getBusinessStatus(address)
                    }
                })
            }).catch(err => {
                console.warn(err)
            })
        },
        reverseGeocodeByID(placeId) {
            return this.geocoder.geocode({ 'placeId': placeId }).then(response => {
                response.results.forEach(address => {
                    if(address.address_components.length >= 7)
                        this.getBusinessStatus(address)
                })
            }).catch(err => {
                console.warn(err)
            })
        },
        getBusinessStatus(address){
            const request = {
                placeId: address.place_id,
                fields: ['address_components', 'business_status', 'name', 'place_id', 'type', 'url']
            }

            this.service.getDetails(request, (place, status) => {
                if(status === google.maps.places.PlacesServiceStatus.OK) {
                    address['business_name'] = place.name
                    address['business_status'] = place.business_status
                    if(place.address_components) address['address_components'] = place.address_components
                    if(place.url) address['url'] = place.url

                    let duplicate = false

                    if(this.resultList.length > 0) {
                        this.resultList.forEach(result => {
                            if(place.place_id === result.place_id) duplicate = true
                        })
                    }

                    if(!duplicate) this.resultList.push(address)
                }
            })
        },
        preselectAddress(address){
            // This creates a bug because it will not always be the correct place without a unique place ID stored in the DB we can check
            if(address['business_name'].toLowerCase().includes(this.location.store_name.toLowerCase()))
                this.selectAddress(address)
        },
        selectAddress(address){
            this.setMarker(address.geometry.location, address.formatted_address)

            this.selectedLocation = {
                street_number: '',
                route: '',
                subpremise: '',
                locality: '',
                administrative_area_level_2: '',
                administrative_area_level_1: '',
                country: '',
                postal_code: '',
                place_id: address.place_id,
                map_url: address.url ? address.url : `https://www.google.com/maps/search/?api=1&query=${address.formatted_address.replaceAll(' ', '%20')}&query_place_id=${address.place_id}`,
                lat: this.latlng.lat(),
                lng: this.latlng.lng(),
            }

            address.address_components.forEach(comp => {
                comp.types.forEach(type => {
                    this.selectedLocation[type] = comp.short_name
                })
            })

            this.updateLocation(this.selectedLocation)
        }
    },
}
</script>

<style lang="scss" scoped>
.goog-map-wrap {
    width: 100%;
    position: relative;

    & .results-list {
        position: absolute;
        top: 4rem;
        left: 0.7rem;
        height: 450px;
        width: 400px;
        z-index: 49;
        overflow-y: scroll;
        padding: 0.8rem 1rem;
        background: #00000022;
        border-radius: 0.25rem;

        & > div {
            border-radius: 0.25rem;
        }

        & .selected, .selected h6 {
            background-color: rgb(66, 153, 225);
            color: white;
        }
    }

    & #goog-map {
        height: 100%;
        width: 100%;
    }
}
</style>
