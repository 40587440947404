<template>
  <div>
    <div class="mb-10">
      <h2 class="mb-5">Newsroom &ndash; Press Releases</h2>
      <NewsroomFilters
        :title="'Press Release'"
        :url="`/newsroom/press-releases`"
        :canCreateNewsItem="canCreatePressRelease"
        :statuses="statuses"
        :status="pressReleaseStatus"
        @search="search"
      />
    </div>

    <div>
      <template v-if="loading">
        <div class="flex items-center space-x-5">
          <Loader class="w-10 h-10 text-green" />
          <p>Loading...</p>
        </div>
      </template>
      <template v-else-if="pressReleases.data && pressReleases.data.length">
        <ListView
          class="mb-10"
          :newsType="'pressRelease'"
          :newsItems="pressReleases.data"
          :headers="headers"
          @archive="confirmArchive"
          @delete="confirmDelete"
        />
        <Pagination
          v-if="totalPressReleases > 10"
          :page="page"
          :total-items="totalPressReleases"
          :per-page="perPage"
          @change="pageUpdated"
        />
      </template>
      <template v-else>
        <p></p>
        <p>
          <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No press
          releases to show. Please refine your critera.
        </p>
      </template>
    </div>

    <!-- Modals -->
    <ConfirmArchiveModal
      :newsItemId="pressReleaseId"
      :newsType="'pressRelease'"
      :visible="showConfirmArchiveModal"
      @close="showConfirmArchiveModal = false"
      @success="getPressReleases"
    />

    <ConfirmDeleteModal
      :newsItemId="pressReleaseId"
      :newsType="'pressRelease'"
      :visible="showConfirmDeleteModal"
      @close="showConfirmDeleteModal = false"
      @success="getPressReleases"
    />
  </div>
</template>

<script>
import ConfirmDeleteModal from '@components/newsroom/modals/ConfirmDelete'
import ConfirmArchiveModal from '@components/newsroom/modals/ConfirmArchive'
import ListView from '@components/newsroom/ListView'
import NewsroomFilters from '@components/newsroom/NewsroomFilters'
import Loader from '@components/global/Loader'
import Pagination from '@components/global/Pagination'

export default {
  name: 'PressReleases',

  components: {
    ConfirmDeleteModal,
    ConfirmArchiveModal,
    ListView,
    NewsroomFilters,
    Loader,
    Pagination,
  },

  metaInfo() {
    return {
      title: this.title,
    }
  },

  data() {
    return {
      headers: ['Title', '', '', 'Status', 'Publish Date', '', '', ''],

      statuses: ['draft', 'scheduled', 'published', 'archived'],

      showConfirmDeleteModal: false,
      showConfirmArchiveModal: false,

      pressReleases: [],
      pressReleaseId: null,
      pressReleaseStatus: '',

      loading: true,

      page: parseInt(this.$route.query.page) || 1,
      perPage: parseInt(this.$route.query.per_page) || 10,
    }
  },

  mounted() {
    this.setViewableStatuses()
    this.getPressReleases()
  },

  computed: {
    canViewAllPressReleases() {
      return this.$app.services.auth.hasPermission('newsroom.pressreleases.view')
    },
    canCreatePressRelease() {
      return this.$app.services.auth.hasPermission('newsroom.pressreleases.create')
    },
    title() {
      if (this.$route.query.search)
        return `Search: ${this.$route.query.search} | Newsroom - Press Releases`
      return 'Newsroom - Press Releases'
    },
    totalPressReleases() {
      return this.pressReleases.meta?.total ?? 0
    },
  },

  methods: {
    getPressReleases() {
      // default params
      const params = {
        relations: 'article|status',
        status: 'published|scheduled',
        admin: true,
        order_by: 'status:draft,published,archived|published_at|edited_at',
        page: this.$route.query.page || this.page,
        per_page: this.$route.query.per_page || this.perPage,
      }

      if (this.canViewAllPressReleases) delete params.status

      // optional search params
      if (this.$route.query.search) {
        const search = this.$route.query.search.split(',')

        // status
        const statuses = search.filter((i) => this.statuses.includes(i))
        this.pressReleaseStatus = statuses.length === 1 ? statuses[0] : ''
        if (statuses.length > 0) params.status = statuses.join('|')

        // title
        const title = search.filter((i) => !this.statuses.includes(i))
        if (title.length > 0) params.title = title.join(' ')
      }

      // optional date range params
      if (this.$route.query.after_date) params.after_date = this.$route.query.after_date
      if (this.$route.query.before_date)
        params.before_date = this.$route.query.before_date

      this.loading = true

      console.log(this.$route.query)
      console.log(params)

      this.$app.api.newsroom
        .getPressReleases(params)
        .then((res) => {
          this.pressReleases = res.data
          console.log(this.pressReleases)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => (this.loading = false))
    },

    confirmDelete(pressReleaseId) {
      this.showConfirmDeleteModal = true
      this.pressReleaseId = pressReleaseId
    },

    confirmArchive(pressReleaseId) {
      this.showConfirmArchiveModal = true
      this.pressReleaseId = pressReleaseId
    },

    setViewableStatuses() {
      if (!this.canViewAllPressReleases)
        this.statuses = this.statuses.filter((i) => i !== 'draft' && i !== 'archived')
    },

    pageUpdated(page) {
      this.page = page
      this.getPressReleases()
    },

    search() {
      this.page = 1
      this.getPressReleases()
    },
  },
}
</script>

<style lang="scss" scoped></style>
