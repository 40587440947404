import Resource from './resource'

export default class Locations extends Resource {

    /**
     * 
     * @param {object} params 
     */
    getLocations(params = {}) {
        return this.axios.get(`locations`, { params })
    }

    /**
     * 
     * @param {string|int} locationId location ID
     * @param {object} params query params
     */
    getLocation(locationId, params = {}) {
        return this.axios.get(`locations/${locationId}`, { params })
    }

    /**
    * 
    * @param {object} data post data
    */
    createLocation(data = {}) {
        return this.axios.post(`locations`, data)
    }

    /**
     * 
     * @param {string|int} locationId location ID
     * @param {object} data put data
     */
    editLocation(locationId, data = {}) {
        return this.axios.put(`locations/${locationId}`, data)
    }

    /**
     * 
     * @param {string|int} locationId location ID
     */
    deleteLocation(locationId) {
        return this.axios.delete(`locations/${locationId}`)
    }

    updateLocationStatus(locationId, data = {}) {
        return this.axios.put(`locations/${locationId}/active`, data)
    }

    getVendors(params = {}) {
        return this.axios.get(`vendors`, { params })
    }

    syncVendors() {
        return this.axios.post(`vendors/sync`)
    }

    exportZipcodes(locationId) {
        return this.axios.get(`locations/${locationId}/export_zipcodes`)
    }
}
