<template>
    <div class="relative w-full text-left outline-none my-4" :tabindex="tabindex" @blur="open = false">
        <label v-if="label" class="text-sm font-bold text-green uppercase mb-2">{{ label }}</label>
        <div class="input bg-white relative m-0 cursor-pointer border border-green" :class="{open: open, 'cp-input-error': invalid}" @click="open = !open">
            <p class="select-none pr-5" v-if="selected">{{ typeof selected == 'object' ? selected[displayKey] : selected }}</p>
            <p v-else class="text-gray-300 select-none pr-5">{{  placeholder }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" class="absolute right-3 top-2 select-none h-6 w-6 text-green transition-all transform" :class="{'rotate-180 top-3 -translate-y-1': open}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
        </div>
        <div class="absolute w-full z-10 overscroll-contain overflow-y-scroll max-h-80 border border-gray bg-white rounded-lg shadow-md" :class="{selectHide: !open}">
            <slot :options="options" :selectOption="selectOption" :selected="value">
                <SelectOption
                    v-for="option in options" :key="typeof option == 'object' ? option[valueKey] : option"
                    :selected="typeof selected == 'object' ? selected[valueKey] == option[valueKey] : selected == option"
                    :disabled="option.disabled ? true : false"
                    :value="option"
                    :displayValue="typeof option == 'object' ? option[displayKey] || option[valueKey] : option"
                    @click="selectOption(option)"
                />
            </slot>
        </div>
    </div>
</template>

<script>
import SelectOption from '@/components/global/forms/SelectOption'

export default {
    name: 'CpSelect',

    components: {
        SelectOption
    },

    props:{
        value: {
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        valueKey: {
            type: String,
            default: 'value'
        },
        displayKey: {
            type: String,
            default: 'name'
        },
        placeholder: {
            type: String,
            default: 'Select an option'
        },
        label: {
            type: String,
        },
        invalid: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            displayValue: null,
            open: false,
            tabindex: 0
        };
    },

    computed: {
        selected(){
            return this.options.find(option => {
                if(typeof option == 'object' && this.value != null) return option[this.valueKey] == this.value[this.valueKey]
                else return option == this.value
            })
        }
    },

    methods: {
        selectOption(option){
            this.open = false
            this.$emit('input', option)
        }
    }

}
</script>

<style lang="scss" scoped>
.selectHide {
  display: none;
}
</style>
