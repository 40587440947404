<template>
    <div>
        <div class="focus:outline-none" tabindex="0" @keydown.esc="hide" @keydown.enter="toggle">
            <slot name="button" :toggle="toggle" :hide="hide" :show="show" :visible="visible">
                <button class="btn white" :class="{active: visible}" @click="toggle">
                    <div class="flex items-center space-x-2">
                        <p class="normal-case">{{ text }}</p>
                        <i class="text-sm fas fa-chevron-down transition-transform" :class="{'rotate-180': visible}"></i>
                    </div>
                </button>
            </slot>
        </div>
        <div class="relative" :class="[`float-${align}`]">
            <transition name="fade-slide">
                <div v-if="visible" v-click-outside="hide" class="absolute z-10 top-0 right-0 pt-2" :class="[`${align}-0`]">
                    <slot :toggle="toggle" :hide="hide" :show="show" :visible="visible">
                        <div class="bg-white rounded shadow-lg border border-gray">
                            <p>Add options via default slot</p>
                        </div>
                    </slot>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Actions'
        },
        align: {
            type: String,
            default: 'left'
        }
    },

    data(){
        return {
            visible: false
        }
    },

    methods: {
        show(){
            this.visible = true
        },
        hide(){
            this.visible = false
        },
        toggle(){
            this.visible = !this.visible
        }
    }
}
</script>

<style lang="scss" scoped>
.btn.active,
.btn:hover {
    @apply bg-green text-white border-green;
}

.fade-slide-enter-active, .fade-slide-leave-active {
  transition: all ease-in .2s;
}
.fade-slide-enter, .fade-slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
