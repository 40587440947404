<template>
    <div>
        <div class="mb-10">
            <h2 class="mb-5">Newsroom &ndash; In the News</h2>
            <NewsroomFilters :title="'News Link'" :url="`/newsroom/in-the-news`" :canCreateNewsItem="canCreateNewsLink" :statuses="statuses" :status="newsLinkStatus" @search="search" />
        </div>

        <div>
            <template v-if="loading">
                <div class="flex items-center space-x-5">
                    <Loader class="w-10 h-10 text-green"/>
                    <p>Loading...</p>
                </div>
            </template>
            <template v-else-if="newsLinks.data && newsLinks.data.length">
                <ListView class="mb-10" :newsType="'newsLink'" :newsItems="newsLinks.data" :headers="headers" @archive="confirmArchive" @delete="confirmDelete" />
                <Pagination v-if="totalNewsLinks > 10" :page="page" :total-items="totalNewsLinks" :per-page="perPage" @change="pageUpdated"/>
            </template>
            <template v-else>
                <p></p>
                <p><i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No news links to show. Please refine your critera.</p>
            </template>
        </div>

        <!-- Modals -->
        <ConfirmArchiveModal 
            :newsItemId="newsLinkId"
            :newsType="'newsLink'"
            :visible="showConfirmArchiveModal" 
            @close="showConfirmArchiveModal = false"
            @success="getNewsLinks"
        />

        <ConfirmDeleteModal 
            :newsItemId="newsLinkId"
            :newsType="'newsLink'"
            :visible="showConfirmDeleteModal" 
            @close="showConfirmDeleteModal = false"
            @success="getNewsLinks"
        />
    </div>
</template>

<script>
import ConfirmDeleteModal from '@components/newsroom/modals/ConfirmDelete'
import ConfirmArchiveModal from '@components/newsroom/modals/ConfirmArchive'
import ListView from '@components/newsroom/ListView'
import NewsroomFilters from '@components/newsroom/NewsroomFilters'
import Loader from '@components/global/Loader'
import Pagination from '@components/global/Pagination'

export default {
    name: 'PressReleases',

    components: {
        ConfirmDeleteModal,
        ConfirmArchiveModal,
        ListView,
        NewsroomFilters,
        Loader,
        Pagination,
    },

    metaInfo() {
        return  {
            title: this.title,
        }
    },

    data() {
        return {
            headers: [
                'Title', '', '',
                'Status',
                'Publish Date', '',
                '', ''
            ],

            statuses: [
                'draft', 'scheduled', 'published', 'archived'
            ],

            showConfirmDeleteModal: false,
            showConfirmArchiveModal: false,

            newsLinks: [],
            newsLinkId: null,
            newsLinkStatus: '',

            loading: true,

            page: parseInt(this.$route.query.page) || 1,
            perPage: parseInt(this.$route.query.per_page) || 10,
        }
    },

    mounted() {
        this.setViewableStatuses()
        this.getNewsLinks()
    },

    computed: {
        canViewAllNewsLinks() {
            return this.$app.services.auth.hasPermission('newsroom.newslinks.view')
        },
        canCreateNewsLink() {
            return this.$app.services.auth.hasPermission('newsroom.newslinks.create')
        },
        title() {
            if (this.$route.query.search) return `Search: ${this.$route.query.search} | Newsroom - In the News`
            return 'Newsroom - In the News'
        },
        totalNewsLinks() {
            return this.newsLinks.meta?.total ?? 0
        },
    },

    methods: {
        getNewsLinks() {
            // default params
            const params = {
                relations: 'status',
                status: 'published|scheduled',
                admin: true,
                order_by: 'status:draft,published,archived|published_at|updated_at',
                page: this.$route.query.page || this.page,
                per_page: this.$route.query.per_page || this.perPage,
            }

            if (this.canViewAllNewsLinks) delete params.status

            // optional search params
            if (this.$route.query.search) {
                const search = this.$route.query.search.split(',')

                // status
                const statuses = search.filter(i => this.statuses.includes(i))
                this.newsLinkStatus = statuses.length === 1 ? statuses[0] : ''
                if (statuses.length > 0) params.status = statuses.join('|')

                // title
                const title = search.filter(i => !this.statuses.includes(i))
                if (title.length > 0) params.title = title.join(' ')
            }

            // optional date range params
            if (this.$route.query.after_date) params.after_date = this.$route.query.after_date
            if (this.$route.query.before_date) params.before_date = this.$route.query.before_date

            this.loading = true

            this.$app.api.newsroom.getNewsLinks(params)
                .then(res => {
                    this.newsLinks = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => this.loading = false);
        },

        confirmDelete(newsLinkId) {
            this.showConfirmDeleteModal = true
            this.newsLinkId = newsLinkId
        },

        confirmArchive(newsLinkId) {
            this.showConfirmArchiveModal = true
            this.newsLinkId = newsLinkId
        },
        
        setViewableStatuses() {
            if (!this.canViewAllNewsLinks)
                this.statuses = this.statuses.filter(i => i !== 'draft' && i !== 'archived')
        },

        pageUpdated(page) {
            this.page = page
            this.getNewsLinks()
        },

        search() {
            this.page = 1
            this.getNewsLinks()
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
