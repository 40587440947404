<template>
  <div class="flex flex-col p-6 w-full">
    <div class="mb-8 flex justify-between space-x-3">
      <h3>Create User</h3>
    </div>

    <div class="md:grid grid-cols-1 gap-4">
      <div class="col-span-1">
        <label class="block text-xs font-body text-gray-dark mb-2">First Name*:</label>
        <input type="text" class="cp-input mb-2" v-model="user.first_name" />
      </div>

      <div class="col-span-1">
        <label class="block text-xs font-body text-gray-dark mb-2">Last Name*:</label>
        <input type="text" class="cp-input mb-2" v-model="user.last_name" />
      </div>

      <div class="col-span-1">
        <label class="block text-xs font-body text-gray-dark mb-2">Email*:</label>
        <input type="text" class="cp-input mb-2" v-model="user.email" />
      </div>

      <div class="col-span-1">
        <label class="block text-xs font-body text-gray-dark mb-2">Phone*:</label>
        <the-mask mask="(###) ###-####" type="tel"
          class="bg-white !font-body text-base text-green card-input mb-2 border rounded-[7px] h-[38px] px-3 pb-[7px] tracking-[.3px]"
          :placeholder="''" autocomplete="tel-national" v-model="user.phone" />
      </div>

      <div class="col-span-1">
        <label class="block text-xs font-body text-gray-dark mb-2">Role:</label>
        <multiselect class="w-5/12 mb-2 multiselect" v-model="$v.user.role.$model" :options="roles"
          :loading="loading.roles" :disabled="loading.roles" :searchable="false" :close-on-select="true"
          :show-labels="false" :allow-empty="false" label="display_name" track-by="role_id" placeholder="Select role">
          <template slot="placeholder">
            <span class="text-gray-400">Select role</span>
          </template>
          <template #caret="{ toggle }">
            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
              <span class="absolute right-3 top-3 select-none text-green">
                <i class="fas fa-chevron-down"></i>
              </span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="space-x-5">
        <router-link class="btn white px-6" :to="`/users`">Cancel</router-link>
        <button class="btn button-green px-6" @click="handleSave" :disabled="!isValid || !canCreateUser">
          Save
        </button>
      </div>
    </div>

    <div v-if="error" class="flex space-x-5 mb-4">
      <p class="text-red ">{{ error }}</p>
    </div>
    <div v-if="saving" class="flex space-x-5 mb-4">
      <p class="text-green ">Saving...</p>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "CreateUser",

  data() {
    return {
      error: null,
      saving: false,
      roles: [],
      user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        role: null
      },
      loading: {
        roles: false
      }
    };
  },

  computed: {
    isValid() {
      return !this.$v.user.$invalid;
    },
    canCreateUser() {
      return this.$app.services.auth.hasPermission("users.create");
    }
  },

  methods: {
    async handleSave() {
      this.$v.$touch();
      if (this.isValid) {
        this.saving = true;

        let phone = this.user.phone.replace(/\D/g, "");
        if (phone.length === 10) {
          phone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
        } else {
          this.error = "Invalid phone number.";
          this.saving = false;
          return;
        }

        try {
          const [phoneResponse, emailResponse] = await Promise.all([
            this.$app.api.users.isPhoneUnique(phone),
            this.$app.api.users.isEmailUnique(this.user.email)
          ]);

          let phoneUnique = true;
          if (phoneResponse.data === "") {
            phoneUnique = false;
          }

          let emailUnique = true;
          if (emailResponse.data === "") {
            emailUnique = false;
          }

          if (!phoneUnique && !emailUnique) {
            this.error = "Phone and email already in use.";
            this.saving = false;
            return;
          }

          if (!phoneUnique) {
            this.error = "Phone already in use.";
            this.saving = false;
            return;
          }

          if (!emailUnique) {
            this.error = "Email already in use.";
            this.saving = false;
            return;
          }

          const userData = await this.$app.services.auth.createUser(
            this.user.email,
            this.user.first_name,
            this.user.last_name,
            phone,
            this.user.role?.role_id || null
          );

          this.$router.push("/users/" + userData.id);
        } catch (err) {
          console.error(err);
          this.error = err.message || "Unknown error";
        } finally {
          this.saving = false;
        }
      }
    },
    async getRoles() {
      this.loading.roles = true;
      return this.$app.api.roles
        .getRoles()
        .then(res => {
          this.roles = res.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.loading.roles = false));
    }
  },

  async created() {
    await Promise.all([this.getRoles()]);
  },

  validations: {
    user: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone: { required },
      role: {}
    }
  }
};
</script>
