const routes = [
    {
        path: '/blog',
        name: 'blog-list',
        component:  () => import('@views/blog/Blog.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['blogs.posts.view']
        }
    },
    {
        path: '/blog/create',
        name: 'blog-create',
        component: () => import('@views/blog/BlogPost.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['blogs.posts.create']
        }
    },
    {
        path: '/blog/:slug',
        name: 'blog-edit',
        component:  () => import('@views/blog/BlogPost.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['blogs.posts.update']
        }
    },
]

export default routes
