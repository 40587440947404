<template>
    <div>
        <div class="hidden lg:grid grid-cols-6 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="label">ID</h6>
            <h6 class="label">Payment ID</h6>
            <h6 class="label">Service</h6>
            <h6 class="label">Method</h6>
            <h6 class="label">Transaction ID</h6>
            <h6 class="label">Amount</h6>
        </div>
        <div class="lg:divide-y lg:divide-gray-200 lg:border lg:border-t-0 border-gray-200 lg:border-gray-200 rounded-b-md">
            <div v-for="refund in refunds" :key="refund.id" class="bg-gray-100 p-5 mb-5 lg:mb-0 rounded-md lg:rounded-none lg:bg-transparent lg:px-0 lg:py-3">
                <div class="grid grid-cols-1 lg:grid-cols-6 gap-2 gap-y-4 lg:px-2">
                    <div>
                        <h6 class="label lg:hidden">ID</h6>
                        <p>{{ refund.id }}</p>
                    </div>
                    <div>
                        <h6 class="label lg:hidden">Payment ID</h6>
                        <p>{{ refund.payment_id }}</p>
                    </div>
                    <div>
                        <h6 class="label lg:hidden">Service</h6>
                        <p>{{ refund.service.name }}</p>
                        <p v-if="refund.gift_card_transaction && refund.gift_card_transaction.card" class="break-words text-sm font-mono font-medium">
                            <span class="bg-white lg:bg-gray-100 rounded px-2 py-1 decoration-clone">
                                {{ refund.gift_card_transaction.card.card_number }}
                            </span>
                        </p>
                    </div>
                    <div >
                        <h6 class="label lg:hidden">Method</h6>
                        <p>{{ refund.type.name }}</p>
                    </div>
                    <div>
                        <h6 class="label lg:hidden">Transaction ID</h6>
                        <p class="break-words text-sm font-mono font-medium">
                            <span class="bg-white lg:bg-gray-100 rounded px-2 py-1 decoration-clone">{{ refund.transaction_id }}</span>
                        </p>
                    </div>
                    <div>
                        <h6 class="label lg:hidden">Amount</h6>
                        <p class="font-medium">{{ refund.amount | currency }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        refunds: {
            type: Array,
            required: true
        }
    },

    data(){
        return {
        }
    },
}
</script>

<style>

</style>
