<template>
    <div>
        <div class="hidden lg:grid grid-cols-4 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="col-span-1 label">ID</h6>
            <h6 class="col-span-1 label">Amount</h6>
            <h6 class="col-span-1 label">Date</h6>
            <h6 class="col-span-1 label">Order</h6>
        </div>
        <div class="lg:divide-y lg:divide-gray-200 lg:border lg:border-t-0 border-gray-200 lg:border-gray-200 rounded-b-md">
            <div v-for="transaction in transactions" :key="transaction.id" class="bg-gray-100 mb-5 lg:mb-0 rounded-md lg:rounded-none lg:bg-transparent p-5 lg:px-0 lg:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-2 gap-y-4 lg:px-3">
                    <div class="lg:col-span-1">
                        <h6 class="label lg:hidden">ID</h6>
                        <p>{{ transaction.id }}</p>
                    </div>
                    <div class="lg:col-span-1">
                        <h6 class="label lg:hidden">ID</h6>
                        <p class="font-medium" :class="[transaction.amount > 0 ? 'text-green' : 'text-red']"><template v-if="transaction.amount > 0">+ </template>{{ transaction.amount | currency }}</p>
                    </div>
                    <div class="lg:col-span-1">
                        <h6 class="label lg:hidden">Date</h6>
                        <p><Date :date="transaction.created_at" preset="DATE_FULL"/></p>
                        <p><Date :date="transaction.created_at" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
                    </div>
                    <div class="lg:col-span-1">
                        <h6 class="label lg:hidden">Order</h6>
                        <p v-if="transaction.payment && transaction.payment.orders && transaction.payment.orders.length">
                            <router-link v-for="(order, index) in transaction.payment.orders" :to="`/orders/${order.id}`" class="hover:underline">
                                <span>{{ order.id }}</span><template v-if="index < transaction.payment.orders.length - 1">,</template>
                            </router-link>
                        </p>
                        <p v-else>-</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Date from '@components/global/dates/Date'

export default {
    props: {
        transactions: {
            type: Array,
            required: true
        }
    },

    components: {
        Date,
    }
}
</script>

<style lang="scss" scoped>
</style>
