<template>
    <div>
        <ShippingForm :value="address" @input="handleAddress"/>
    </div>
</template>

<script>
import ShippingForm from '@components/registrations/update/shipping/ShippingForm';

export default {
    name: "ShippingAddress",

    components: {
        ShippingForm
    },

    props: {
        address: {
            type: Object,
            required: false
        }
    },

    data() {
        return {}
    },

    computed: {},

    methods: {
        handleAddress(value) {
            this.$emit('isValid', !value.$invalid)
            if(!value.$invalid) {
                let payload = {
                    first_name: value.$model.first_name,
                    last_name: value.$model.last_name,
                    phone: value.$model.phone,
                    street_1: value.$model.street_1,
                    street_2: value.$model.street_2,
                    locality: value.$model.locality,
                    administrative_area: value.$model.administrative_area,
                    postal_code: value.$model.postal_code
                }

                this.$app.store.commit('registration/setUpdateData', {shipping: payload})
            }
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
