const routes = [
    {
        path: '/calendars',
        name: 'calendars',
        component: () => import('@views/calendars/Calendars.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['calendars.view']
        }
    },
    // {
    //     path: '/calendars/:id',
    //     name: 'calendar',
    //     component: () => import('@views/calendars/Calendars.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         roles: [],
    //         permissions: []
    //     }
    // },
]

export default routes
