<template>
    <span class="badge" :class="[color]">
        <template v-if="enabled">Enabled</template>
        <template v-else>Disabled</template>
    </span>
</template>

<script>
export default {
    props: {
        enabled: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        color(){
            if(this.enabled) return 'green'
            else return 'gray'
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
