<template>
    <Tooltip :message="tooltipMessage">
        <span class="badge text-sm" :class="{
            'green': status === 'Closed',
            'mint-green': status === 'Active',
            'green-outline': status === 'Inactive',
            'red': status === 'Canceled',
            'red': status === 'Error',
        }">
            {{ status }}
        </span>
    </Tooltip>
</template>

<script>
import Tooltip from '@components/global/Tooltip'
import { DateTime } from 'luxon';

export default {
    components: {
        Tooltip,
    },

    props: {
        classEvent: {
            type: Object,
            required: true
        },
    },

    computed: {
        status() {
            if (this.classEvent.canceled) return 'Canceled'
            if (this.classEvent.completed) return 'Completed'
            if (this.classEvent.menu) {
                if (this.isMenuActive(this.classEvent)) {
                    if (this.classEvent.date < DateTime.now().toUTC().toISO()) return 'Closed'
                    else
                        return 'Active'
                }
                else return 'Inactive'
            } else {
                return 'Error'
            }
        },
        tooltipMessage() {
            if (this.classEvent.canceled) return 'This event has been canceled.'
            if (this.classEvent.completed) return 'No more registrations or changes can be made.'
            if (this.classEvent.menu) {
                if (this.classEvent.private_menu) {
                    if (this.classEvent.class_is_public)
                        return 'This event has a private menu that can be purchased.'
                    else
                        return 'This event has a private menu that can be purchased for those with the link.'
                }
                if (this.isMenuActive(this.classEvent)) return 'This event has an active menu that can be purchased.'
                else return 'This event has an inactive menu that cannot be purchased.'
            } else {
                return 'This is an old event that is not linked to a menu.'
            }
        },
    },

    methods: {
        isMenuActive(classEvent) {
            if (classEvent.private_menu) {
                const currentDate = DateTime.now();
                const classDate = DateTime.fromISO(classEvent.date);
                return currentDate <= classDate;
            }
            const currentMonth = DateTime.now().startOf('month');
            const menuDate = DateTime.fromISO(classEvent.menu.month_year);
            return currentMonth <= menuDate;
        },
    },
}
</script>

<style lang="scss" scoped></style>
