<template>
  <button @click="handleClick" class="btn" :class="buttonClass">
    <template v-if="loading">
      <LoadingButton />
    </template>
    <template v-else><slot></slot></template>
  </button>
</template>

<script>
import AdminLoadingButton from './AdminLoadingButton.vue'

export default {
  name: 'AdminCPButton',
  components: {
    LoadingButton: AdminLoadingButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      buttonClass: undefined,
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
  created() {
    this.buttonClass = {
      primary: 'btn-green',
      secondary: 'btn-secondary',
    }[this.variant]
  },
}
</script>
