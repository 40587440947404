import Resource from "./resource";

export default class Classes extends Resource {
  /**
   *
   * @param {object} params
   */
  getClasses(params = {}) {
    return this.axios.get(`classes`, { params });
  }

  /**
   *
   * @param {object} params
   */
  getTypes(params = {}) {
    return this.axios.get(`classes/types`, { params });
  }

  /**
   *
   * @param {string|int} sku
   * @param {object} params
   */
  getClass(sku, params = {}) {
    return this.axios.get(`classes/${sku}`, { params });
  }

  /**
   * @param {object} data class data
   */
  createClass(data) {
    return this.axios.post(`classes`, data);
  }

  /**
   * Update a class
   * @param {string|int} id
   * @param {object} data
   */
  update(id, data) {
    return this.axios.put(`classes/${id}`, data);
  }

  /**
   * Completes the class
   * @param {string|int} id class event ID
   * @param {object} data post data
   */
  complete(id, data = {}) {
    return this.axios.put(`classes/${id}/complete`, data);
  }

  /**
   * Closes the class
   * @param {string|int} id class event ID
   * @param {object} data post data
   */
  closeGroup(id, data = {}) {
    return this.axios.put(`classes/${id}/close`, data);
  }

  /**
   * Reopens the class
   * @param {string|int} id class event ID
   * @param {object} data post data
   */
  reopen(id, data = {}) {
    return this.axios.put(`classes/${id}/reopen`, data);
  }

  /**
   * Cancels the class
   * @param {string|int} id class event ID
   * @param {object} data post data
   */
  cancel(id, data = {}) {
    return this.axios.put(`classes/${id}/cancel`, data);
  }

  /**
   * Deletes the class
   * @param {string|int} id class event ID
   * @param {object} data post data
   */
  delete(id, data = {}) {
    return this.axios.delete(`classes/${id}`, { data });
  }

  archive(id, shouldArchive = true) {
    if (shouldArchive) {
      return this.axios.put(`classes/${id}/archive`);
    } else {
      return this.axios.put(`classes/${id}/unarchive`);
    }
  }

  generateClassInformation(id, params = {}) {
    return this.axios.get(`classes/${id}/information`, { params });
  }

  /**
   * @param {object} data custom class request data
   */
  sendCustomClassRequest(data) {
    return this.axios.post("classes/custom", data);
  }

  getCustomClassRequests(params = {}) {
    return this.axios.get(`custom-classes`, { params });
  }

  deleteCustomClassRequest(id, data = {}) {
    return this.axios.delete(`custom-classes/${id}`, { data });
  }

  /**
   * @param {string|int} id class event ID
   * @param {object} data class is public boolean
   */
  setClassIsPublic(id, data = {}) {
    return this.axios.put(`classes/${id}/public`, data);
  }

  /**
   * @param {string|int} id class event ID
   */
  generateParticipantLabels(id, params = {}) {
    return this.axios.get(`classes/${id}/labels`, { params });
  }

  /**
   * @param {string|int} id class event ID
   */
  generateForecast(id) {
    return this.axios.get(`classes/${id}/forecast`);
  }

  /**
   * @param {string|int} id class event ID
   */
  generateGroceryOrder(id) {
    return this.axios.get(`classes/${id}/grocery`);
  }

  generateKiyomi(params = {}) {
    return this.axios.post(`kiyomi`, { params })
  }

}
