import Service from "@shared/app/services/service"

export default class Location extends Service {

    constructor(router, store, api){
        super(router, store, api)

        this.stateCodeMap = {
            'AZ': 'Arizona',
            'CO': 'Colorado',
            'ID': 'Idaho',
            'NM': 'New Mexico',
            'NV': 'Nevada',
            'UT': 'Utah',
        }
    }

    getOrderedLocations(locations, hideMalouf = true) {
        if(hideMalouf) {
            const idx = locations.findIndex(location => location.name === 'Malouf')
            if(idx > -1) locations.splice(idx, 1)
        }

        let locationsMap = new Map()
        let options = []

        locations.forEach((location) => {
            if(location.address) {
                if(location.name === 'Farmington' && location.address?.administrative_area === 'NM') location.name = 'Farmington, NM'

                if (!locationsMap.has(location.address?.administrative_area)) {
                    locationsMap.set(location.address?.administrative_area, [location])
                } else {
                    locationsMap.get(location.address?.administrative_area).push(location)
                }
            }
        })

        locationsMap.forEach((value, key) => {
            options.push({
                state: this.stateCodeMap[key],
                locations: value
            })
        })

        return options.sort((a,b) => {
            if(a.state < b.state) return -1
            if(a.state > b.state) return 1
            return 0
        })
    }
}
