const routes = [
    {
        path: '/promotions',
        name: 'promotions',
        component: () => import('@views/promotions/Promotions.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['promotions.view']
        }
    },
    {
        path: '/promotions/create',
        name: 'promotion-create',
        component: () => import('@views/promotions/CreatePromotion.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['promotions.create']
        }
    },
    {
        path: '/promotions/:id/edit',
        name: 'promotion-edit',
        component: () => import('@views/promotions/EditPromotion.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['promotions.update',]
        }
    },
    {
        path: '/promotions/:id',
        name: 'promotion-details',
        component: () => import('@views/promotions/Promotion.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['promotions.view', 'promotions.codes.view']
        }
    },
]

export default routes
