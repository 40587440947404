<template>
    <form>
        <div class="w-full mb-3">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">First Name*</label>
            <input v-model="$v.address.first_name.$model" type="text" @blur="$v.address.first_name.$touch"
                class="w-full mb-0" :class="{ 'border-red': $v.address.first_name.$error }" placeholder="">
            <template v-if="$v.address.first_name.$error">
                <p v-if="!$v.address.first_name.required" class="text-red text-xs ml-1">First name is required</p>
            </template>
        </div>
        <div class="w-full mb-3">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">Last Name*</label>
            <input v-model="$v.address.last_name.$model" type="text" @blur="$v.address.last_name.$touch" class="w-full mb-0"
                :class="{ 'border-red': $v.address.last_name.$error }" placeholder="">
            <template v-if="$v.address.last_name.$error">
                <p v-if="!$v.address.last_name.required" class="text-red text-xs ml-1">Last name is required</p>
            </template>
        </div>
        <div class="w-full mb-3">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">Phone</label>
            <the-mask mask="(###) ###-####" v-model="$v.address.phone.$model" @blur.native="$v.address.phone.$touch"
                type="tel" class="w-full mb-0" :class="{ 'border-red': $v.address.phone.$error }"
                placeholder="(###) ###-####" />
            <template v-if="$v.address.phone.$error">
                <!--                <p v-if="!$v.address.phone.required" class="text-red text-xs ml-1">Phone is required</p>-->
            </template>
        </div>
        <div class="w-full mb-3">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">Street Address*</label>
            <input v-model="$v.address.street_1.$model" type="text" @blur="$v.address.street_1.$touch" class="w-full mb-0"
                :class="{ 'border-red': $v.address.street_1.$error }" placeholder="">
            <template v-if="$v.address.street_1.$error">
                <p v-if="!$v.address.street_1.required" class="text-red text-xs ml-1">Street 1 is required</p>
            </template>
        </div>
        <div class="w-full mb-3">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">Apartment/unit/suite</label>
            <input v-model="$v.address.street_2.$model" type="text" @blur="$v.address.street_2.$touch" class="w-full mb-0"
                :class="{ 'border-red': $v.address.street_2.$error }" placeholder="">
        </div>
        <div class="w-full mb-3">
            <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">City*</label>
            <input v-model="$v.address.locality.$model" type="text" @blur="$v.address.locality.$touch" class="w-full mb-0"
                :class="{ 'border-red': $v.address.locality.$error }" placeholder="">
            <template v-if="$v.address.locality.$error">
                <p v-if="!$v.address.locality.required" class="text-red text-xs ml-1">City is required</p>
            </template>
        </div>
        <div class="md:flex md:justify-between md:space-x-4">
            <div class="w-full mb-3">
                <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">State*</label>
                <cp-select class="my-0" :invalid="$v.address.administrative_area.$error"
                    v-model="$v.address.administrative_area.$model" @blur.native="$v.address.administrative_area.$touch"
                    :options="shippingStates" placeholder="Select a state" />
                <p v-if="$v.address.administrative_area.$error && !$v.address.administrative_area.required"
                    class="text-red text-xs ml-1">State is required</p>
            </div>
            <div class="w-full mb-3">
                <label class="block text-xs font-bold uppercase font-body text-green mb-1 ml-1">Postal Code*</label>
                <input v-mask="'#####'" v-model="$v.address.postal_code.$model" @blur="$v.address.postal_code.$touch"
                    type="text" class="w-full mb-0" :class="{ 'border-red': $v.address.postal_code.$error }"
                    placeholder="Enter street address">
                <p v-if="$v.address.postal_code.$error && !$v.address.postal_code.required" class="text-red text-xs ml-1">
                    Postal code is required</p>
            </div>
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import CpSelect from '@/components/global/forms/Select'

export default {
    name: "ShippingForm",

    components: {
        CpSelect,
    },

    props: {
        value: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            shippingStates: [
                { value: 'ID', name: 'Idaho' },
                { value: 'NV', name: 'Nevada' },
                { value: 'UT', name: 'Utah' },
                { value: 'AZ', name: 'Arizona' },
            ],
            address: {
                first_name: '',
                last_name: '',
                phone: '',
                street_1: '',
                street_2: '',
                locality: '',
                administrative_area: '',
                postal_code: ''
            }
        }
    },

    watch: {
        address: {
            handler(newValue, oldValue) {
                this.emitInput()
            },
            deep: true,
        },
    },

    computed: {},

    methods: {
        emitInput() {
            this.$emit('input', this.$v.address)
        }
    },

    created() {
        if (this.value) {
            this.address.first_name = this.value?.first_name || null
            this.address.last_name = this.value?.last_name || null
            this.address.phone = this.value?.phone || null
            this.address.street_1 = this.value?.street_1 || null
            this.address.street_2 = this.value?.street_2 || null
            this.address.locality = this.value?.locality || null
            this.address.administrative_area = this.value?.administrative_area ? this.shippingStates.filter((state) => { return state.value === this.value.administrative_area })[0] : null
            this.address.postal_code = this.value?.postal_code || null
        }
    },

    validations: {
        address: {
            first_name: { required },
            last_name: { required },
            phone: {},
            street_1: { required },
            street_2: {},
            locality: { required },
            administrative_area: { required },
            postal_code: { required }
        }
    }
}
</script>

<style lang="scss" scoped></style>
