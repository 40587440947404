<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Forecast Menu</h3>
                <button class="px-2 text-xl text-green" @click="close" :disabled="isProcessing"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="mb-6">
                <p class="mb-6">The process may take a few minutes to complete. Upon completion, you will be automatically
                    redirected to the Google Drive folder containing the forecast.</p>
                <label class="block text-xs font-body text-gray-dark mb-2">Select the menu you'd like to
                    forecast:</label>
                <multiselect class="max-w-xs mb-4" v-model="selectedMenu" :options="menus.data" :searchable="true"
                    :close-on-select="true" :show-labels="false" :allow-empty="false" label="name" track-by="id"
                    :loading="menus.loading" placeholder="Select an active menu">

                    <template slot="placeholder"><span class="text-gray-400">Select a menu</span></template>

                    <template #caret="{ toggle }">
                        <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                            <span class="absolute right-3 top-3 select-none text-green"><i
                                    class="fas fa-chevron-down"></i></span>
                        </div>
                    </template>
                </multiselect>
                <!-- add a number input that defaults to 30 -->
                <label class="block text-xs font-body text-gray-dark mb-2">Sets:</label>
                <input type="number" class="input" v-model="sets" min="1" max="100" step="1" />
                <div v-if="isProcessing && !error" class="flex items-center space-x-1 mt-4">
                    <span class="mr-2">Generating forecast...</span>
                    <Loader class="w-5 h-5" />
                </div>
                <div v-if="error" class="flex items-center space-x-1">
                    <span class="text-red">Error: {{ error }}</span>
                </div>
            </div>
            <div class="flex space-x-5">
                <button class="btn px-6" @click="forecastMenu"
                    :disabled="isProcessing || sets === null || selectedMenu === null">Forecast</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'

export default {
    name: "SmartForecastModal",

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        Modal,
        Loader
    },

    data() {
        return {
            isProcessing: false,
            error: null,
            menus: {
                loading: false,
                data: [],
            },
            selectedMenu: null,
            sets: 30,
        };
    },

    mounted() {
        this.getMenus();
    },

    methods: {
        close() {
            this.$emit('close')
        },
        forecastMenu() {
            this.isProcessing = true;
            this.error = null;
            let params = {
                sets: this.sets,
            };
            this.$app.api.menus.generateForecast(this.selectedMenu.id, params).then(response => {
                this.checkJobStatus('SmartForecastJob');
            }).catch(error => {
                console.error(error);
                this.isProcessing = false;
                this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
            });
        },
        checkJobStatus(jobType) {
            let uuid = null;
            const interval = setInterval(() => {
                this.$app.api.jobs.getMenuJobId(this.selectedMenu.id, {
                    jobType: jobType
                }).then(response => {
                    if (response.data) {
                        uuid = response.data;
                    } else {
                        clearInterval(interval);
                        if (uuid) {
                            this.checkFailedJob(uuid);
                        } else {
                            console.error('Something went wrong: UUID is null.');
                            this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                        }
                    }
                }).catch(error => {
                    console.error('Error:', error);
                    clearInterval(interval);
                    this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                });
            }, 5000);
        },
        checkFailedJob(uuid) {
            this.$app.api.jobs.checkFailedJob(uuid).then(response => {
                if (!response.data.failed) {
                    const url = 'https://drive.google.com/drive/folders/1n7Yp3jcjjapcy-QtjXECZFzqWMzNDH0D';
                    window.open(url, "_blank");
                    this.visible = false;
                    this.close();
                } else {
                    console.error('Something went wrong: Job failed.');
                    this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                }
                this.isProcessing = false;
            }).catch(error => {
                console.error(error);
                this.isProcessing = false;
                this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
            });
        },
        getMenus() {
            this.menus.loading = true
            return this.$app.api.menus
                .getMenus({
                    future: true,
                    custom: false,
                })
                .then((response) => {
                    this.menus.data = response.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => (this.menus.loading = false))
        },
    },
}
</script>

<style scoped></style>
