<template>
    <div>
        <div class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="col-span-4 label">Name</h6>
            <h6 class="col-span-2 label">Surcharge</h6>
            <h6 class="col-span-3 label">Type</h6>
            <h6 class="col-span-3 label">Active</h6>
        </div>
        <div
            class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md">
            <div v-for="meal in meals" :key="meal.id"
                class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-4">
                        <h6 class="label xl:hidden">Meal</h6>
                        <router-link class="hover:underline block mr-2" :to="`/meals/${meal.id}`">
                            <p>{{ meal.name }}</p>
                        </router-link>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Surcharge</h6>
                        <p>${{ meal.price_offset }}</p>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Type</h6>
                        <p>
                        <p v-for="mealType in meal.meal_types" class="inline-block badge small mr-2">{{ mealType.name }}
                        </p>
                        </p>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Active</h6>
                        <p>
                            <StatusBadge class="text-xs" :active="meal.active" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from '@components/meals/StatusBadge'

export default {
    props: {
        meals: {
            type: Array,
            required: true
        }
    },
    components: {
        StatusBadge,
    },

    created() { }
};
</script>

<style></style>