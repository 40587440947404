<template>
    <div class="flex flex-col p-6 w-full">
        <div class="mb-8 flex justify-between space-x-3">
            <h3>Create Meal</h3>
        </div>

        <div class="col-span-1 w-3/4">All new meals need to be setup in Galley Solutions. Once created, select them from the
            dropdown
            and
            then sync.</div>

        <div class="col-span-1">
            <multiselect class="max-w-xs mt-4" v-model="newMeal" :options="unsyncedGalleyMeals" :searchable="true"
                :close-on-select="true" :show-labels="false" :allow-empty="false"
                :disabled="unsyncedGalleyMeals.length === 0" :loading="loading" label="name" track-by="id"
                placeholder="Select galley meal">
                <template slot="placeholder"><span class="text-gray-400">Select galley meal</span></template>
                <template #caret="{ toggle }">
                    <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                        <span class="absolute right-3 top-3 select-none text-green"><i
                                class="fas fa-chevron-down"></i></span>
                    </div>
                </template>
            </multiselect>
        </div>

        <div v-if="newMeal" class="mt-6">
            <p class="mb-2">This will create a new meal in the system with the following meal variants:</p>
            <ul class="list-disc ml-6">
                <li v-for="variant in mealVariants(newMeal)" :key="variant.id">{{ variant.name }}</li>
            </ul>
            <p class="mt-2">If you are ready to proceed, click sync. This process may take several minutes.</p>
        </div>

        <div v-if="error" class="flex space-x-5 mb-4">
            <p class="text-red ml-1">{{ error }}</p>
        </div>

        <div class="flex space-x-5 mt-6">
            <router-link v-if="!syncing" class="btn white px-6" :to="`/meals`">Cancel</router-link>
            <button class="btn button-green px-6 flex items-center" @click="handleSync"
                :disabled="syncing || loading || !newMeal">
                <span v-if="syncing" class="mr-2">
                    <Loader class="w-5 h-5 text-white" />
                </span>
                <span>Sync</span>
            </button>
        </div>
    </div>
</template>

<script>
import Loader from '@components/global/Loader'

export default {
    name: "CreateMeal",

    components: {
        Loader
    },

    data() {
        return {
            error: null,
            newMeal: null,
            unsyncedGalleyMeals: [],
            allGalleyMeals: [],
            loading: false,
            syncing: false
        }
    },

    methods: {
        fetchUnsyncedGalleyRecipes() {
            this.loading = true
            this.$app.api.meals.fetchUnsyncedGalleyRecipes().then((response) => {
                this.allGalleyMeals = Object.values(response.data.data)
                const regularMeals = this.allGalleyMeals.filter((meal) => !meal.name.includes('-') && !meal.name.toLowerCase().includes('testing'))
                const bulkMeals = this.allGalleyMeals.filter((meal) => meal.name.toLowerCase().includes('bulk'))
                this.unsyncedGalleyMeals = regularMeals.concat(bulkMeals.filter((meal) => !regularMeals.some((m) => meal.name.includes(m.name))))
            }).catch((error) => {
                this.error = error.response.data.message
            }).finally(() => {
                this.loading = false
            })
        },

        mealVariants(meal) {
            return this.allGalleyMeals.filter((m) => m.name.includes(meal.name))
        },

        handleSync() {
            this.syncing = true;
            const dataToSend = {
                galley_meals: this.mealVariants(this.newMeal)
            };

            this.$app.api.meals.createMeal(dataToSend)
                .then((response) => {
                    this.$router.push('/meals');
                })
                .catch((error) => {
                    this.error = error.response.data.message;
                })
                .finally(() => {
                    this.syncing = false;
                });
        }
    },

    created() {
        this.fetchUnsyncedGalleyRecipes()
    }
}

</script>