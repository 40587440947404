<template>
  <div>
    <div v-if="loading.registration">
      <div class="flex items-center space-x-5">
        <Loader class="w-10 h-10 text-green" />
        <p>Loading registration...</p>
      </div>
    </div>
    <div v-else-if="registration">
      <!-- confirmation modal -->
      <SendConfirmationModal :registration="registration" :visible="modals.sendConfirmation"
        @close="modals.sendConfirmation = false" />

      <!-- reminder modal -->
      <SendReminderModal :registration="registration" :visible="modals.sendReminder"
        @close="modals.sendReminder = false" />

      <!-- Header -->
      <div class="mb-14">
        <div class="flex justify-between space-x-2">
          <h2 class="mb-2">Registration #{{ registration.id }}</h2>
          <div v-if="canPerformActions">
            <Popover align="right">
              <template #default="{ hide }">
                <ActionsMenu class="w-64" :registration="registration" @sendConfirmation="
                  modals.sendConfirmation = true
                hide()
                  " @sendReminder="
                    modals.sendReminder = true
                  hide()
                    " @edit="$router.push(`/registrations/${registration.id}/edit`)" />
              </template>
            </Popover>
          </div>
        </div>
        <div>
          <multiselect class="w-40" v-model="status" :options="statuses"
            :loading="loading.statuses || loading.updateStatus" :disabled="loading.statuses || loading.updateStatus"
            :searchable="false" :close-on-select="true" :show-labels="false" :allow-empty="false" label="name"
            track-by="id" placeholder="Select status" @input="updateStatus">
            <template slot="placeholder"><span class="text-gray-400">Select status</span></template>
            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
              </div>
            </template>
          </multiselect>
        </div>
        <!-- <StatusBadge :registration="registration"/> -->
      </div>

      <!-- Details -->
      <div class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-6 mb-4">
        <div>
          <h6 class="label">Order ID</h6>
          <router-link :to="`/orders/${registration.order.id}`" class="block hover:underline font-normal">
            <p>{{ registration.order.id }}</p>
          </router-link>
        </div>
        <div>
          <h6 class="label">Total Cost</h6>
          <p>{{ registration.total | currency }}</p>
        </div>
        <div>
          <h6 class="label">Portion Size</h6>
          <p>{{ registration.portion_size.name }}</p>
        </div>
        <div>
          <h6 class="label">Portion Quantity</h6>
          <p>{{ registration.portion_quantity.name }}</p>
        </div>
        <div>
          <h6 class="label">Guest</h6>
          <p>{{ registration.guest.name }}</p>
          <p>{{ registration.guest.email }}</p>
          <p>{{ registration.guest.phone }}</p>
        </div>
        <div>
          <h6 class="label">Confirmation Sent</h6>
          <p v-if="registration.confirmation_sent_at">
            {{ registration.confirmation_sent_at | date('DATETIME_FULL') }}
          </p>
          <p v-else>No</p>
        </div>
        <div v-if="registration.class.fulfillment_id == 4">
          <h6 class="label">Expected Delivery</h6>
          <p v-if="registration.arrival_date">
            {{ registration.arrival_date | date('DATETIME_FULL') }}
          </p>
          <p v-else>Uh oh... something is missing</p>
        </div>
        <div>
          <h6 class="label">Reminder Sent</h6>
          <p v-if="registration.reminder_sent_at">
            {{ registration.reminder_sent_at | date('DATETIME_FULL') }}
          </p>
          <p v-else>No</p>
        </div>
        <div v-if="registration.instructions">
          <h6 class="label">User Instructions</h6>
          <p>{{ registration.instructions }}</p>
        </div>
        <div v-if="userAllergies">
          <h6 class="label">User Allergies</h6>
          <p>{{ userAllergies }}</p>
        </div>
        <div v-if="userSpiceTolerance">
          <h6 class="label">User Spice Preference</h6>
          <p>{{ userSpiceTolerance }}</p>
        </div>
      </div>
      <div v-if="userAllergyNotes" class="mb-6">
        <h6 class="label">User Allergy Notes</h6>
        <p>{{ userAllergyNotes }}</p>
      </div>

      <TextBox class="mb-2" v-model="editablePrepInstructions" label="Prep Instructions:"
        placeholder="No onion, mild spice, etc..." />

      <div class="flex space-x-2 mb-14">
        <button class="btn white text-sm" @click="resetPrepInstructions">Cancel</button>
        <button class="btn cp-btn cp-btn-green-border text-sm" @click="savePrepInstructions" :disabled="isSaveDisabled">
          Save
        </button>
      </div>

      <!-- Class -->
      <div v-if="registration.class_event_id" class="mb-14">
        <div class="mb-5">
          <h4><i class="fas fa-lemon pr-2"></i>Class</h4>
        </div>
        <div class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <h6 class="label">SKU</h6>
            <router-link :to="`/classes/${registration.class.sku}`" class="block hover:underline">
              <p>{{ registration.class.sku }}</p>
            </router-link>
          </div>
          <div>
            <h6 class="label">Location</h6>
            <p>{{ registration.class.location.name }}</p>
          </div>
          <div>
            <h6 class="label">Address</h6>
            <p>{{ registration.class.location.store_name }}</p>
            <div v-if="registration.class.location.address">
              <p>{{ registration.class.location.address.street_1 }}</p>
              <p>
                {{ registration.class.location.address.locality }},
                {{ registration.class.location.address.administrative_area }}
                {{ registration.class.location.address.postal_code }}
              </p>
            </div>
          </div>
          <div>
            <h6 class="label">Date</h6>
            <p>
              <Date :date="registration.class.date" :timezone="registration.class.location.timezone" />
            </p>
          </div>
        </div>
      </div>

      <!-- Guests -->
      <div class="mb-14" v-if="registration.guests && registration.guests.length">
        <div class="flex space-x-2 mb-3 items-center">
          <h4><i class="fas fa-user-friends pr-2"></i>Guests</h4>
          <p class="text-sm font-semibold rounded-2xl bg-mint px-3">
            {{ registration.guests.length }}
          </p>
        </div>
        <GuestListView :guests="registration.guests" />
      </div>

      <!-- Shipments -->
      <div class="mb-14" v-if="registration.class.fulfillment_id === 4">
        <div class="flex space-x-2 mb-3 items-center">
          <h4><i class="fas fa-shipping-fast pr-2"></i>Shipments</h4>
          <p class="text-sm font-semibold rounded-2xl bg-mint px-3">
            {{ registration.shippo_orders.length }}
          </p>
        </div>
        <button class="btn cp-btn cp-btn-green-border text-sm mb-4" @click="getShippingLabel">
          Get Shipping Label</button>
        <ShipmentsListView v-if="registration.shippo_orders && registration.shippo_orders.length"
          :shipments="registration.shippo_orders" />
      </div>

      <!-- Selections -->
      <div class="mb-14" v-if="registration.selections && registration.selections.length">
        <div class="flex space-x-2 mb-3 items-center">
          <h4><i class="fas fa-utensils pr-2"></i>Selections</h4>
          <p class="text-sm font-semibold rounded-2xl bg-mint px-3">
            {{ registration.selections.length }}
          </p>
        </div>
        <SelectionListView :selections="registration.selections" />
      </div>
    </div>
    <div v-else>
      <p>
        <i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops...
        something went wrong!
      </p>
    </div>
  </div>
</template>

<script>
import Loader from '@components/global/Loader'
import SendConfirmationModal from '@components/registrations/modals/SendConfirmation'
import SendReminderModal from '@components/registrations/modals/SendReminder'
import Popover from '@components/global/Popover'
import ActionsMenu from '@components/registrations/ActionsMenu'
import GuestListView from '@components/registrations/guests/ListView'
import ShipmentsListView from '@components/registrations/shipments/ListView'
import SelectionListView from '@components/registrations/selections/ListView'
import StatusBadge from '@components/registrations/StatusBadge'
import Date from '@components/global/dates/Date'
import TextBox from '@/components/global/forms/Textbox'
import { DateTime } from 'luxon'

export default {
  components: {
    Loader,
    SendConfirmationModal,
    SendReminderModal,
    Popover,
    ActionsMenu,
    GuestListView,
    ShipmentsListView,
    SelectionListView,
    StatusBadge,
    Date,
    TextBox,
  },

  metaInfo() {
    return {
      title:
        (this.registration?.id ? `${this.registration.id} | ` : '') + 'Registrations',
    }
  },

  data() {
    return {
      registration: null,
      editablePrepInstructions: null,
      status: null,
      statuses: [],
      selectedOption: null,
      selectedGroup: null,
      loading: {
        registration: false,
        statuses: false,
        updateStatus: false,
      },
      modals: {
        sendConfirmation: false,
        sendReminder: false,
      },
      spiceOptions: [
        { label: 'Mild', value: 'mild' },
        { label: 'No Preference', value: 'no-preference' },
      ],
    }
  },

  computed: {
    canPerformActions() {
      return this.$app.services.auth.hasOneOfPermissions(['registrations.update']) && this.registration.class_event_id
    },
    canEdit() {
      return (
        this.$app.services.auth.hasRole('admin') ||
        this.$app.services.auth.hasRole('regional_manager') ||
        (!this.registration.class.completed &&
          DateTime.now() < DateTime.fromISO(this.registration.class.refundable_until))
      )
    },
    isSaveDisabled() {
      return this.editablePrepInstructions === this.registration.prep_instructions
    },
    userAllergies() {
      return this.registration.order?.user?.allergies
        .map((allergy) => {
          return allergy.name
        })
        .join(', ')
    },
    userSpiceTolerance() {
      return (
        this.spiceOptions.find(
          (option) => option.value === this.registration.order?.user?.spice_tolerance
        )?.label || this.registration.order?.user?.spice_tolerance
      )
    },
    userAllergyNotes() {
      return this.registration.order?.user?.allergy_notes
    },
  },

  methods: {
    handleGroupClick(group) {
      this.selectedGroup = group
    },
    selectOption(option) {
      this.selectedOption = option;
    },
    getStatuses() {
      this.loading.statuses = true
      return this.$app.api.registrations
        .getStatuses()
        .then((response) => {
          this.statuses = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (this.loading.statuses = false))
    },
    updateStatus() {
      let data = {
        status_id: this.status.id,
      }

      this.loading.updateStatus = true
      return this.$app.api.registrations
        .setStatus(this.registration.id, data)
        .then((response) => {
          this.registration = { ...this.registration, ...response.data }
          this.status = this.registration.status
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (this.loading.updateStatus = false))
    },
    getRegistration() {
      let params = {}

      this.loading.registration = true
      return this.$app.api.registrations
        .getRegistration(this.$route.params.id, params)
        .then((response) => {
          this.registration = response.data
          this.status = this.registration.status
          this.resetPrepInstructions()
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (this.loading.registration = false))
    },
    getShippingLabel() {
      this.$app.api.registrations
        .getShippoOrderUrl(this.registration.id)
        .then((response) => {
          window.open(response.data, '_blank')
        })
        .catch((error) => {
          console.error('getShippingLabel error', error)
        })
    },
    resetPrepInstructions() {
      this.editablePrepInstructions = this.registration.prep_instructions
      this.selectedOption = null
    },
    savePrepInstructions() {
      let params = {
        class_event_id: this.registration.class_event_id,
        instructions: this.registration.instructions,
        prep_instructions: this.editablePrepInstructions,
        override_refund: true,
      }

      return this.$app.api.registrations
        .updateRegistration(this.$route.params.id, params)
        .then((response) => {
          this.registration.prep_instructions = response.data.prep_instructions
          this.resetPrepInstructions()
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },

  created() {
    this.getRegistration()
    this.getStatuses()
  },
}
</script>

<style></style>
