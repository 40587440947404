<template>
  <div class="flex flex-col p-6 w-full">
    <div class="mb-8 flex justify-between space-x-3">
      <h3>{{ sku ? "Edit Event" : "Create Event" }}</h3>
    </div>

    <ClassForm v-model="classEvent" :sku="sku" @input="handleChange" />

    <div v-if="error" class="flex space-x-5 mb-4">
      <p class="text-red ml-1">{{ error }}</p>
    </div>

    <div class="flex space-x-5 mt-6 ml-1">
      <router-link class="btn white px-6" :to="`/classes`">Cancel</router-link>
      <button class="btn button-green px-6 flex items-center" @click="handleSave" :disabled="!canCreateClass || saving">
        <span v-if="saving" class="mr-2">
          <Loader class="w-5 h-5 text-white" />
        </span>
        <span v-if="sku">Update</span>
        <span v-else>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
import ClassForm from "@components/classes/ClassForm";
import Loader from '@components/global/Loader'
import { DateTime } from "luxon";
import { formatDateToMonthDay } from "@shared/utils/helpers";

export default {
  name: "CreateClass",

  components: {
    ClassForm,
    Loader
  },

  props: {
    sku: {
      type: String,
      default: null
    },
    classEventProp: {
      type: Object,
      default: function () {
        return {
          fulfillmentType: null,
          reservationSize: null,
          menu: null,
          location: null,
          capacity: null,
          date: new Date().setHours(19, 0, 0, 0),
          classIsPublic: true,
          privateMenu: false,
          hostName: null,
          hostEmail: null,
          notes: null,
          registrations: false
        }
      }
    }
  },

  created() {
    if (this.sku) {
      this.getClassEvent(this.sku);
    }
  },

  data() {
    return {
      saving: false,
      error: null,
      classEvent: { ...this.classEventProp },
      menu: null,
      isValid: false,
      classEventId: null,
      loading: {
        classEvent: false
      }
    };
  },

  methods: {
    handleChange({ classEvent, isValid, menu }) {
      this.classEvent = classEvent;
      this.menu = menu;
      this.isValid = isValid;
    },
    getClassEvent() {
      this.loading.classEvent = true;
      this.$app.api.classes
        .getClass(this.sku)
        .then(response => {
          this.classEventId = response.data.id;
          this.classEvent = {
            fulfillmentType: {
              id: response.data.fulfillment.id,
              name: response.data.fulfillment.name,
              key: response.data.fulfillment.key
            },
            reservationSize: {
              id: response.data.reservation_size.id,
              name: response.data.reservation_size.name,
              key: response.data.reservation_size.key
            },
            menu: response.data.menu,
            location: response.data.location,
            capacity: response.data.capacity,
            date: DateTime.fromISO(response.data.date, { zone: response.data.location.timezone }).toUTC().toISO(),
            classIsPublic: response.data.class_is_public,
            privateMenu: response.data.private_menu,
            hostName: response.data.host ? response.data.host.name : null,
            hostEmail: response.data.host ? response.data.host.email : null,
            notes: response.data.notes,
            registrations: response.data.registrations_count > 0
          };
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => (this.loading.classEvent = false));
    },
    formatDataForCreation(classEvent) {
      return {
        date: classEvent.date,
        location_id: classEvent.location.id,
        capacity: classEvent.capacity,
        private_menu: classEvent.privateMenu,
        class_is_public: classEvent.classIsPublic,
        is_complete: false,
        notes: classEvent.notes,
        menu_id: classEvent.menu.id,
        fulfillment_id: classEvent.fulfillmentType.id,
        reservation_size_id: classEvent.reservationSize.id,
        host_name: classEvent.hostName,
        host_email: classEvent.hostEmail,
      };
    },
    async handleSave() {
      this.saving = true;
      this.error = null;

      if (this.classEvent.privateMenu && this.menu) {
        if (this.sku) {
          try {
            const formattedDate = formatDateToMonthDay(new Date(this.classEvent.date));
            const menuName = `${formattedDate} class in ${this.classEvent.location.name}`;
            const monthYear = DateTime.fromJSDate(this.classEvent.date).startOf('month').toISODate();
            await this.$app.api.menus.updateMenu(this.classEvent.menu.id, {
              name: menuName,
              month_year: monthYear,
            });
          } catch (error) {
            console.error(error);
            this.error = error.response?.data?.message || 'Unknown error.';
            this.saving = false;
            return;
          }
        } else {
          try {
            const formattedDate = formatDateToMonthDay(new Date(this.classEvent.date));
            const menuName = `${formattedDate} class in ${this.classEvent.location.name}`;
            const monthYear = DateTime.fromJSDate(this.classEvent.date).startOf('month').toISODate();
            let menuData = {
              name: menuName,
              month_year: monthYear,
              meal_type_id: this.menu.type.id != 1 ? this.menu.type.id : null,
              custom: true,
              meals: this.menu.selections
                .filter(selection => selection.meal != null)
                .map(selection => ({
                  meal_id: selection.meal.id,
                  delivery_only: selection.delivery,
                })),
            };

            const menuResponse = await this.$app.api.menus.createMenu(menuData);
            this.classEvent.menu = menuResponse.data;
          } catch (error) {
            console.error(error);
            this.error = error.response?.data?.message || 'Unknown error.';
            this.saving = false;
            return;
          }
        }
      }

      try {
        const classData = this.formatDataForCreation(this.classEvent);

        if (this.sku) {
          const updateResponse = await this.$app.api.classes.update(this.classEventId, classData);
          this.$router.push("/classes/" + updateResponse.data.sku);
        } else {
          const createResponse = await this.$app.api.classes.createClass(classData);
          if (this.$route.query.customClassId) {
            const customClassId = this.$route.query.customClassId;
            await this.$app.api.classes.deleteCustomClassRequest(customClassId);
          }
          this.$router.push("/classes/" + createResponse.data.sku);
        }
      } catch (error) {
        console.error(error);
        this.error = error.response?.data?.message || "Unknown error.";
      } finally {
        this.saving = false;
      }
    },
  },

  computed: {
    canCreateClass() {
      return (
        this.classEvent && this.isValid &&
        this.$app.services.auth.hasPermission("classes.create") &&
        this.$app.services.auth.hasPermission("menus.private.create")
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
