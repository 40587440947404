<template>
    <div>
        <div v-if="loading.meal" class="flex items-center space-x-5">
            <Loader class="w-10 h-10 text-green" />
            <p>Loading meal details...</p>
        </div>
        <div v-else-if="meal">
            <GalleySyncModal :visible="modals.galleySync" :meal="meal" @close="modals.galleySync = false" />
            <!-- Heading -->
            <div class="mb-8 flex justify-between">
                <div>
                    <h2 class="mb-2">{{ meal.name }}</h2>
                    <div class="flex flex-wrap items-center mb-4 mt-4">
                        <div v-for="fulfillment in meal.meal_variants">
                            <a v-if="fulfillment.variants[0].galley_recipe_id"
                                :href="`https://app.galleysolutions.com/recipes/${fulfillment.variants[0].galley_recipe_id}`"
                                target="_blank">
                                <span v-if="fulfillment.fulfillment.key == 'in-person'"
                                    class="btn btn-green text-sm mr-2">Galley Recipe</span>
                                <span v-else-if="fulfillment.fulfillment.key == 'delivery'"
                                    class="btn btn-green text-sm mr-2">Galley Recipe (Bulk)</span>
                            </a>
                            <div v-else>
                                <label class="block text-xs font-body text-gray-dark mb-2">Input the missing Galley
                                    recipe
                                    ID:</label>
                                <input v-model="missingGalleyID" type="text" class="cp-input w-5/6 mb-2" />
                                <button :disabled="!missingGalleyID || saving" class="btn btn-green text-sm"
                                    @click="handleSave">Save</button>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap items-center">
                        <StatusBadge class="mr-2" :active="meal.active" />
                        <p v-for="mealType in meal.meal_types" class="inline-block badge mr-2">{{ mealType.name }}</p>
                    </div>
                </div>
                <div>
                    <div class="flex justify-between">
                        <button class="btn flex items-center mr-2" @click.prevent="modals.galleySync = true">
                            <span v-if="syncing" class="mr-2">
                                <Loader class="w-5 h-5 text-white" />
                            </span>
                            Sync with Galley
                        </button>
                        <button class="btn white px-6" @click="activateMeal"><span
                                v-if="meal.active">Deactivate</span><span v-else>Activate</span></button>
                    </div>
                </div>
            </div>

            <div class="mb-6">Surcharge: ${{ meal.price_offset }}</div>

            <div class="mb-6">
                <h4 class="mb-2">Description</h4>
                <p v-if="meal.description">{{ meal.description }}</p>
                <p v-else>No description found.</p>
            </div>

            <!-- Image Display -->
            <div class="meal-image mb-6 w-1/2">
                <h4 class="mb-2">Primary Image</h4>
                <img class="mb-4" :src="meal.primary_image" alt="No primary image" />
                <h4 class="mb-2">Secondary Image</h4>
                <img class="mb-4" :src="meal.secondary_image" alt="No secondary image" />
            </div>

            <!-- Details -->
            <div class="w-8/12 mb-10">
                <div v-for="instructions in meal.general_instructions" class="mb-6">
                    <h4 class="mb-2">{{ instructions.title }}</h4>
                    <p v-if="instructions.title == 'Groceries Needed' || instructions.title == 'Optional Additions'"
                        v-html="formattedInstructions(instructions.content, null, true)"></p>
                    <p v-if="instructions.title == 'Preparation & Serving Instructions'"
                        v-html="formattedInstructions(instructions.content, '10px')"></p>
                    <p v-if="instructions.title == 'Alternative Serving Instructions'"
                        v-html="formattedInstructions(instructions.content, '10px')"></p>
                </div>

                <div class="flex">
                    <template v-if="hasInstructions(3)">
                        <div class="w-1/3 bg-gray-100 p-5 rounded-xl mr-4">
                            <h4 class="mb-2">Small</h4>
                            <h6 class="mb-2">Meals feed 2-3</h6>
                            <div v-for="instructions in filteredInstructions(3)" :key="instructions.id">
                                <h5 class="mb-2">{{ instructions.title }}</h5>
                                <p v-html="formattedInstructions(instructions.content, '10px')"></p>
                            </div>
                        </div>
                    </template>

                    <template v-if="hasInstructions(1)">
                        <div class="w-1/3 bg-gray-100 p-5 rounded-xl mr-4">
                            <h4 class="mb-2">Medium</h4>
                            <h6 class="mb-2">Meals feed 4-6</h6>
                            <div v-for="instructions in filteredInstructions(1)" :key="instructions.id">
                                <h5 class="mb-2">{{ instructions.title }}</h5>
                                <p v-html="formattedInstructions(instructions.content, '10px')"></p>
                            </div>
                        </div>
                    </template>

                    <template v-if="hasInstructions(2)">
                        <div class="w-1/3 bg-gray-100 p-5 rounded-xl mr-4">
                            <h4 class="mb-2">Large</h4>
                            <h6 class="mb-2">Meals feed 8-12</h6>
                            <div v-for="instructions in filteredInstructions(2)" :key="instructions.id">
                                <h5 class="mb-2">{{ instructions.title }}</h5>
                                <p v-html="formattedInstructions(instructions.content, '10px')"></p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            
            <h4 class="mb-4"><i class="fas fa-fw fa-apple-alt mr-2"></i>Nutrition</h4>
            <div class="mb-6">
                <h5 class="mb-2">Nutrition Labels</h5>
                <div class="flex">
                    <div class="w-1/3" v-for="fulfillment in meal.meal_variants" :key="fulfillment.id"
                        v-if="meal && meal.meal_variants">
                        <h6 class="mb-2">{{ fulfillment.fulfillment.name }}</h6>
                        <div class="mb-4" v-for="variant in fulfillment.variants" :key="variant.id"
                            v-if="fulfillment.variants">
                            <div class="flex flex-col">
                                <a v-for="label in variant.labels" :href="label.label_link" target="_blank"
                                    :key="label.id" class="mb-2" v-if="variant.labels && label.label_link">
                                    <span class="btn btn-green text-sm">
                                        {{ variant.fulfillment.key }} {{ variant.size.key }}
                                        <span v-if="label.new_label"> (new)</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="fulfillment in meal.meal_variants" class="mb-6">
                <div class="mb-2 flex items-center">
                    <h5>{{ fulfillment.fulfillment.name }}</h5>
                    <p class="ml-2 mr-2">|</p>
                    <p v-if="
                        fulfillment.variants[0].serving_size
                        && fulfillment.variants[0].serving_size.serving_size
                        && fulfillment.variants[0].serving_size.serving_unit">Serving Size:
                        {{ fulfillment.variants[0].serving_size.serving_size }} {{
                            fulfillment.variants[0].serving_size.serving_unit
                        }}
                    </p>
                    <p v-else>No serving size found. Try syncing with Galley.</p>
                </div>
                <div v-for="variant in fulfillment.variants" class="flex items-center">
                    <h6>{{ variant.size.name }}</h6>
                    <p class="ml-2 mr-2">|</p>
                    <p v-if="variant.meal_sizes && variant.meal_sizes.oz">{{ variant.meal_sizes.oz }} oz</p>
                    <p v-else>Missing oz</p>
                    <p class="ml-2 mr-2">|</p>
                    <p v-if="variant.meal_sizes && variant.meal_sizes.lb">{{ variant.meal_sizes.lb }} lb</p>
                    <p v-else>Missing lb</p>
                    <p class="ml-2 mr-2">|</p>
                    <p v-if="variant.meal_sizes && variant.meal_sizes.kg">{{ variant.meal_sizes.kg }} kg</p>
                    <p v-else>Missing kg</p>
                </div>
                <!-- Each variant of a fulfillment type has the same nutrients since they are based on the same Galley recipe -->
                <NutritionTable class="mt-2" :nutritionals="fulfillment.variants[0].nutritionals" />
            </div>

            <h4 class="mb-4"><i class="fas fa-fw fa-lemon mr-2"></i>Ingredients</h4>
            <div v-for="fulfillment in meal.meal_variants" class="mb-6">
                <h5 class="mb-2">{{ fulfillment.fulfillment.name }}</h5>
                <IngredientsTable :variants="fulfillment.variants" />
            </div>
        </div>
        <div v-else>
            <p>
                <i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!
            </p>
        </div>
    </div>
</template>

<script>
import Loader from "@components/global/Loader";
import StatusBadge from '@components/meals/StatusBadge'
import NutritionTable from "@components/meals/NutritionTable";
import IngredientsTable from "@components/meals/IngredientsTable";
import GalleySyncModal from "@components/meals/modals/GalleySyncModal";

export default {
    components: {
        Loader,
        StatusBadge,
        NutritionTable,
        IngredientsTable,
        GalleySyncModal,
    },

    metaInfo() {
        return {
            title: this.meal?.name ? `Meal #${this.meal?.name}` : "Meal Details"
        };
    },

    data() {
        return {
            loading: {
                meal: false,
            },
            modals: {
                galleySync: false,
            },
            meal: null,
            syncing: false,
            saving: false,
            missingGalleyID: null
        };
    },

    methods: {
        async getMeal() {
            this.loading.meal = true;
            return this.$app.api.meals
                .getMeal(this.$route.params.id)
                .then(res => {
                    this.meal = res.data;
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => (this.loading.meal = false));
        },
        async activateMeal() {
            let data = {
                active: !this.meal.active
            }

            this.loading.meal = true;
            return this.$app.api.meals
                .updateMeal(this.$route.params.id, data)
                .then(res => {
                    this.getMeal();
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => (this.loading.meal = false));
        },
        async syncMeal() {
            this.syncing = true;
            try {
                await this.$app.api.meals.syncMeal(this.meal.id);
                this.getMeal();
            } catch (error) {
                console.error(error);
            } finally {
                this.syncing = false;
            }
        },
        async handleSave() {
            this.saving = true;
            try {
                await this.$app.api.meals.updateMealVariant(this.meal.id, { galley_recipe_id: this.missingGalleyID });
                await this.syncMeal();
            } catch (error) {
                console.error(error);
            } finally {
                this.saving = false;
            }
        },
        formattedInstructions(text, marginBottom = null, list = false) {
            if (!text) return '';
            text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            text = text.trim();

            return text.split('\n').map((line, index, array) => {
                let style = marginBottom ? ` style="margin-bottom: ${marginBottom};"` : "";
                let bulletPoint = list ? '• ' : '';

                return `<div${style}>${bulletPoint}${line}</div>`;
            }).join('');
        },
        hasInstructions(portionSizeId) {
            return this.meal.meal_instructions.some(instructions => instructions.portion_size_id === portionSizeId);
        },
        filteredInstructions(portionSizeId) {
            return this.meal.meal_instructions.filter(instructions => instructions.portion_size_id === portionSizeId);
        },
        allergiesList(ingredients) {
            const allergyNames = new Set();

            ingredients.forEach(ingredient => {
                if (ingredient.ingredient.allergies && ingredient.ingredient.allergies.length > 0) {
                    ingredient.ingredient.allergies.forEach(allergy => {
                        allergyNames.add(allergy.name);
                    });
                }
            });

            return Array.from(allergyNames).join(', ');
        }
    },

    async created() {
        await this.getMeal();
    }
};
</script>

<style></style>