import Resource from './resource'

export default class Announcements extends Resource {

    getAnnouncements(params = {}) {
        return this.axios.get(`announcements`, { params });
    }

    createAnnouncement(data = {}) {
        return this.axios.post(`announcements`, data);
    }

    removeAnnouncement(id, data = {}) {
        return this.axios.put(`announcements/${id}/remove`, data);
    }

}
