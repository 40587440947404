<template>
  <div>
    <div v-if="loading.classEvent" class="flex items-center space-x-5">
      <Loader class="w-10 h-10 text-green" />
      <p>Loading class...</p>
    </div>
    <div v-else-if="classEvent">
      <!-- confirm complete -->
      <ConfirmCompleteModal :visible="modals.confirmComplete" :class-event-id="classEvent.id"
        @close="modals.confirmComplete = false" @update="classDidUpdate" />

      <!-- confirm close -->
      <ConfirmCloseModal :visible="modals.confirmClose" :class-event="classEvent" :date="classEvent.date"
        @close="modals.confirmClose = false" @update="classDidUpdate" />

      <!-- confirm delete -->
      <ConfirmDeleteModal :visible="modals.confirmDelete" :class-event-id="classEvent.id"
        @close="modals.confirmDelete = false" @success="$router.back()" />

      <!-- confirm cancel -->
      <ConfirmCancelModal :visible="modals.confirmCancel" :class-event-id="classEvent.id"
        @close="modals.confirmCancel = false" @update="classDidUpdate" />

      <!-- confirm reopen modal -->
      <ConfirmReopenModal :visible="modals.confirmReopen" :class-event-id="classEvent.id"
        @close="modals.confirmReopen = false" @update="classDidUpdate" />

      <!-- label prep address modal -->
      <LabelDateModal :visible="modals.labelAddress" :classDate="classEvent.date"
        :fulfillmentId="classEvent.fulfillment_id" @close="modals.labelAddress = false" @print="getClassLabels" />

      <GroceryOrderModal :visible="modals.groceryOrder" :classEvent="classEvent" @close="modals.groceryOrder = false" />
      <ForecastModal :visible="modals.forecast" :classEvent="classEvent" @close="modals.forecast = false" />
      <ShippingTransferModal :visible="modals.shippingTransfer" :registrationsArray="transfers"
        :menuId="classEvent.menu.id" @close="modals.shippingTransfer = false" />

      <div class="mb-14">
        <div class="flex justify-between">
          <div>
            <div class="flex flex-wrap items-center">
              <h2 class="mr-2">{{ classEvent.sku }}</h2>
              <a :href="`${frontendAppUrl}/menu/${classEvent.sku}`" target="_blank">
                <span class="btn cp-btn cp-btn-green text-sm mr-2">Meals in Menu</span>
              </a>
              <a :href="`${frontendAppUrl}/classes/${classEvent.sku}`" target="_blank">
                <span class="btn cp-btn cp-btn-green-border text-sm mr-2">Preview</span>
              </a>

              <p v-if="classEvent.canceled" class="inline-block badge mr-2">Canceled</p>

              <p v-if="classEvent.private_menu" class="inline-block badge mr-2">
                Private Menu
              </p>
              <p v-else-if="classEvent.template_id === 30" class="inline-block badge mr-2">
                Meals With Mom
              </p>
              <p v-else-if="classEvent.reservation_size.key === 'couples'" class="inline-block badge mr-2">
                Couples
              </p>
              <p v-if="isVegetarian" class="inline-block badge mr-2">Vegetarian</p>
              <p v-if="isSoup"><span class="badge small">Soup</span></p>
            </div>
            <div class="flex">
              <div>
                <span class="text-xs text-gray-400">ID:</span>
                <span class="text-sm text-green-400 mr-4">{{ classEvent.id }}</span>
              </div>
              <div>
                <span class="text-xs text-gray-400">Name:</span>
                <span class="text-sm text-green-400">{{ classEvent.name }}</span>
              </div>
            </div>
            <p v-if="classEvent.fulfillment.key === 'shipping'">
              <span>Close date: </span>
              <Date :date="classEvent.date" :timezone="classEvent.location.timezone" />
            </p>
            <p v-if="classEvent.fulfillment.key === 'shipping' && classEvent.prep_date">
              <span>Prep date: </span>
              <Date :date="classEvent.prep_date" :timezone="classEvent.location.timezone" />
            </p>
            <p v-if="classEvent.fulfillment.key === 'shipping' && classEvent.shipping_date">
              <span>Ship date: </span>
              <Date :date="classEvent.shipping_date" :timezone="classEvent.location.timezone" />
            </p>
            <p v-if="classEvent.fulfillment.key !== 'shipping'">
              <Date :date="classEvent.date" :timezone="classEvent.location.timezone" />
            </p>
            <div class="flex flex-wrap items-center mt-5">
              <FulfillmentBadge :fulfillmentKey="classEvent.fulfillment.key" :small="true" class="mr-2" />
              <p>
                <StatusBadge class="mr-2" :class-event="classEvent" />
              </p>
              <div v-if="classEvent.fulfillment_id != 4">
                <p v-if="classEvent.class_is_public">
                  <i class="fas fa-fw fa-eye mr-2"></i>
                </p>
                <p v-else><i class="fas fa-fw fa-eye-slash mr-2"></i></p>
              </div>
            </div>
          </div>
          <div v-if="canPerformActions">
            <Popover align="right">
              <template #default="{ hide }">
                <ActionsMenu class="w-64" :class-event="classEvent" @edit="
                  $router.push({ name: 'class.edit', params: { sku: classEvent.sku } })
                  " @copy="
                    $router.push({
                      name: 'class.create',
                      params: { classEventProp: copyClassEvent() },
                    })
                    " @close="
                      modals.confirmClose = true
                    hide()
                      " @complete="
                        modals.confirmComplete = true
                      hide()
                        " @delete="
                        modals.confirmDelete = true
                      hide()
                        " @cancel="
                        modals.confirmCancel = true
                      hide()
                        " @reopen="
                        modals.confirmReopen = true
                      hide()
                        " @display="toggleDisplay" @archive="toggleArchive" @meal-labels="
                        modals.labelAddress = true
                      hide()
                        " @participant-labels="getParticipantLabels" @pickup-sheets="getPickupSheets"
                  @class-information="getClassInformation" @forecast="
                    modals.forecast = true
                  hide()
                    " @grocery="
                      modals.groceryOrder = true
                    hide()
                      " />
              </template>
            </Popover>
          </div>
        </div>
      </div>
      <div :class="{ 'grid-cols-2': !classEvent.host, 'grid-cols-3': classEvent.host }"
        class="grid grid-cols-1 xs:mb-14 gap-5">
        <div>
          <h4 class="mb-2">
            <i class="fas fa-fw fa-map-marker-alt mr-2"></i>{{ classEvent.location.name }}
          </h4>
          <p v-if="classEvent.location.store_name">
            {{ classEvent.location.store_name }}
          </p>
          <div v-if="classEvent.location.address">
            <p>{{ classEvent.location.address.street_1 }}</p>
            <p>
              {{ classEvent.location.address.locality }},
              {{ classEvent.location.address.administrative_area }}
              {{ classEvent.location.address.postal_code }}
            </p>
          </div>
        </div>
        <div>
          <h4 class="mb-2"><i class="fas fa-fw fa-user mr-2"></i>Lead</h4>
          <p>{{ classEvent.location.lead.name }}</p>
          <p>{{ classEvent.location.lead.email }}</p>
        </div>
        <div v-if="classEvent.host">
          <h4 class="mb-2"><i class="fas fa-fw fa-user mr-2"></i>Host</h4>
          <p>{{ classEvent.host.name }}</p>
          <p>{{ classEvent.host.email }}</p>
        </div>
      </div>
      <div class="grid grid-cols-1 xs:grid-cols-2 mb-14 gap-5">
        <div>
          <template v-if="classEvent.menu">
            <div class="flex space-x-2 mb-2 items-center">
              <router-link class="hover:underline block" :to="`/menus/${classEvent.menu.id}`">
                <h4>
                  <i class="fas fa-fw fa-map mr-2"></i>{{ classEvent.menu.name }} Menu
                </h4>
              </router-link>
              <p class="text-sm font-semibold rounded-2xl bg-mint px-3">
                {{ classEvent.menu.meals.length }}
              </p>
            </div>
            <div v-for="meal in classEvent.menu.meals" :key="meal.id" class="py-1">
              <p>
                {{ meal.meal.name
                }}<span v-if="classEvent.private_menu && meal.meal.price_offset != 0.0">
                  ${{ meal.meal.price_offset }}</span>
              </p>
            </div>
          </template>
          <template v-else-if="classEvent.old_meals">
            <div class="flex space-x-2 mb-2 items-center">
              <h4><i class="fas fa-fw fa-lemon mr-2"></i>Recipes</h4>
              <p class="text-sm font-semibold rounded-2xl bg-mint px-3">
                {{ classEvent.old_meals.length }}
              </p>
            </div>
            <h6>(This is an old event that is not linked to a menu)</h6>
            <div v-for="meal in classEvent.old_meals" :key="meal.id" class="py-1">
              <p>{{ meal.name }}</p>
            </div>
          </template>
        </div>
        <div>
          <div class="flex space-x-2 mb-2 items-center">
            <h4 class="mr-4"><i class="fas fa-fw fa-dollar-sign mr-2"></i>Pricing</h4>
            <p v-if="selectedVariationPrice">{{ selectedVariationPrice | currency }}</p>
            <p v-else>
              {{ classEvent.price_range.low | currency }} -
              {{ classEvent.price_range.high }}
            </p>
          </div>
          <div class="max-w-xs">
            <PortionSelector :classEvent="classEvent" :value="portion" @input="handlePortions" />
          </div>
        </div>
      </div>
      <div class="mb-14">
        <div class="xs:flex xs:justify-between mb-4 items-center">
          <div class="flex space-x-2 items-center mb-2 xs:mb-0">
            <h4><i class="fas fa-fw fa-calendar-check mr-2"></i>Registrations</h4>
            <p class="text-sm font-semibold rounded-2xl bg-mint px-3">
              {{ classEvent.registrations_count }}
            </p>
            <button class="btn cp-btn white text-sm" @click="registrationDetails = !registrationDetails">
              <span v-if="registrationDetails">Hide Details</span>
              <span v-else>Show Details</span>
            </button>
            <button v-if="classEvent.fulfillment_id == 3" class="btn cp-btn white text-sm"
              @click="enableBulkTransfer()">
              <span>Bulk Transfer</span>
            </button>
            <button v-if="bulkTransfer" :disabled="transfers.length == 0" @click="modals.shippingTransfer = true"
              class="btn cp-btn text-sm">
              Transfer</button>
            <button v-if="registrationDetails" class="btn cp-btn white text-sm" @click="copyNamesToClipboard()">
              {{ copiedNames ? 'Copied!' : 'Copy Names' }}
            </button>
            <button v-if="registrationDetails" class="btn cp-btn white text-sm" @click="copyEmailsToClipboard()">
              {{ copiedEmails ? 'Copied!' : 'Copy Emails' }}
            </button>
          </div>
          <div class="flex items-center space-x-2">
            <ProgressRing class="flex-shrink-0" :radius="15" :stroke="3" :progress="Math.floor((classEvent.registrations_count / classEvent.capacity) * 100)
              " />
            <p>
              <span class="font-semibold">{{ classEvent.remaining_capacity }}</span>
              {{ classEvent.remaining_capacity == 1 ? 'spot' : 'spots' }} remaining
            </p>
          </div>
        </div>
        <RegistrationsListView v-if="classEvent.registrations.length" :registrations="classEvent.registrations"
          :showDetails="registrationDetails" :bulkTransfer="bulkTransfer" :showTracking="true"
          @transfer="updateTransfers" />
        <div v-else>
          <p>No registrations for this class.</p>
        </div>
      </div>
      <div class="grid grid-cols-1 xs:grid-cols-2 mb-14 gap-5">
        <div>
          <div class="flex space-x-2 mb-2 items-center">
            <h4><i class="fas fa-fw fa-clipboard mr-2"></i>Notes</h4>
          </div>
          <p v-if="classEvent.notes">{{ classEvent.notes }}</p>
          <p v-else>No notes for this class.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <p>
        <i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops...
        something went wrong!
      </p>
    </div>
  </div>
</template>

<script>
import Loader from '@components/global/Loader'
import StatusBadge from '@components/classes/StatusBadge'
import Popover from '@components/global/Popover'
import ActionsMenu from '@components/classes/ActionsMenu'
import RegistrationsListView from '@components/registrations/ListView'
import ProgressRing from '@components/global/ProgressRing'
import FulfillmentBadge from '@components/meals/FulfillmentBadge'
import Date from '@components/global/dates/Date'

import { DateTime } from 'luxon'

// Modals
import ConfirmReopenModal from '@components/classes/modals/ConfirmReopen'
import ConfirmCancelModal from '@components/classes/modals/ConfirmCancel'
import ConfirmDeleteModal from '@components/classes/modals/ConfirmDelete'
import ConfirmCompleteModal from '@components/classes/modals/ConfirmComplete'
import ConfirmCloseModal from '@components/classes/modals/ConfirmClose'
import LabelDateModal from '@components/classes/modals/LabelDateModal'
import ForecastModal from '@components/classes/modals/ForecastModal'
import GroceryOrderModal from '@components/classes/modals/GroceryOrderModal'
import ShippingTransferModal from '@components/shipping/modals/ShippingTransferModal'
import PortionSelector from '@components/classes/PortionSelector'

export default {
  components: {
    PortionSelector,
    Loader,
    StatusBadge,
    Popover,
    ActionsMenu,
    RegistrationsListView,
    ProgressRing,
    FulfillmentBadge,
    Date,
    ConfirmReopenModal,
    ConfirmCancelModal,
    ConfirmDeleteModal,
    ConfirmCompleteModal,
    ConfirmCloseModal,
    LabelDateModal,
    ForecastModal,
    GroceryOrderModal,
    ShippingTransferModal,
  },

  metaInfo() {
    return {
      title: this.classEvent?.name ?? 'Class Details',
    }
  },

  data() {
    return {
      classEvent: null,
      registrationDetails: false,
      bulkTransfer: false,
      copiedNames: false,
      copiedEmails: false,
      transfers: [],
      modals: {
        confirmComplete: false,
        confirmClose: false,
        confirmCancel: false,
        confirmDelete: false,
        confirmReopen: false,
        labelAddress: false,
        groceryOrder: false,
        forecast: false,
        shippingTransfer: false,
      },
      portion: {
        size: null,
        quantity: null,
      },
      loading: {
        classEvent: false,
        display: false,
        archive: false,
      },
      frontendAppUrl:
        process.env.VUE_APP_FRONTEND_APP_URL || 'https://app.citruspear.com',
    }
  },

  computed: {
    canPerformActions() {
      return this.$app.services.auth.hasOneOfPermissions([
        'classes.update',
        'classes.delete',
      ])
    },
    selectedVariation() {
      return this.classEvent.portion_variations.find((variation) => {
        return (
          this.portion.size?.id === variation.portion_size_id &&
          this.portion.quantity?.id === variation.portion_quantity_id
        )
      })
    },
    selectedVariationPrice() {
      if (this.selectedVariation)
        return this.classEvent.variation_prices[this.selectedVariation.id]
      return false
    },
    isVegetarian() {
      return (
        this.classEvent.menu &&
        this.classEvent.menu.type &&
        this.classEvent.menu.type.key === 'vegetarian'
      )
    },
    isSoup() {
      return (
        this.classEvent.menu &&
        this.classEvent.menu.type &&
        this.classEvent.menu.type.key === 'soup'
      )
    },
  },

  methods: {
    handlePortions(portion) {
      this.portion = { ...this.portion, ...portion }
    },
    classDidUpdate(update) {
      this.classEvent = { ...this.classEvent, ...update }
    },
    copyClassEvent() {
      return {
        fulfillmentType: {
          id: this.classEvent.fulfillment.id,
          name: this.classEvent.fulfillment.name,
          key: this.classEvent.fulfillment.key,
        },
        reservationSize: {
          id: this.classEvent.reservation_size.id,
          name: this.classEvent.reservation_size.name,
          key: this.classEvent.reservation_size.key,
        },
        menu: this.classEvent.menu,
        location: this.classEvent.location,
        capacity: this.classEvent.capacity,
        date: DateTime.fromISO(this.classEvent.date, {
          zone: this.classEvent.location.timezone,
        })
          .toUTC()
          .toISO(),
        classIsPublic: this.classEvent.class_is_public,
        privateMenu: this.classEvent.private_menu,
        hostName: this.classEvent.host?.name ?? null,
        hostEmail: this.classEvent.host?.email ?? null,
        notes: this.classEvent.notes,
      }
    },
    getClassEvent() {
      let params = {
        relations: [
          'registrations.class',
          'registrations.class.reservationSize',
          'registrations.class.host',
          'registrations.status',
          'registrations.guests',
          'registrations.user',
          'registrations.user.allergies',
          'registrations.portionSize',
          'registrations.portionQuantity',
          'registrations.selections',
          'registrations.selections.meal',
          'registrations.shippoOrders',
          'registrations.shippoOrders.trackingStatus',
        ].join('|'),
      }

      this.loading.classEvent = true
      this.$app.api.classes
        .getClass(this.$route.params.sku, params)
        .then((response) => {
          this.classEvent = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (this.loading.classEvent = false))
    },
    getClassLabels(date, useNewLabels) {
      window
        .open(
          `${process.env.VUE_APP_API_BASE_URL}/classes/${this.classEvent.id}/labels?date=${date}&newLabels=${useNewLabels}`,
          '_blank'
        )
        .focus()
    },
    getParticipantLabels() {
      window
        .open(
          `${process.env.VUE_APP_API_BASE_URL}/classes/${this.classEvent.id}/participant_labels`,
          '_blank'
        )
        .focus()
    },
    getPickupSheets() {
      window.open(
        `${process.env.VUE_APP_API_BASE_URL}/classes/${this.classEvent.id}/pickup`,
        '_blank'
      )
    },
    getClassInformation() {
      window.open(
        `${process.env.VUE_APP_API_BASE_URL}/classes/${this.classEvent.id}/information`,
        '_blank'
      )
    },
    getShippingLabel(registrationId) {
      this.$app.api.registrations
        .getShippoOrderUrl(registrationId)
        .then((response) => {
          window.open(response.data, '_blank')
        })
        .catch((error) => {
          console.error('getShippingLabel error', error)
        })
    },
    toggleDisplay() {
      let params = {
        isPublic: !this.classEvent.class_is_public,
      }

      this.loading.display = false
      return this.$app.api.classes
        .setClassIsPublic(this.classEvent.id, params)
        .then((response) => {
          this.classDidUpdate(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          return (this.loading.display = false)
        })
    },
    toggleArchive() {
      this.loading.archive = false
      return this.$app.api.classes
        .archive(this.classEvent.id, !this.classEvent.archived)
        .then((response) => {
          this.classDidUpdate(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          return (this.loading.archive = false)
        })
    },
    copyNamesToClipboard() {
      const names = this.getRegistrations()
        .map((registration) => {
          if (registration.user) {
            return registration.user.first_name + ' ' + registration.user.last_name
          } else if (registration.guest) {
            return registration.guest.name
          }
        })
        .join(', ')
      navigator.clipboard.writeText(names)
      this.copiedNames = true
      setTimeout(() => {
        this.copiedNames = false
      }, 2000)
    },
    copyEmailsToClipboard() {
      const emails = this.getRegistrations()
        .map((registration) => {
          if (registration.user) {
            return registration.user.email
          } else if (registration.guest) {
            return registration.guest.email
          }
        })
        .join(', ')
      navigator.clipboard.writeText(emails)
      this.copiedEmails = true
      setTimeout(() => {
        this.copiedEmails = false
      }, 2000)
    },
    getRegistrations() {
      return this.classEvent.registrations.filter((reg) => {
        return reg.status.key === 'completed'
      })
    },
    updateTransfers(registrationId, isChecked) {
      if (isChecked) {
        this.transfers.push(registrationId)
      } else {
        this.transfers = this.transfers.filter((id) => id !== registrationId)
      }
    },
    enableBulkTransfer() {
      this.bulkTransfer = !this.bulkTransfer
      this.transfers = []
    },
  },

  created() {
    this.getClassEvent()
  },
}
</script>

<style lang="scss" scoped></style>
