const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component:  () => import('@views/login/Login.vue'),
        meta: {
            layout: 'login',
            requiresAuth: false,
            redirectWhenLoggedIn: true,
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component:  () => import('@views/login/ForgotPassword.vue'),
        meta: {
            layout: 'login',
            requiresAuth: false,
            redirectWhenLoggedIn: true
        }
    },
]

export default routes
