<template>
    <div class="mb-4 w-10/12 md:w-8/12 lg:w-6/12">
        <PortionSelector class="mb-4" :classEvent="classEvent" :value="portion" @input="handlePortions" />
    </div>
</template>

<script>
import PortionSelector from '@components/classes/PortionSelector';

export default {
    name: "Portions",

    components: {
        PortionSelector
    },

    props: {},

    data() {
        return {
            errors: [],
        }
    },

    computed: {
        classEvent() {
            return this.$app.store.state.registration.update.data.classEvent
        },
        portion() {
            return this.$app.store.state.registration.update.data.portion
        },
        portionSizes() {
            return this.classEvent.portion_sizes
        },
        validVariations() {
            return this.classEvent.portion_variations.filter((variation) => {
                if (this.portion.size && this.portion.size.id !== variation.portion_size_id) return false
                return true
            }).sort((a, b) => {
                if (a.portion_quantity.value < b.portion_quantity.value) return -1
                if (a.portion_quantity.value > b.portion_quantity.value) return 1
                return 0
            })
        },
        validPortionQuantities() {
            return this.validVariations
                .map((variation) => { return variation.portion_quantity })
                .filter((element, index, self) => {
                    if (!element) return false
                    return self.findIndex(el => el.id === element.id) >= 0
                })
        },
        selectedVariation() {
            return this.classEvent.portion_variations.find((variation) => {
                return this.portion.size?.id === variation.portion_size_id && this.portion.quantity?.id === variation.portion_quantity_id
            })
        },
    },

    methods: {
        sizeValid(size) {
            return !!this.portionSizes.find((portionSize) => {
                return size.id === portionSize.id;
            })
        },
        quantityValid(quantity) {
            return !!this.validPortionQuantities.find((validQuantity) => {
                return quantity.id === validQuantity.id;
            })
        },
        handlePortions(value) {
            let selectedPortion = value

            if (!this.sizeValid(selectedPortion.size)) {
                selectedPortion.size = this.portionSizes[0]
                this.setPortionNeedsReview()
            }
            if (selectedPortion.quantity && !this.quantityValid(selectedPortion.quantity)) {
                selectedPortion.quantity = this.validPortionQuantities[0] || null
                this.setPortionNeedsReview()
            }

            this.updatePortionState(selectedPortion)
        },
        updatePortionState(portion) {
            this.$app.store.commit('registration/setUpdateData', { portion: { size: portion.size, quantity: portion.quantity } })
            this.$app.store.commit('registration/setUpdateData', { variation: this.selectedVariation })
        },
        setPortionNeedsReview() {
            this.$app.store.commit('registration/setUpdateReview', { portion: true })
        },
    },

    watch: {
        classEvent: {
            handler(oldValue, newValue) {
                this.handlePortions(this.portion)
            }
        },
    },

    created() { },

    mounted() {
        if (this.selectedVariation?.id) {
            this.handlePortions(this.portion)
        }
    }
}
</script>

<style lang="scss" scoped></style>
