<template>
    <div>
        <div v-if="loading">
            <div class="flex items-center space-x-5">
                <Loader class="w-10 h-10 text-green"/>
                <p>Loading promotion...</p>
            </div>
        </div>
        <div v-else-if="promotion">
            <PromotionForm :value="promotion" @input="handleChange"/>

            <template v-if="error">
                <div class="flex flex-col items-center justify-center">
                    <p class="text-red text-xs ml-1">{{ error }}</p>
                </div>
            </template>

            <div class="flex space-x-5 mt-6">
                <button class="btn white px-6 mr-2" @click="cancelEdit">Cancel</button>
                <button class="btn button-green px-6" @click="updatePromotion" :disabled="!canPerformActions || saving">
                    <div v-if="saving" class="flex items-center space-x-1"><Loader class="w-5 h-5"/><span>Saving</span></div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Saved!</span>
                    <span v-else>Save</span>
                </button>
            </div>
        </div>
        <div v-else>
            <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/global/Loader'
import PromotionForm from '@/components/promotions/PromotionForm'

export default {
    name: "EditPromotion",

    components: {
        Loader,
        PromotionForm,
    },

    data() {
        return {
            error: null,
            loading: false,
            saving: false,
            success: false,
            promotion: null,
            updateData: null,
        }
    },

    computed: {
        canPerformActions() {
            return true
        },
        promotionId() {
            return parseInt(this.$route.params.id)
        },
    },

    methods: {
        handleChange(value) {
            this.updateData = value
        },
        getPromotion() {
            this.loading = true
            this.$app.api.promotions.getPromotion(this.promotionId)
                .then((response) => {
                    this.promotion = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        cancelEdit() {
            this.$router.push(`/promotions/${this.promotionId}`)
        },
        updatePromotion() {
            this.saving = true
            this.$app.services.promotion.updatePromotion(this.promotion.id, this.updateData)
                .then((response) => {
                    this.success = true
                    setTimeout(() => {
                        this.success = false
                        this.$router.push(`/promotions/${this.promotionId}`)
                    }, 2000)
                })
                .catch((error) => {
                    this.error = error?.response?.data?.message
                    console.error(error)
                })
                .finally(() => {
                    this.saving = false
                })
        },
    },

    created() {
        this.getPromotion()
    }
}
</script>

<style lang="scss" scoped>
</style>
