const routes = [
    {
        path: '/shipping',
        name: 'shipping',
        component: () => import('@views/shipping/ShippingGroups.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['classes.view']
        }
    },
    {
        path: '/shipping/:sku',
        name: 'shipping detail',
        component: () => import('@views/classes/Class.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['classes.view']
        }
    },
]

export default routes
