<template>
  <div class="flex flex-col text-gray-dark">
    <label :for="label" class="text-xs">{{ label }}</label>
    <textarea
        :name="label"
        class="cp-input mt-1"
        rows="5"
        :value="value"
        @input="handleInput"
        :disabled="disabled"
        :placeholder="placeholder"
    ></textarea>
  </div>
</template>

<script>
export default {
    name: 'Textbox',

    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value : {
            default: null
        }
    },

    data() {
        return {}
    },

    computed: {},

    methods: {
        handleInput(event) {
            this.$emit('input', event.target.value);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
