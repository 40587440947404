<template>
    <div class="bg-gray-100 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
        <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 gap-2 xl:px-3">
            <div>
                <p class="text-xs text-gray-400 xl:hidden">SKU</p>
                <template v-if="links">
                    <router-link class="hover:underline block" :to="`/classes/${classEvent.sku}`">
                        <p class="font-semibold">{{ classEvent.sku }}</p>
                    </router-link>
                </template>
                <template v-else>
                    <p class="font-semibold">{{ classEvent.sku }}</p>
                </template>
                <p v-if="classEvent.private_menu">
                    <span class="badge small">Private</span>
                </p>
                <p v-else-if="classEvent.reservation_size.key === 'couples'">
                    <span class="badge small">Couples</span>
                </p>
                <p v-if="classEvent.menu && classEvent.menu.type">
                    <span class="badge small">{{ classEvent.menu.type.name }}</span>
                </p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Status</p>
                <p><StatusBadge class="text-xs" :class-event="classEvent"/></p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Date</p>
                <p><Date :date="classEvent.date" :timezone="classEvent.location.timezone" preset="DATE_FULL"/></p>
                <p><Date :date="classEvent.date" :timezone="classEvent.location.timezone" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Location</p>
                <p>{{ classEvent.location.name }}</p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Lead</p>
                <p>{{ classEvent.location.lead.name }}</p>
            </div>
            <div class="xl:col-span-1">
                <p class="text-xs text-gray-400 xl:hidden">Type</p>
                <p>{{ classEvent.fulfillment.key | ucwords }}</p>
            </div>
            <div class="xl:col-span-1">
                <p class="text-xs text-gray-400 xl:hidden">Capacity</p>
                <div class="flex items-center space-x-0.5">
                    <ProgressRing class="flex-shrink-0" :radius="13" :stroke="3" :progress="Math.floor((classEvent.registrations_count/classEvent.capacity) * 100)"/>
                    <p>{{ classEvent.registrations_count }}/{{classEvent.capacity}}</p>
                </div>
            </div>
            <div>
                <button class="btn green xs" @click="$emit('select', classEvent)">Select Class</button>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from '@components/classes/StatusBadge'
import ProgressRing from '@components/global/ProgressRing'
import Date from '@components/global/dates/Date'

export default {
    name: "ClassCard",

    props: {
        classEvent: {
            type: Object,
            required: true,
        },
        links: {
            type: Boolean,
            default: true,
        }
    },

    components: {
        StatusBadge,
        ProgressRing,
        Date,
    }
}
</script>

<style lang="scss" scoped>
</style>
