<template>
    <div class="bg-gray-200 mb-5 rounded-md p-5">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div v-for="customClass in customClassRequests" :key="customClass.id" class="bg-gray-100 rounded-md p-5">
                <div class="grid grid-cols-1 xs:grid-cols-2 gap-2 gap-y-4">
                    <div class="xs:col-span-2">
                        <h6 class="label">Host</h6>
                        <p class="font-medium">{{ customClass.contact_first_name + ' ' + customClass.contact_last_name |
                ucwords }}</p>
                        <p class="break-words">
                            <a :href="`mailto:${customClass.contact_email}`" class="lowercase mr-2 hover:underline">
                                {{ customClass.contact_email }}
                            </a>
                        </p>
                        <p>{{ customClass.contact_phone | phone }}</p>
                    </div>
                    <div v-if="customClass.notes" class="xs:col-span-2">
                        <h6 class="label">Notes</h6>
                        <p>{{ customClass.notes }}</p>
                    </div>
                    <div class="xs:col-span-2">
                        <h6 class="label">Location</h6>
                        <p>{{ customClass.location.name }}</p>
                    </div>
                    <div class="xs:col-span-2">
                        <h6 class="label">Date</h6>
                        <p>{{ formatDate(customClass.date) }}</p>
                    </div>
                    <div class="xs:col-span-2">
                        <h6 class="label">Meals</h6>
                        <div v-for="meal in customClass.meals" :key="meal.id">
                            <div class="grid grid-cols-2 gap-2">
                                <div>
                                    <div class="flex items-center">
                                        <p>{{ meal.name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Create class and delete class button -->
                <div class="flex justify-between mt-4">
                    <button @click="createCustomClass(customClass)" class="btn cp-btn cp-btn-green-border text-sm mr-2">
                        <span>Create Class</span>
                    </button>
                    <button class="fas fa-fw fa-trash" @click="deleteClass(customClass.id)">
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
    name: 'CustomClassGridView',

    props: {
        customClassRequests: {
            type: Array,
            required: true
        }
    },

    methods: {
        formatDate(dateString) {
            const dt = DateTime.fromFormat(dateString, 'yyyy-MM-dd HH:mm:ss');
            return dt.isValid ? dt.toLocaleString(DateTime.DATETIME_SMALL) : 'Invalid DateTime';
        },
        customClassEvent(customClass) {
            return {
                fulfillmentType: {
                    id: 1,
                    name: "In-person Preparation Class",
                    key: "in-person"
                },
                reservationSize: {
                    id: 1,
                    name: "Individual",
                    key: "individual"
                },
                customClassMeals: customClass.meals,
                location: customClass.location,
                capacity: customClass.location.default_class_size,
                date: customClass.date,
                classIsPublic: false,
                privateMenu: true,
                hostName: customClass.contact_first_name + ' ' + customClass.contact_last_name,
                hostEmail: customClass.contact_email,
                notes: customClass.notes,
            }
        },
        createCustomClass(customClass) {
            this.$router.push({ name: 'class.create', params: { classEventProp: this.customClassEvent(customClass) }, query: { customClassId: customClass.id } });
        },
        async deleteClass(customClassId) {
            await this.$app.api.classes.deleteCustomClassRequest(customClassId);
            this.$router.go();
        }
    }
}
</script>