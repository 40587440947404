import Resource from './resource'

export default class ContactUs extends Resource {

    /**
     * @param {object} data contact us data
     */
    sendContactUsEmail(data) {
        return this.axios.post('contact-us', data)
    }

}
