<template>
    <div class="gap-4">
        <div>
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Title*:</label>
            <input v-model="announcement.title" type="text" class="input mb-4" placeholder="Enter title" />

            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Content*:</label>
            <textarea v-model="announcement.content" class="input w-1/2 mb-4" placeholder="Enter content"></textarea>

            <div class="col-span-1">
                <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Who is this announcement going out
                    to?</label>
                <multiselect class="w-5/12 mb-2 ml-1 multiselect" v-model="announcement.roles" :options="availableRoles"
                    label="display_name" track-by="role_id" :multiple="true" :close-on-select="false">
                    <template slot="placeholder"><span>Select roles</span></template>
                    <template slot="caret" slot-scope="{ toggle }">
                        <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                            <img class="absolute top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] select-none"
                                src="/img/icons/down-chevron.svg" alt="icon" />
                        </div>
                    </template>
                </multiselect>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Multiselect from "vue-multiselect"
import Checkbox from '@components/global/forms/Checkbox'

export default {
    mixins: [validationMixin],
    name: "AnnouncementForm",

    components: {
        Multiselect,
        Checkbox,
    },

    props: {
        value: {
            default: null
        },
    },

    data() {
        return {
            announcement: {
                title: '',
                content: '',
                active: true,
                roles: [],
            },
            availableRoles: [],
        }
    },

    methods: {
        emitAnnouncement() {
            if (this.isValid) this.$emit('input', this.announcement)
        },
        getRoles() {
            this.$app.api.roles.getRoles().then(response => {
                this.availableRoles = response.data
                this.availableRoles = this.availableRoles.filter(role => role.name !== 'admin')
            })
        },
    },

    computed: {
        isValid() {
            return !this.$v.announcement.$invalid
        },
    },

    watch: {
        announcement: {
            handler(value) {
                this.debouncedEmit()
            },
            deep: true
        }
    },

    async created() {
        await this.getRoles()
        this.debouncedEmit = _.debounce(this.emitAnnouncement, 500)
    },

    validations() {
        const validations = {
            announcement: {
                title: { required },
                content: { required },
                roles: { required },
            }
        };

        return validations;
    }
};
</script>

<style lang="scss" scoped></style>
