<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Meal Labels</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="mb-5">
                <p class="mb-2">Please select the meal preparation date.</p>
                <v-date-picker :value="jsDate" @input="(date) => { changed(date) }" color="green"
                    class="shadow-sm border-gray" />
            </div>
            <div class="mb-5">
                <Checkbox v-model="useNewLabels" label="Use new labels format" />
            </div>
            <div v-if="isProcessing && !error" class="flex items-center space-x-1 mb-5">
                <Loader class="w-5 h-5" />
            </div>
            <div v-if="error" class="flex items-center space-x-1">
                <span class="text-red">Error: {{ error }}</span>
            </div>
            <div class="flex space-x-5">
                <button class="btn white px-6" @click="close" :disabled="isProcessing">Cancel</button>
                <button class="btn px-6" @click="printLabels" :disabled="invalidDate || isProcessing">Print</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import { DateTime } from "luxon";
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'
import Checkbox from '@components/global/forms/Checkbox'

export default {
    name: "LabelDateModal",

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        classDate: {
            type: String,
            required: true,
        },
        fulfillmentId: {
            type: Number,
            required: false,
            default: 0,
        },
    },

    components: {
        Modal,
        Checkbox,
        Loader
    },

    data() {
        return {
            isProcessing: false,
            error: null,
            date: null,
            useNewLabels: false,
        }
    },

    computed: {
        jsDate() {
            return this.classDate ? DateTime.fromISO(this.classDate).toJSDate() : null
        },
        invalidDate() {
            return !!this.date.invalid
        }
    },

    methods: {
        close() {
            this.$emit('close')
        },
        printLabels() {
            this.$emit('print', this.date.toISODate(), this.useNewLabels)
        },
        changed(date) {
            this.date = DateTime.fromJSDate(date)
        },
    },

    created() {
        if (this.classDate) {
            this.date = DateTime.fromISO(this.classDate)
        }
    },
}
</script>

<style scoped></style>
