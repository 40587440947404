<template>
    <div class="md:grid grid-cols-3 gap-4">
        <div class="col-span-1">
            <p class="text-sm font-semibold mb-2">Select Meals:</p>
            <div v-for="(selection, index) in menu.selections" :key="index" class="mb-2">
                <multiselect class="w-full multiselect" :options="filteredMealOptions[index]"
                    v-model="menu.selections[index].meal" label="name" track-by="id" :loading="meals.loading"
                    :disabled="meals.loading" :placeholder="'Select Meal ' + (index + 1)"
                    @input="checkMealVariants(index)">
                    <template slot="caret" slot-scope="{ toggle }">
                        <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                            <img class="absolute top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] select-none"
                                src="/img/icons/down-chevron.svg" alt="icon" />
                        </div>
                    </template>
                </multiselect>
            </div>
        </div>
        <div class="col-span-1">
            <p class="text-sm font-semibold mb-2">Projected Cost for Regular Meals</p>
            <CostListView :selectedMeals="selectedMeals()" :fulfillment="'in-person'" />
        </div>
        <div class="col-span-1">
            <p class="text-sm font-semibold mb-2">Projected Cost for Bulk Meals</p>
            <CostListView :selectedMeals="selectedMeals()" :fulfillment="'delivery'" />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { validationMixin } from 'vuelidate'
import Multiselect from "vue-multiselect"
import CostListView from '@components/reports/costs/ListView'

export default {
    mixins: [validationMixin],
    name: "MenuForm",

    components: {
        Multiselect,
        CostListView,
    },

    props: {
        value: {
            default: null
        },
        menuId: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            menu: {
                selections: this.initializeSelections(),
                month_year: null,
            },
            meals: {
                loading: false,
                data: [],
            },
        }
    },

    methods: {
        initializeSelections() {
            return Array.from({ length: 12 }, () => ({ meal: null, delivery: false }));
        },
        async getMeals() {
            let params = {
                active: true,
                prices: true,
            }

            this.meals.loading = true
            return this.$app.api.meals.getMeals(params)
                .then((response) => {
                    this.meals.data = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => this.meals.loading = false)
        },
        async checkMealVariants(index) {
            const selectedMeal = this.menu.selections[index].meal;
            if (selectedMeal) {
                await this.updateMealVariants(selectedMeal.id);
            }
        },
        async updateMealVariants(mealId) {
            try {
                this.meals.loading = true;
                await this.$app.api.meals.updateMealVariantCosts(mealId)
                    .then((response) => {
                        const newMeal = response.data;
                        this.menu.selections.forEach((selection, selectionIndex) => {
                            if (selection.meal && selection.meal.id === newMeal.id) {
                                this.$set(this.menu.selections, selectionIndex, { meal: newMeal, delivery: selection.delivery });
                            }
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.meals.loading = false;
                    });
            } catch (error) {
                console.error(error);
            }
        },
        selectedMeals() {
            return this.menu.selections.filter(selection => selection.meal).map(selection => selection.meal);
        },
    },

    computed: {
        filteredMealOptions() {
            return this.menu.selections.map((selected, index) => {
                return this.meals.data.filter(meal => {
                    return !this.menu.selections.some((otherSelected, otherIndex) =>
                        otherIndex !== index && otherSelected.meal && otherSelected.meal.id === meal.id);
                });
            });
        },
    },

    async created() {
        await this.getMeals()
    },
};
</script>

<style lang="scss" scoped></style>
