const routes = [
    {
        path: '/orders',
        name: 'orders',
        component:  () => import('@views/orders/Orders.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['orders.view']
        }
    },
    {
        path: '/orders/:id',
        name: 'order-details',
        component:  () => import('@views/orders/Order.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['orders.view']
        }
    },
]

export default routes
