<template>
  <div class="flex flex-wrap">
    <div class="mb-2">
      <form>
        <div class="flex flex-wrap">
          <select
            @change="filterStatus"
            v-model="newsItemStatus"
            class="rounded-md border border-gray bg-white mr-2 px-3 py-2"
            name="status"
            id="status"
            style="height: 42px"
          >
            <option value="" selected>Status</option>
            <option
              v-for="(status, index) in statuses"
              :value="status"
              :key="`${index}-${status}`"
            >
              {{ status[0].toUpperCase() + status.slice(1) }}
            </option>
          </select>
        </div>
      </form>
    </div>

    <SearchInput
      class="mr-2 mb-2"
      v-model="search"
      placeholder="Search by title"
      @search="runSearch()"
    />

    <DateRangePicker
      class="mr-2"
      :value="dateRange"
      :default="defaultDateRange"
      :placeholder="'Select Publish Dates'"
      @input="dateRangeChanged"
    />

    <button
      class="rounded-md border border-gray bg-white mr-2 mb-2 px-3 py-2 cursor-pointer"
      @click="clearSearch"
    >
      Clear
    </button>

    <router-link
      v-if="canCreateNewsItem"
      class="btn green border border-green mr-2 mb-2 px-3 py-2"
      :to="`${url}/create`"
      :disabled="!canCreateNewsItem"
    >
      <span><i class="fas fa-plus mr-2"></i>Create {{ title }}</span>
    </router-link>
  </div>
</template>

<script>
import Date from '@components/global/dates/Date'
import DateRangePicker from '@components/global/dates/DateRangePicker'
import SearchInput from '@components/global/SearchInput'
import { DateTime } from 'luxon'

export default {
  name: 'NewsroomFilters',

  components: {
    Date,
    DateRangePicker,
    SearchInput,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    canCreateNewsItem: {
      type: Boolean,
      default: false,
    },
    statuses: {
      type: Array,
      default: function () {
        return ['scheduled', 'published']
      },
    },
    status: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      newsItemStatus: this.status,
      search: this.$route.query.search || '',
      dateRange: this.getDateRange(),
      defaultDateRange: this.getDefaultDateRange(),
    }
  },

  watch: {
    status(newStatus) {
      this.newsItemStatus = newStatus
    },
  },

  methods: {
    filterStatus(e) {
      if (!e.target.value)
        if (this.search)
          this.search = this.search
            .split(',')
            .map((i) => i.trim())
            .join(',')

      let search = []
      if (this.search)
        search = this.search
          .split(',')
          .map((i) => i.trim())
          .filter((i) => !this.statuses.includes(i))
      search.push(e.target.value)
      this.search = search.join(',')

      this.runSearch()
    },

    runSearch() {
      let query = {
        ...this.$route.query,
        search: this.search
          .split(',')
          .map((i) => i.trim())
          .join(','),
      }
      delete query.page

      if (!this.search) delete query.search

      this.pushQueryToRouter(query)
    },

    clearSearch() {
      this.newsItemStatus = ''
      this.search = ''

      let query = { ...this.$route.query }
      delete query.page
      delete query.search
      delete query.after_date
      delete query.before_date

      this.dateRange = this.getDefaultDateRange()

      this.pushQueryToRouter(query)
    },

    getDefaultDateRange() {
      return {
        start: null,
        end: null,
      }
    },

    getDateRange() {
      let range = this.getDefaultDateRange()

      if (this.$route.query.after_date)
        range.start = DateTime.fromISO(this.$route.query.after_date).startOf('day')
      if (this.$route.query.before_date)
        range.end = DateTime.fromISO(this.$route.query.before_date).endOf('day')

      return {
        start: range.start,
        end: range.end,
      }
    },

    dateRangeChanged(input) {
      const range = input.range
      this.dateRange = range

      let query = { ...this.$route.query }
      delete query.page

      if (
        +range.start === +this.defaultDateRange.start &&
        +range.end === +this.defaultDateRange.end
      ) {
        delete query.after_date
        delete query.before_date
      } else {
        query.after_date = range.start.toISODate()
        query.before_date = range.end.toISODate()
      }

      this.pushQueryToRouter(query)
    },
    pushQueryToRouter(query) {
      this.$router
        .push({ path: this.$route.path, query })
        .then(() => {
          this.$emit('search')
        })
        .catch((error) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            error.name !== 'NavigationCancelled'
          ) {
            console.error('Router navigation error:', error)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
