<template>
  <div>
    <!-- Modals -->
    <ConfirmDeletePromotionModal
      :visible="modals.deletePromotion"
      :promotion="selectedPromotion"
      @close="modals.deletePromotion = false"
      @update="updatePromotions"
    />

    <!-- Promotions -->
    <div class="mb-10">
      <h2 class="mb-5">Promotions</h2>
      <div class="flex flex-wrap space-y-2">
        <SearchInput
          class="mr-2"
          v-model="promotions.search"
          placeholder="Search by name or code"
          @search="runSearch"
        />
        <DateRangePicker
          class="mr-2"
          :value="promotions.dateRange"
          :default="promotions.defaultDateRange"
          @input="dateRangeChanged"
        />
        <router-link
          v-if="canCreatePromotion"
          class="btn green border border-green px-3 py-2"
          :to="`/promotions/create`"
          :disabled="!canCreatePromotion"
        >
          <span><i class="fas fa-plus mr-2"></i>Create Promotion</span>
        </router-link>
      </div>
    </div>
    <div>
      <template v-if="promotions.loading">
        <div class="flex items-center space-x-5">
          <Loader class="w-10 h-10 text-green" />
          <p>Loading promotions...</p>
        </div>
      </template>
      <template v-else-if="promotions.data.data && promotions.data.data.length">
        <ListView
          class="mb-10"
          :promotions="promotions.data.data"
          @edit="editPromotion"
          @delete="deletePromotion"
        />
        <Pagination
          :page="promotions.page"
          :total-items="totalPromotions"
          :per-page="promotions.perPage"
          @change="pageUpdated"
        />
      </template>
      <template v-else>
        <p></p>
        <p>
          <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No promotions
          to show. Please refine your criteria.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import Loader from '@components/global/Loader'
import SearchInput from '@components/global/SearchInput'
import Pagination from '@components/global/Pagination'
import DateRangePicker from '@components/global/dates/DateRangePicker'
import ListView from '@components/promotions/ListView'
import ConfirmDeletePromotionModal from '@/components/promotions/modals/ConfirmDeletePromotionModal'
import { DateTime } from 'luxon'

export default {
  name: 'Promotions',

  components: {
    Loader,
    SearchInput,
    Pagination,
    ListView,
    DateRangePicker,
    ConfirmDeletePromotionModal,
  },

  metaInfo() {
    return {
      title: this.title,
    }
  },

  data() {
    return {
      promotions: {
        loading: false,
        search: this.$route.query.search ?? '',
        page: parseInt(this.$route.query.page) || 1,
        perPage: parseInt(this.$route.query.per_page) || 10,
        dateRange: this.getDateRange(),
        defaultDateRange: this.getDefaultDateRange(),
        data: {},
      },
      selectedPromotion: null,
      modals: {
        deletePromotion: false,
      },
    }
  },

  computed: {
    canCreatePromotion() {
      return this.$app.services.auth.hasPermission('promotions.create')
    },
    title() {
      if (this.$route.query.search)
        return `Search: ${this.$route.query.search} | Promotions`
      return 'Promotions'
    },
    totalPromotions() {
      return this.promotions.data.meta?.total ?? 0
    },
  },

  methods: {
    getDefaultDateRange() {
      return {
        start: null,
        end: null,
      }
    },
    getDateRange() {
      let range = this.getDefaultDateRange()

      if (this.$route.query.after_date)
        range.start = DateTime.fromISO(this.$route.query.after_date).startOf('day')
      if (this.$route.query.before_date)
        range.end = DateTime.fromISO(this.$route.query.before_date).endOf('day')

      return range
    },
    dateRangeChanged(input) {
      const range = input.range
      this.promotions.dateRange = range
      this.promotions.page = 1

      let query = { ...this.$route.query }
      delete query.page

      if (
        +range.start === +this.promotions.defaultDateRange.start &&
        +range.end === +this.promotions.defaultDateRange.end
      ) {
        delete query.after_date
        delete query.before_date
      } else {
        query.after_date = range.start.toISODate()
        query.before_date = range.end.toISODate()
      }
      this.$router.replace({ path: this.$route.path, query })
      this.getPromotions()
    },
    pageUpdated(page) {
      this.promotions.page = page
      this.getPromotions()
    },
    runSearch() {
      this.promotions.page = 1

      let query = { ...this.$route.query, search: this.promotions.search }
      delete query.page

      if (!this.promotions.search) delete query.search
      this.$router.replace({ path: this.$route.path, query })
      this.getPromotions()
    },
    getPromotions() {
      let params = {
        page: this.$route.query.page || this.promotions.page,
        per_page: this.$route.query.per_page || this.promotions.perPage,
      }

      let search = this.$route.query.search || this.promotions.search
      if (search) params.search = search

      if (this.promotions.dateRange.start)
        params.after_date = this.promotions.dateRange.start.toUTC().toISO()
      if (this.promotions.dateRange.end)
        params.before_date = this.promotions.dateRange.end.toUTC().toISO()

      this.promotions.loading = true
      return this.$app.api.promotions
        .getPromotions(params)
        .then((response) => {
          this.promotions.data = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (this.promotions.loading = false))
    },
    editPromotion(promotion) {
      this.$router.push(`\\promotions\\${promotion.id}\\edit`)
    },
    deletePromotion(promotion) {
      this.selectedPromotion = promotion
      this.modals.deletePromotion = true
    },
    updatePromotions() {
      this.getPromotions()
    },
  },

  created() {
    this.getPromotions()
  },
}
</script>

<style scoped></style>
