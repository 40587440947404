<template>
    <div class="flex flex-col p-6 w-full">
        <div class="mb-8 flex justify-between space-x-3">
            <h3>Create Promotion</h3>
        </div>

        <PromotionForm v-model="promotion" />

        <div v-if="error" class="flex space-x-5 mb-4">
            <p class="text-red ml-1">{{ error }}</p>
        </div>

        <div class="flex space-x-5 mt-6">
            <router-link class="btn white px-6" :to="`/promotions`">Cancel</router-link>
            <button class="btn button-green px-6" @click="handleSave" :disabled="!canCreatePromotion || saving">Save</button>
        </div>
    </div>
</template>

<script>
import PromotionForm from '@components/promotions/PromotionForm'

export default {
    name: "CreatePromotion",

    components: {
        PromotionForm,
    },

    data() {
        return {
            saving: false,
            promotion: null,
            error: null,
        }
    },

    methods: {
        handleSave() {
            let data = this.promotion

            this.saving = true
            this.$app.services.promotion.createPromotion(data)
                .then((response) => {
                    this.$router.push('/promotions')
                })
                .catch((error) => {
                    console.error(error)
                    this.error = error.error?.response?.data?.message ? error.error.response.data.message : 'Unknown error.'
                })
                .finally(() => {
                    this.saving = false
                })
        },
    },

    computed: {
        canCreatePromotion() {
            return !!this.promotion && this.$app.services.auth.hasPermission('promotions.create')
        }
    },

}
</script>

<style lang="scss" scoped>
</style>
