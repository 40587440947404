<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 overflow-y-auto h-full w-full flex justify-center items-center"
  >
    <div
      :class="{ 'modal-overlay-opening': isOpen, 'modal-overlay-closing': isClosing }"
      class="modal-overlay absolute inset-0 bg-gray-300"
      @click="closeModal"
    />
    <div
      :class="{ 'modal-opening': isOpen, 'modal-closing': isClosing }"
      class="modal-box relative rounded-md shadow-md"
    >
      <!-- rounded so the shadow looks correct -->
      <div
        class="flex flex-col bg-white p-6"
        :class="hasFooter ? 'rounded-top' : 'rounded-all'"
      >
        <!-- Header -->
        <div
          class="flex justify-between items-center bg-white border-b border-gray-50 pb-6 gap-16"
        >
          <!-- Adding a hidden X to the left lets us center the title without needing to do any funky positioning with the visible X -->
          <div class="invisible">
            <CloseIcon class="h-4 w-4" />
          </div>
          <h3 class="modal-title text-center">{{ title }}</h3>
          <button
            v-if="showClose"
            @click="closeModal"
            class="icon-container text-green-500"
          >
            <CloseIcon class="h-4 w-4" />
          </button>
        </div>
        <div class="bg-white pt-6">
          <slot></slot>
        </div>
      </div>
      <div
        v-if="hasFooter"
        class="bg-cream-500 p-6"
        style="border-radius: 0 0 0.5rem 0.5rem"
      >
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/img/icons/close-icon.vue'

export default {
  name: 'AdminBaseModal',
  components: {
    CloseIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    clickOutsideToClose: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Sign In',
    },
    hasFooter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      isClosing: false,
    }
  },
  methods: {
    closeModal() {
      this.isClosing = true
      setTimeout(() => {
        this.$emit('close')
        this.isClosing = false
      }, 150) // needs to match css animation duration
    },
    handleEscPress(event) {
      if (event.key === 'Escape') {
        this.closeModal()
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscPress)
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleEscPress)
  },
}
</script>

<style scoped>
@keyframes overlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}

@keyframes overlayFadeOut {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 0;
  }
}
/* Bottom needs to be rounded if there's no footer  */
.rounded-top {
  border-radius: 0.5rem 0.5rem 0 0;
}

.rounded-all {
  border-radius: 0.5rem;
}

.modal-overlay {
  /* Tailwind's opacity isn't working */
  opacity: 0.6;
}

.modal-overlay-opening {
  animation: overlayFadeIn 0.15s ease-out forwards;
}

.modal-overlay-closing {
  animation: overlayFadeOut 0.15s ease-out forwards;
}

@keyframes modalFadeInGrow {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalFadeOutShrink {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.modal-box {
  opacity: 1;
  max-width: calc(100% - 24px);
  max-height: calc(100vh - 24px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.modal-opening {
  animation: modalFadeInGrow 0.15s ease-out forwards;
}

.modal-closing {
  animation: modalFadeOutShrink 0.15s ease-out forwards;
}

.icon-container {
  display: block;
  height: auto;
  padding: 0.5rem;
}

.modal-title {
  font-size: 2rem;
  font-weight: 400;
  line-height: 120%;
}
</style>
