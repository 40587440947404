import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import firebase from 'firebase/app';
import 'firebase/auth';
import store from '@shared/store/user'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // handle browser's back/forward buttons
    if (savedPosition) {
      return savedPosition
    }
    // handle anchors
    else if (to.hash) {
      return { selector: to.hash }
    }
    // handle changing query params only
    else if (from.path === to.path) {
      return {}
    }

    // default scroll to top
    return { x: 0, y: 0 };
  },
})

let isAuthChecked = false

router.beforeEach(async (to, from, next) => {
  if (isAuthChecked) {
    isAuthChecked = false
    next()
    return
  }

  const waitForAuth = new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      unsubscribe()

      const authService = Vue.prototype.$app.services.auth
      const currentUrl = window.location.origin + to.fullPath

      if (firebase.auth().isSignInWithEmailLink(currentUrl)) {
        let email = window.localStorage.getItem('CPEmailForSignIn')
        if (!email) {
          // For security reasons, we need to prompt for the email if the user hasn't stored their email on this device
          email = window.prompt('Please provide your email for confirmation')
        }
        if (email) {
          try {
            await authService.loginWithEmailLink(email, currentUrl);
            resolve('/')
            return
          } catch (error) {
            console.error('Error signing in with email link', error)
            resolve('/login')
            return
          }
        }
      }

      if (!user && to.path !== '/login') {
        // no user, redirecting to /login
        resolve('/login')
      } else if (!user && to.path === '/login') {
        // no user, but already on /login, resolving
        resolve()
      } else {
        const hasPermission = await authService.hasPermission('websites.admin.access')
        if (!hasPermission) {
          // no permission, logging out
          await authService.logout()
          isAuthChecked = true
          resolve()
        } else {
          // has permission, resolving
          resolve()
        }
      }
    })
  })

  waitForAuth.then((path) => {
    if (path) {
      next(path)
    } else {
      next()
    }
  })
})


export default router
