<template>
    <div class="relative h-screen flex flex-col">
        <SitewideModals/>
        <Alerts/>
        <div class="flex">
            <NavigationSidePanel :open="panelOpen" @close="togglePanel"/>
            <div class="flex-1 min-h-screen">
                <NavigationHeader @toggle-menu="togglePanel"/>
                <router-view :key="$route.path" class="max-w-screen-2xl w-full px-5 md:px-8 py-10"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import NavigationHeader from '@components/navigation/Header'
import NavigationSidePanel from '@components/navigation/SidePanel'
import SitewideModals from '@components/global/modals/SitewideModals'
import Alerts from '@components/global/Alerts'

export default {
    name: 'AdminLayout',

    metaInfo() {
        return {
            title: 'Citrus Pear',
            titleTemplate: '%s | Citrus Pear Admin',
        }
    },

    components: {
        NavigationHeader,
        NavigationSidePanel,
        SitewideModals,
        Alerts,
    },

    data(){
        return {
            panelOpen: false
        }
    },

    methods: {
        togglePanel(){
            this.panelOpen = !this.panelOpen
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
