<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Edit Event</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="mb-10">
                <div v-if="errors.length" class="flex space-x-5 mb-4">
                    <p class="text-red ml-1">{{ errors }}</p>
                </div>
                <div>
                    <label class="block text-xs font-body text-gray-dark mb-2 ml-1">*Name:</label>
                    <input v-model="$v.eventData.name.$model"
                           type="text" class="cp-input mb-0"
                           :class="{'cp-input-error': $v.eventData.name.$error}">
                    <template v-if="$v.eventData.name.$error">
                        <p v-if="!$v.eventData.name.required" class="text-red text-xs ml-1">Name is required</p>
                    </template>
                </div>
                <div>
                    <Textbox
                        v-model="$v.eventData.description.$model"
                        :class="{'cp-input-error': $v.eventData.description.$error}"
                        label="Description:"
                        placeholder=""
                    />
                    <template v-if="$v.eventData.description.$error">
                    </template>
                </div>
                <div>
                    <Textbox
                        v-model="$v.eventData.notes.$model"
                        :class="{'cp-input-error': $v.eventData.notes.$error}"
                        label="Notes:"
                        placeholder=""
                    />
                    <template v-if="$v.eventData.notes.$error">
                    </template>
                </div>
                <div>
                    <Checkbox v-model="$v.eventData.allDay.$model" label="All Day" />
                </div>
                <template v-if="!eventData.allDay">
                    <div>
                        <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Event Time:</label>
                        <v-date-picker
                            mode="time"
                            :value="jsRange"
                            @input="updateTimeRange"
                            is-range
                        />
                    </div>
                </template>
                <template v-else>
                    <p class="text-xs text-gray-400 xl:hidden">Date:</p>
                    <p><Date :date="displayDate" preset="DATE_FULL"/></p>
                </template>
            </div>
            <div class="flex space-x-5">
                <button class="btn gray px-6" @click="close">Cancel</button>
                <button class="btn green px-6" @click="saveEvent" :disabled="!canCreateEvent || saving || success">
                    <div v-if="saving" class="flex items-center"><Loader class="w-5 mr-1"/><span>Saving</span></div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Saved!</span>
                    <span v-else>Save</span>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import {DateTime} from "luxon";
import { required } from 'vuelidate/lib/validators'
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'
import Textbox from '@components/global/forms/Textbox'
import Checkbox from '@components/global/forms/Checkbox'
import Date from '@components/global/dates/Date'

export default {
    name: "EditEventModal",

    components: {
        Modal,
        Loader,
        Textbox,
        Checkbox,
        Date,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            required: true,
        },
        day: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            saving: false,
            success: false,
            errors: [],
            eventData: {
                name: null,
                description: null,
                notes: null,
                allDay: false,
                range: this.getDefaultTimeRange()
            },
        }
    },

    computed: {
        displayDate() {
            return DateTime.fromJSDate(this.day.date).toISO()
        },
        canCreateEvent() {
            return this.hasPermission && this.isValid
        },
        hasPermission() {
            return this.$app.services.auth.hasPermission('calendars.events.update')
        },
        isValid() {
            return !this.$v.eventData.$invalid
        },
        jsRange() {
            let range = this.eventData.range
            return {start: range.start.toJSDate(), end: range.end.toJSDate()}
        },
    },

    methods: {
        close() {
            this.reset()
            this.$emit('close')
        },
        reset() {
            this.eventData = this.getEventDefault()
            this.errors = []
            this.saving = false
            this.success = false
            this.$v.$reset()
        },
        getEventDefault() {
            return {
                name: this.event.name,
                description: this.event.description,
                notes: this.event.notes,
                allDay: this.event.all_day,
                range: this.getDefaultTimeRange()
            }
        },
        saveEvent() {
            let data = {
                ...this.event,
                key: `${this.event.calendar_id}-${this.eventData.name.trim().replaceAll(' ', '-').toLowerCase()}`,
                name: this.eventData.name,
                description: this.eventData.description,
                notes: this.eventData.notes,
                all_day: this.eventData.allDay,
                start_time: this.eventData.range.start.toUTC().toISO(),
                end_time: this.eventData.range.end.toUTC().toISO(),
            }

            if(this.eventData.allDay) {
                data.start_time = DateTime.fromJSDate(this.day.date).startOf('day').toUTC()
                data.end_time = DateTime.fromJSDate(this.day.date).endOf('day').toUTC()
            }

            this.saving = true
            this.$app.api.calendars.updateEvent(this.event.id, data)
                .then((response) => {
                    this.success = true
                    setTimeout(() => {
                        this.close()
                        this.$emit('update', response.data)
                    }, 250)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.saving = false
                })
        },
        updateTimeRange(change) {
            this.eventData.range = {
                start: DateTime.fromJSDate(change.start),
                end: DateTime.fromJSDate(change.end)
            }
        },
        getDefaultTimeRange() {
            return { start: DateTime.fromISO(this.event.start_time), end: DateTime.fromISO(this.event.end_time) }
        },
    },

    created() {
        this.eventData = this.getEventDefault()
    },

    mounted() {},

    validations: {
        eventData: {
            name: { required },
            description: {},
            notes: {},
            allDay: {},
            startsAt: {},
            endsAt: {},
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
