var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?[_c('label',{staticClass:"block text-xs font-body text-gray-dark mb-2 ml-1"},[_vm._v(_vm._s(_vm.label))])]:_vm._e(),_c('div',{staticClass:"flex flex-row"},[_c('button',{staticClass:"btn bg-white border-gray-200 text-gray-800 font-normal border border-r-0 rounded-r-none capitalize",on:{"click":function($event){return _vm.buttonClicked('Past')}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('Popover',{scopedSlots:_vm._u([{key:"button",fn:function(ref){
var toggle = ref.toggle;
var visible = ref.visible;
return [_c('div',{staticClass:"border border-gray bg-white px-3 py-2 cursor-pointer",class:{
          'bg-green-600 border-green-600 text-white': visible || _vm.rangeSelected,
        },on:{"click":toggle}},[(_vm.rangeSelected)?_c('p',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.value.start,'DATE_SHORT'))+" "),(!_vm.rangeIsSingleDay)?_c('span',[_c('i',{staticClass:"fas fa-long-arrow-alt-right px-1"}),_vm._v(" "+_vm._s(_vm._f("date")(_vm.value.end,'DATE_SHORT'))+" ")]):_vm._e(),(!_vm.rangeIsDefault)?_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.reset.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-times-circle px-1"})]):_vm._e()]):_c('p',[_vm._v(_vm._s(_vm.placeholder))])])]}},{key:"default",fn:function(ref){
        var hide = ref.hide;
return [_c('v-date-picker',{staticClass:"shadow-sm border-gray",attrs:{"value":_vm.jsDateRange,"attributes":_vm.attributes,"is-range":"","mode":_vm.mode,"color":"green"},on:{"input":function (range) {
            _vm.changed(range)
            if (!_vm.time) { hide() }
          }}})]}}])}),_c('button',{staticClass:"btn bg-white border-gray-200 text-gray-800 font-normal border border-l-0 rounded-l-none capitalize",on:{"click":function($event){return _vm.buttonClicked('Future')}}},[_c('i',{staticClass:"fas fa-chevron-right"})])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }