<template>
    <ListView :headers="headers">
        <template #list>
            <slot>
                <div v-for="event in events" :key="event.key" class="mb-5 xl:mb-0">
                    <EventCard :event="event" />
                </div>
            </slot>
        </template>
    </ListView>
</template>

<script>
import ListView from '@components/global/ListView'
import EventCard from '@components/calendars/EventCard'

export default {
    props: {
        events: {
            type: Array,
            default: function () {
                return []
            },
        },
        headers: {
            type: Array,
            default: function () {
                return [
                    'Name',
                    'Description',
                    'Notes',
                    'All Day',
                    'Start',
                    'End',
                    'Status',
                    'Manage',
                ]
            },
        },
    },

    components: {
        ListView,
        EventCard,
    },
}
</script>

<style lang="scss" scoped>
</style>
