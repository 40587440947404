<template>
    <div>
        <div v-if="location.loading" class="flex items-center space-x-5">
            <Loader class="w-10 h-10 text-green" />
            <p>Loading location...</p>
        </div>
        <div v-else-if="location.data">
            <!-- Modals -->
            <ConfirmDeleteLocationModal :visible="modals.deleteLocation" :locationId="location.data.id"
                @close="modals.deleteLocation = false" @update="deleteSuccess" />

            <!-- Location -->
            <div class="mb-14 flex justify-between">
                <div>
                    <h2 class="mb-2">{{ location.data.name }}</h2>
                    <div class="mb-5 flex flex-wrap items-center space-x-2">
                        <h6 class="label">ID:</h6>
                        <p>{{ location.data.id }}</p>
                        <h6 class="label">Abbreviation:</h6>
                        <p>{{ location.data.code }}</p>
                    </div>
                    <p v-if="location.data.shipping" class="mb-2">
                        <FulfillmentBadge :fulfillmentKey="'shipping'" />
                    </p>
                    <p>
                        <StatusBadge :location="location.data" />
                    </p>
                </div>
                <div v-if="canPerformActions">
                    <button v-if="canPerformEdit" class="btn white px-6 mr-2" @click="editLocation">Edit</button>
                    <button v-if="canPerformEdit"
                        :class="{ 'btn gray px-6 mr-2': location.data.active, 'btn green px-6 mr-2': !location.data.active }"
                        @click="updateLocationStatus">
                        {{ location.data.active ? 'Disable' : 'Enable' }}
                    </button>
                    <button v-if="canPerformDelete" class="btn red px-6" :disabled="!canPerformDelete"
                        @click="deleteLocation">Delete</button>
                </div>
            </div>

            <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-5 gap-x-4 gap-y-6 mb-14">
                <!-- Row 1 -->
                <div>
                    <h6 class="label">Store Name:</h6>
                    <p>{{ location.data.address.company }}</p>
                </div>
                <div>
                    <h6 class="label">Store Address:</h6>
                    <p>{{ location.data.address.street_1 }}</p>
                    <p v-if="location.data.address.street_2">{{ location.data.address.street_2 }}</p>
                    <p>{{ location.data.address.locality }}, {{ location.data.address.administrative_area }} {{
                        location.data.address.postal_code }}</p>
                </div>
                <div>
                    <h6 class="label">Timezone:</h6>
                    <p>{{ location.data.timezone }}</p>
                </div>
                <div>
                    <h6 class="label">Lead:</h6>
                    <p>{{ location.data.lead.name }}</p>
                    <p class="break-words">{{ location.data.lead.email }}</p>
                </div>
                <div>
                    <h6 class="label">Regional Lead:</h6>
                    <p>{{ location.data.regional.name }}</p>
                    <p class="break-words">{{ location.data.regional.email }}</p>
                </div>

                <!-- Row 2 -->
                <div>
                    <h6 class="label">Google Drive FolderID:</h6>
                    <p class="break-all">{{ location.data.google_drive_folder }}</p>
                </div>
                <div>
                    <h6 class="label">Default Class Size:</h6>
                    <p>{{ location.data.default_class_size }}</p>
                </div>
                <div>
                    <h6 class="label">Couple Class Size:</h6>
                    <p>{{ location.data.couple_class_size }}</p>
                </div>
                <div>
                    <h6 class="label">Pickup Class Size:</h6>
                    <p>{{ location.data.pickup_class_size }}</p>
                </div>
                <div>
                    <h6 class="label">Allow Custom Class:</h6>
                    <p v-if="location.data.allow_custom_class">True</p>
                    <p v-else>False</p>
                </div>

                <!-- Row 3 -->
                <div>
                    <h6 class="label">Grocery Etrog Note:</h6>
                    <p>{{ location.data.grocery_etrog_note }}</p>
                </div>
                <div>
                    <h6 class="label">Meat Etrog Note:</h6>
                    <p>{{ location.data.meat_etrog_note }}</p>
                </div>
                <div>
                    <h6 class="label">Pricing Multiplier:</h6>
                    <p>{{ location.data.price_modifier }}</p>
                </div>
                <div>
                    <h6 class="label">Pricing Offset:</h6>
                    <p>${{ location.data.surcharge }}</p>
                </div>
            </div>
            <div v-if="deliveryZipcodes" class="mb-14">
                <h6 class="label">Delivery Zipcodes:</h6>
                <p>{{ deliveryZipcodes }}</p>
                <button class="mt-2 btn flex items-center px-3 py-2 border border-green"
                    @click.prevent="exportZipcodes">
                    <span class="whitespace-nowrap"><i class="fas fa-file-download mr-2"></i>Export</span>
                </button>
            </div>

            <!-- Calendar Link -->
            <div v-if="showCalendarLink" class="mb-14">
                <router-link :to="{ name: 'calendar', params: this.location.data.calendar_id }">
                    <button class="btn green px-6" type="button"><i
                            class="fas fa-fw fa-calendar-alt mr-2"></i>Calendar</button>
                </router-link>
            </div>

            <!-- Store Locator -->
            <div class="flex justify-between items-center mb-6">
                <h3><i class="fas fa-fw fa-map-marker-alt mr-2"></i>Store Location</h3>
            </div>
            <GoogleMapLocator :location="location.data" :updateLocation="updateLocation" :viewMap="true" />

            <!-- Classes -->
            <div class="flex justify-between items-center mt-14 mb-6">
                <h3 v-if="location.data.shipping"><i class="fas fa-fw fa-lemon mr-2"></i>Groups</h3>
                <h3 v-else><i class="fas fa-fw fa-lemon mr-2"></i>Classes</h3>
            </div>
            <div v-if="classes.loading" class="flex items-center space-x-5">
                <Loader class="w-10 h-10 text-green" />
                <p>Loading classes...</p>
            </div>
            <div v-else-if="classes.data.data && classes.data.data.length">
                <GroupsListView v-if="location.data.shipping" class="mb-10" :groups="classes.data.data" />
                <ClassesListView v-else class="mb-10" :classes="classes.data.data" />
                <Pagination v-if="totalClasses > 10" :page="classes.page" :total-items="totalClasses"
                    :per-page="classes.perPage" @change="pageUpdated" />
            </div>
            <div v-else>
                <p>No classes for this location.</p>
            </div>
        </div>
        <div v-else>
            <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
        </div>
    </div>
</template>

<script>
import ClassesListView from '@components/classes/ListView'
import GroupsListView from '@components/shipping/ListView'
import ConfirmDeleteLocationModal from '@components/locations/modals/ConfirmDeleteLocationModal'
import GoogleMapLocator from '@components/locations/GoogleMapLocator'
import Loader from '@components/global/Loader'
import Pagination from '@components/global/Pagination'
import StatusBadge from '@components/locations/StatusBadge'
import FulfillmentBadge from '@components/meals/FulfillmentBadge'

export default {
    name: 'Location',

    props: {},

    components: {
        ClassesListView,
        GroupsListView,
        ConfirmDeleteLocationModal,
        GoogleMapLocator,
        Loader,
        Pagination,
        StatusBadge,
        FulfillmentBadge,
    },

    metaInfo() {
        return {
            title: this.location?.data?.name ?? 'Location Details',
        }
    },

    data() {
        return {
            classes: {
                loading: false,
                data: {},
                page: parseInt(this.$route.query.c_page) || 1,
                perPage: parseInt(this.$route.query.c_per_page) || 10,
                pageQueryString: 'c_page',
                perPageQueryString: 'c_per_page',
            },
            location: {
                loading: false,
                data: {},
            },
            modals: {
                deleteLocation: false,
            },
        }
    },

    computed: {
        canPerformActions() {
            return this.$app.services.auth.hasOneOfPermissions(['locations.update', 'locations.delete'])
        },
        canPerformEdit() {
            return this.$app.services.auth.hasOneOfPermissions(['locations.update'])
        },
        canPerformDelete() {
            return this.$app.services.auth.hasOneOfPermissions(['locations.delete'])
        },
        showCalendarLink() {
            return this.location.data.calendar_id && this.$router.resolve({ name: 'calendar' }).href === `/calendars/${this.location.data.calendar_id}`
        },
        totalClasses() {
            return this.classes.data.meta?.total ?? 0
        },
        deliveryZipcodes() {
            const zipcodesWithNullDistance = this.location.data.zipcodes
                ?.filter(locationZipcode => locationZipcode.distance === null)
                .map(locationZipcode => locationZipcode.zipcode.zipcode)
                .sort((a, b) => parseInt(a) - parseInt(b))
                .join(', ');

            return zipcodesWithNullDistance;
        }
    },

    methods: {
        updateLocationStatus() {
            this.$app.api.locations.updateLocationStatus(this.location.data.id)
                .then(response => {
                    this.getLocation()
                })
                .catch(error => {
                    console.error(error)
                })
        },
        deleteLocation() {
            this.modals.deleteLocation = true
        },
        editLocation() {
            this.$router.push(`/locations/${this.$route.params.id}/edit`)
        },
        getLocation() {
            // default params
            const params = {
                relations: [
                    'address',
                    'zipcodes',
                    'zipcodes.zipcode',
                ].join('|')
            }

            this.location.loading = true
            this.$app.api.locations.getLocation(this.$route.params.id, params).then(response => {
                this.location.data = response.data
                this.getClasses()
            }).catch(error => {
                console.error(error)
            }).finally(() => this.location.loading = false)
        },
        getClasses() {
            // default params
            const params = {
                location_ids: this.location.data.id,
                orderBy: 'date',
                orderDirection: 'desc',
                page: this.$route.query.c_page || this.classes.page,
                per_page: this.$route.query.c_per_page || this.classes.perPage,
            }

            if (this.location.data.shipping)
                params.fulfillment_ids = '4'
            else
                params.fulfillment_ids = '1|2|3'

            this.classes.loading = true
            this.$app.api.classes.getClasses(params).then(response => {
                this.classes.data = response.data
            }).catch(error => {
                console.error(error)
            }).finally(() => this.classes.loading = false)
        },
        pageUpdated(page) {
            this.classes.page = page
            this.getClasses()
        },
        updateLocation() {
            return false
        },
        deleteSuccess() {
            this.$router.push(`/locations`)
        },
        exportZipcodes() {
            this.$app.api.locations.exportZipcodes(this.location.data.id)
                .then(response => {
                    const blob = new Blob([response.data], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${this.location.data.name}_zipcodes.csv`;
                    document.body.appendChild(a);  // Needed for Firefox
                    a.click();
                    document.body.removeChild(a);  // Clean up
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },

    created() {
        this.getLocation()
    },
}
</script>

<style lang="scss" scoped></style>
