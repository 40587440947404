<template>
    <div class="min-h-screen min-w-screen bg-cream py-10 md:py-24 px-5">
        <div class="mb-10">
            <img src="/img/logos/logo.svg" class="mx-auto w-60">
        </div>
        <div class="px-6 xs:px-10 sm:px-14 py-14 xs:py-20 bg-white rounded-md w-full max-w-md mx-auto">
            <h3 class="mb-8 text-center">Forgot Password</h3>
            <form v-if="!sent" class="cp-account-form" v-on:submit.prevent="requestReset">
                <div class="mb-8">
                    <label class="block text-sm mb-1">Email Address</label>
                    <input v-model="$v.email.$model" type="email" class="w-full" placeholder="Enter email address" @focus="error = false">
                    <template v-if="$v.email.$error">
                        <p v-if="!$v.email.required" class="text-red-500 text-xs">Email is required.</p>
                        <p v-if="!$v.email.email" class="text-red-500 text-xs">Please enter a valid email address.</p>
                    </template>
                </div>
                <button class="btn mx-auto w-full" :disabled="$v.$invalid || sent || loading">Request Reset</button>
                <router-link to="/login">
                    <button class="btn white mx-auto w-full mt-3">Cancel</button>
                </router-link>
            </form>
            <div v-else>
                <p class="mb-5">A password reset email has been sent to <span class="font-bold">{{ email }}</span>. Please check your inbox for instructions on how to reset your email.</p>
                <router-link to="/login">
                    <button class="btn mx-auto w-full mt-3">Continue</button>
                </router-link>
                <button class="btn outline mx-auto w-full mt-3" @click="sent = false">Retry</button>
            </div>
            <div v-if="error" class="mt-5">
                <p class="text-red-500">{{ error }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
    name: "ForgotPassword",

    metaInfo(){
        return {
            title: 'Forgot Password'
        }
    },

    data() {
        return {
            loading: false,
            email: "",
            sent: false,
            error: false,
        };
    },
    methods: {
        getSignInMethods() {
            return this.$app.services.auth
                .getSignInMethods(this.email)
                .then(methods => {
                    return methods
                })
                .catch(error => {
                    console.error(error);
                });
        },
        requestReset() {
            this.error = false
            this.loading = true
            return this.$app.services.auth.getSignInMethods(this.email).then(methods => {
                if(!methods.includes('password')) throw "No account found for the email provided."
                return this.$app.services.auth.sendPasswordResetEmail(this.email)
            }).then(() => {
                this.sent = true;
            }).catch(error => {
                this.error = error;
            }).finally(() => this.loading = false)
        },
    },

    validations: {
        email: { required, email }
    }
}
</script>

<style>

</style>
