const routes = [
    {
        path: "/menus",
        name: "menus",
        component: () => import("@views/menus/Menus.vue"),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ["menus.view"]
        }
    },
    {
        path: '/menus/create',
        name: 'menu-create',
        component: () => import('@views/menus/CreateMenu.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['menus.create']
        }
    },
    {
        path: '/menus/:id/edit',
        name: 'menu-edit',
        component: () => import('@views/menus/CreateMenu.vue'),
        props: route => ({ id: Number(route.params.id) }),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['menus.update', 'menus.private.create']
        }
    },
    {
        path: "/menus/:id",
        name: "menu",
        component: () => import("@views/menus/Menu.vue"),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ["menus.view"]
        }
    }
];

export default routes;