
function ucwords(word) {
    let str = word.toLowerCase();
    return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (s) {
        return s.toUpperCase();
    });
}

export default Vue => {
    Vue.filter('ucwords', ucwords)
}