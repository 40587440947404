import Resource from './resource'

export default class Calendars extends Resource {

    /**
     * @param {string|int} calendarId calendar ID
     * @param {object} params get calendar params object
     */
    getCalendar(calendarId, params = {}){
        return this.axios.get(`calendars/${calendarId}`, { params })
    }

    /**
     * @param {object} params get calendars params object
     */
    getCalendars(params = {}){
        return this.axios.get(`calendars`, { params })
    }

    /**
     *  @param {string|int} calendarId calendar ID
     *  @param {object} params get calendar events params object
     **/
    getCalendarEvents(calendarId, params = {}) {
        return this.axios.get(`calendars/${calendarId}/events`, { params })
    }

    /**
     * @param {object} data calendar create data
     */
    createCalendar(data){
        return this.axios.post(`calendars`, data)
    }

    /**
     * @param {string|int} calendarId calendar ID
     * @param {object} data calendar update data
     */
    updateCalendar(calendarId, data){
        return this.axios.put(`calendars/${calendarId}`, data)
    }

    /**
     * @param {string|int} calendarId calendar ID
     */
    deleteCalendar(calendarId){
        return this.axios.delete(`calendars/${calendarId}`)
    }

    /**
     *  @param {string|int} eventId calendar event ID
     *  @param {object} params get calendar event params object
     **/
    getEvent(eventId, params = {}) {
        return this.axios.get(`events/${eventId}`, { params })
    }

    /**
     *  @param {string|int} calendarId calendar ID
     *  @param {object} data create calendar event data object
     **/
    createEvent(calendarId, data) {
        return this.axios.post(`calendars/${calendarId}/events`, data)
    }

    /**
     *  @param {string|int} eventId event ID
     *  @param {object} data update calendar event data object
     **/
    updateEvent(eventId, data) {
        return this.axios.put(`events/${eventId}`, data)
    }

    /**
     *  @param {string|int} eventId calendar event ID
     **/
    deleteEvent(eventId) {
        return this.axios.delete(`events/${eventId}`)
    }

    /**
     * Get CalendarEvent statuses
     */
    getEventStatuses() {
        return this.axios.get('calendarsEventStatuses')
    }
}
