<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Reopen Class</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="mb-10">
                <p class="mb-2">Are you sure you want to reopen this class?</p>
            </div>
            <div class="flex space-x-5">
                <button class="btn white px-6" @click="close">Cancel</button>
                <button class="btn px-6" @click="reopen" :disabled="loading || success">
                    <div v-if="loading" class="flex items-center"><Loader class="w-5 mr-1"/><span>Reopening</span></div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Reopened!</span>
                    <span v-else>Reopen</span>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'

export default {
    props: {
        classEventId: {
            type: Number|String,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },

    components: {
        Modal,
        Loader
    },

    data(){
        return {
            loading: false,
            success: false,
        }
    },

    methods: {
        close(){
            this.$emit('close')
        },
        reopen(){
            this.loading = false
            return this.$app.api.classes.reopen(this.classEventId).then(res => {
                this.success = true
                this.$emit('update', res.data)
                setTimeout(() => {
                    this.success = false
                    this.close()
                }, 2000);
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading = false)
        },
    }
}
</script>

<style>

</style>
