<template>
  <div class="flex justify-start items-center pr-2">
    <div
      :class="{ 'bg-gray-200': disabled }"
      class="bg-white border border-gray-light w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500"
    >
      <input
        :id="label"
        type="checkbox"
        :value="value"
        :checked="value"
        @input="handleInput"
        :disabled="disabled"
        class="opacity-0 absolute"
      />
      <svg
        class="fill-current hidden w-4 h-4 text-green pointer-events-none"
        viewBox="0 0 20 20"
      >
        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
      </svg>
    </div>
    <div class="select-none text-base text-gray-dark">
      <label :for="label">{{ label }}</label>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event.target.checked);
    }
  }
};
</script>

<style lang="scss" scoped>
input:checked + svg {
  display: block;
}

input:disabled + svg {
  opacity: 0.6;
}
</style>
