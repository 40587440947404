<template>
    <router-view :key="$route.path"></router-view>
</template>

<script>
export default {
    name: 'LoginLayout',
    metaInfo() {
        return {
            title: 'Login',
            titleTemplate: '%s | Citrus Pear',
        }
    },
}
</script>

<style>

</style>
