<template>
    <div>
        <slot :totalPages="totalPages" :pages="pages" :perPage="perPage">
            <div class="sm:flex sm:justify-between sm:space-x-5">
                <div class="flex mb-3 space-x-1">
                    <div v-if="showFirstLastButtons">
                        <router-link v-if="page != 1" :to="getPaginatedRoute(1)" replace @click.native="() => goToPage(1)">
                            <p class="page-btn"><i class="fas fa-fw fa-angle-double-left"></i></p>
                        </router-link>
                        <p v-else class="page-btn disabled"><i class="fas fa-fw fa-angle-double-left"></i></p>
                    </div>
                    <div v-if="showPrevNextButtons">
                        <router-link v-if="page != 1" :to="getPaginatedRoute(Math.max(page - 1, 1))" replace @click.native="() => goToPage(Math.max(page - 1, 1))">
                            <p class="page-btn"><i class="fas fa-fw fa-angle-left"></i></p>
                        </router-link>
                        <p v-else class="page-btn disabled"><i class="fas fa-fw fa-angle-left"></i></p>
                    </div>
                    <div v-for="pageNumber in pages" :key="pageNumber">
                        <router-link v-if="pageNumber != page" :to="getPaginatedRoute(pageNumber)" replace @click.native="() => goToPage(pageNumber)">
                            <p class="page-btn font-semibold">{{ pageNumber }}</p>
                        </router-link>
                        <p v-else class="page-btn font-semibold active">{{ pageNumber }}</p>
                    </div>
                    <div v-if="showPrevNextButtons">
                        <router-link v-if="page != totalPages && totalPages != 0" :to="getPaginatedRoute(Math.min(page + 1, totalPages))" replace @click.native="() => goToPage(Math.min(page + 1, totalPages))">
                            <p class="page-btn"><i class="fas fa-fw fa-angle-right"></i></p>
                        </router-link>
                        <p v-else class="page-btn disabled"><i class="fas fa-fw fa-angle-right"></i></p>
                    </div>
                    <div v-if="showFirstLastButtons" >
                        <router-link v-if="page != totalPages && totalPages != 0" :to="getPaginatedRoute(totalPages)" replace @click.native="() => goToPage(totalPages)">
                            <p class="page-btn"><i class="fas fa-fw fa-angle-double-right"></i></p>
                        </router-link>
                        <p v-else class="page-btn disabled"><i class="fas fa-fw fa-angle-double-right"></i></p>
                    </div>
                </div>
                <div class="text-sm text-right">
                    <div class="mb-2">
                        <span>Page</span>
                        <input type="number" class="w-10 h-8 px-0 py-0 text-center mx-1" style="min-width: 2rem;" :value="page" @change="handlePageInput">
                        <span>of {{ totalPages }}</span>
                    </div>
                    <div>
                        <p class="text-sm">Showing {{ startCount }} to {{ endCount }} of {{ totalItems }}</p>
                    </div>
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            default: 10,
            validator: (perPage) => perPage >= 1,
        },
        totalItems: {
            type: Number,
            default: 0,
            validator: (page) => page >= 0,
        },
        limit: {
            type: Number,
            default: 5,
            validator: (page) => page > 0,
        },
        showPrevNextButtons: {
            type: Boolean,
            default: true
        },
        showFirstLastButtons: {
            type: Boolean,
            default: true
        },
        pageQueryString: {
            type: String,
            default: 'page'
        },
        perPageQueryString: {
            type: String,
            default: 'per_page'
        }
    },

    computed: {
        totalPages() {
            if (this.perPage <= 0) return 0;
            return Math.ceil(this.totalItems / this.perPage);
        },
        pages(){
            const lower = this.page - Math.round(this.limit / 2) + 1
            const lower2 = this.totalPages - this.limit + 1
            const from = Math.max(Math.min(lower, lower2), 1)
            const to = Math.min(from + this.limit - 1, this.totalPages)
            return Array.from({length: (to - from) + 1}, (v,k) => k + from)
        },
        startCount(){
            return Math.min(this.page * this.perPage - this.perPage + 1, this.totalItems)
        },
        endCount(){
            return Math.min(this.page * this.perPage, this.totalItems)
        }
    },

    methods: {
        goToPage(page){
            this.$emit('change', page)
        },
        validatePage(page){
            if(page > this.totalPages) page = this.totalPages
            if(page < 1) page = 1
            return page
        },
        getPaginatedRoute(page){
            page = this.validatePage(page)
            let route = {
                path: this.$route.path,
                query: { ...this.$route.query }
            }

            route.query[this.pageQueryString] = page
            if(page == 1) delete route.query[this.pageQueryString]

            return route
        },
        handlePageInput(event){
            let page = parseInt(event.target.value)
            if(page){
                this.$router.replace(this.getPaginatedRoute(page))
                this.goToPage(page)
                event.target.blur()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.page-btn {
    @apply transition-colors duration-100 flex justify-center items-center cursor-pointer rounded text-center text-green hover:bg-green hover:text-white px-1;

    min-width: 30px;
    min-height: 30px;

    &.active {
        @apply bg-green text-white;
    }

    &.disabled {
        @apply cursor-not-allowed bg-gray-200 hover:bg-gray-200 hover:text-green;
    }
}
</style>
