<template>
    <div class="flex flex-col p-6 w-full">
        <div class="mb-8 flex justify-between space-x-3">
            <h3>Create Gift Card</h3>
        </div>

        <GiftCardForm v-model="giftCard" />

        <div v-if="error" class="flex space-x-5 mb-4">
            <p class="text-red ml-1">{{ error }}</p>
        </div>

        <div class="flex space-x-5 mt-6">
            <router-link class="btn white px-6" :to="`/gift-cards`">Cancel</router-link>
            <button class="btn button-green px-6" @click="handleSave" :disabled="!canCreateGiftCard || saving">Save</button>
        </div>
    </div>
</template>

<script>
import GiftCardForm from '@components/giftcards/GiftCardForm'
import CSV from '@shared/mixins/csv'

export default {
    name: "CreateGiftCard",
    mixins: [CSV],

    components: {
        GiftCardForm,
    },

    data() {
        return {
            saving: false,
            giftCard: null,
            error: null,
        }
    },

    methods: {
        handleSave() {
            let singleGiftCardData = {
                amount: this.giftCard.amount,
                enabled: this.giftCard.enabled,
            };

            let data = {
                giftCards: Array(parseInt(this.giftCard.numCards)).fill(singleGiftCardData)
            };

            this.saving = true;

            this.$app.api.giftCards.createGiftCards(data)
                .then((response) => {
                    if (this.giftCard.csv) {
                        const giftCards = response.data;
                        const csvContent = this.generateCsvFromGiftCards(giftCards);
                        this.downloadCsv('giftcards', csvContent);
                    }
                    this.$router.push('/gift-cards');
                })
                .catch((error) => {
                    console.error(error);
                    this.error = error.error?.response?.data?.message ? error.error.response.data.message : 'Unknown error.';
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        generateCsvFromGiftCards(giftCards) {
            const headers = ['card_number', 'balance'];
            const csvRows = [headers.join(',')];

            for (const giftCard of giftCards) {
                const row = [
                    giftCard.card_number,
                    giftCard.balance
                ];
                csvRows.push(row.join(','));
            }

            return csvRows.join('\n');
        }
    },

    computed: {
        canCreateGiftCard() {
            return !!this.giftCard && this.$app.services.auth.hasPermission('giftcards.create')
        }
    },

}
</script>

<style lang="scss" scoped></style>