<template>
    <span class="badge" :class="{ small: small, [color]: color }">
        {{ text }}
    </span>
</template>

<script>
export default {
    props: {
        fulfillmentKey: {
            type: String,
            required: true
        },
        small: {
            type: Boolean,
            default: true
        },
    },

    computed: {
        color() {
            if (this.fulfillmentKey === 'in-person') return 'in-person-color'
            else if (this.fulfillmentKey === 'pre-assembled') return 'pre-assembled-color'
            else if (this.fulfillmentKey === 'delivery') return 'delivery-color'
            else if (this.fulfillmentKey === 'shipping') return 'shipping-color'
            else return 'default-color'
        },
        text() {
            if (this.fulfillmentKey === 'in-person') return 'In-person'
            else if (this.fulfillmentKey === 'pre-assembled') return 'Pre-assembled'
            else if (this.fulfillmentKey === 'delivery') return 'Delivery'
            else if (this.fulfillmentKey === 'shipping') return 'Shipping'
            else return 'Unknown'
        }
    }
}
</script>

<style lang="scss" scoped>
.in-person-color {
    background-color: #F6CDD7;
}

.pre-assembled-color {
    background-color: #FFD100;
}

.delivery-color {
    background-color: #AFDAB2
}

.shipping-color {
    background-color: #B2D4F2;
}

.default-color {
    background-color: #FFFFFF;
}
</style>
