<template>
  <div>
    <div
      class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200"
    >
      <h6 class="col-span-2 label">Employee</h6>
      <h6 class="col-span-2 label">Ordered on</h6>
      <h6 class="col-span-2 label">Order ID</h6>
      <h6 class="col-span-2 label">Location</h6>
      <h6 class="col-span-3 label">Promotion Code</h6>
      <h6 class="col-span-2 label">Payroll Deduction</h6>
    </div>
    <div
      class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md"
    >
      <div
        v-for="order in orders"
        class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2"
      >
        <div
          class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3"
        >
          <div class="xl:col-span-2">
            <p class="text-xs text-gray-400 xl:hidden">Employee</p>
            <template v-if="links && order.user_id">
              <router-link
                class="hover:underline block"
                :to="`/users/${order.user_id}`"
              >
                <p class="font-semibold">{{ order.name }}</p>
              </router-link>
            </template>
            <template v-else>
              <p class="font-semibold">{{ order.name }}</p>
            </template>
          </div>
          <div class="xl:col-span-2">
            <p class="text-xs text-gray-400 xl:hidden">Ordered on</p>
            <p>
              <Date :date="order.created_at" preset="DATE_FULL" />
            </p>
            <p>
              <Date
                :date="order.created_at"
                preset="TIME_SIMPLE_WITH_SHORT_OFFSET"
              />
            </p>
          </div>
          <div class="xl:col-span-2">
            <p class="text-xs text-gray-400 xl:hidden">Order ID</p>
            <template v-if="links">
              <router-link
                class="hover:underline block"
                :to="`/orders/${order.id}`"
              >
                <p class="font-semibold">{{ order.id }}</p>
              </router-link>
            </template>
            <template v-else>
              <p class="font-semibold">{{ order.id }}</p>
            </template>
          </div>
          <div class="xl:col-span-2">
            <p class="text-xs text-gray-400 xl:hidden">Location</p>
            <div v-for="location of order.locations">
              <template v-if="links">
                <router-link
                  class="hover:underline block"
                  :to="`/locations/${location.id}`"
                >
                  <p class="font-semibold">{{ location.name }}</p>
                </router-link>
              </template>
              <template v-else>
                <p class="font-semibold">{{ location.name }}</p>
              </template>
            </div>
          </div>
          <div class="xl:col-span-3">
            <p class="text-xs text-gray-400 xl:hidden">Promotion Code</p>
            <template v-if="links">
              <router-link
                class="hover:underline block"
                :to="`/promotions/${order.promotion_id}`"
              >
                <p>
                  <span class="badge medium">{{ order.promotion_code }}</span>
                </p>
              </router-link>
            </template>
            <template v-else>
              <p>
                <span class="badge medium">{{ order.promotion_code }}</span>
              </p>
            </template>
          </div>
          <div class="xl:col-span-2">
            <p class="text-xs text-gray-400 xl:hidden">
              Payroll Deduction
            </p>
            <p>{{ formattedDeduction(order.deduction) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListView from "@components/global/ListView";
import Date from "@components/global/dates/Date";

export default {
  props: {
    orders: {
      type: Array,
      default: function() {
        return [];
      }
    },
    links: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Array,
      default: function() {
        return [
          "Employee",
          "Ordered on",
          "Order ID",
          "Promotion ID",
          "Payroll Deduction"
        ];
      }
    }
  },

  components: {
    ListView,
    Date
  },

  methods: {
    formattedDeduction(deduction) {
      const value = parseFloat(deduction).toFixed(2);
      return deduction !== 0 ? `-$${value}` : `$${value}`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
