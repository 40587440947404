<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Create Calendar</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="mb-10">
                <div v-if="errors.length" class="flex space-x-5 mb-4">
                    <p class="text-red ml-1">{{ errors }}</p>
                </div>
                <div class="col-span-1">
                    <label class="block text-xs font-body text-gray-dark mb-2 ml-1">*Name:</label>
                    <input v-model="$v.name.$model"
                           type="text" class="cp-input mb-0"
                           :class="{'cp-input-error': $v.name.$error}">
                    <template v-if="$v.name.$error">
                        <p v-if="!$v.name.required" class="text-red text-xs ml-1">Name is required</p>
                    </template>
                </div>
                <div class="col-span-1">
                    <Textbox
                        v-model="$v.description.$model"
                        :class="{'cp-input-error': $v.description.$error}"
                        label="Description:"
                        placeholder=""
                    />
                    <template v-if="$v.description.$error">
                    </template>
                </div>
            </div>
            <div class="flex space-x-5">
                <button class="btn gray px-6" @click="close">Cancel</button>
                <button class="btn green px-6" @click="saveCalendar" :disabled="!canCreateCalendar || saving || success">
                    <div v-if="saving" class="flex items-center"><Loader class="w-5 mr-1"/><span>Saving</span></div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Saved!</span>
                    <span v-else>Save</span>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import Modal from '@components/global/modals/Modal'
import Loader from '@/components/global/Loader'
import Textbox from '@components/global/forms/Textbox'

export default {
    name: "CreateCalendarModal",

    components: {
        Modal,
        Loader,
        Textbox,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        location: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            saving: false,
            success: false,
            errors: [],
            name: null,
            description: null,
        }
    },

    computed: {
        canCreateCalendar() {
            return this.hasPermission && this.isValid
        },
        hasPermission() {
            return this.$app.services.auth.hasPermission('calendars.create')
        },
        isValid() {
            return !this.$v.$invalid
        },
    },

    methods: {
        close() {
            this.reset()
            this.$emit('close')
        },
        reset() {
            this.name = null
            this.description = null
            this.errors = []
            this.saving = false
            this.success = false
            this.$v.$reset()
        },
        saveCalendar() {
            let data = {
                key: this.name.trim(),
                name: this.name,
                description: this.description,
                location_id: this.location.id,
            }

            this.saving = true
            this.$app.api.calendars.createCalendar(data)
                .then((response) => {
                    this.success = true
                    setTimeout(() => {
                        this.close()
                        this.$emit('update', {calendar: response.data, location: this.location})
                    }, 250)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.saving = false
                })
        },
    },

    created() {},

    validations: {
        name: {required},
        description: {},
    }
}
</script>

<style lang="scss" scoped>
</style>
