<template>
    <div class="bg-gray-200 mb-5 rounded-md p-5">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div v-for="registration in unconfirmedRegistrations" :key="registration.id"
                class="bg-gray-100 rounded-md p-5">
                <div class="grid grid-cols-1 xs:grid-cols-2 gap-2 gap-y-4">
                    <div class="xs:col-span-2">
                        <h6 class="label">Participant</h6>
                        <p class="font-medium">{{ registration.guest.name | ucwords }}</p>
                        <p class="break-words">
                            <a :href="`mailto:${registration.guest.email}`" class="lowercase mr-2 hover:underline">
                                {{ registration.guest.email }}
                            </a>
                        </p>
                        <p v-if="registration.guest.phone">{{ registration.guest.phone }}</p>
                    </div>
                    <div class="xs:col-span-2">
                        <h6 class="label">ID</h6>
                        <router-link :to="`/registrations/${registration.id}`" class="hover:underline">
                            <p>{{ registration.id }}</p>
                        </router-link>
                    </div>
                    <div class="xs:col-span-2">
                        <h6 class="label">Order ID</h6>
                        <router-link v-if="registration.order_id" :to="`/orders/${registration.order_id}`"
                            class="hover:underline">
                            <p>{{ registration.order_id }}</p>
                        </router-link>
                    </div>
                    <div class="xs:col-span-2">
                        <h6 class="label">Class</h6>
                        <router-link :to="`/classes/${registration.class_event_id}`" class="hover:underline">
                            <p>{{ registration.class.sku }}</p>
                        </router-link>
                    </div>
                    <div class="xs:col-span-2">
                        <h6 class="label">Meal Size</h6>
                        <p>{{ registration.portion_size.key | ucwords }}</p>
                    </div>
                    <div class="xs:col-span-2">
                        <h6 class="label">Meal Qty</h6>
                        <p>{{ registration.portion_quantity.value }}</p>
                    </div>
                    <div class="xs:col-span-2">
                        <h6 class="label">Cost</h6>
                        <p class="font-medium">{{ registration.total | currency }}</p>
                    </div>
                </div>

                <!-- Create class and delete class button -->
                <div class="flex justify-between mt-4">
                    <button @click="$router.push({ name: 'registration edit', params: { id: registration.id } });"
                        class="btn cp-btn cp-btn-green-border text-sm mr-2">
                        <span>Edit Selections</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UnconfirmedRegistrationsGridView',

    props: {
        unconfirmedRegistrations: {
            type: Array,
            required: true
        }
    },
}
</script>