<template>
    <component :is="layout"></component>
</template>

<script>
import AdminLayout from '@/layouts/Admin'
import DefaultLayout from '@/layouts/Default'
import LoginLayout from '@/layouts/Login'

export default {
    name: 'App',

    components: {
        AdminLayout,
        DefaultLayout,
        LoginLayout,
    },

    data(){
        return {
            ready: false
        }
    },

    computed: {
        layout(){
            if(this.ready){
                switch(this.$route.meta?.layout){
                    case 'login': return 'LoginLayout'
                    default: return 'AdminLayout'
                }
            }
            return 'DefaultLayout'
        }
    },

    methods: {
        onAuthReady(){
            this.ready = true
        },
        getLocations() {
            this.$app.store.dispatch('locations/getLocations', {})
        },
    },

    created(){
        this.getLocations()
        this.$app.events.$on('auth-ready', this.onAuthReady)
    },

    beforeDestroy(){
        this.$app.events.$off('auth-ready', this.onAuthReady)
    }
}
</script>

<style lang="scss" scoped>
</style>
