const routes = [
    {
        path: '/payments/:id/refund',
        name: 'refund-payment',
        component:  () => import('@views/payments/Refund.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['payments.refund']
        }
    },
]

export default routes
