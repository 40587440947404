<template>
    <div>
        <div class="hidden lg:grid grid-cols-2 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <div class="flex items-center space-x-3">
                <p class="text-xs text-gray-400 font-medium">Meal</p>
            </div>
            <p class="text-xs text-gray-400 font-medium">Quantity</p>
        </div>
        <div class="lg:divide-y lg:divide-gray-200 lg:border lg:border-t-0 border-gray-200 lg:border-gray-200 rounded-b-md">
            <div v-for="selection in selections" :key="selection.id" class="bg-gray-100 mb-5 lg:mb-0 rounded-md lg:rounded-none lg:bg-transparent px-5 py-3 lg:px-0 lg:py-2 lg:hover:bg-gray-100">
                <div class="grid grid-cols-2 gap-2 lg:px-2">
                    <div>
                        <p class="text-xs text-gray-400 lg:hidden">Meal</p>
                        <div class="flex items-center">
                            <p>{{ selection.meal.name }}</p>
                        </div>
                    </div>
                    <div>
                        <p class="text-xs text-gray-400 lg:hidden">Quantity</p>
                        <p>{{ selection.quantity }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        selections: {
            type: Array,
            required: true
        }
    },

    data(){
        return {
            selected: []
        }
    },

    computed: {
        canUpdateRegistrations(){
            return this.$app.services.auth.hasPermissions([
                'registrations.update'
            ])
        },
        allSelected(){
            return this.selected.length == this.selections.length
        },
        subsetSelected(){
            return this.selected.length > 0 && this.selected.length != this.selections.length
        }
    },

    methods: {
        checkSelection(event){
            const id = parseInt(event.target.value)
            const idx = this.selected.findIndex(existing => existing == id)
            if(idx >= 0) this.selected.splice(idx, 1)
            else this.selected.push(id)
        },
        checkAll(event){
            if(this.allSelected) this.selected = []
            else this.selected = this.selections.map(sel => sel.id)
        },
    }
}
</script>

<style>

</style>
