import AuthService from '@shared/app/services/auth'
import firebase from "firebase/app";

export default class Auth extends AuthService {

  _addRouterGuard(){
    this.router.beforeEach((to, from, next) => {
      const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
      const user = this.store.state.user.user

      if (requiresAuth && !this.hasPermission('websites.admin.access')) {
        return this.logout().then(() => {
          if (to.path !== '/login') {
            next('/login')
          }
        })
      }

      if (to.meta.redirectWhenLoggedIn && user.id){
        if (to.path !== '/dashboard') {
          next('/dashboard');
        }
        return
      }

      if (to.meta.permissions?.length){
        if(!this.hasOneOfPermissions(to.meta.permissions)){
          this.events.$emit('alert', {title: 'Unauthorized', message: 'You are not authorized to view this page.'})
          next(false)
          return
        }
      }

      if (to.meta.roles?.length){
        if(!this.hasOneOfRoles(to.meta.roles)){
          this.events.$emit('alert', {title: 'Unauthorized', message: 'You are not authorized to view this page.'})
          next(false)
          return
        }
      }

      next();
    });
  }

  isUserSignedIn() {
    return !!this.firebase.currentUser;
  }

  /**
     * Sends an email with a sign-in link, and stores the email locally for verification
     * @param {string} email
     * @param {string} url
     * Returns null if successful, and an error string if something went wrong
     * @returns {string|null}
   */
  async sendPasswordlessSignInEmail(email, url) {
    const actionCodeSettings = {
      url,
      handleCodeInApp: true,
    }
    try {
      await this.api.users.syncFirebaseEmail(email)
      await this.api.users.sendFirebaseSignInEmail(email, actionCodeSettings)
      // For security reasons, we need to ask for the email again when the user clicks the link
      // But we can save the email locally so if they sign in on this device, we don't need to prompt for an email
      localStorage.setItem('CPEmailForSignIn', email)
    } catch (error) {
      console.error('Email failed to send: ', error)
      let errorMessage = ''
      if (error.message.includes('invalid-email')) {
        errorMessage = 'Please enter a valid email address'
      } else {
        errorMessage = 'An unexpected error occurred. Please try again later, or try a different sign-in method'
      }
      throw new Error(errorMessage)
    }
  }

  async sendSignInCodeText(mobileNumber, widgetId, appVerifier, grecaptcha) {
    try {
      const confirmationResult = await this.firebase.signInWithPhoneNumber(mobileNumber, appVerifier);
      window.confirmationResult = confirmationResult
      return confirmationResult
    } catch (error) {
      console.error('Text failed to send: ', error)
      grecaptcha.reset(widgetId)
      throw error
    }
  }

  async loginWithEmailLink(email, currentUrl) {
    let result = null
    try {
      result = await this.firebase.signInWithEmailLink(email, currentUrl)
      this.preventAutomatedStateChangeActions = true
      await this.login()
      localStorage.removeItem('CPEmailForSignIn')
      return { status: 'success' }
    } catch (error) {
      if (error.response?.status === 401 && !!result) {
        // Valid sign-in method, unrecognized credential
          return { status: 'error' }
      } else {
        console.error('Error signing in with email link', error)
        return { status: 'error' }
      }
    }
  }

  async loginWithGoogleSignIn() {
    let result = null
    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      result = await this.firebase.signInWithPopup(provider)
      const credential = firebase.auth.GoogleAuthProvider.credential(result.credential.idToken)
      this.preventAutomatedStateChangeActions = true
      await this.login()
      return 'success'
    } catch (error) {
      if (error.status === 401) {
        // Valid sign-in method, unrecognized credential
        // check if email matches email in database
        try {
          const response = await this.api.users.getUidFromMatchingAccount({uid: result?.user.uid})
          const uidFromMatchingAccount = response.data
          if (!uidFromMatchingAccount) {
            console.log('no user found')
            return 'no-user'
          } else {
            // User found, link this credential to that user
            if (!!result) {
              await this.api.users.linkNewUidToExistingUid(result.user.uid, uidFromMatchingAccount)
            }
            // User needs to re-login to get the new credential
            return 're-login'
          }
        } catch (error) {
          console.error(error)
          return 'error'
        }
      }
      console.error('Error signing in with Google', error)
      return 'error'
    }
  }
}
