import Resource from './resource'

export default class Orders extends Resource {

    /**
     * Get orders
     * @param {object} params query string params
     */
     getOrders(params = {}){
        return this.axios.get(`orders`, { params })
    }

    /**
     * Get an order
     * @param {string|int} id order ID
     */
    getOrder(id) {
        return this.axios.get(`orders/${id}`)
    }

    getCharityDonations(params = {}) {
        return this.axios.get(`orders/charity-donations`, { params })
    }

    /**
     * Update an order
     * @param {string|int} id order ID
     * @param {object} data update data
     */
    updateOrder(id, data){
        return this.axios.put(`orders/${id}`, data)
    }

    /**
     * Get order statuses
     */
    getStatuses(){
        return this.axios.get(`orderStatuses`)
    }
}
