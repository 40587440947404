<template>
    <div>
        <div class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-light">
            <h6 class="col-span-3 label">Name</h6>
            <h6 class="col-span-2 label">Discount</h6>
            <h6 class="col-span-1 label">Use Count</h6>
            <h6 class="col-span-2 label">Start Date</h6>
            <h6 class="col-span-2 label">End Date</h6>
            <h6 class="col-span-1 label">Status</h6>
            <h6 class="col-span-1 label"></h6>
        </div>
        <div class="xl:divide-y xl:divide-gray-light xl:border xl:border-t-0 border-gray-200 xl:border-gray-light rounded-b-md">
            <div v-for="promotion in promotions" :key="promotion.id" class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-3 xl:grid-cols-12 gap-2 xl:px-3">
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Name</h6>
                        <router-link class="hover:underline block mr-2" :to="`/promotions/${promotion.id}`">
                            <h5>{{ promotion.name }}</h5>
                        </router-link>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Discount</h6>
                        <p>{{ discount(promotion) }}</p>
                    </div>
                    <div class="xl:col-span-1">
                        <h6 class="label xl:hidden">Use Count</h6>
                        <p>{{ promotion.uses }}</p>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Start Date</h6>
                        <template v-if="promotion.start">
                            <p><Date :date="promotion.start" preset="DATE_FULL"/></p>
                            <p><Date :date="promotion.start" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
                        </template>
                        <template v-else>
                            <p><br></p>
                        </template>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">End Date</h6>
                        <template v-if="promotion.end">
                            <p><Date :date="promotion.end" preset="DATE_FULL"/></p>
                            <p><Date :date="promotion.end" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
                        </template>
                        <template v-else>
                            <p><br></p>
                        </template>
                    </div>
                    <div class="xl:col-span-1">
                        <h6 class="label xl:hidden">Status</h6>
                        <p><StatusBadge class="text-xs" :promotion="promotion"/></p>
                    </div>
                    <div class="xl:col-span-1">
                        <div v-if="canDeletePromotion" class="flex justify-start">
                            <button class="btn xs gray" @click="deletePromotion(promotion)">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from '@components/promotions/StatusBadge'
import Date from '@components/global/dates/Date'

export default {
    name: "PromotionsListView",

    props: {
        promotions: {
            type: Array,
            required: true
        }
    },

    components: {
        StatusBadge,
        Date,
    },

    computed: {
        canEditPromotion() {
            return this.$app.services.auth.hasPermission('promotions.update')
        },
        canDeletePromotion() {
            return this.$app.services.auth.hasPermission('promotions.delete')
        },
    },

    methods: {
        discount(promotion) {
            if(!promotion.discount_percent || promotion?.discount_percent === '0.00') return `$${promotion.discount_amount}`
            else return `${parseInt(promotion.discount_percent)}%`
        },
        editPromotion(promotion) {
            this.$emit('edit', promotion)
        },
        deletePromotion(promotion) {
            this.$emit('delete', promotion)
        },
    },
}
</script>

<style scoped>

</style>
