<template>
    <div class="flex items-center mb-1">
        <input :id="id" type="radio" name="radio" class="hidden" :checked="isChecked" :value="value" @change="change"/>
        <label :for="id" class="flex items-center cursor-pointer">
            <span class="w-4 h-4 inline-block mr-2 rounded-full border border-grey flex-shrink-0"></span>
            <slot>
                {{ label }}
            </slot>
        </label>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'modelValue',
        event: 'change'
    },

    props: {
        value: {
            type: Boolean|String,
            required: true
        },
        label: {
            type: String,
            default: 'Label'
        },
        modelValue: {
            type: Boolean|String,
            default: null
        }
    },

    data(){
        return {
            id: Math.random().toString(36).substr(2, 5)
        }
    },

    computed: {
        isChecked(){
            return this.modelValue == this.value
        },
    },

    methods: {
        change(event){
            let value = event.target.value
            if(value == 'true') value = true
            if(value == 'false') value = false
            this.$emit('change', value)
        }
    }
}
</script>

<style>

</style>
