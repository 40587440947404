<template>
    <div>
        <template v-if="!enabledBalance && !disabledBalance">
            <button class="btn disabled:" @click="getBalance" :disabled="loading || success">
                <div v-if="loading" class="flex items-center space-x-1">
                    <Loader class="w-5 h-5" /><span>Getting Balance</span>
                </div>
                <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Success!</span>
                <span v-else>Get Balance</span>
            </button>
        </template>
        <template v-else>
            <div class="flex items-center">
                <button class="btn mr-2" @click="sync"><i class="fas fa-sync-alt"></i></button>
                <p>Outstanding Balance: <span>{{ enabledBalance | currency }}</span></p>
                <p>Disabled Balance: <span>{{ disabledBalance | currency }}</span></p>
            </div>
        </template>
    </div>
</template>

<script>
import Loader from '@components/global/Loader'

export default {
    name: "GiftCardReport",

    components: {
        Loader,
    },

    data() {
        return {
            loading: false,
            success: false,
            enabledBalance: null,
            disabledBalance: null,
        }
    },

    computed: {},

    methods: {
        sync() {
            this.enabledBalance = null
            this.disabledBalance = null
            this.getBalance()
        },
        getBalance() {
            this.enabledBalance = false
            this.disabledBalance = false
            this.loading = true
            this.$app.api.reports.getGiftCardOutstandingBalance()
                .then((response) => {
                    this.success = true
                    setTimeout(() => {
                        this.enabledBalance = response.data.enabled
                        this.disabledBalance = response.data.disabled
                        this.success = false
                    }, 1000)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },

    created() { },

    mounted() { },
}
</script>

<style scoped></style>
