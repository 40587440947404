
export default class Permission {

    /**
     * @param {string} permission
     */
    constructor(permission){
        this.wildcardToken = '*'
        this.partDelimiter = '.'
        this.subpartDelimiter = ','

        this.permission = permission
        this.parts = []

        this.setParts()
    }


    setParts(){
        let parts = this.permission.split(this.partDelimiter)
        for(let part of parts){
            let subparts = part.split(this.subpartDelimiter)
            if(subparts.length == 0 || subparts.includes('')) throw `Invalid permission subpart format: ${this.permission}`

            this.parts.push(subparts)
        }

        if(this.parts.length == 0) throw `Invalid permission format: ${this.permission}`
    }

    containsAll(parts, otherParts){
        for(let otherPart of otherParts){
            if(!parts.includes(otherPart)) return false
        }
        return true
    }

    implies(permission){
        if(typeof permission === 'string') permission = new this.constructor(permission)

        let i = 0
        for(const otherPart of permission.parts){
            if(this.parts.length - 1 < i) return true

            const part = this.parts?.[i]
            if(part && Array.isArray(part) && Array.isArray(otherPart)){
                if(!part.includes(this.wildcardToken) && !this.containsAll(part, otherPart)){
                    return false
                }
            }

            i += 1
        }

        for(i; i < this.parts.length; i++){
            const part = this.parts?.[i]
            if(part && Array.isArray(part) && !part.includes(this.wildcardToken)) return false
        }

        return true
    }
}
