<template>
    <div>
        <!-- Modals -->
        <CreateCalendarModal v-if="location" :visible="modals.calendar" :location="location"
            @close="modals.calendar = false" @update="handleNewCalendar" />

        <CreateEventModal v-if="location && location.calendar && day" :visible="modals.event"
            :calendarId="location.calendar.id" :day="day" @close="reset" @update="handleEventUpdate" />

        <BlockEventModal v-if="location && location.calendar && blocking" :visible="modals.block"
            :calendarId="location.calendar.id" :days="blockedDays" @close="reset" @update="handleEventUpdate" />

        <EditEventModal v-if="selectedEvent" :visible="modals.eventEdit" :event="selectedEvent" :day="day"
            @update="handleEventEdit" @close="modals.eventEdit = false" />

        <ConfirmEventDeleteModal v-if="selectedEvent" :visible="modals.deleteEvent" :event="selectedEvent"
            @update="handleEventDelete" @close="modals.deleteEvent = false" />

        <ConfirmCalendarDeleteModal v-if="selectedCalendar" :visible="modals.deleteCalendar"
            :calendar="selectedCalendar" @update="handleCalendarDelete" @close="modals.deleteCalendar = false" />

        <!-- Calendars -->
        <template v-if="loading.locations">
            <div class="flex items-center space-x-5">
                <Loader class="w-10 h-10 text-green" />
                <p class="text-green">Loading locations & calendars...</p>
            </div>
        </template>
        <template v-else-if="!loading.locations && locations">
            <div>
                <div class="px-6 md:px-0 mb-6">
                    <p class="text-sm font-bold text-green uppercase mb-2">Location</p>
                    <multiselect class="multiselect w-full md:w-1/2 lg:w-1/4" v-model="location" :options="locations"
                        :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                        :taggable="false" placeholder="Search Locations" label="name" track-by="name"
                        :group-select="false" group-label="state" group-values="locations" @input="onLocationSelect">
                        <template slot="placeholder"><span>Select Location</span></template>
                        <template slot="caret" slot-scope="{toggle}">
                            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                <img class="absolute top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] select-none"
                                    src="/img/icons/down-chevron.svg" alt="icon">
                            </div>
                        </template>
                    </multiselect>
                </div>
                <template v-if="loading.calendar">
                    <div class="flex items-center space-x-5">
                        <Loader class="w-10 h-10 text-green" />
                        <p class="text-green">Loading calendar...</p>
                    </div>
                </template>
                <template v-else>
                    <div v-if="location" class="mb-4">
                        <p class="text-xs text-gray-400">Allow Custom Classes:</p>
                        <p class="capitalize">{{ location.allow_custom_class }}</p>
                    </div>
                    <div v-if="location && location.calendar && !loading.calendar">
                        <div class="mb-4">
                            <div class="mb-2">
                                <p class="text-xs text-gray-400">Name:</p>
                                <p>{{ location.calendar.name }}</p>
                            </div>
                            <div>
                                <p class="text-xs text-gray-400">Description:</p>
                                <p>{{ location.calendar.description }}</p>
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <div class="mb-2">
                                <!-- TODO: create a calendar edit/create form and update the modal to handle both-->
                                <!-- <button v-if="canEditCalendar" class="btn gray xs" :disabled="!canEditCalendar" @click="modals.editCalendar = true">Delete Calendar</button>-->
                                <button v-if="canDeleteCalendar" class="btn gray xs" :disabled="!canDeleteCalendar"
                                    @click="modals.deleteCalendar = true">Delete Calendar</button>
                            </div>
                            <v-calendar ref="calendar" class="mb-4" trim-weeks :attributes="attributes"
                                :min-date="startDate" @dayclick="handleDayClick" />
                        </div>
                        <div v-if="day">
                            <div class="flex mb-4">
                                <div class="mr-2">
                                    <button class="btn green" :disabled="!selectedDateValid"
                                        @click="modals.event = true">Add New Event</button>
                                </div>
                                <div>
                                    <button class="btn white" @click="reset">Cancel</button>
                                </div>
                            </div>

                            <template v-if="!loading.eventStatuses && events.length">
                                <ListView :events="events" />
                            </template>
                        </div>
                        <div v-else-if="blocking" class="flex mb-4">
                            <div class="mr-2">
                                <button class="btn green" :disabled="!validBlockDates"
                                    @click="modals.block = true">Block Dates</button>
                            </div>
                            <div>
                                <button class="btn white" @click="reset">Cancel</button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="mb-4">
                                <button class="btn green" :disabled="day || blocking" @click="blocking = true">Block
                                    out multiple
                                    days</button>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="location && !location.calendar">
                        <p>No Calendar found for this location.</p>
                        <button class="btn green" :disabled="!canCreateCalendar" @click="modals.calendar = true">Add
                            Calendar</button>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="flex items-center space-x-5">
                <p>Oops, something went wrong...</p>
            </div>
        </template>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { calendarEventStatus } from '@shared/utils/constants'
import Loader from '@components/global/Loader'
import PopoverRow from 'v-calendar/lib/components/popover-row.umd.min'
import EventCard from '@components/calendars/EventCard'
import ListView from '@components/calendars/modals/ListView'
import CreateEventModal from '@components/calendars/modals/CreateEventModal'
import CreateCalendarModal from '@components/calendars/modals/CreateCalendarModal'
import BlockEventModal from '@components/calendars/modals/BlockEventModal'
import EditEventModal from '@components/calendars/modals/EditEventModal'
import ConfirmEventDeleteModal from '@components/calendars/modals/ConfirmEventDeleteModal'
import ConfirmCalendarDeleteModal from '@components/calendars/modals/ConfirmCalendarDeleteModal'

export default {
    name: "Calendars",

    metaInfo() {
        return {
            title: this.title,
        }
    },

    components: {
        Loader,
        PopoverRow,
        BlockEventModal,
        CreateEventModal,
        CreateCalendarModal,
        EditEventModal,
        ConfirmEventDeleteModal,
        ConfirmCalendarDeleteModal,
        EventCard,
        ListView,
    },

    props: {},

    data() {
        return {
            errors: [],
            loading: {
                locations: false,
                eventStatuses: false,
                calendar: false,
            },
            locations: null,
            location: null,
            day: null,
            blockedDays: [],
            blocking: false,
            modals: {
                event: false,
                block: false,
                eventEdit: false,
                deleteEvent: false,
                deleteCalendar: false,
                calendar: false,
            },
            selectedEvent: null,
        }
    },

    computed: {
        canCreateCalendar() {
            return this.$app.services.auth.hasPermission('calendars.create')
        },
        canDeleteCalendar() {
            return this.$app.services.auth.hasPermission('calendars.delete')
        },
        startDate() {
            return DateTime.now().startOf('day').toJSDate()
        },
        selectedDateValid() {
            if (!this.day) return false
            return DateTime.fromJSDate(this.day.date) >= DateTime.fromJSDate(this.startDate)
        },
        validBlockDates() {
            return this.blockedDays.length > 0
        },
        events() {
            let events = []

            if (this.day && this.day?.attributes) {
                this.day.attributes.forEach((attribute) => {
                    if (attribute.customData) events.push(attribute.customData)
                })
            }
            return events
        },
        attributes() {
            let attributes = []

            if (this.location && this.location?.calendar?.events) {
                if (this.day) {
                    attributes.push({
                        key: 'selected-day',
                        dates: this.day.range,
                        highlight: 'blue',
                        popover: false
                    })
                } else if (this.blockedDays.length) {
                    this.blockedDays.forEach((date) => {
                        attributes.push({
                            key: 'blocked-day',
                            dates: { start: date, end: date },
                            highlight: 'blue',
                            popover: false
                        })
                    })
                }
                this.location?.calendar?.events.forEach((event) => {
                    let range = {
                        start: DateTime.fromISO(event.start_time).toJSDate(),
                        end: DateTime.fromISO(event.end_time).toJSDate()
                    }
                    attributes.push({
                        key: event.key,
                        dates: range,
                        bar: this.eventColor(event.status_id),
                        customData: event,
                        popover: false,
                    })
                });
            }
            return attributes
        },
        selectedCalendar() {
            if (this.location) return this.location.calendar
            return null
        },
    },

    methods: {
        eventColor(statusId) {
            switch (statusId) {
                case calendarEventStatus.ENABLED:
                    return 'green'
                case calendarEventStatus.DISABLED:
                    return 'gray'
                case calendarEventStatus.PENDING:
                    return 'yellow'
                case calendarEventStatus.DENIED:
                    return 'red'
                case calendarEventStatus.CANCELED:
                    return 'gray'
                default:
                    return 'blue'
            }
        },
        eventStatus(event) {
            return event.attributes[0]?.customData.status.id === calendarEventStatus.PENDING
        },
        onLocationSelect(event) {
            this.day = null
        },
        handleDayClick(date) {
            if (this.blocking) {
                this.blockedDays.push(date)
                return
            } else {
                this.day = date
            }
        },
        handleNewCalendar(calendar) {
            this.getLocations()
        },
        handleEventUpdate(calendarId) {
            this.getCalendar(calendarId)
        },
        handleEventDelete(event) {
            this.handleEventUpdate(this.location.calendar.id)
            this.selectedEvent = null
        },
        handleCalendarDelete(event) {
            this.getLocation()
        },
        handleEventEdit(event) {
            this.handleEventUpdate(this.location.calendar.id)
            this.selectedEvent = null
        },
        getLocations() {
            let params = {
                active: true,
                relations: 'address|calendar|calendar.events|calendar.events.status',
            }

            this.loading.locations = true
            return this.$app.api.locations.getLocations(params)
                .then((response) => {
                    this.locations = this.$app.services.location.getOrderedLocations(response.data, false)
                    if (this.location) this.location = response.data.filter((location) => { return location.id === this.location.id })[0]
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading.locations = false
                })
        },
        getLocation(locationId) {
            let params = {
                active: true,
                relations: 'address|calendar|calendar.events|calendar.events.status',
            }

            this.loading.locations = true
            return this.$app.api.locations.getLocation(locationId, params)
                .then((response) => {
                    this.location.calendar = response.data.calendar
                    this.locations.forEach((location) => {
                        if (location.id === response.data.id) location.calendar = response.data.calendar
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading.locations = false
                })
        },
        getEventStatuses() {
            this.loading.eventStatuses = true
            return this.$app.api.calendars.getEventStatuses()
                .then((response) => {
                    this.$app.store.commit('calendars/setStatuses', { event: response.data })
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading.eventStatuses = false
                })
        },
        getCalendar(calendarId) {
            this.loading.calendar = true
            return this.$app.api.calendars.getCalendar(calendarId)
                .then((response) => {
                    this.location.calendar = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading.calendar = false

                    if (this.day) {
                        this.$nextTick(() => {
                            this.$refs.calendar.move(this.day.date, { transition: 'none' })
                            this.day = null
                        })
                    }
                })
        },
        reset() {
            this.day = null
            this.blockedDays = []
            this.blocking = false
            this.selectedEvent = null
            this.modals.event = false
            this.modals.block = false
        },
    },

    created() {
        this.getLocations()
        this.getEventStatuses()

        this.$app.events.$on('calendar-event-edit', (payload) => {
            this.selectedEvent = payload
            this.modals.eventEdit = true
        })
        this.$app.events.$on('calendar-event-delete', (payload) => {
            this.selectedEvent = payload
            this.modals.deleteEvent = true
        })
    },

    mounted() { },

    beforeDestroy() {
        this.$app.events.$off('calendar-event-edit', (payload) => { console.log(payload) })
        this.$app.events.$off('calendar-event-delete', (payload) => { console.log(payload) })
    },
}
</script>

<style lang="scss" scoped></style>
