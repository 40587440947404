const routes = [
    {
        path: "/announcements/create",
        name: 'announcements.create',
        component: () => import('@views/announcements/CreateAnnouncement.vue'),
        meta: {
            requiresAuth: true,
            roles: [],
            permissions: ['announcements.create']
        }
    },
]

export default routes
