<template>
  <div class="md:grid grid-cols-1 gap-4">
    <div class="col-span-1">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Name*:</label>
      <input
        v-model="$v.promotion.name.$model"
        type="text"
        class="cp-input mb-0"
        :class="{ 'cp-input-error': $v.promotion.name.$error }"
      />
      <template v-if="$v.promotion.name.$error">
        <p v-if="!$v.promotion.name.required" class="text-red text-xs ml-1">
          Promotion name is required
        </p>
      </template>
    </div>
    <div class="col-span-1">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
        >Display Name:</label
      >
      <input
        v-model="$v.promotion.display_name.$model"
        type="text"
        class="cp-input mb-0"
        :class="{ 'cp-input-error': $v.promotion.display_name.$error }"
      />
      <template v-if="$v.promotion.display_name.$error"> </template>
    </div>
    <div class="col-span-1">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Status:</label>
      <Checkbox v-model="$v.promotion.enabled.$model" label="enabled" />
    </div>
    <div class="col-span-1">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Type*:</label>
      <Radio :label="`Percent`" value="percent" v-model="$v.promotion.type.$model" />
      <Radio :label="`Amount`" value="amount" v-model="$v.promotion.type.$model" />
      <template v-if="$v.promotion.type.$error">
        <p v-if="!$v.promotion.type.required" class="text-red text-xs ml-1">
          Promotion type is required
        </p>
      </template>
    </div>
    <div class="col-span-1">
      <div class="flex">
        <DateRangePicker
          class="mr-2"
          label="Dates:"
          :time="true"
          :value="dateRange"
          :default="defaultDateRange"
          @input="dateRangeChanged"
        />
      </div>
    </div>
    <div class="col-span-1">
      <Textbox
        v-model="$v.promotion.description.$model"
        class="w-5/12"
        :class="{ 'cp-input-error': $v.promotion.description.$error }"
        label="Description:"
        placeholder=""
      />
      <template v-if="$v.promotion.description.$error"> </template>
    </div>
    <div class="col-span-1">
      <Textbox
        v-model="$v.promotion.terms.$model"
        class="w-5/12"
        :class="{ 'cp-input-error': $v.promotion.terms.$error }"
        label="Terms:"
        placeholder=""
      />
      <template v-if="$v.promotion.terms.$error"> </template>
    </div>
    <template v-if="promotion.type === 'percent'">
      <div class="col-span-1">
        <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
          >Discount Percent*:</label
        >
        <input
          v-model="$v.promotion.discount_percent.$model"
          type="number"
          class="cp-input mb-0"
          :class="{ 'cp-input-error': $v.promotion.discount_percent.$error }"
        />
        <template v-if="$v.promotion.discount_percent.$error">
          <p v-if="!$v.promotion.discount_percent.required" class="text-red text-xs ml-1">
            Discount percent is required
          </p>
        </template>
      </div>
      <div class="col-span-1">
        <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Min Total:</label>
        <input
          v-model="$v.promotion.min_total.$model"
          type="number"
          class="cp-input mb-0"
          :class="{ 'cp-input-error': $v.promotion.min_total.$error }"
        />
        <template v-if="$v.promotion.min_total.$error"> </template>
      </div>
      <div class="col-span-1">
        <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
          >Max Discount Amount*:</label
        >
        <input
          v-model="$v.promotion.max_discount.$model"
          type="number"
          class="cp-input mb-0"
          :class="{ 'cp-input-error': $v.promotion.max_discount.$error }"
        />
        <template v-if="$v.promotion.max_discount.$error"> </template>
      </div>
    </template>
    <template v-else-if="promotion.type === 'amount'">
      <div class="col-span-1">
        <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
          >Discount Amount*:</label
        >
        <input
          v-model="$v.promotion.discount_amount.$model"
          type="number"
          class="cp-input mb-0"
          :class="{ 'cp-input-error': $v.promotion.discount_amount.$error }"
        />
        <template v-if="$v.promotion.discount_amount.$error">
          <p v-if="!$v.promotion.discount_amount.required" class="text-red text-xs ml-1">
            Discount percent is required
          </p>
        </template>
      </div>
    </template>
    <div class="col-span-1" v-if="promotion.type">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Max Uses:</label>
      <input
        v-model="$v.promotion.max_uses.$model"
        type="number"
        class="cp-input mb-0"
        :class="{ 'cp-input-error': $v.promotion.max_uses.$error }"
      />
      <template v-if="$v.promotion.max_uses.$error"> </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { DateTime } from 'luxon'
import { required, requiredIf } from 'vuelidate/lib/validators'
import Textbox from '@/components/global/forms/Textbox'
import Checkbox from '@components/global/forms/Checkbox'
import Radio from '@components/global/forms/Radio'
import DateRangePicker from '@components/global/dates/DateRangePicker'

export default {
  name: 'PromotionForm',
  components: {
    DateRangePicker,
    Radio,
    Checkbox,
    Textbox,
  },

  props: {
    value: {
      default: null,
    },
  },

  data() {
    return {
      promotion: {
        name: null,
        display_name: null,
        description: null,
        start: null,
        end: null,
        terms: null,
        enabled: true,
        max_uses: null,
        min_total: null,
        max_discount: null,
        discount_percent: null,
        discount_amount: null,
        type: null,
      },
      dateRange: this.getDefaultDateRange(),
      defaultDateRange: this.getDefaultDateRange(),
    }
  },

  methods: {
    getDefaultDateRange() {
      return {
        start: null,
        end: null,
      }
    },
    dateRangeChanged(input) {
      const range = input.range
      this.dateRange = range
      this.promotion.start = range.start
      this.promotion.end = range.end
    },
    emitPromotion() {
      if (this.promotion.type === 'percent') {
        this.promotion.discount_amount = null
      } else {
        this.promotion.discount_percent = null
      }

      if (this.promotion.start) this.promotion.start = this.promotion.start.toUTC()
      if (this.promotion.end) this.promotion.end = this.promotion.end.toUTC()

      if (this.isValid) this.$emit('input', this.promotion)
    },
  },

  computed: {
    isValid() {
      return !this.$v.promotion.$invalid
    },
  },

  watch: {
    promotion: {
      handler(value) {
        this.debouncedEmit()
      },
      deep: true,
    },
  },

  created() {
    this.debouncedEmit = _.debounce(this.emitPromotion, 500)

    if (this.value) {
      this.promotion = JSON.parse(JSON.stringify(this.value))
      if (this.value.start) this.promotion.start = DateTime.fromISO(this.value.start)
      if (this.value.end) this.promotion.end = DateTime.fromISO(this.value.end)

      this.dateRange = {
        start: this.value.start ? DateTime.fromISO(this.value.start) : null,
        end: this.value.end ? DateTime.fromISO(this.value.end) : null,
      }

      if (this.value.discount_amount) {
        this.promotion.type = 'amount'
      } else if (this.value.discount_percent) {
        this.promotion.type = 'percent'
      } else {
        this.promotion.type = null
      }
    }
  },

  validations: {
    promotion: {
      name: { required },
      display_name: {},
      description: {},
      start: {},
      end: {},
      terms: {},
      enabled: {},
      max_uses: {},
      min_total: {},
      max_discount: {
        required: requiredIf((form) => {
          return form.type === 'percent'
        }),
      },
      discount_percent: {
        required: requiredIf((form) => {
          return form.type === 'percent'
        }),
      },
      discount_amount: {
        required: requiredIf((form) => {
          return form.type === 'amount'
        }),
      },
      type: { required },
    },
  },
}
</script>

<style lang="scss" scoped>
.promotion-multiselect::v-deep {
  .multiselect {
    min-height: 42px;
  }

  .multiselect__tags {
    min-height: 42px;
  }

  .multiselect__placeholder {
    padding-top: 0;
  }
}
</style>
