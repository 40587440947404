import Service from "./service";
import Permission from "../models/permission";
import firebase from "firebase/app";
import "firebase/auth";

export default class Auth extends Service {
  constructor(app) {
    super(app);

    this._firebaseLoaded = false;

    this._addRouterGuard();
    this.api.setAuthTokenCallback(this.getAuthToken.bind(this));
    this._initializeFirebase();
  }

  _initializeFirebase() {
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
    };

    firebase.initializeApp(firebaseConfig);
    this.firebase = firebase.auth();
    this.firebase.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    this._subscribeToAuthStateChanges();

    // https://stackoverflow.com/questions/37517208/firebase-kicks-out-current-user/38013551#38013551
    this.secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");
  }

  getFirebaseAuth() {
    return this.firebase;
  }

  getFirebaseApp() {
    return this.appInstance;
  }

  getSecondaryFirebaseApp() {
    return this.secondaryApp;
  }

  _addRouterGuard() { }

  _getExternalAuthProvider(providerName) {
    switch (providerName) {
      case "google":
        return new firebase.auth.GoogleAuthProvider();
      case "facebook":
        return new firebase.auth.FacebookAuthProvider();
      case "github":
        return new firebase.auth.GithubAuthProvider();
      case "twitter":
        return new firebase.auth.TwitterAuthProvider();
      case "apple":
        let appleProvider = new firebase.auth.OAuthProvider("apple.com");
        appleProvider.addScope("email");
        appleProvider.addScope("name");
        return appleProvider;
      case "microsoft":
        let microsoftProvider = new firebase.auth.OAuthProvider(
          "microsoft.com"
        );
        microsoftProvider.addScope("email");
        microsoftProvider.addScope("profile");
        return microsoftProvider;
      default:
        return null;
    }
  }

  _routeRequiresAuth(route) {
    return route?.meta?.requiresAuth;
  }

  _redirectIfNecessary(path = "/login") {
    if (this._routeRequiresAuth(this.router.currentRoute))
      this.router.replace(path);
  }

  _subscribeToAuthStateChanges() {
    this.unsubscribe = this.firebase.onAuthStateChanged(
      this._onAuthStateChanged.bind(this)
    );
  }

  _unsubscribeToAuthStateChanges() {
    if (this.unsubscribe) {
      this.unsubscribe();
      delete this.unsubscribe;
    }
  }

  _onAuthStateChanged(user) {
    this._firebaseLoaded = true;
    if (this.preventAutomatedStateChangeActions)
      return delete this.preventAutomatedStateChangeActions;

    if (user) {
      return this.login().then((user) => {
        this._userLoaded = true;
        this.events.$emit("auth-ready", user);
      });
    }

    if (this.store.state.user.user?.id) {
      this.store.commit("user/delete");
      this._redirectIfNecessary();
    }

    this.events.$emit("auth-ready");
  }

  _assignCartIfNecessary(user) {
    const cart = this.store.state.cart?.cart;
    if (cart?.id && !cart.user) {
      return this.api.carts
        .updateCart(cart.uuid, { user_id: user.id })
        .then((response) => {
          this.store.commit("cart/set", response.data);
          return user;
        });
    } else if (cart?.id && cart.user?.id && cart.user.id != user.id) {
      this.store.commit("cart/reset");
    }
    return user;
  }

  /**
   * Get the firebase auth token
   */
  getAuthToken() {
    return new Promise((resolve, reject) => {
      if (!this._firebaseLoaded) {
        const unsubscribe = this.firebase.onAuthStateChanged((user) => {
          unsubscribe();
          this._firebaseLoaded = true;
          if (!user) return resolve(null);
          return user.getIdToken().then((token) => resolve(token));
        });
      } else {
        if (!this.firebase.currentUser) return resolve(null);
        return this.firebase.currentUser
          .getIdToken()
          .then((token) => resolve(token));
      }
    });
  }

  /**
   * Attempt to get the authenticated user from backend
   */
  login() {
    return this.api.users
      .login()
      .then((response) => {
        this.store.commit("user/set", response.data);
        return response.data;
      })
      .catch((error) => {
        if (error.response?.status == 401) this.logout(true);
        throw error;
      });
  }

  /**
   *
   * @param {string} email
   * @param {string} password
   */
  loginWithEmailPassword(email, password) {
    this.preventAutomatedStateChangeActions = true;
    return this.firebase
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        return this.login();
      });
  }

  /**
   * @param {string} provider provider string
   * @param {boolean} redirect whether to use the redirect flow
   */
  loginWithProvider(provider, redirect = false) {
    const authProvider = this._getExternalAuthProvider(provider);
    if (!authProvider) return Promise.reject("Invalid auth provider.");

    if (redirect) return this.firebase.signInWithRedirect(authProvider);

    this.preventAutomatedStateChangeActions = true;
    return this.firebase
      .signInWithPopup(authProvider)
      .then((userCredential) => {
        console.log(`Signed in with ${provider}`);
        return this.login();
      });
  }

  /**
   * @param {string} provider provider string
   * @param {boolean} redirect whether to use the redirect flow
   */
  linkWithProvider(provider, redirect = false) {
    const authProvider = this._getExternalAuthProvider(provider);
    if (!authProvider) return Promise.reject("Invalid auth provider.");
    if (!this.firebase.currentUser) return Promise.reject("No user logged in.");

    if (redirect)
      return this.firebase.currentUser.linkWithRedirect(authProvider);

    return this.firebase.currentUser
      .linkWithPopup(authProvider)
      .then((response) => {
        console.log(`User successfully linked with ${provider}`);
        return response;
      });
  }

  /**
   * Determines the result after a redirect action
   */
  getRedirectResult() {
    this.preventAutomatedStateChangeActions = true;
    return this.firebase
      .getRedirectResult()
      .then((userCredential) => {
        if (
          ["signIn", "reauthenticate"].includes(userCredential.operationType)
        ) {
          return this.login().then((user) => {
            return userCredential;
          });
        }
        delete this.preventAutomatedStateChangeActions;
        return userCredential;
      })
      .catch((error) => {
        if (error.code == "auth/account-exists-with-different-credential") {
          return this.firebase
            .fetchSignInMethodsForEmail(error.email)
            .then((methods) => {
              error.methods = methods;
              throw error;
            });
        }
        throw error;
      });
  }

  /**
   * Logout the currently signed in user
   */
  logout(redirect = false, path = "/login") {
    this.preventAutomatedStateChangeActions = true;
    return this.firebase.signOut().then(() => {
      this.store.commit("cart/reset");
      this.store.commit("user/delete");
      this.store.commit("checkout/reset");
      if (redirect) this._redirectIfNecessary(path);
    });
  }

  /**
   * @param {object} data registration data
   */
  register(data) {
    this.preventAutomatedStateChangeActions = true;
    return this.api.users.register(data).then((response) => {
      this.store.commit("user/set", response.data);
      return response.data;
    });
  }

  /**
   *
   * @param {string} email
   * @param {string} password
   * @param {string} first_name
   * @param {string} last_name
   * @param {string} phone
   * @param {array|null} allergies
   */
  registerWithEmailPassword(
    email,
    password,
    first_name,
    last_name,
    phone,
    allergies = null
  ) {
    this.preventAutomatedStateChangeActions = true;
    return this.firebase
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        return userCredential.user.getIdToken();
      })
      .then((token) => {
        return this.register({
          first_name,
          last_name,
          email,
          phone,
          allergies,
          firebase_id_token: token,
        });
      });
  }

  /**
   *
   * @param {string} email
   * @param {string} first_name
   * @param {string} last_name
   * @param {string} phone
   * @param {array|null} allergies
   */
  createUser(email, first_name, last_name, phone, role_id, allergies = null) {
    this.preventAutomatedStateChangeActions = true;
    return this.secondaryApp
      .auth()
      .createUserWithEmailAndPassword(email, "citruspear")
      .then((userCredential) => userCredential.user.uid)
      .then((uid) => {
        return this.api.users.createUser({
          first_name,
          last_name,
          email,
          phone,
          allergies,
          firebase_uid: uid,
          role_id,
        });
      })
      .then((response) => {
        this.sendPasswordResetEmail(response.data.email);
        return response.data;
      });
  }

  /**
   * @param {string} email email address specified by user
   */
  sendPasswordResetEmail(email) {
    return this.firebase.sendPasswordResetEmail(email);
  }

  /**
   * @param {string} code code sent in password reset email
   * @param {string} newPassword new password defined by user
   */
  confirmPasswordReset(code, newPassword) {
    return this.firebase.confirmPasswordReset(code, newPassword);
  }

  /**
   * @param {string} code
   */
  verifyPasswordResetCode(code) {
    return this.firebase.verifyPasswordResetCode(code).then((email) => {
      return email;
    });
  }

  /**
   * @param {string} currentPassword user's current password
   * @param {string} newPassword requested new password
   */
  updatePassword(currentPassword, newPassword) {
    if (!this.firebase.currentUser?.email)
      return Promise.reject("User not logged in, unable to update password.");

    let authCredential = firebase.auth.EmailAuthProvider.credential(
      this.firebase.currentUser?.email,
      currentPassword
    );
    if (!authCredential) return Promise.reject("Invalid password provided.");

    return this.firebase.currentUser
      .reauthenticateWithCredential(authCredential)
      .then((userCredential) => {
        return this.firebase.currentUser.updatePassword(newPassword);
      });
  }

  /**
   * @param {string} newEmail requested new email
   * @param {string} currentPassword user's current password
   */
  updateEmail(newEmail, currentPassword) {
    if (!this.firebase.currentUser?.email)
      return Promise.reject("User not logged in, unable to update email.");

    let authCredential = firebase.auth.EmailAuthProvider.credential(
      this.firebase.currentUser?.email,
      currentPassword
    );
    if (!authCredential) return Promise.reject("Invalid password provided.");

    return this.firebase.currentUser
      .reauthenticateWithCredential(authCredential)
      .then((userCredential) => {
        return this.firebase.currentUser.updateEmail(newEmail);
      });
  }

  /**
   * @param {string|null} email
   */
  getSignInMethods(email = null) {
    if (!email) {
      if (!this.firebase.currentUser?.email) return Promise.resolve([]);
      email = this.firebase.currentUser.email;
    }

    return this.firebase.fetchSignInMethodsForEmail(email);
  }

  /**
   * Determine if user has a permission
   * @param {string} permission
   * @return {bool}
   */
  hasPermission(permission) {
    const user = this.store.state.user.user;
    if (!user?.permissions?.length) return false;

    permission = new Permission(permission);
    for (let userPermission of user.permissions) {
      userPermission = new Permission(userPermission);
      if (userPermission.implies(permission)) return true;
    }

    return false;
  }

  /**
   *
   * @param {array<string>} permissions
   * @returns {bool}
   */
  hasPermissions(permissions) {
    for (const permission of permissions) {
      if (!this.hasPermission(permission)) return false;
    }
    return true;
  }

  /**
   * Determines if user has one of the submitted permissions
   * @param {array<string>} permissions
   * @returns {bool}
   */
  hasOneOfPermissions(permissions) {
    for (const permission of permissions) {
      if (this.hasPermission(permission)) return true;
    }
    return false;
  }

  /**
   * Determine if user has a role
   * @param {string} role
   * @returns {bool}
   */
  hasRole(role) {
    const user = this.store.state.user.user;
    if (!user?.roles?.length) return false;

    return user.roles.includes(role);
  }

  /**
   *
   * @param {array<string>} permissions
   * @returns {bool}
   */
  hasRoles(roles) {
    for (const role of roles) {
      if (!this.hasRole(role)) return false;
    }
    return true;
  }

  getRoles() {
    const user = this.store.state.user.user;
    return user.roles;
  }

  /**
   * Determines if user has one of the submitted roles
   * @param {array<string>} roles
   * @returns {bool}
   */
  hasOneOfRoles(roles) {
    for (const role of roles) {
      if (this.hasRole(role)) return true;
    }
    return false;
  }

  locationManagerVerification() {
    if (this.hasRole("location_manager") || this.hasRole("regional_manager") || this.hasRole("admin")) {
      const locations = this.store.state.locations.locations.filter((location) => location.lead.email === this.store.state.user.user.email);
      const locationIds = locations.map(location => location.id);

      if (locationIds.length > 0) {
        return locationIds;
      } else {
        return [];
      }
    }

  }
}
