<template>
  <div>
    <div
      class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-light"
    >
      <h6 class="col-span-1 label">ID</h6>
      <h6 class="col-span-3 label">Name</h6>
      <h6 class="col-span-2 label">Key</h6>
      <h6 class="col-span-3 label">Description</h6>
      <h6 class="col-span-1 label">Single Meal Limit*</h6>
      <h6 class="col-span-1 label">Trade Limit**</h6>
      <h6 class="col-span-1 label"></h6>
    </div>
    <div
      class="xl:divide-y xl:divide-gray-light xl:border xl:border-t-0 border-gray-200 xl:border-gray-light rounded-b-md"
    >
      <div
        v-for="fulfillment in fulfillments"
        :key="fulfillment.id"
        class="mb-5 xl:mb-0"
      >
        <FulfillmentCard :fulfillment="fulfillment" @update="$emit('update')"/>
      </div>
    </div>
  </div>
</template>

<script>
import ListView from "@components/global/ListView";
import FulfillmentCard from "@components/fulfillments/FulfillmentCard";

export default {
  props: {
    fulfillments: {
      type: Array,
      default: function() {
        return [];
      }
    },
    headerNames: {
      type: Array,
      default: function() {
        return [
          "ID",
          "Name",
          "Key",
          "Description",
          "Single Meal Limit",
          "Opt-out Limit"
        ];
      }
    }
  },

  components: {
    ListView,
    FulfillmentCard
  },

  methods: {}
};
</script>

<style lang="scss" scoped></style>
