const routes = [
  {
    path: "/users",
    name: "users",
    component: () => import("@views/users/Users.vue"),
    meta: {
      requiresAuth: true,
      roles: [],
      permissions: ["users.view"]
    },
    children: []
  },
  {
    path: "/users/create",
    name: "user.create",
    component: () => import("@views/users/CreateUser.vue"),
    meta: {
      requiresAuth: true,
      roles: [],
      permissions: ["users.create"]
    }
  },
  {
    path: "/users/:id",
    name: "user",
    component: () => import("@views/users/User.vue"),
    meta: {
      requiresAuth: true,
      roles: [],
      permissions: ["users.view"]
    }
  },
  {
    path: "/users/:id/edit",
    name: "user.edit",
    component: () => import("@views/users/EditUser.vue"),
    meta: {
      requiresAuth: true,
      roles: [],
      permissions: ["users.edit"]
    }
  }
];

export default routes;
