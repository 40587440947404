<template>
    <div>
        <div class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="col-span-1 label">ID</h6>
            <h6 class="col-span-2 label">Card Number</h6>
            <h6 class="col-span-1 label">Total</h6>
            <h6 class="col-span-2 label">Recipient</h6>
            <h6 class="col-span-2 label">Recipient</h6>
            <h6 class="col-span-2 label">Email Sent</h6>
            <h6 class="col-span-1 label">Status</h6>
        </div>
        <div class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md">
            <div v-for="gift_card in gift_cards" :key="gift_card.id" class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-1">
                        <h6 class="label xl:hidden">ID</h6>
                        <router-link :to="`/gift-cards/${gift_card.id}`" class="hover:underline">
                            <span>{{ gift_card.id }}</span>
                        </router-link>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Card Number</h6>
                        <p v-if="gift_card.card_number" class="whitespace-nowrap">{{ gift_card.card_number }}</p>
                    </div>
                    <div class="xl:col-span-1">
                        <h6 class="label xl:hidden">Amount</h6>
                        <p>{{ gift_card.balance | currency }}</p>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Purchaser</h6>
                        <template v-if="gift_card.purchaser">
                            <p>{{ gift_card.purchaser.email }}</p>
                        </template>
                        <template v-else><p>-</p></template>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Recipient</h6>
                        <template v-if="gift_card.recipient">
                            <p>{{ gift_card.recipient.email }}</p>
                        </template>
                        <template v-else><p>-</p></template>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Email Sent</h6>
                        <template v-if="!gift_card.order_item">
                            <p>-</p>
                        </template>
                        <template v-else-if="!gift_card.order_item.digital_sent_at">
                            <p>No</p>
                        </template>
                        <template v-else>
                            <p><Date :date="gift_card.order_item.digital_sent_at" preset="DATE_FULL"/></p>
                            <p><Date :date="gift_card.order_item.digital_sent_at" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
                        </template>
                    </div>
                    <div class="xl:col-span-1">
                        <h6 class="label xl:hidden">Status</h6>
                        <p><StatusBadge class="text-xs" :enabled="gift_card.enabled"/></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Date from '@components/global/dates/Date'
import StatusBadge from '@components/giftcards/StatusBadge'

export default {
    props: {
        gift_cards: {
            type: Array,
            required: true
        }
    },

    components: {
        Date,
        StatusBadge,
    }
}
</script>

<style lang="scss" scoped>
</style>
