<template>
    <div>
        <!-- confirm modal -->
<!--        <ConfirmClassSelectModal-->
<!--            :visible="confirmModal"-->
<!--            :errors="errors"-->
<!--            :warnings="warnings"-->
<!--            :canOverride="canOverride"-->
<!--            @close="confirmModal = false"-->
<!--            @confirm="handleConfirm"-->
<!--        />-->

        <div class="mb-2">
            <ClassListView>
                <ClassCard :classEvent="classEvent" :links="false"/>
            </ClassListView>
        </div>

        <!-- search and select a class to transfer to -->
        <div>
            <!-- class search -->
            <div class="flex">
                <SearchInput class="w-60 mr-2 mb-6" v-model="toClass.search" placeholder="Search classes by SKU" @search="runSearch"/>
            </div>

            <!-- class list -->
            <template v-if="toClass.loading">
                <div class="flex items-center space-x-5">
                    <Loader class="w-10 h-10 text-green"/>
                    <p>Searching classes...</p>
                </div>
            </template>
            <template v-else-if="!toClass.loading && !pagination.data"></template>
            <template v-else-if="pagination.data && !toClass.loading">
                <div v-if="pagination.data.length > 0">
                    <ClassSelect class="mb-10" :classes="pagination.data" @selected="handleSelected"/>
                    <Pagination
                        v-if="pagination.meta.total > pagination.perPage"
                        :page="pagination.page" :per-page="pagination.perPage"
                        :total-items="pagination.meta.total" @change="classEventsUpdated"
                        :pageQueryString="pagination.pageQueryString"
                        :perPageQueryString="pagination.perPageQueryString"
                    />
                </div>
                <div v-else>
                    <p>No classes found.</p>
                </div>
            </template>
            <template v-else>
                <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
            </template>
        </div>

    </div>
</template>

<script>
// modules
import { DateTime } from "luxon";

// components
import Loader from '@components/global/Loader'
import SearchInput from '@components/global/SearchInput'
import ClassSelect from '@components/registrations/update/classes/ClassSelect'
import ConfirmClassSelectModal from '@components/registrations/update/classes/modals/ConfirmClassSelectModal'
import ClassCard from '@components/classes/ClassCard'
import ClassListView from '@components/classes/ListView'
import Pagination from '@/components/global/Pagination'

export default {
    name: "Exchange",

    components: {
        Loader,
        SearchInput,
        ClassSelect,
        ConfirmClassSelectModal,
        ClassCard,
        ClassListView,
        Pagination,
    },

    props: {
        open: {
            type: Boolean,
            default: false,
        },
        registration: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            errors: [],
            warnings: [],
            confirmModal: false,
            override: false,
            toClass: {
                loading: false,
                selected: null,
                search: null,
                dateRange: {},
                defaultDateRange: {},
            },
            pagination: {
                loading: false,
                data: null,
                meta: {},
                links: {},
                page: parseInt(this.$route.query.page) || 1,
                perPage: parseInt(this.$route.query.per_page) || 5,
                pageQueryString: 'page',
                perPageQueryString: 'per_page',
            }
        }
    },

    computed: {
        classEvent() {
            return this.$app.store.state.registration.update.data.classEvent
        },
        portion() {
            return this.$app.store.state.registration.update.data.portion
        },
        isActive() {
            return this.toClass.selected?.enabled
        },
        notCompleted() {
            return !this.toClass.selected?.completed
        },
        isDifferent() {
            return this.toClass.selected?.id !== this.registration.class_event_id
        },
        hasCapacity() {
            return this.toClass.selected?.remaining_capacity > 0
        },
        notPastCutoff() {
            return DateTime.now() < DateTime.fromISO(this.toClass.selected?.registration_ends)
        },
        validClass() {
            return this.isActive && this.notCompleted && this.isDifferent && this.hasCapacity && this.notPastCutoff
        },
        selectionChangeRequired() {
            if (this.toClass.selected) {
                return this.$app.services.registration.selectionChangeRequired(this.registration.class, this.toClass.selected)
            }
            return false
        },
        portionChangeRequired() {
            if(this.toClass.selected) {
                return this.$app.services.registration.portionChangeRequired(this.portion, this.toClass.selected)
            }
            return false
        },
        shippingAddressRequired() {
            if(this.toClass.selected) {
                return this.toClass.selected.fulfillment.key === 'delivery'
            }
            return false
        },
        canOverride() {
            return this.isActive && this.isDifferent
        },
        canSave() {
            if(this.override) {
                return true
            }
            else if(this.validClass) {
                return true
            }
            return false
        },
    },

    methods: {
        handleSelected(value) {
            // TODO: handle the warnings/errors a little differently
            // this.errors = []
            // this.warnings = []
            // this.toClass.selected = value
            //
            // if(this.selectionChangeRequired) this.warnings.push('The class menus are different. Meal selections will be changed.')
            // if(this.portionChangeRequired) this.warnings.push('The class portion variations are different. The selected portion will change.')
            // if(this.shippingAddressRequired) this.warnings.push('The selected class fulfillment is delivery. A shipping address is required.')
            //
            // if(!this.validClass) {
            //     if(!this.isDifferent) this.errors.push('Selected class is the origin class.')
            //     if(!this.isActive) this.errors.push('Selected class is inactive.')
            //     if(!this.notCompleted) this.errors.push('Selected class has been completed.')
            //     if(!this.hasCapacity) this.errors.push('Selected class is full.')
            //     if(!this.notPastCutoff) this.errors.push('Selected class is past the cutoff date.')
            //     if(!this.canOverride) this.errors.push('Selected class is invalid, please select a different class.')
            // }
            //
            // if(this.errors.length || this.warnings.length) this.confirmModal = true
            // else this.save()
            this.toClass.selected = value
            this.save()
        },
        handleConfirm(event) {
            if(event?.override) {
                this.override = event.override
                this.$emit('override', {override: event.override})
            }
            this.save()
        },
        save() {
            this.$app.store.commit('registration/setUpdateData', {classEvent: this.toClass.selected})
            this.$app.store.commit('registration/setUpdateReview', {portion: this.portionChangeRequired, selections: this.selectionChangeRequired, shipping: this.shippingAddressRequired})
            // clear search
            this.toClass.search = null

            // clear pagination
            this.pagination.data = null
            this.pagination.links = {}
            this.pagination.meta = {}
        },
        classEventsUpdated(page) {
            this.pagination.page = page
            this.runSearch()
        },
        runSearch() {
            let params = {
                sku: this.toClass.search,
                page: this.pagination.page,
                per_page: this.pagination.perPage,
                completed: false,
                canceled: false,
                orderBy: 'date',
                orderDirection: 'desc',
                relations: 'menu|menu.type|fulfillment|portionVariations|portionVariations.portionSize|portionVariations.portionQuantity',
            }

            this.toClass.loading = true
            return this.$app.api.classes.getClasses(params)
                .then((response) => {
                    this.pagination.links = response.data.links
                    this.pagination.meta = response.data.meta
                    this.pagination.data = response.data.data.filter((classEvent) => { return classEvent.id !== this.registration.class.id })
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.toClass.loading = false
                })
        },
    },

    created() {},
}
</script>

<style lang="scss" scoped>
</style>
