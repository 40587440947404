import Resource from './resource'

export default class Promotions extends Resource {

    /**
     * @param {string|int} promotionId promotion ID
     * @param {object} params
     */
    getOrders(promotionId, params = {}) {
        return this.axios.get(`promotions/${promotionId}/orders`, { params })
    }

    /**
     *
     * @param {object} params
     */
    getPromotions(params = {}) {
        return this.axios.get(`promotions`, { params })
    }

    /**
     *
     * @param {string|int} promotionId promotion ID
     * @param {object} params query params
     */
    getPromotion(promotionId, params = {}) {
        return this.axios.get(`promotions/${promotionId}`)
    }

    /**
     *
     * @param {string|int} promotionId promotion ID
     * @param {object} params query params
     */
    getCodes(promotionId, params = {}) {
        return this.axios.get(`promotions/${promotionId}/codes`, { params })
    }

    /**
     * @param {object} data promotion data
     */
    createPromotion(data) {
        return this.axios.post(`promotions`, data)
    }

    /**
     * @param {string|int} promotionId promotion ID
     * @param {object} data code data
     */
    createCodes(promotionId, data) {
        return this.axios.post(`promotions/${promotionId}/codes`, data)
    }

    /**
     * @param {string|int} promotionId promotion ID
     * @param {object} data code data
     */
    updatePromotion(promotionId, data) {
        return this.axios.put(`promotions/${promotionId}`, data)
    }

    /**
     * @param {string|int} codeId code Id
     * @param {object} data code data
     */
    updateCode(codeId, data) {
        return this.axios.put(`codes/${codeId}`, data)
    }

    /**
     * @param {string|int} codeId code Id
     */
    deleteCode(codeId) {
        return this.axios.delete(`codes/${codeId}`)
    }

    /**
     * @param {string|int} promotionId promotion Id
     */
    deletePromotion(promotionId) {
        return this.axios.delete(`promotions/${promotionId}`)
    }
}
