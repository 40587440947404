<template>
    <ListView :headers="headers">
        <template #list>
            <slot>
                <div v-for="location in locations" :key="location.id" class="mb-5 xl:mb-0">
                    <LocationCard :location="location" v-on="$listeners"/>
                </div>
            </slot>
        </template>
    </ListView>
</template>

<script>
import ListView from '@components/global/ListView'
import LocationCard from '@components/locations/LocationCard'

export default {
    props: {
        locations: {
            type: Array,
            default: function () {
                return []
            },
        },
        headers: {
            type: Array,
            default: function () {
                return [
                    'ID',
                    'Location',
                    'Store Name',
                    'Store Address',
                    'Lead',
                    '',
                    'Status',
                    ''
                ]
            },
        }
    },

    components: {
        ListView,
        LocationCard,
    },
}
</script>

<style lang="scss" scoped>
</style>
