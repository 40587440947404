<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>{{ title }}</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div>
                <p class="mb-2">{{ message }}</p>
            </div>
            <div v-for="warning in warnings">
                <p class="mb-2">{{ warning }}</p>
            </div>
            <div v-for="error in errors">
                <p class="text-red mb-2">{{ error }}</p>
            </div>
            <div v-if="canOverride">
                <Checkbox class="mb-4" v-model="overrideSelected" :label="'Override'"/>
            </div>
            <div class="flex space-x-5">
                <button class="btn white px-6" @click="close">Cancel</button>
                <button class="btn green px-6" :disabled="!canOverride || (canOverride && !overrideSelected)" @click="confirm">Select Class</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Checkbox from '@components/global/forms/Checkbox'

export default {
    props: {
        title: {
            type: String,
            default: 'Select Class',
        },
        message: {
            type: String,
            default: function () { return `There are warnings or errors with the selected class.` },
        },
        warnings: {
            type: Array,
            default: function () { return [] },
        },
        errors: {
            type: Array,
            default: function () { return [] },
        },
        canOverride: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: false,
        }
    },

    components: {
        Modal,
        Checkbox
    },

    data(){
        return {
            overrideSelected: false,
        }
    },

    methods: {
        close() {
            this.$emit('close')
        },
        confirm() {
            this.$emit('confirm', {override: this.overrideSelected})
            this.$emit('close')
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
