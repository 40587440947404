<template>
  <div class="w-full rounded-md p-2 bg-white shadow-sm border border-gray-200">
    <!-- edit -->
    <div v-if="classEvent.fulfillment_id != 4">
      <button class="menu-item green" :disabled="!canEditClass" @click="$emit('edit')">
        <p><i class="fas fa-fw fa-edit mr-2"></i>Edit</p>
      </button>
      <!-- copy -->
      <button class="menu-item green" :disabled="!canCreateClass" @click="$emit('copy')">
        <p><i class="fas fa-fw fa-copy mr-2"></i>Copy</p>
      </button>

      <!-- divider -->
      <div class="px-2 py-2">
        <hr />
      </div>
    </div>

    <!-- meal labels -->
    <button class="menu-item green" @click="$emit('meal-labels')">
      <p><i class="fas fa-fw fa-sticky-note mr-2"></i>Meal Labels</p>
    </button>
    <!-- class labels -->
    <button class="menu-item green" @click="$emit('participant-labels')">
      <p><i class="fas fa-fw fa-user-tag mr-2"></i>Class Labels</p>
    </button>
    <!-- pickup sheets -->
    <button v-if="classEvent.fulfillment.key === 'in-person'" class="menu-item green" @click="$emit('pickup-sheets')">
      <p class="flex items-center">
        <i class="fas fa-fw fa-shopping-cart mr-2"></i>
        Pickup Sheets
      </p>
    </button>
    <!-- reports -->
    <button class="menu-item green" @click="$emit('class-information')">
      <p class="flex items-center">
        <i class="fas fa-fw fa-clipboard mr-2"></i>
        <span v-if="classEvent.fulfillment.key === 'delivery'">Delivery Report</span>
        <span v-if="classEvent.fulfillment.key === 'shipping'">Shipping Report</span>
        <span v-if="classEvent.fulfillment.key === 'in-person'">In-person Report</span>
        <span v-if="classEvent.fulfillment.key === 'pre-assembled'">Pre-assembled Report</span>
      </p>
    </button>

    <!-- divider -->
    <div class="px-2 py-2">
      <hr />
    </div>

    <div>
      <!-- forecast -->
      <button v-if="classEvent.fulfillment_id != 4" class="menu-item green" @click="$emit('forecast')">
        <p class="flex items-center">
          <i class="fas fa-fw fa-umbrella mr-2"></i>
          Forecast
        </p>
      </button>
      <!-- grocery -->
      <button class="menu-item green" @click="$emit('grocery')">
        <p class="flex items-center">
          <i class="fas fa-fw fa-shopping-basket mr-2"></i>
          Grocery Order
        </p>
      </button>

      <!-- divider -->
      <div class="px-2 py-2">
        <hr />
      </div>
    </div>

    <!-- hide/display class, class_is_public -->
    <template v-if="permissionToUpdate">
      <div v-if="classEvent.fulfillment_id != 4">
        <button v-if="canDisplayClass" class="menu-item green" @click="$emit('display')">
          <p><i class="fas fa-fw fa-eye-slash mr-2"></i>Publicly Hidden</p>
        </button>
        <button v-else class="menu-item green" @click="$emit('display')">
          <p><i class="fas fa-fw fa-eye mr-2"></i>Publicly Visible</p>
        </button>
      </div>
      <button v-if="classIsArchived" class="menu-item green" @click="$emit('archive')">
        <p><i class="fas fa-fw fa-folder-minus mr-2" />Unarchive</p>
      </button>
      <button v-else class="menu-item green" @click="$emit('archive')">
        <p><i class="fas fa-fw fa-folder-plus mr-2" />Archive</p>
      </button>
    </template>
    <template v-else>
      <button v-if="canDisplayClass" class="menu-item green" disabled>
        <p><i class="fas fa-fw fa-eye-slash mr-2"></i>Publicly Hidden</p>
      </button>
      <button v-else class="menu-item green" disabled>
        <p><i class="fas fa-fw fa-eye mr-2"></i>Publicly Visible</p>
      </button>
      <button v-if="classIsArchived" class="menu-item green" disabled>
        <p><i class="fas fa-fw fa-folder-minus mr-2" />Unarchive</p>
      </button>
      <button v-else class="menu-item green" disabled>
        <p><i class="fas fa-fw fa-folder-plus mr-2" />Archive</p>
      </button>
    </template>

    <!-- close -->
    <button v-if="classEvent.fulfillment_id == 4" class="menu-item green" :disabled="!canCloseClass"
      @click="$emit('close')">
      <p><i class="fas fa-fw fa-times-circle mr-2"></i>Close</p>
    </button>

    <!-- reopen -->
    <button v-if="canReopenClass" class="menu-item green" @click="$emit('reopen')">
      <p><i class="fas fa-fw fa-door-open mr-2"></i>Reopen</p>
    </button>

    <!-- complete -->
    <button v-else class="menu-item green" :disabled="!canCompleteClass" @click="$emit('complete')">
      <p><i class="fas fa-fw fa-check-circle mr-2"></i>Complete</p>
    </button>

    <!-- divider -->
    <div class="px-2 py-2">
      <hr />
    </div>

    <!-- cancel -->
    <button v-if="classEvent.fulfillment_id != 4" class="menu-item red" :disabled="!canCancelClass"
      @click="$emit('cancel')">
      <p><i class="fas fa-fw fa-ban mr-2"></i>Cancel</p>
    </button>

    <!-- delete -->
    <button class="menu-item red" :disabled="!canDeleteClass" @click="$emit('delete')">
      <p><i class="fas fa-fw fa-trash mr-2"></i>Delete</p>
    </button>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import Loader from '@components/global/Loader'

export default {
  components: {
    Loader,
  },
  props: {
    classEvent: {
      type: Object,
      required: true,
    },
  },

  computed: {
    permissionToUpdate() {
      return this.$app.services.auth.hasPermission('classes.update')
    },
    permissionToCreate() {
      return this.$app.services.auth.hasPermission('classes.create')
    },
    permissionToDelete() {
      return this.$app.services.auth.hasPermission('classes.delete')
    },
    hasActiveRegistrations() {
      return this.classEvent.registrations.some(
        (registration) => registration.status.key === 'completed' || registration.status.key === 'processing'
      )
    },
    pastClassDate() {
      return DateTime.fromISO(this.classEvent.date) < DateTime.now()
    },
    canEditClass() {
      return (
        this.permissionToUpdate && !this.classEvent.completed && !this.classEvent.canceled
      )
    },
    canCreateClass() {
      return this.permissionToCreate
    },
    canCompleteClass() {
      if (this.classEvent.fulfillment_id == 4) {
        return this.permissionToUpdate && !this.classEvent.completed && !this.canCloseClass
      }
      return this.permissionToUpdate && !this.classEvent.completed
    },
    canCloseClass() {
      const classEventDate = DateTime.fromISO(this.classEvent.date);
      const nowUTC = DateTime.now();

      return this.permissionToUpdate &&
        !this.classEvent.completed &&
        classEventDate > nowUTC;
    },
    canReopenClass() {
      return (
        this.permissionToUpdate &&
        this.classEvent.completed &&
        !this.classEvent.canceled &&
        !this.pastClassDate
      )
    },
    canCancelClass() {
      return this.permissionToUpdate
    },
    canDeleteClass() {
      return (
        this.permissionToDelete &&
        !this.hasActiveRegistrations &&
        !this.classEvent.completed
      )
    },
    canDisplayClass() {
      return !this.classEvent.class_is_public && !this.classEvent.completed
    },
    classIsArchived() {
      return this.classEvent.archived
    },
  },
}
</script>

<style></style>
