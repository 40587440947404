import Resource from './resource'

export default class Newsroom extends Resource {

    // NEWS LINKS

    /**
     * 
     * @param {object} params
     */
    getNewsLinks(params = {})
    {
        return this.axios.get(`newsroom/in-the-news`, { params })
    }

    /**
     * 
     * @param {string|int} newsLinkId news link ID
     * @param {object} params query params
     */
    getNewsLink(newsLinkId, params = {})
    {
        return this.axios.get(`newsroom/in-the-news/${newsLinkId}`, { params })
    }

    /**
     * 
     * @param {FormData} data post form data
     */
    storeNewsLink(data = {})
    {
        return this.axios.post(`newsroom/in-the-news`,
            data,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        )
    }

    /**
     * 
     * @param {string|int} newsLinkId news link ID
     * @param {FormData} data put form data
     */
    updateNewsLink(newsLinkId, data = {})
    {
        if (!data.get('_method') === 'put') data.append('_method', 'put')

        return this.axios.post(`newsroom/in-the-news/${newsLinkId}`,
            data,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        )
    }

    /**
     * 
     * @param {string|id} newsLinkId news link ID
     */
    deleteNewsLink(newsLinkId)
    {
        return this.axios.delete(`newsroom/in-the-news/${newsLinkId}`)
    }

    /**
     * 
     * @param {string|id} newsLinkId news link ID
     */
    archiveNewsLink(newsLinkId)
    {
        return this.axios.put(`newsroom/in-the-news/${newsLinkId}/archive`)
    }

    // PRESS RELEASES

    /**
     * 
     * @param {object} params
     */
    getPressReleases(params = {})
    {
        return this.axios.get(`newsroom/press-releases`, { params })
    }
 
    /**
     * 
     * @param {string|int} pressReleaseId press release ID
     * @param {object} params query params
     */
    getPressRelease(pressReleaseId, params = {})
    {
        return this.axios.get(`newsroom/press-releases/${pressReleaseId}`, { params })
    }
 
    /**
     * 
     * @param {FormData} data post form data
     */
    storePressRelease(data = {})
    {
        return this.axios.post(`newsroom/press-releases`,
            data,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        )
    }
 
    /**
     * 
     * @param {string|int} pressReleaseId press release ID
     * @param {FormData} data put form data
     */
    updatePressRelease(pressReleaseId, data = {})
    {
        if (!data.get('_method') === 'put') data.append('_method', 'put')

        return this.axios.post(`newsroom/press-releases/${pressReleaseId}`,
            data,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        )
    }
 
    /**
     * 
     * @param {string|id} pressReleaseId press release ID
     */
    deletePressRelease(pressReleaseId)
    {
        return this.axios.delete(`newsroom/press-releases/${pressReleaseId}`)
    }
 
    /**
     * 
     * @param {string|id} pressReleaseId press release ID
     */
    archivePressRelease(pressReleaseId)
    {
        return this.axios.put(`newsroom/press-releases/${pressReleaseId}/archive`)
    }
 
    /**
     * 
     * @param {string} pressReleaseSlug press release slug
     * @param {object} params query params
     */
    getPressReleaseBySlug(pressReleaseSlug, params = {})
    {
        return this.axios.get(`newsroom/press-releases/${pressReleaseSlug}`, { params })
    }

    // STATUSES

    /**
     * Get statuses
     */
    getStatuses()
    {
        return this.axios.get(`newsroom/statuses`)
    }
}
