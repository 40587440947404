<template>
    <div class="md:grid grid-cols-1 gap-4">
        <div v-if="!menuId" class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Month*:</label>
            <div class="mb-2 ml-1">
                <multiselect class="max-w-xs" v-model="menu.month_year" :options="months" :searchable="false"
                    :close-on-select="true" :show-labels="false" :allow-empty="false" label="name" track-by="id"
                    placeholder="Select month">
                    <template slot="placeholder"><span class="text-gray-400">Select month</span></template>
                    <template #caret="{ toggle }">
                        <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                            <span class="absolute right-3 top-3 select-none text-green"><i
                                    class="fas fa-chevron-down"></i></span>
                        </div>
                    </template>
                </multiselect>
            </div>
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Menu Type*:</label>
            <div class="mb-2 ml-1">
                <multiselect class="max-w-xs" v-model="menu.type" :options="types" :searchable="false"
                    :close-on-select="true" :show-labels="false" :allow-empty="false" label="name" track-by="id"
                    placeholder="Select status" @input="typeChanged">
                    <template slot="placeholder"><span class="text-gray-400">Select status</span></template>
                    <template #caret="{ toggle }">
                        <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                            <span class="absolute right-3 top-3 select-none text-green"><i
                                    class="fas fa-chevron-down"></i></span>
                        </div>
                    </template>
                </multiselect>
            </div>
        </div>

        <div class="col-span-1">
            <!-- add a checkbox for favorite -->
            <div class="flex items-center mb-4 ml-1">
                <checkbox v-model="menu.favorite"></checkbox>
                <label class="block text-xs font-body text-gray-dark">Customer Favorite</label>
            </div>
            <div class="flex">
                <label class="w-full md:w-5/12 block text-xs font-body text-gray-dark mb-2 ml-1">Meals*:</label>
                <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Delivery Only</label>
            </div>
            <div v-for="(selection, index) in menu.selections" :key="index" class="mb-2 ml-1 flex">
                <multiselect class="w-full md:w-5/12 multiselect mr-8" :options="filteredMealOptions[index]"
                    v-model="menu.selections[index].meal" label="name" track-by="id" :loading="meals.loading"
                    :placeholder="'Select Meal ' + (index + 1)">
                    <template slot="caret" slot-scope="{ toggle }">
                        <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                            <img class="absolute top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] select-none"
                                src="/img/icons/down-chevron.svg" alt="icon" />
                        </div>
                    </template>
                </multiselect>
                <checkbox v-model="menu.selections[index].delivery"></checkbox>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Multiselect from "vue-multiselect"
import Checkbox from '@components/global/forms/Checkbox'
import { DateTime } from 'luxon'

export default {
    mixins: [validationMixin],
    name: "MenuForm",

    components: {
        Multiselect,
        Checkbox,
    },

    props: {
        value: {
            default: null
        },
        menuId: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            menu: {
                selections: this.initializeSelections(),
                month_year: null,
                type: { id: 1, name: 'Standard', key: 'standard' },
                favorite: false,
            },
            meals: {
                loading: false,
                data: [],
            },
            types: [{ id: 1, name: 'Standard', key: 'standard' }, { id: 5, name: 'Vegetarian', key: 'vegetarian' }, { id: 6, name: 'Soup', key: 'soup' }],
            months: this.getMonths(),
        }
    },

    methods: {
        initializeSelections() {
            return Array.from({ length: 12 }, () => ({ meal: null, delivery: false }));
        },
        emitMenu() {
            if (this.isValid) this.$emit('input', this.menu)
        },
        async getMeals() {
            let params = {
                active: true
            }

            if (this.menu.type.id > 1) {
                params.type = this.menu.type.id
            }

            this.meals.loading = true
            return this.$app.api.meals.getMeals(params)
                .then((response) => {
                    this.meals.data = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => this.meals.loading = false)
        },
        async getMenu(id) {
            this.menu.loading = true
            return this.$app.api.menus.getMenu(id)
                .then((response) => {
                    this.menu.selections = response.data.meals.map(meal => ({ meal: meal.meal, delivery: meal.delivery_only }))
                    if (response.data.type) {
                        this.menu.type = this.types.find(type => type.id === response.data.type.id)
                    }
                    this.menu.favorite = response.data.favorite
                    if (!this.$app.services.auth.hasPermission('menus.update') && !response.data.custom) {
                        this.$router.push(`/menus/${this.menuId}`)
                    }
                    this.typeChanged()
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => this.menu.loading = false)
        },
        getMonths() {
            let months = []
            let date = DateTime.local().plus({ months: 1 }).startOf('month')
            for (let i = 0; i < 12; i++) {
                months.push({
                    id: date.toISODate(),
                    name: date.toFormat('MMMM yyyy')
                })
                date = date.plus({ months: 1 })
            }
            return months
        },
        typeChanged() {
            this.getMeals()
        },
    },

    computed: {
        filteredMealOptions() {
            return this.menu.selections.map((selected, index) => {
                return this.meals.data.filter(meal => {
                    return !this.menu.selections.some((otherSelected, otherIndex) =>
                        otherIndex !== index && otherSelected.meal && otherSelected.meal.id === meal.id);
                });
            });
        },
        isValid() {
            return !this.$v.menu.$invalid
        },
    },

    watch: {
        menu: {
            handler(value) {
                this.debouncedEmit()
            },
            deep: true
        }
    },

    async created() {
        await this.getMeals()
        this.debouncedEmit = _.debounce(this.emitMenu, 500)

        if (this.value) {
            this.menu = JSON.parse(JSON.stringify(this.value))
        }
        if (this.menuId) {
            await this.getMenu(Number(this.menuId))
        }
    },

    validations() {
        const validations = {
            menu: {
                selections: { isValidMealsLength }
            }
        };

        if (this.menuId === null) {
            validations.menu.month_year = { required };
            validations.menu.type = { required };
        }

        return validations;
    }
};

function isValidMealsLength(value) {
    if (!Array.isArray(value) || value.length < 10 || value.length > 12) {
        return false;
    }

    const mealCount = value.reduce((count, mealEntry) => {
        return mealEntry && mealEntry.meal != null ? count + 1 : count;
    }, 0);

    return mealCount >= 10;
}
</script>

<style lang="scss" scoped></style>
