<template>
  <div>
    <ExportClasses
      :visible="modals.export"
      :startDate = "classes.dateRange.start.toUTC().toISO()"
      :endDate = "classes.dateRange.end.toUTC().toISO()"
      :locationIds = "filters.location_ids.join('|')"
      @close="modals.export = false"
    />
    <div class="mb-10">
      <h2 class="mb-5">Meal Events</h2>
      <div class="flex justify-between items-start">
        <div class="flex flex-wrap gap-y-2">
          <SearchInput
            class="mr-2"
            v-model="classes.search"
            placeholder="Search by SKU"
            @search="runSearch"
            inputClass="w-48"
          />
          <DateRangePicker
            class="mr-2"
            :value="classes.dateRange"
            :default="classes.defaultDateRange"
            @input="dateRangeChanged"
            sortable
          />
          <!-- Location picker -->
          <multiselect
            class="w-[340px] multiselect mr-2"
            v-model="locations.data"
            :options="groupedLocations"
            :group-values="'locations'"
            :group-label="'state'"
            label="name"
            track-by="name"
            :loading="locations.loading"
            placeholder="Filter by location"
            multiple
            :close-on-select="false"
          >
            <template slot="caret" slot-scope="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <img
                  class="absolute top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] select-none"
                  src="/img/icons/down-chevron.svg"
                  alt="icon"
                />
              </div>
            </template>
          </multiselect>
          <!-- Filters -->
          <div class="relative mr-2">
            <div class="flex">
              <button
                class="btn bg-white text-gray-800 font-normal border border-gray-200 border-r-0 rounded-r-none capitalize py-2"
                @click.prevent="filters.showFilters = !filters.showFilters"
              >
                <span v-if="!filters.showFilters">Show Filters</span>
                <span v-else>Close Filters</span>
              </button>
              <button
                type="button"
                class="btn font-normal border border-green capitalize rounded-l-none"
                @click.prevent="applyFilters"
              >
                Apply
              </button>
            </div>
            <div
              v-show="filters.showFilters"
              class="absolute top-12 max-h-[400px] bg-white overflow-y-auto border border-gray-200 rounded-md px-4 py-2 z-50"
            >
              <div>
                <!-- fulfillment methods -->
              </div>
              <div>
                <!-- class event statuses, canceled, completed, visibility, published -->
                <Checkbox v-model="filters.includeArchived" label="Include Archived" />
              </div>
              <!-- <div>
                <div v-for="group in groupedLocations">
                  <div>{{ group.state }}</div>
                  <div v-for="location in group.locations">
                    <Checkbox
                      @input="handleLocationFilter(location)"
                      :value="locationSelected(location)"
                      :label="location.name"
                    />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <!-- TODO: add a filter for different statuses. ie archived -->
        </div>
        <div class="flex flex-row">
          <router-link
            class="h-[42px] btn flex items-center green border border-green px-3 py-2 mr-2"
            :to="`/classes/create`"
          >
            <span class="whitespace-nowrap"
              ><i class="fas fa-plus mr-2"></i>New Event</span
            >
          </router-link>

          <button
            v-if="hasPermission"
            class="btn flex items-center px-3 py-2 border"
            :class="canExport ? 'border-green' : 'border-green-200'"
            :disabled="!canExport"
            @click.prevent="modals.export = true"
          >
            Export
          </button>
        </div>
      </div>
    </div>
    <div>
      <template v-if="classes.loading">
        <div class="flex items-center space-x-5">
          <Loader class="w-10 h-10 text-green" />
          <p>Loading classes...</p>
        </div>
      </template>
      <template v-else-if="classes.data.data && classes.data.data.length">
        <ListView
          class="mb-10"
          :classes="classes.data.data"
          @changeSort="changeSort"
          :headersWithSort="headersWithSort"
        />
        <Pagination
          :page="classes.page"
          :total-items="totalClasses"
          :per-page="classes.perPage"
          @change="pageUpdated"
        />
      </template>
      <template v-else>
        <p></p>
        <p>
          <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No events to
          show. Please refine your critera.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import Loader from '@components/global/Loader'
import SearchInput from '@components/global/SearchInput'
import Pagination from '@components/global/Pagination'
import ListView from '@components/classes/ListView'
import DateRangePicker from '@components/global/dates/DateRangePicker'
import Checkbox from '@components/global/forms/Checkbox'
import Multiselect from 'vue-multiselect'
import ExportClasses from '@components/classes/modals/ExportClasses'

export default {
  components: {
    Checkbox,
    Loader,
    SearchInput,
    Pagination,
    ListView,
    DateRangePicker,
    ExportClasses,
    Multiselect,
  },

  metaInfo() {
    return {
      title: this.title,
    }
  },

  data() {
    return {
      classes: {
        page: parseInt(this.$route.query.page) || 1,
        perPage: parseInt(this.$route.query.per_page) || 10,
        loading: false,
        search: this.$route.query.search || '',
        data: {},
        exporting: false,
        dateRange: this.getDateRange(),
        defaultDateRange: this.getDefaultDateRange(),
      },
      modals: {
        export: false,
      },
      locations: {
        loading: false,
        data: [],
      },
      filters: {
        changed: false,
        showFilters: false,
        includeArchived: false,
        location_ids: [],
        fulfillment_ids: [],
      },
      sorts: {
        date: 'desc',
      },
    }
  },

  computed: {
    title() {
      if (this.$route.query.search) return `Search: ${this.$route.query.search} | Classes`
      return 'Classes'
    },
    totalClasses() {
      return this.classes.data.meta?.total ?? 0
    },
    groupedLocations() {
      let activeLocations = this.$app.store.getters['locations/getAllActiveLocations']
      return this.$app.services.location.getOrderedLocations(activeLocations, false)
    },
    locationOptions() {
      return this.$app.store.getters['locations/getAllActiveLocations']
    },
    canExport() {
      return (
        this.totalClasses > 0 &&
        !!this.classes.dateRange?.start &&
        !!this.classes.dateRange?.end
      )
    },
    hasPermission() {
      return this.$app.services.auth.hasPermission('payroll')
    },

    headersWithSort() {
      return [{ name: 'Date', sort: this.sorts.date }]
    },
  },

  methods: {
    locationSelected(location) {
      return this.filters.location_ids.includes(location.id)
    },
    handleLocationFilter(location) {
      if (this.filters.location_ids.includes(location.id)) {
        this.filters.location_ids = this.filters.location_ids.filter((id) => {
          return id !== location.id
        })
      } else {
        this.filters.location_ids.push(location.id)
      }
    },
    handleFulfillmentFilter(fulfillment) {
      console.log(fulfillment)
    },
    applyFilters() {
      this.filters.showFilters = false
      this.getClasses()
    },
    getDefaultDateRange() {
      return {
        // start: DateTime.now().startOf('day'),
        // end: DateTime.now().plus({week: 1}).endOf('day')
        start: null,
        end: null,
      }
    },
    getDateRange() {
      //let range = this.getDefaultDateRange()
      let range = {
        start: DateTime.now().startOf('day'),
        end: DateTime.now().plus({ years: 1 }).endOf('day'),
      }

      if (this.$route.query.after_date)
        range.start = DateTime.fromISO(this.$route.query.after_date).startOf('day')
      if (this.$route.query.before_date)
        range.end = DateTime.fromISO(this.$route.query.before_date).endOf('day')

      return {
        start: range.start,
        end: range.end,
      }
    },
    dateRangeChanged(input) {
      const range = input.range
      const sort = input.sort
      if (sort && sort.name === 'Date') {
        this.sorts.date = sort.orderBy
      }
      this.classes.dateRange = range
      this.classes.page = 1

      let query = { ...this.$route.query }
      delete query.page

      if (
        +range.start === +this.classes.defaultDateRange.start &&
        +range.end === +this.classes.defaultDateRange.end
      ) {
        delete query.after_date
        delete query.before_date
      } else {
        query.after_date = range.start.toISODate()
        query.before_date = range.end.toISODate()
      }
      if (!sort && query === this.$route.query)
        this.$router.push({ path: this.$route.path, query })
      this.getClasses()
    },
    pageUpdated(page) {
      this.classes.page = page
      this.getClasses()
    },
    runSearch() {
      this.classes.page = 1

      let query = { ...this.$route.query, search: this.classes.search }
      delete query.page

      if (!this.classes.search) delete query.search
      this.$router.push({ path: this.$route.path, query })
      this.getClasses()
    },
    getClasses() {
      // default params
      let params = {
        relations: 'reservationSize',
        page: this.$route.query.page || this.classes.page,
        per_page: this.$route.query.per_page || this.classes.perPage,
        orderBy: 'date',
        orderDirection: 'desc',
        includeArchived: false,
        fulfillment_ids: '1|2|3',
      }

      if (this.filters.includeArchived) params.includeArchived = true
      if (this.filters.location_ids.length > 0)
        params.location_ids = this.filters.location_ids.join('|')

      // optional params
      if (this.classes.search) params.sku = this.classes.search
      // if(!this.classes.search) params.after_date = DateTime.now().startOf('day').toUTC().toISO()
      if (this.classes.dateRange.start)
        params.after_date = this.classes.dateRange.start.toUTC().toISO()
      if (this.classes.dateRange.end)
        params.before_date = this.classes.dateRange.end.toUTC().toISO()

      if (this.sorts.date) {
        params.orderBy = 'date'
        params.orderDirection = this.sorts.date
      }

      this.classes.loading = true
      return this.$app.api.classes
        .getClasses(params)
        .then((response) => {
          this.classes.data = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (this.classes.loading = false))
    },
    getLocations() {
      this.$app.store.dispatch('locations/getLocations')
    },
    changeSort(sort) {
      if (sort.name === 'Date') {
        this.sorts.date = sort.orderBy
        this.getClasses()
      }
    },
  },

  created() {
    this.getLocations()
    this.dateRangeChanged({
      range: this.classes.dateRange,
      sort: { name: 'Date', orderBy: 'asc' },
    })
    //this.getClasses()
  },

  watch: {
    'locations.data': function (newVal) {
      this.filters.location_ids = newVal.map((location) => location.id)
      this.getClasses()
    },
  },
}
</script>

<style>
.multiselect__placeholder {
  color: #a0aec0;
  font-family: AvenirNext, sans-serif;
  font-size: 16px;
  margin-bottom: 0.5rem;
  padding-top: 0rem;
}

.multiselect__tags {
  border: 1px solid #e0e0df;
  border-radius: 0.375rem;
}

.multiselect__tag {
  margin-bottom: 4px;
}
</style>
