<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Close Group</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="mb-10">
                <p class="mb-2">Are you sure you want to close this group? It is scheduled to close on {{
                    formatDate(classEvent.date, classEvent.location) }}.
                    Closing it now
                    will prevent customers from registering for this group.</p>
            </div>
            <div class="flex space-x-5">
                <button class="btn white px-6" @click="close">Cancel</button>
                <button class="btn px-6" @click="closeGroup" :disabled="loading || success">
                    <div v-if="loading" class="flex items-center space-x-1">
                        <Loader class="w-5 h-5" /><span>Closing</span>
                    </div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Closed!</span>
                    <span v-else>Close Group</span>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import { DateTime } from 'luxon'
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'

export default {
    props: {
        classEvent: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        date: {
            type: String,
            default: ''
        }
    },

    components: {
        Modal,
        Loader
    },

    data() {
        return {
            loading: false,
            success: false,
            sendReminders: true,
        }
    },

    methods: {
        close() {
            this.$emit('close')
        },
        closeGroup() {
            let params = { reminders: this.sendReminders }
            this.loading = true
            return this.$app.api.classes.closeGroup(this.classEvent.id, params).then(res => {
                this.success = true
                this.$emit('update', res.data)
                setTimeout(() => {
                    this.success = false
                    this.close()
                }, 2000)
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading = false)
        },
        formatDate(date, shippingLocation) {
            if (!date) return null;
            const { timezone } = shippingLocation || { timezone: 'UTC' };
            const utcDate = DateTime.fromISO(date, { zone: 'utc' });
            const localDate = utcDate.setZone(timezone);
            return localDate.toLocaleString(DateTime.DATETIME_FULL);
        }
    }
}
</script>

<style></style>
