<template>
  <div class="relative grid">
    <h2 class="mb-5">Blog</h2>

    <div class="flex flex-wrap mb-10 items-center">
      <div class="mb-2">
        <form>
          <div class="flex flex-wrap">
            <select
              @change="filterStatus"
              v-model="blogStatus"
              class="rounded-md border border-gray bg-white mr-2 px-3 py-2"
              name="status"
              id="status"
              style="height: 42px"
            >
              <option value="" selected>Status</option>
              <option
                v-for="(status, index) in statuses"
                :value="status"
                :key="`${index}-${status}`"
              >
                {{ status[0].toUpperCase() + status.slice(1) }}
              </option>
            </select>
          </div>
        </form>
      </div>

      <SearchInput
        class="mr-2 mb-2"
        v-model="search"
        placeholder="Search by title"
        @search="runSearch()"
      />

      <button
        class="rounded-md border border-gray bg-white mr-2 mb-2 px-3 py-2 cursor-pointer"
        @click="clearSearch"
      >
        Clear
      </button>

      <router-link
        v-if="canCreateBlog"
        class="btn green border border-green mr-2 mb-2 px-3 py-2"
        :to="`/blog/create`"
        :disabled="!canCreateBlog"
      >
        <span><i class="fas fa-plus mr-2"></i>Create Blog Post</span>
      </router-link>

      <p v-if="!canCreateBlog" class="text-sm text-gray-400 mb-2 ml-2">
        New blog posts are now created and managed in Webflow.
      </p>
    </div>

    <div class="overflow-hidden border border-gray rounded-md mb-10">
      <table class="table-auto w-full min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr class="text-left text-sm text-gray-400 py-4">
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              v-for="(header, index) in headers"
              :key="`${index}-${header}`"
            >
              {{ header }}
            </th>
          </tr>
        </thead>

        <tbody class="bg-white divide-y divide-gray-200">
          <!-- Inject the actual data from the data source. -->
          <template v-if="loading">
            <tr>
              <td colspan="4" class="w-full text-center py-6">
                <div class="relative flex items-center justify-center">
                  <Loader class="w-5 h-5 mr-2" />Loading...
                </div>
              </td>
            </tr>
          </template>

          <template v-else-if="blogs.data && blogs.data.length">
            <tr v-for="(blog, index) in blogs.data" :key="blog.id" class="text-sm">
              <td class="table-cell px-6 py-4 whitespace-nowrap">
                <router-link
                  v-if="canEditBlog"
                  class="hover:underline block"
                  :to="`/blog/${blog.article.slug}`"
                >
                  <p class="font-semibold">{{ blog.article.title }}</p>
                </router-link>
                <p v-else class="font-semibold">{{ blog.article.title }}</p>
              </td>
              <td class="table-cell px-6 py-4 whitespace-nowrap">
                <div v-for="author in blog.authors" :key="`author-${author.id}`">
                  {{ author.name }}
                </div>
              </td>
              <td class="table-cell px-6 py-4 whitespace-nowrap">
                <StatusBadge class="text-xs" :blogStatus="getBlogStatus(blog)" />
              </td>
              <td class="table-cell px-6 py-4 whitespace-nowrap">
                <Date
                  v-if="blog.published_at"
                  :date="blog.published_at"
                  preset="DATETIME_FULL"
                  :class="{ 'font-semibold': isPast(blog.published_at) }"
                />
              </td>
              <td
                v-if="canEditBlog || canDeleteBlog"
                class="flex px-6 py-4 whitespace-nowrap"
              >
                <button
                  v-if="canEditBlog && blog.status.key !== 'archived'"
                  @click="editBlog(blog.article.slug)"
                  class="btn xs text-green-600 bg-mint mr-2"
                >
                  Edit
                </button>

                <button
                  v-if="canDeleteBlog && blog.status.key === 'draft'"
                  @click="confirmDelete(blog)"
                  class="btn xs gray mr-2"
                >
                  Delete
                </button>
                <button
                  v-else-if="canDeleteBlog && blog.status.key === 'published'"
                  @click="confirmArchive(blog.id)"
                  class="btn xs gray mr-2"
                >
                  Archive
                </button>
              </td>
            </tr>
          </template>

          <template v-else>
            <tr>
              <td colspan="5" class="w-full text-center py-6">
                No blog posts to show. Please refine your critera.
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <Pagination
      v-if="totalBlogs > 10"
      :page="page"
      :total-items="totalBlogs"
      :per-page="perPage"
      @change="pageUpdated"
    />

    <!-- Modals -->
    <ConfirmArchiveModal
      :blog-id="blogId"
      :visible="showConfirmArchiveModal"
      @close="showConfirmArchiveModal = false"
      @success="getBlogs"
    />

    <ConfirmDeleteModal
      :blog-id="blogId"
      :visible="showConfirmDeleteModal"
      @close="showConfirmDeleteModal = false"
      @success="getBlogs"
    />
  </div>
</template>

<script>
import ConfirmDeleteModal from '@components/blog/modals/ConfirmDelete'
import ConfirmArchiveModal from '@components/blog/modals/ConfirmArchive'
import Loader from '@components/global/Loader'
import StatusBadge from '@components/blog/StatusBadge'
import Date from '@components/global/dates/Date'
import SearchInput from '@components/global/SearchInput'
import Pagination from '@components/global/Pagination'
import { DateTime } from 'luxon'

export default {
  name: 'Blog',

  components: {
    ConfirmDeleteModal,
    ConfirmArchiveModal,
    Loader,
    StatusBadge,
    Date,
    SearchInput,
    Pagination,
  },

  metaInfo() {
    return {
      title: this.title,
    }
  },

  data() {
    return {
      headers: ['Title', 'Authors', 'Status', 'Publish Date', ''],

      statuses: ['draft', 'scheduled', 'published', 'archived'],

      showConfirmDeleteModal: false,
      showConfirmArchiveModal: false,

      blogs: [],
      blogId: null,
      blogStatus: '',

      loading: true,

      search: this.$route.query.search || '',

      page: parseInt(this.$route.query.page) || 1,
      perPage: parseInt(this.$route.query.per_page) || 10,
    }
  },

  mounted() {
    this.setViewableStatuses()
    this.getBlogs()
  },

  computed: {
    canViewAllBlogs() {
      return this.$app.services.auth.hasPermission('blogs.posts.view')
    },
    canCreateBlog() {
      //return this.$app.services.auth.hasPermission('blogs.posts.create')
      return false
    },
    canEditBlog() {
      return this.$app.services.auth.hasPermission('blogs.posts.update')
    },
    canDeleteBlog() {
      return this.$app.services.auth.hasPermission('blogs.posts.delete')
    },
    title() {
      if (this.$route.query.search) return `Search: ${this.$route.query.search} | Blog`
      return 'Blog'
    },
    totalBlogs() {
      return this.blogs.meta?.total ?? 0
    },
  },

  methods: {
    filterStatus(e) {
      if (!e.target.value)
        if (this.search)
          this.search = this.search
            .split(',')
            .map((i) => i.trim())
            .join(',')

      let search = []
      if (this.search)
        search = this.search
          .split(',')
          .map((i) => i.trim())
          .filter((i) => !this.statuses.includes(i))
      if (this.blogStatus) search.push(e.target.value)
      this.search = search.join(',')

      this.runSearch()
    },

    runSearch() {
      this.page = 1

      let query = {
        ...this.$route.query,
        search: this.search
          .split(',')
          .map((i) => i.trim())
          .join(','),
      }
      delete query.page

      if (!this.search) delete query.search

      this.$router.push({ path: this.$route.path, query })

      this.getBlogs()
    },

    clearSearch() {
      this.blogStatus = ''
      this.search = ''
      this.page = 1

      let query = { ...this.$route.query }
      delete query.page
      delete query.search

      this.$router.push({ path: this.$route.path, query })

      this.getBlogs()
    },

    getBlogs() {
      // default params
      const params = {
        relations: 'article|status',
        status: 'published|scheduled',
        admin: true,
        order_by: 'edited_at',
        page: this.$route.query.page || this.page,
        per_page: this.$route.query.per_page || this.perPage,
      }

      if (this.canViewAllBlogs) delete params.status

      // optional params
      if (this.$route.query.search) {
        const search = this.$route.query.search.split(',')

        // status
        const statuses = search.filter((i) => this.statuses.includes(i))
        if (statuses.length > 0) {
          this.blogStatus = statuses.length === 1 ? statuses[0] : ''
          params.status = statuses.join('|')
        }

        // title
        const title = search.filter((i) => !this.statuses.includes(i))
        if (title.length > 0) params.title = title.join(' ')
      }

      this.loading = true

      this.$app.api.blogs
        .getBlogs(params)
        .then((res) => {
          this.blogs = res.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => (this.loading = false))
    },

    editBlog(blogSlug) {
      this.$router.push(`/blog/${blogSlug}`)
    },

    confirmDelete(blog) {
      if (blog.status.key !== 'draft') return

      this.showConfirmDeleteModal = true
      this.blogId = blog.id
    },

    confirmArchive(blogId) {
      this.showConfirmArchiveModal = true
      this.blogId = blogId
    },

    isPast(date) {
      return DateTime.now() >= DateTime.fromISO(date)
    },

    getBlogStatus(blog) {
      if (!blog.published_at || this.isPast(blog.published_at)) return blog.status
      else return { name: 'Scheduled' }
    },

    setViewableStatuses() {
      if (!this.canViewAllBlogs)
        this.statuses = this.statuses.filter((i) => i !== 'draft' && i !== 'archived')
    },

    pageUpdated(page) {
      this.page = page
      this.getBlogs()
    },
  },
}
</script>

<style></style>
