<template>
    <div>
        <div class="hidden xl:grid grid-cols-14 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-light">
            <h6 class="col-span-1 label">Order</h6>
            <h6 class="col-span-3 label">Contact</h6>
            <h6 class="col-span-3 label">Date</h6>
            <h6 class="col-span-3 label">Code</h6>
            <h6 class="col-span-2 label">Order Discount</h6>
            <h6 class="col-span-2 label">Order Subtotal</h6>
        </div>
        <div class="xl:divide-y xl:divide-gray-light xl:border xl:border-t-0 border-gray-200 xl:border-gray-light rounded-b-md">
            <div v-for="discount in discounts" :key="discount.id" class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-14 gap-2 xl:px-3">
                    <div class="xl:col-span-1">
                        <h6 class="label lg:hidden">Order</h6>
                        <router-link class="hover:underline block mr-2" :to="`/orders/${discount.order_id}`">
                            <p class="font-medium">{{ discount.order_id }}</p>
                        </router-link>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label lg:hidden">Contact</h6>
                        <template v-if="discount.order.user">
                            <p class="font-medium">{{ discount.order.user.email }}</p>
                        </template>
                        <template v-else>
                            <p class="font-medium">{{ discount.order.contacts[0].email }}</p>
                        </template>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label lg:hidden">Date</h6>
                        <p>
                        <Date :date="discount.order.created_at" preset="DATE_FULL" />
                        </p>
                        <p>
                        <Date :date="discount.order.created_at" preset="TIME_SIMPLE_WITH_SHORT_OFFSET" />
                        </p>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label lg:hidden mb-1">Code</h6>
                        <p v-if="discount.promotion_code.key" class="font-mono text-sm font-semibold uppercase">
                            <span class="bg-white lg:bg-gray-100 px-3 py-1 rounded">{{ discount.promotion_code.key }}</span>
                        </p>
                        <p v-else>---</p>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label lg:hidden">Order Discount</h6>
                        <p class="font-medium">{{ discount.amount | currency }}</p>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label lg:hidden">Order Subtotal</h6>
                        <p class="font-medium">{{ discount.order.subtotal | currency }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Date from '@components/global/dates/Date'

export default {
    name: "CodesListView",

    props: {
        discounts: {
            type: Array,
            required: true
        }
    },

    components: {
        Date,
    },

    computed: {},

    methods: {},
}
</script>

<style lang="scss" scoped>
</style>
