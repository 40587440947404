var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sticky top-0 bg-white z-30 border-b border-gray-200"},[_c('div',{staticClass:"flex justify-between items-center w-full max-w-screen-2xl px-5 py-2 md:px-8"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex space-x-5 items-center flex-nowrap md:hidden"},[_c('div',[_c('button',{staticClass:"text-green w-10 h-10 relative focus:outline-none active:bg-white bg-white",on:{"click":_vm.toggleMenu}},[_c('span',{staticClass:"sr-only"},[_vm._v("Menu")]),_vm._m(0)])]),_vm._m(1)]),_c('div')]),(_vm.user.id)?_c('div',{staticClass:"flex"},[_c('Popover',{attrs:{"align":"right"},scopedSlots:_vm._u([{key:"button",fn:function(ref){
var toggle = ref.toggle;
var visible = ref.visible;
return [_c('div',{staticClass:"relative w-10 h-10 rounded-full text-center content-center cursor-pointer transition-colors",class:{
                            'bg-green': visible,
                            'bg-mint' : !visible
                        },on:{"click":toggle}},[_c('h6',{staticClass:"text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",class:{'text-white':visible}},[_vm._v(_vm._s(_vm.initials))])])]}},{key:"default",fn:function(ref){
                        var hide = ref.hide;
return [_c('div',{staticClass:"bg-white rounded-md border border-gray-200 shadow-sm w-40 p-3"},[_c('p',{staticClass:"transition-colors px-3 py-1 cursor-pointer rounded hover:bg-green hover:text-white",on:{"click":function () {hide(); _vm.logout()}}},[_c('i',{staticClass:"fas fa-sign-out-alt pr-2"}),_vm._v(" Logout")])])]}}],null,false,2674636250)})],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block w-6 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"},[_c('span',{staticClass:"block absolute rounded h-0.5 w-5 bg-green transform -translate-y-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"block absolute rounded h-0.5 w-7 bg-green",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"block absolute rounded h-0.5 w-5 bg-green transform translate-y-2",attrs:{"aria-hidden":"true"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"w-36",attrs:{"src":"/img/logos/logo.svg"}})])}]

export { render, staticRenderFns }