const routes = [
    {
        path: '/fulfillments',
        name: 'fulfillments',
        component: () => import('@views/fulfillments/Fulfillments.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
            permissions: []
        }
    },
]

export default routes
