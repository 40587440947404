<template>
  <div>
    <!--        <form v-on:submit.prevent="getReport">-->
    <div class="flex flex-row">
      <DateRangePicker
        class="mr-4"
        :value="dateRange"
        :default="defaultDateRange"
        @input="dateRangeChanged"
      />
      <!--                <button class="btn mr-2" :disabled="!canSubmit" @click.prevent="getReport">Submit</button>-->
      <button class="btn" :disabled="!canSubmit" @click.prevent="getDownload">
        Download
      </button>
    </div>
    <div v-if="!loading && addresses">
      <!-- TODO: list view to see the report in the admin -->
      <div v-for="address in addresses">
        {{ address.order_id }}
      </div>
    </div>
    <!--        </form>-->
  </div>
</template>

<script>
import DateRangePicker from '@components/global/dates/DateRangePicker'

export default {
  name: 'DeliveryAddressesReport',

  components: {
    DateRangePicker,
  },

  data() {
    return {
      loading: false,
      dateRange: this.getDefaultDateRange(),
      defaultDateRange: this.getDefaultDateRange(),
      addresses: null,
    }
  },

  computed: {
    canSubmit() {
      return !!this.dateRange?.start && !!this.dateRange?.end
    },
  },

  methods: {
    getDefaultDateRange() {
      return {
        start: null,
        end: null,
      }
    },
    dateRangeChanged(input) {
      const range = input.range
      this.dateRange.start = range.start
      this.dateRange.end = range.end
    },
    getReport() {
      let params = {
        from_date: this.dateRange.start.toUTC().toJSDate(),
        to_date: this.dateRange.end.toUTC().toJSDate(),
        download: false,
      }

      this.loading = true
      this.$app.api.reports
        .getDeliveryAddresses(params)
        .then((response) => {
          this.addresses = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getDownload() {
      if (this.canSubmit) {
        let from = `from_date=${this.dateRange.start.toUTC()}`
        let to = `to_date=${this.dateRange.end.toUTC()}`

        window.open(
          `${process.env.VUE_APP_API_BASE_URL}/reports/classes/deliveryAddresses?${from}&${to}&download=true`,
          '_blank'
        )
      }
    },
  },

  created() {},

  mounted() {},
}
</script>

<style scoped></style>
