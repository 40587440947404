<template>
    <div>
        <div
            :class="`hidden xl:grid grid-cols-14 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-light`">
            <h6 class="col-span-3 label">SKU</h6>
            <h6 class="col-span-3 label">Status</h6>
            <h6 v-if="queueList" class="col-span-3 label">Closed at</h6>
            <h6 v-else class="col-span-3 label">Closes at</h6>
            <h6 class="col-span-3 label">Capacity</h6>
            <h6 v-if="transfer" class="col-span-2 label">Select</h6>
        </div>
        <div
            class="xl:divide-y xl:divide-gray-light xl:border xl:border-t-0 border-gray-200 xl:border-gray-light rounded-b-md">
            <div v-for="group in groups" :key="group.id"
                class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div :class="`grid grid-cols-1 xs:grid-cols-3 xl:grid-cols-14 gap-2 xl:px-3`">
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">SKU</h6>
                        <router-link class="hover:underline block mr-2" :to="`/shipping/${group.id}`">
                            <h5>{{ group.sku }}</h5>
                        </router-link>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Status</h6>
                        <StatusBadge :classEvent="group" />
                    </div>
                    <div class="xl:col-span-3">
                        <h6 v-if="queueList" class="label xl:hidden">Closed at</h6>
                        <h6 v-else class="label xl:hidden">Closes at</h6>
                        <p>{{ formatDate(group.date, group.location) }}</p>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Closes</h6>
                        <div class="flex items-center space-x-0.5">
                            <ProgressRing class="flex-shrink-0" :radius="13" :stroke="3"
                                :progress="Math.floor((group.registrations_count / group.capacity) * 100)" />
                            <p>{{ group.registrations_count }}/{{ group.capacity }}</p>
                        </div>
                    </div>
                    <div v-if="transfer && numberOfTransfers + group.registrations_count <= group.capacity" class="xl:col-span-2">
                        <button class="btn green xs" @click="$emit('select', group)">Select</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon'
import StatusBadge from '@components/classes/StatusBadge'
import ProgressRing from '@components/global/ProgressRing'

export default {
    props: {
        groups: {
            type: Array,
            required: true
        },
        queueList: {
            type: Boolean,
            default: false
        },
        transfer: {
            type: Boolean,
            default: false
        },
        numberOfTransfers: {
            type: Number,
            default: 0
        }
    },

    components: {
        StatusBadge,
        ProgressRing
    },

    methods: {
        formatDate(date, shippingLocation) {
            if (!date) return null;
            const { timezone } = shippingLocation || { timezone: 'UTC' };
            const utcDate = DateTime.fromISO(date, { zone: 'utc' });
            const localDate = utcDate.setZone(timezone);
            return localDate.toLocaleString(DateTime.DATETIME_FULL);
        }
    }
}
</script>