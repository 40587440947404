import Service from '@shared/app/services/service'

export default class Promotion extends Service {

    /**
     *
     */
    createPromotion(data) {
        return this.api.promotions.createPromotion(data)
    }

    /**
     *
     */
    updatePromotion(promotionId, data) {
        return this.api.promotions.updatePromotion(promotionId, data)
    }

    /**
     *
     */
    createPromotionCodes(promotionId, data) {
        return this.api.promotions.createCodes(promotionId, data)
    }

    /**
     *
     */
    updatePromotionCode(codeId, data) {
        return this.api.promotions.updateCode(codeId, data)
    }

    /**
     *
     */
    deletePromotionCode(codeId) {
        return this.api.promotions.deleteCode(codeId)
    }

    /**
     *
     */
    deletePromotion(promotionId) {
        return this.api.promotions.deletePromotion(promotionId)
    }
}
