import Dinero from 'dinero.js'

export default function currency(value){
    value = convertToMinorCurrency(value)
    return Dinero({amount: value})   
}

export function formattedCurrency(value){
    return currency(value).toFormat()
}

function isInteger(value){
    return Number.isInteger(value) || typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

function convertFloat(value, precision = 2){
    return Math.round(value * Math.pow(10, precision));
}

function convertString(value, precision = 2){
   return convertFloat(Number(value), precision)
}

function convertToMinorCurrency(value){
    // return 0 if nothing passed
    if(!value) return 0

    // attempt to parse value from string
    if(typeof value === 'string') return convertString(value)
    
    // convert float or use int value
    else if(typeof value === 'number'){
        if(!isInteger(value)){
            return convertFloat(value)
        }
        return value
    }
    
    throw 'convertToMinorCurrency: Invalid type passed. Float, String, or Integer required.'
}