<template>
  <svg
    :height="radius + normalizedRadius - stroke"
    :width="radius + normalizedRadius - stroke"
  >
    <circle
      :stroke="backgroundColor"
      :stroke-width="stroke"
      fill="transparent"
      :r="normalizedRadius"
      cx="50%"
      cy="50%"
    />
    <circle
      :stroke="dynamicColor"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset: strokeDashoffset }"
      :stroke-width="stroke"
      fill="transparent"
      :r="normalizedRadius"
      cx="50%"
      cy="50%"
    />
  </svg>
</template>

<script>
export default {
  props: {
    radius: {
      type: Number,
      default: 20
    },
    progress: {
      type: Number,
      default: 100
    },
    stroke: {
      type: Number,
      default: 4
    },
    color: {
      type: String,
      default: "#6F8672"
    },
    warningColor: {
      type: String,
      default: "#FF5C35"
    },
    backgroundColor: {
      type: String,
      default: "#ECECEB"
    }
  },
  data() {
    return {};
  },
  computed: {
    cappedProgress() {
      return this.progress < 0 ? 0 : this.progress > 100 ? 100 : this.progress;
    },
    normalizedRadius() {
      return this.radius - this.stroke * 2;
    },
    circumference() {
      return this.normalizedRadius * 2 * Math.PI;
    },
    strokeDashoffset() {
      return (
        this.circumference - (this.cappedProgress / 100) * this.circumference
      );
    },
    dynamicColor() {
      return this.cappedProgress >= 66 ? this.color : this.warningColor;
    }
  }
};
</script>

<style scoped>
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
