<template>
    <div>
        <ShippingGroupsModal :visible="modals.shippingGroups" @close="refreshGroups" />
        <SmartForecastModal :visible="modals.smartForecast" @close="modals.forecast = false" />
        <div class="mb-10">
            <div class="flex justify-between">
                <h2 class="mb-10">Shipping</h2>
                <button class="h-[42px] btn flex items-center green border border-green px-3 py-2"
                    @click="modals.smartForecast = true">
                    <p><i class="fas fa-fw fa-umbrella mr-2"></i>Smart Forecast</p>
                </button>
            </div>
            <h4 class="mb-4"><i class="fas fa-fw fa-spinner mr-2"></i>Queue</h4>
            <div class="mb-10">
                <ListView v-if="queuedGroups.data.length > 0" :groups="queuedGroups.data" :queueList="true" />
                <p v-else>
                    No groups in the queue. Any groups that
                    are closed and have not been completed will appear here.
                </p>
            </div>
            <h4 class="mb-4"><i class="fas fa-fw fa-lemon mr-2"></i>Groups</h4>
            <div class="flex justify-between items-start">
                <div class="flex flex-wrap gap-y-2">
                    <SearchInput class="mr-2" v-model="groups.search" placeholder="Search by SKU" @search="runSearch"
                        inputClass="w-48" />
                    <DateRangePicker class="mr-2" :value="groups.dateRange" :default="groups.defaultDateRange"
                        @input="dateRangeChanged" sortable />
                </div>
                <div class="flex flex-row">
                    <button class="h-[42px] btn flex items-center green border border-green px-3 py-2 mr-2"
                        @click="modals.shippingGroups = true">
                        <span class="whitespace-nowrap"><i class="fas fa-plus mr-2"></i>Shipping Groups</span>
                    </button>
                </div>
            </div>
        </div>
        <div>
            <template v-if="groups.loading">
                <div class="flex items-center space-x-5">
                    <Loader class="w-10 h-10 text-green" />
                    <p>Loading groups...</p>
                </div>
            </template>
            <template v-else-if="groups.data.data && groups.data.data.length">
                <ListView class="mb-10" :groups="groups.data.data" />
                <Pagination :page="groups.page" :total-items="totalGroups" :per-page="groups.perPage"
                    @change="pageUpdated" />
            </template>
            <template v-else>
                <p></p>
                <p>
                    <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No groups to
                    show. Please refine your critera.
                </p>
            </template>
        </div>
    </div>
</template>
<!-- Look at calendar selector you made -->
<script>
import { DateTime } from 'luxon'
import Loader from '@components/global/Loader'
import Pagination from '@components/global/Pagination'
import SearchInput from '@components/global/SearchInput'
import DateRangePicker from '@components/global/dates/DateRangePicker'
import SmartForecastModal from '@components/shipping/modals/SmartForecastModal'
import ShippingGroupsModal from '@components/shipping/modals/ShippingGroupsModal'
import ListView from '@components/shipping/ListView'

export default {
    components: {
        Loader,
        Pagination,
        SearchInput,
        DateRangePicker,
        SmartForecastModal,
        ShippingGroupsModal,
        ListView,
    },
    data() {
        return {
            groups: {
                search: this.$route.query.search || '',
                dateRange: this.getDateRange(),
                defaultDateRange: {
                    start: null,
                    end: null,
                },
                page: parseInt(this.$route.query.page) || 1,
                perPage: parseInt(this.$route.query.per_page) || 10,
                data: [],
                loading: false,
            },
            queuedGroups: {
                data: [],
                loading: false,
            },
            modals: {
                shippingGroups: false,
                smartForecast: false,
            },
            sorts: {
                date: 'asc',
            },
        }
    },
    computed: {
        totalGroups() {
            return this.groups.data.meta?.total ?? 0
        },
    },
    methods: {
        getDateRange() {
            let range = {
                start: DateTime.now().startOf('day'),
                end: DateTime.now().plus({ years: 1 }).endOf('day'),
            }

            if (this.$route.query.after_date)
                range.start = DateTime.fromISO(this.$route.query.after_date).startOf('day')
            if (this.$route.query.before_date)
                range.end = DateTime.fromISO(this.$route.query.before_date).endOf('day')

            return {
                start: range.start,
                end: range.end,
            }
        },
        dateRangeChanged(input) {
            const range = input.range
            const sort = input.sort
            if (sort && sort.name === 'Date') {
                this.sorts.date = sort.orderBy
            }
            this.groups.dateRange = range
            this.groups.page = 1

            let query = { ...this.$route.query }
            delete query.page

            if (
                +range.start === +this.groups.defaultDateRange.start &&
                +range.end === +this.groups.defaultDateRange.end
            ) {
                delete query.after_date
                delete query.before_date
            } else {
                query.after_date = range.start.toISODate()
                query.before_date = range.end.toISODate()
            }
            if (!sort && query === this.$route.query)
                this.$router.push({ path: this.$route.path, query })
            this.getGroups()
        },
        pageUpdated(page) {
            this.groups.page = page
            this.getGroups()
        },
        runSearch() {
            this.groups.page = 1

            let query = { ...this.$route.query, search: this.groups.search }
            delete query.page

            if (!this.groups.search) delete query.search
            this.$router.push({ path: this.$route.path, query })
            this.getGroups()
        },
        refreshGroups() {
            this.modals.shippingGroups = false
            this.getGroups()
            this.getQueuedGroups()
        },
        getGroups() {
            let params = {
                relations: 'reservationSize',
                page: this.$route.query.page || this.groups.page,
                per_page: this.$route.query.per_page || this.groups.perPage,
                orderBy: 'date',
                orderDirection: 'desc',
                includeArchived: false,
                fulfillment_ids: '4',
            }

            if (this.groups.search) params.sku = this.groups.search
            if (this.groups.dateRange.start)
                params.after_date = this.groups.dateRange.start.toUTC().toISO()
            if (this.groups.dateRange.end)
                params.before_date = this.groups.dateRange.end.toUTC().toISO()

            if (this.sorts.date) {
                params.orderBy = 'date'
                params.orderDirection = this.sorts.date
            }

            this.groups.loading = true
            return this.$app.api.classes
                .getClasses(params)
                .then((response) => {
                    this.groups.data = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => (this.groups.loading = false))
        },
        getQueuedGroups() {
            let params = {
                relations: 'reservationSize',
                orderBy: 'date',
                orderDirection: 'desc',
                includeArchived: false,
                fulfillment_ids: '4',
                queued: true,
            }

            if (this.sorts.date) {
                params.orderBy = 'date'
                params.orderDirection = this.sorts.date
            }

            this.queuedGroups.loading = true
            return this.$app.api.classes
                .getClasses(params)
                .then((response) => {
                    this.queuedGroups.data = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => (this.queuedGroups.loading = false))
        },
    },
    created() {
        this.getGroups()
        this.getQueuedGroups()
    }
}
</script>