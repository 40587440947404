<template>
  <div
    class="bg-gray-100 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2"
  >
    <div
      class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-12 gap-2 xl:px-3"
    >
      <div class="xl:col-span-1">
        <p class="text-xs text-gray-400 xl:hidden">ID</p>
        <p class="">{{ fulfillment.id }}</p>
      </div>
      <div class="xl:col-span-3">
        <p class="text-xs text-gray-400 xl:hidden">Name</p>
        <p class="font-semibold">{{ fulfillment.name }}</p>
      </div>
      <div class="xl:col-span-2">
        <p class="text-xs text-gray-400 xl:hidden">Key</p>
        <p class="">{{ fulfillment.key }}</p>
      </div>
      <div class="xl:col-span-3">
        <p class="text-xs text-gray-400 xl:hidden">Description</p>
        <p>{{ fulfillment.description }}</p>
      </div>
      <div class="xl:col-span-1 xl:flex w-full h-full items-center">
        <p class="text-xs text-gray-400 xl:hidden">Single Meal Limit</p>
        <input
          v-if="editing"
          class="inline w-12 h-8 mb-0"
          type="number"
          step="1"
          v-model="$v.fulfillment.single_meal_limit.$model"
          @keyup.enter="saveFulfillment"
        />
        <p v-else>{{ fulfillment.single_meal_limit }}</p>
      </div>
      <div class="xl:col-span-1 xl:flex w-full h-full items-center">
        <p class="text-xs text-gray-400 xl:hidden">Trade Limit</p>
        <input
          v-if="editing"
          class="inline w-12 h-8 mb-0"
          type="number"
          step="1"
          v-model="$v.fulfillment.trade_limit.$model"
          @keyup.enter="saveFulfillment"
        />
        <p v-else>{{ fulfillment.trade_limit }}</p>
      </div>
      <div class="xl:col-span-1 xl:flex w-full items-center justify-center">
        <div
          class="flex flex-row xl:flex-col gap-1 xl:items-end items-center h-full w-full"
        >
          <button
            v-if="editing"
            class="btn xs mint xl:w-full w-24 !px-0 h-8"
            @click="saveFulfillment"
          >
            <i class="fa fa-save" aria-hidden="true"></i>
          </button>
          <button
            v-else
            class="btn xs xl:w-full w-24 !px-0 h-8"
            @click="openEdit"
          >
            <i class="ml-[3px] far fa-edit"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FulfillmentCard",

  props: {
    fulfillment: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      editing: false,
      showHint: false
    };
  },

  computed: {},

  methods: {
    openEdit() {
      this.editing = true;
    },
    saveFulfillment() {
      this.$app.api.fulfillments
        .updateFulfillment(this.fulfillment.id, {
          single_meal_limit: this.$v.fulfillment.single_meal_limit.$model,
          trade_limit: this.$v.fulfillment.trade_limit.$model
        })
        .then(response => {
          this.$emit("update");
        });
      this.$emit("update", this.fulfillment);
      this.editing = false;
    }
  },

  validations: {
    fulfillment: {
      id: {},
      name: {},
      key: {},
      description: {},
      single_meal_limit: {},
      trade_limit: {}
    }
  }
};
</script>

<style lang="scss" scoped></style>
