<template>
  <div>
    <div v-if="loading.user" class="flex items-center space-x-5">
      <Loader class="w-10 h-10 text-green" />
      <p>Loading user details...</p>
    </div>
    <div v-else-if="user">
      <!-- Heading -->
      <div class="mb-14 flex justify-between">
        <div>
          <h2 class="mb-2">User #{{ user.id }}</h2>
          <h3>{{ user.first_name }} {{ user.last_name }}</h3>
        </div>
      </div>

      <!-- Details -->
      <div class="gap-y-6">
        <div class="mb-2">
          <h6 class="label">Email</h6>
          <input
            type="text"
            class="w-1/2"
            v-model="user.email"
            :class="{ 'border-red-500': !isValidEmail }"
          />
          <p v-if="!isValidEmail" class="text-sm text-red-500">Invalid email</p>
        </div>
        <div class="mb-4">
          <h6 class="label">Phone</h6>
          <input
            type="text"
            class="w-1/2"
            v-model="user.phone"
            :class="{ 'border-red-500': !isValidPhone }"
          />
          <p v-if="!isValidPhone" class="text-sm text-red-500">
            Phone number must be 10 digits
          </p>
        </div>
        <button
          class="btn px-6"
          :disabled="!isValidEmail || !isValidPhone"
          @click="saveUser"
        >
          Save
        </button>
        <p v-if="error" class="text-red-500">{{ error }}</p>
      </div>
    </div>
    <div v-else>
      <p>
        <i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>
        Whoops... something went wrong!
      </p>
    </div>
  </div>
</template>

<script>
import Loader from "@components/global/Loader";

export default {
  components: {
    Loader
  },

  metaInfo() {
    return {
      title: this.user?.id ? `User #${this.user?.id}` : "User Details"
    };
  },

  data() {
    return {
      loading: {
        user: false
      },
      user: null,
      error: null
    };
  },

  computed: {
    isValidEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.user?.email || "");
    },

    isValidPhone() {
      const phonePattern = /^\d{10}$/;
      return phonePattern.test(this.user?.phone || "");
    }
  },

  methods: {
    async getUser() {
      this.loading.user = true;
      return this.$app.api.users
        .getUser(this.$route.params.id)
        .then(res => {
          this.user = res.data;
          this.user.phone = this.user.phone.replace(/\D/g, "");
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.loading.user = false));
    },
    async saveUser() {
      this.error = null;

      try {
        // Update user in backend
        await this.$app.api.users.updateUser(this.user.id, this.user);

        // Sync Firebase email
        await this.$app.api.users.syncFirebaseEmail(this.user.email);

        // Sync Firebase phone number
        await this.$app.api.users.syncFirebaseNumber(this.user.phone);

        // If all succeeded, navigate to user page
        this.$router.push({
          name: "user",
          params: { id: this.user.id }
        });
      } catch (err) {
        // Handle error if any promise fails
        console.error(err);

        // Show the error message from the response if available
        this.error = "An error occurred while saving the user. Make sure a different user with the same email or phone number doesn't exist.";

        // Prevent any further actions, like page navigation, by returning early
        return;
      }
    }
  },

  async created() {
    await Promise.all([this.getUser()]);
  }
};
</script>

<style></style>
