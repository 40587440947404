import { DateTime } from 'luxon'

const state = () => {
    return {
        locations: [],
        loading: false,
        error: null,
        pulled_at: null,
    }
}

const getters = {
    getAllLocations(state) {
        return state.locations
    },
    getAllActiveLocations(state) {
        return state.locations.filter((location) => { return location?.active === true })
    },
    getLocationsByCustomClass(state) {
        return state.locations.filter((location) => { return location?.allow_custom_class && location?.calendar_id })
    },
    getLocationsByFulfillment: (state) => (fulfillment_id) => {
        return state.locations.filter((location) => { return location?.location_fulfillment_ids.includes(fulfillment_id) })
    },
}

const mutations = {
    setLocations(state, value) {
        state.locations = value
    },
    setLoading(state, value) {
        state.loading = value
    },
    setPulledAt(state, value) {
        state.pulled_at = value
    },
    setError(state, value) {
        state.error = value
    },
    reset(storeState){
        Object.assign(storeState, state())
    }
}

const actions = {
    getLocations({commit, state}, params = null) {
        const defaultParams = () => {
            return {
                active: true,
                // class_public: true,
                // class_future: true,
                relations: 'address',
            }
        }
        let locationParams = params
        if(!params) locationParams = defaultParams()

        if(state.loading) return
        if(state.pulled_at && DateTime.fromMillis(state.pulled_at).plus({minutes: 60}) > DateTime.now().toMillis()) return

        commit('setLoading', true)
        this._vm.$app.api.locations.getLocations(params)
            .then((response) => {
                commit('setLocations', response.data)
                commit('setPulledAt', DateTime.now().toMillis())
            })
            .catch((error) => {
                // TODO: plug into an error reporting system
                console.error(error)
                commit('setError', error)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    resetState({commit}) {
        return new Promise((resolve, reject) => {
            commit('reset')
            resolve()
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}
