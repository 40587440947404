<template>
    <ListView :headers="headers">
        <template #list>
            <slot>
                <div v-for="newsItem in newsItems" :key="newsItem.id" class="mb-5 xl:mb-0">
                    <NewsroomCard :newsItem="newsItem" :newsType="newsType" v-on="$listeners"/>
                </div>
            </slot>
        </template>
    </ListView>
</template>

<script>
import ListView from '@components/global/ListView'
import NewsroomCard from '@components/newsroom/NewsroomCard'

export default {
    props: {
        newsType: {
            type: String,
            required: true,
            validator: function(type) {
                return ['pressRelease', 'newsLink'].includes(type)
            },
        },
        newsItems: {
            type: Array,
            default: function() {
                return []
            },
        },
        headers: {
            type: Array,
            default: function() {
                return [
                    'Title',
                    'Status',
                    'Publish Date',
                    ''
                ]
            },
        }
    },

    components: {
        ListView,
        NewsroomCard,
    },
}
</script>

<style lang="scss" scoped>
</style>
