import Vue from 'vue'
import API from './api'

export default class DefaultApp {
    constructor(router, store){
        this.events = new Vue()
        this.api = new API()
        this.router = router
        this.store = store
        this.services = {}
    }
}
