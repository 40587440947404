<template>
  <div class="min-h-screen min-w-screen bg-cream py-10 md:py-24 px-5">
    <div class="mb-10">
      <img src="/img/logos/logo.svg" class="mx-auto w-60" />
    </div>
    <div
      class="flex flex-col px-6 xs:px-10 sm:px-14 pt-14 xs:pt-20 pb-10 xs:pb-16 bg-white rounded-md w-full max-w-md mx-auto items-center"
    >
      <div
        @click="loginModalOpen = true"
        class="btn btn-green mb-4 mt-1 w-full cursor-pointer"
      >
        SIGN IN
      </div>
      <p>
        Having trouble? Make sure you're using the correct email/phone/Google account, and
        that your account has admin access. Contact an administrator if you need help.
      </p>
      <!-- <form class="cp-account-form" v-on:submit.prevent="loginWithEmailPassword">
        <div class="mb-3">
          <label class="block text-sm mb-1">Email Address</label>
          <input
            v-model="$v.email.$model"
            type="email"
            class="w-full"
            :class="{ error: $v.email.$error }"
            placeholder="Enter email address"
            autocomplete="username"
          />
          <template v-if="$v.email.$error">
            <p v-if="!$v.email.required" class="text-red-500 text-xs">
              Email is required.
            </p>
            <p v-if="!$v.email.email" class="text-red-500 text-xs">
              Please enter a valid email address.
            </p>
          </template>
        </div>
        <div class="mb-8">
          <label class="block text-sm mb-1">Password</label>
          <input
            v-model="$v.password.$model"
            type="password"
            class="w-full placeholder-wider"
            :class="{ error: $v.password.$error }"
            placeholder="••••••••"
            autocomplete="current-password"
          />
          <p
            v-if="$v.password.$error && !$v.password.required"
            class="text-red-500 text-xs"
          >
            Password is required.
          </p>
        </div>
        <button class="btn mx-auto w-full text-base" :disabled="$v.$invalid || loading">
          login
        </button>
      </form>
      <div class="mt-5 text-center">
        <router-link to="/forgot-password" class="">Forgot password?</router-link>
      </div> -->
      <div v-if="error" class="mt-5">
        <p class="text-red">{{ error }}</p>
      </div>
    </div>
    <AdminLoginModal :isOpen="loginModalOpen" @close="loginModalOpen = false" />
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import AdminLoginModal from '@/components/global/modals/AdminLoginModal.vue'

export default {
  name: 'Login',
  components: {
    AdminLoginModal,
  },
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      loginMethods: [],
      error: null,
      loginModalOpen: true,
      email_login_errors: {
        'auth/wrong-password': 'Invalid password provided.',
        'auth/user-not-found': 'Account not found.',
        'auth/user-disabled': 'This account has been disabled.',
        'auth/invalid-email': 'The email provided is invalid.',
        'auth/unauthorized':
          'Account not authorized. Please contact an administrator for access.',
      },
    }
  },
  methods: {
    getSignInMethods() {
      return this.$app.services.auth
        .getSignInMethods(this.email)
        .then((methods) => {
          this.loginMethods = methods
          if (methods.length == 0) this.error = 'No account found for the email provided.'
        })
        .catch((error) => {
          console.error(error)
        })
    },
    loginWithEmailPassword() {
      this.error = false
      this.loading = true
      return this.$app.services.auth
        .loginWithEmailPassword(this.email, this.password)
        .then((user) => {
          if (!this.$app.services.auth.hasPermission('websites.admin.access')) {
            return this.$app.services.auth.logout().then(() => {
              throw { code: 'auth/unauthorized' }
            })
          }
          this.$router.push('/dashboard')
        })
        .catch((error) => {
          this.error =
            this.email_login_errors[error.code] ??
            'Failed to login. Please verify your information and try again.'
        })
        .finally(() => (this.loading = false))
    },
    loginWithProvider(provider) {
      return this.$app.services.auth
        .loginWithProvider(provider)
        .then((user) => {
          this.$router.push('/account')
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  components: { AdminLoginModal },
}
</script>

<style lang="scss" scoped></style>
