<template>
    <span class="badge decoration-clone" :class="[color]">
        {{ status.name }}
    </span>
</template>

<script>
export default {
    props: {
        status: {
            type: Object,
            required: true
        }
    },

    computed: {
        color(){
            switch(this.status.id){
                case 3: return 'green'
                default: return 'gray'
            }
        }
    }
}
</script>

<style>

</style>
