<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Generate Kiyomi Report?</h3>
                <button class="px-2 text-xl text-green" @click="close" :disabled="isProcessing"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="mb-6">
                <p class="mb-2">The process may take a few minutes to complete. Upon completion, you will be
                    automatically
                    redirected to the google sheet.</p>
                <div v-if="isProcessing && !error" class="flex items-center space-x-1">
                    <span class="mr-2">Generating kiyomi report</span>
                    <Loader class="w-5 h-5" />
                </div>
                <div v-if="error" class="flex items-center space-x-1">
                    <span class="text-red">Error: {{ error }}</span>
                </div>
            </div>
            <div class="flex space-x-5">
                <button class="btn px-6" @click="generateKiyomiReport" :disabled="isProcessing">Generate</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'

export default {
    name: "ExportClasses",

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        startDate: {
            type: String,
            required: true,
        },
        endDate: {
            type: String,
            required: true,
        },
        locationIds: {
            type: String,
            required: true,
        },
    },

    components: {
        Modal,
        Loader
    },

    data() {
        return {
            isProcessing: false,
            error: null,
        };
    },

    methods: {
        close() {
            this.$emit('close')
        },
        generateKiyomiReport() {
            let params = {}

            if (this.startDate && this.endDate) {
                params.class_after_date = this.startDate
                params.class_before_date = this.endDate
            } else {
                return
            }

            if (this.locationIds) {
                params.location_ids = this.locationIds
            }

            this.isProcessing = true
            this.$app.api.classes
                .generateKiyomi(params)
                .then((response) => {
                    this.checkJobStatus('KiyomiReportJob');
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        checkJobStatus(jobType) {
            let uuid = null;
            const interval = setInterval(() => {
                this.$app.api.jobs.getJob({
                    jobType: jobType
                }).then(response => {
                    if (response.data) {
                        uuid = response.data;
                    } else {
                        clearInterval(interval);
                        if (uuid) {
                            this.checkFailedJob(uuid);
                        } else {
                            console.error('Something went wrong: UUID is null.');
                            this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                        }
                    }
                }).catch(error => {
                    console.error('Error:', error);
                    clearInterval(interval);
                    this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                });
            }, 5000);
        },
        checkFailedJob(uuid) {
            this.$app.api.jobs.checkFailedJob(uuid).then(response => {
                if (!response.data.failed) {
                    this.$app.api.jobs.checkJobResult({
                        jobType: 'KiyomiReportJob'
                    }).then(response => {
                        if (response.data) {
                            const spreadsheetId = response.data.result;
                            const url = `https://docs.google.com/spreadsheets/d/${spreadsheetId}`;
                            window.open(url, '_blank');
                            this.visible = false;
                            this.close();
                        } else {
                            console.error('Something went wrong: Job failed.');
                            this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                        }
                        this.isProcessing = false;
                    }).catch(error => {
                        console.error(error);
                        this.isProcessing = false;
                        this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                    });
                } else {
                    console.error('Something went wrong: Job failed.');
                    this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                }
                this.isProcessing = false;
            }).catch(error => {
                console.error(error);
                this.isProcessing = false;
                this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
            });
        },
    },
}
</script>

<style scoped></style>
