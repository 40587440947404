import AnnouncementRoutes from './announcements'
import LinksRoutes from './links'
import ClassRoutes from './classes'
import FulfillmentRoutes from './fulfillments'
import ShippingRoutes from './shipping'
import LoginRoutes from './login'
import OrderRoutes from './orders'
import RegistrationRoutes from './registrations'
import PromotionRoutes from './promotions'
import PaymentRoutes from './payments'
import BlogRoutes from './blog'
import CalendarRoutes from './calendars'
import LocationRoutes from './locations'
import NewsroomRoutes from './newsroom'
import ReportsRoutes from './reports'
import GiftCards from './giftCards'
import UsersRoutes from "./users";
import MealsRoutes from "./meals";
import MenusRoutes from "./menus";

const routes = [
  ...AnnouncementRoutes,
  ...LinksRoutes,
  ...LoginRoutes,
  ...ClassRoutes,
  ...FulfillmentRoutes,
  ...ShippingRoutes,
  ...RegistrationRoutes,
  ...OrderRoutes,
  ...GiftCards,
  ...PromotionRoutes,
  ...PaymentRoutes,
  ...BlogRoutes,
  ...CalendarRoutes,
  ...LocationRoutes,
  ...NewsroomRoutes,
  ...ReportsRoutes,
  ...UsersRoutes,
  ...MealsRoutes,
  ...MenusRoutes,
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
      roles: [],
      permissions: []
    }
  }
];

export default routes
