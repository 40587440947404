<template>
    <div class="bg-gray-100 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
        <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 gap-2 xl:px-3">
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Location ID</p>
                <p>{{ location.id }}</p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Location Name</p>
                <template v-if="links">
                    <router-link class="hover:underline block" :to="`/locations/${location.id}`">
                        <p class="font-semibold">{{ location.name }}</p>
                    </router-link>
                </template>
                <template v-else>
                    <p class="font-semibold">{{ location.name }}</p>
                </template>
                <p v-if="location.allow_custom_class" >
                    <span class="badge small">Custom Class</span>
                </p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Store name</p>
                <p>{{ location.store_name }}</p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Store Address</p>
                <p>{{ location.address.street_1 }}</p>
                <p v-if="location.address.street_2">{{ location.address.street_2 }}</p>
                <p>{{ location.address.locality }}, {{ location.address.administrative_area }} {{ location.address.postal_code }}</p>
            </div>
            <div class="col-span-2">
                <p class="text-xs text-gray-400 xl:hidden">Lead</p>
                <p>{{ location.lead.name }}</p>
                <p>{{ location.lead.email }}</p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Status</p>
                <p>
                    <StatusBadge class="text-xs" :location="location"/>
                </p>
            </div>
            <div class="flex flex-wrap">
                <p v-if="canEditLocation">
                    <button @click="editLocation(location.id)" class="btn xs text-green-600 bg-mint mr-2">Edit</button>
                </p>
                <p v-if="canDeleteLocation">
                    <button @click="deleteLocation(location.id)" class="btn xs gray mr-2">Delete</button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from '@components/locations/StatusBadge'

export default {
    name: "LocationCard",

    props: {
        location: {
            type: Object,
            required: true,
        },
        links: {
            type: Boolean,
            default: true,
        }
    },

    components: {
        StatusBadge,
    },

    computed: {
        canEditLocation() {
            return this.$app.services.auth.hasPermission('locations.update')
        },
        canDeleteLocation() {
            return this.$app.services.auth.hasPermission('locations.delete')
        },
    },

    methods: {
        editLocation(locationId) {
            this.$router.push(`/locations/${locationId}/edit`)
        },
        deleteLocation(locationId) {
            this.$emit('delete', locationId)
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
