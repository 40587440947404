<template>
  <div class="floating-input-wrap">
    <input
      v-if="type === 'tel'"
      v-maska
      data-maska="(###) ###-####"
      class="floating-input floating-text"
      ref="inputRef"
      :class="{ 'is-valid': modelValue, error: error, readonly: readonly }"
      :key="'phone-' + id"
      :value="modelValue"
      :type="type"
      :id="id"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      @input="handleInput"
      @blur="handleBlur"
      @keyup.enter="handleEnter"
    />
    <!-- Unfortunately, maska doesn't seem to accept variable input, hence the code duplication -->
    <input
      v-else
      class="floating-input floating-text"
      ref="inputRef"
      :class="{ 'is-valid': modelValue, error: error, readonly: readonly }"
      :key="id"
      :value="modelValue"
      :type="type"
      :id="id"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      @input="handleInput"
      @blur="handleBlur"
      @keyup.enter="handleEnter"
    />
    <!-- Does @keyup.enter work on mobile devices? -->
    <label class="floating-label" :key="label" :for="id">{{ label }}</label>
  </div>
</template>

<script>
import { vMaska } from 'maska'

export default {
  directives: {
    maska: vMaska,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    modelValue: {
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value)
    },
    handleBlur(event) {
      if (event.target.value) {
        event.srcElement.classList.add('is-valid')
      } else {
        event.srcElement.classList.remove('is-valid')
      }
      this.$emit('blur', event)
    },
    handleEnter() {
      this.$emit('enterPressed')
    },
    focus() {
      this.$refs.inputRef.focus()
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.floating-input-wrap {
  position: relative;
  margin-bottom: 12px;
  width: 100%;

  .floating-label {
    position: absolute;
    color: #183d1d;
    pointer-events: none;
    left: 14px;
    top: 13px;
    transition: 0.2s ease all;
    font-size: 16px;
    font-family: Avenir Next;
    letter-spacing: 0.3px;
  }

  .floating-input {
    font-size: 16px;
    width: 100%;
    border-radius: 7px;
    color: #183d1d;
    border: 1px solid #afd9b1;
    padding: 21px 12px 7px;
    font-family: Avenir Next;
    letter-spacing: 0.3px;
    margin-bottom: 0;

    &:focus {
      border: 2px solid #183d1d;
      padding-left: 11px;
      &::placeholder {
        opacity: 1;
      }
      & + .floating-label {
        top: 5px;
        bottom: 10px;
        left: 14px;
        font-size: 12px;
        opacity: 1;
        font-weight: 600;
      }
    }
    &::placeholder {
      opacity: 0;
      transition: 0.05s;
    }

    &.is-valid + .floating-label {
      top: 5px;
      bottom: 10px;
      left: 14px;
      font-size: 12px;
      opacity: 1;
      font-weight: 600;
    }

    &:autofill + .floating-label {
      top: 5px;
      bottom: 10px;
      left: 14px;
      font-size: 12px;
      opacity: 1;
      font-weight: 600;
      transition: 0s;
    }

    &.error {
      border: 2px solid #dc3e1b;
    }

    &.readonly {
      background-color: inherit;
      color: #183d1d;
      cursor: default;

      &.focus {
        border: 1px solid #afd9b1;
      }
    }
  }
}

.floating-text {
  height: 50px;
}

.floating-text-area {
  height: 80px;
  resize: vertical;
}
</style>
