<template>
    <div class="bg-gray-100 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
        <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 gap-2 xl:px-3">
            <div class="col-span-3">
                <p class="text-xs text-gray-400 xl:hidden">Title</p>
                <router-link v-if="canEditNewsItem" class="hover:underline block" :to="`${subpath}/${newsItem.article ? newsItem.article.slug : newsItem.id}`">
                    <p class="font-semibold">{{ newsItem.article ? newsItem.article.title : newsItem.title }}</p>
                </router-link>
                <p v-else class="font-semibold">{{ newsItem.article ? newsItem.article.title : newsItem.title }}</p>
            </div>

            <div class="col-span-1">
                <p class="text-xs text-gray-400 xl:hidden">Status</p>
                <p>
                    <StatusBadge class="text-xs" :newsItemStatus="getNewsItemStatus(newsItem)" />
                </p>
            </div>

            <div class="col-span-2">
                <p class="text-xs text-gray-400 xl:hidden">Publish Date</p>
                <Date v-if="newsItem.published_at" :date="newsItem.published_at" preset="DATETIME_FULL" class="text-sm" :class="{ 'font-semibold': isPast(newsItem.published_at) }" />
            </div>
            
            <div class="col-span-2 flex flex-wrap">
                <p v-if="canEditNewsItem && newsItem.status.key !== 'archived'">
                    <button @click="editNewsItem(newsItem)" class="btn xs text-green-600 bg-mint mr-2">Edit</button>
                </p>

                <p v-if="canDeleteNewsItem && newsItem.status.key === 'draft'">
                    <button @click="deleteNewsItem(newsItem)" class="btn xs gray mr-2">Delete</button>
                </p>
                <p v-else-if="canDeleteNewsItem && newsItem.status.key === 'published'">
                    <button @click="archiveNewsItem(newsItem.id)" class="btn xs gray mr-2">Archive</button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from '@components/newsroom/StatusBadge'
import Date from '@components/global/dates/Date'
import { DateTime } from 'luxon'

export default {
    name: 'NewsroomCard',

    components: {
        StatusBadge,
        Date,
    },

    props: {
        newsItem: {
            type: Object,
            required: true,
        },
        newsType: {
            type: String,
            required: true,
            validator: function(type) {
                return ['pressRelease', 'newsLink'].includes(type)
            },
        },
    },

    computed: {
        canEditNewsItem() {
            return this.$app.services.auth.hasPermission(`newsroom.${this.subpermission}.update`)
        },
        canDeleteNewsItem() {
            return this.$app.services.auth.hasPermission(`newsroom.${this.subpermission}.delete`)
        },
        subpath() {
            return this.newsType === 'pressRelease' ? 'press-releases' : 'in-the-news'
        },
        subpermission() {
            return this.newsType === 'pressRelease' ? 'pressreleases' : 'newslinks'
        },
    },

    methods: {
        editNewsItem(newsItem) {
            this.$router.push(`/newsroom/${this.subpath}/${newsItem.article ? newsItem.article.slug : newsItem.id}`)
        },

        deleteNewsItem(newsItem) {
            if (newsItem.status.key !== 'draft') return;
            this.$emit('delete', newsItem.id)
        },

        archiveNewsItem(newsItemId) {
            this.$emit('archive', newsItemId)
        },

        isPast(date) {
            return DateTime.now() >= DateTime.fromISO(date)
        },

        getNewsItemStatus(newsItem) {
            if (!newsItem.published_at || this.isPast(newsItem.published_at)) return newsItem.status
            else return { name: 'Scheduled' }
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
