<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Archive Blog Post</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="mb-10">
                <p>Are you sure you want to archive this blog post? This will immediately remove the blog post from the public site!</p>
            </div>
            <div class="flex space-x-5">
                <button class="btn white px-6" @click="close">Cancel</button>
                <button class="btn button-green px-6" @click="archiveBlog" :disabled="loading || success">
                    <div v-if="loading" class="flex items-center space-x-1"><Loader class="w-5 h-5"/><span>Archiving...</span></div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Archived!</span>
                    <span v-else>Archive</span>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'

export default {
    props: {
        blogId: {
            type: Number|String,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Modal,
        Loader
    },
    data(){
        return {
            loading: false,
            success: false,
        }
    },
    methods: {
        close(){
            this.$emit('close')
        },
        archiveBlog(){
            this.loading = true

            return this.$app.api.blogs.archiveBlog(this.blogId).then(res => {
                this.success = true
                setTimeout(() => {
                    this.success = false
                    this.close()
                    this.$emit('success')
                }, 2000)
            }).catch(err => {
                console.error(err)
            }).finally(() => this.loading = false)
        }
    }
}
</script>

<style>
</style>