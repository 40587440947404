<template>
  <div class="md:grid grid-cols-1 gap-4">
    <!-- Status: Publicly Visible, Private Menu -->
    <div class="col-span-1">
      <Checkbox v-model="classEvent.privateMenu" class="mb-2 ml-1" label="Private Menu" :disabled="!!sku"
        @input="privateMenuChecked" />
      <Checkbox v-model="classEvent.classIsPublic" class="mb-2 ml-1" label="Publicly Visible" />
    </div>

    <!-- Host info -->
    <div class="col-span-1" v-if="$v.classEvent.privateMenu.$model">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Host Name*:</label>
      <input v-model="$v.classEvent.hostName.$model" type="text" class="cp-input w-2/12 mb-2 ml-1" />
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Host Email*:</label>
      <input v-model="$v.classEvent.hostEmail.$model" type="text" class="cp-input w-2/12 ml-1" />
    </div>

    <!-- Class type dropdown -->
    <div class="col-span-1" v-if="!$v.classEvent.privateMenu.$model">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Fulfillment Type*:</label>
      <multiselect class="max-w-xs ml-1" v-model="classEvent.fulfillmentType" :options="fulfillmentTypes"
        :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
        :disabled="$v.classEvent.privateMenu.$model || isCouples || classEvent.registrations" label="name" track-by="id"
        placeholder="Select fulfillment type">
        <template slot="placeholder"><span class="text-gray-400">Select fulfillment type</span></template>

        <template #caret="{ toggle }">
          <div class="multiselect__select" @mousedown.prevent.stop="toggle">
            <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
          </div>
        </template>
      </multiselect>
    </div>

    <!-- Location dropdown -->
    <div class="col-span-1">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Location*:</label>
      <multiselect class="max-w-xs ml-1" v-model="classEvent.location" :options="locations.data" :searchable="true"
        :close-on-select="true" :show-labels="false" :allow-empty="false" label="name" track-by="id"
        :loading="locations.loading" placeholder="Select a location" :disabled="!!this.sku">

        <template slot="placeholder"><span class="text-gray-400">Select a location</span></template>

        <template #caret="{ toggle }">
          <div class="multiselect__select" @mousedown.prevent.stop="toggle">
            <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
          </div>
        </template>
      </multiselect>
    </div>

    <!-- Reservation size dropdown -->
    <div class="col-span-1">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Reservation Size*:</label>
      <multiselect class="max-w-xs ml-1" v-model="classEvent.reservationSize" :options="reservationSizes"
        :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
        :disabled="classEvent.registrations" label="name" track-by="id" placeholder="Select reservation type"
        @input="reservationSizeSelected">

        <template slot="placeholder"><span class="text-gray-400">Select reservation type</span></template>

        <template #caret="{ toggle }">
          <div class="multiselect__select" @mousedown.prevent.stop="toggle">
            <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
          </div>
        </template>
      </multiselect>
    </div>

    <!-- Number of Participants -->
    <div class="col-span-1">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">
        Number of Participants*:
      </label>
      <input v-model="classEvent.capacity" type="number" class="cp-input w-6/12 md:w-1/12 mb-2 ml-1" min="1" />
    </div>

    <!-- Date & Time -->
    <div class="col-span-1">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Date & Time*:</label>
      <v-date-picker class="mb-2 ml-1" v-model="classEvent.date"
        :timezone="classEvent.location ? classEvent.location.timezone : 'America/Denver'" :mode="classEvent.fulfillmentType && classEvent.fulfillmentType.key === 'delivery'
          ? 'date'
          : 'dateTime'
          " :min-date="minDate" @input="handleDateInput" />
    </div>

    <!-- Menu dropdown -->
    <div class="col-span-1" v-if="!$v.classEvent.privateMenu.$model">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Menu*:</label>
      <multiselect class="max-w-xs ml-1" v-model="classEvent.menu" :options="menus.data" :searchable="true"
        :close-on-select="true" :show-labels="false" :allow-empty="false" :disabled="classEvent.registrations"
        label="name" track-by="id" :loading="menus.loading" placeholder="Select an active menu">

        <template slot="placeholder"><span class="text-gray-400">Select a menu</span></template>

        <template #caret="{ toggle }">
          <div class="multiselect__select" @mousedown.prevent.stop="toggle">
            <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
          </div>
        </template>
      </multiselect>
    </div>

    <!-- Menu creation for private class -->
    <div class="col-span-1" v-if="$v.classEvent.privateMenu.$model && !this.sku">
      <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Menu Type*:</label>
      <div class="mb-2 ml-1">
        <multiselect class="max-w-xs" v-model="menu.type" :options="types" :searchable="false" :close-on-select="true"
          :show-labels="false" :allow-empty="false" label="name" track-by="id" placeholder="Select status"
          @input="typeChanged">

          <template slot="placeholder"><span class="text-gray-400">Select status</span></template>

          <template #caret="{ toggle }">
            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
              <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="col-span-1" v-if="$v.classEvent.privateMenu.$model && !this.sku">
      <label class="w-5/12 block text-xs font-body text-gray-dark mb-2 ml-1">Meals*:</label>
      <div v-for="(selection, index) in menu.selections" :key="index" class="mb-2 ml-1 flex">
        <multiselect class="w-5/12 multiselect mr-8" :options="filteredMealOptions[index]"
          v-model="menu.selections[index].meal" label="name" track-by="id" :loading="meals.loading"
          :placeholder="'Select Meal ' + (index + 1)">

          <template slot="caret" slot-scope="{ toggle }">
            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
              <img class="absolute top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] select-none"
                src="/img/icons/down-chevron.svg" alt="icon" />
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <div v-if="classEvent.menu">
      <div class="flex space-x-2 mb-2 items-center ml-1">
        <h6><i class="fas fa-fw fa-map mr-2"></i>{{ classEvent.menu.name }} Meals</h6>
      </div>
      <div v-for="meal in filteredMeals" :key="meal.id" class="py-1 ml-1">
        <span>{{ meal.meal.name }}</span>
        <span v-if="meal.delivery_only"> (Delivery only)</span>
      </div>
    </div>

    <!-- Notes -->
    <div class="col-span-1">
      <Textbox v-model="classEvent.notes" class="w-full md:w-5/12 mb-2 ml-1" label="Notes:" placeholder="" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { required, requiredIf } from 'vuelidate/lib/validators'
import Textbox from '@/components/global/forms/Textbox'
import Checkbox from '@components/global/forms/Checkbox'
import { DateTime } from 'luxon'

export default {
  name: 'ClassForm',
  components: {
    Textbox,
    Checkbox,
  },

  props: {
    value: {
      default: null,
    },
    sku: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      classEvent: null,
      menu: {
        selections: this.initializeSelections(),
        month_year: null,
        type: { id: 1, name: 'Standard', key: 'standard' },
      },
      meals: {
        loading: false,
        data: [],
      },
      types: [
        { id: 1, name: 'Standard', key: 'standard' },
        { id: 5, name: 'Vegetarian', key: 'vegetarian' },
        { id: 6, name: 'Soup', key: 'soup' },
      ],
      minDate: DateTime.now().plus({ days: 1 }).toISODate(),
      selectedMonth: null,
      menus: {
        loading: false,
        data: [],
      },
      locations: {
        loading: false,
        data: [],
      },
      fulfillmentTypes: [
        { id: 1, name: 'In-person Preparation Class', key: 'in-person' },
        { id: 2, name: 'Pre-assembled Meals', key: 'pre-assembled' },
        { id: 3, name: 'Delivery', key: 'delivery' },
      ],
      reservationSizes: [
        { id: 1, name: 'Individual', key: 'individual' },
        { id: 2, name: 'Couples', key: 'couples' },
      ],
      loading: {
        classEvent: false,
      },
    }
  },

  computed: {
    filteredMeals() {
      if (!this.classEvent.menu) return []
      if (
        !this.classEvent.fulfillmentType ||
        this.classEvent.fulfillmentType.key === 'delivery' ||
        this.classEvent.fulfillmentType.key === 'pre-assembled'
      ) {
        return this.classEvent.menu.meals
      }
      return this.classEvent.menu.meals.filter((meal) => !meal.delivery_only)
    },
    filteredMealOptions() {
      return this.menu.selections.map((selected, index) => {
        return this.meals.data.filter((meal) => {
          return !this.menu.selections.some(
            (otherSelected, otherIndex) =>
              otherIndex !== index &&
              otherSelected.meal &&
              otherSelected.meal.id === meal.id
          )
        })
      })
    },
    isValid() {
      let valid = !this.$v.classEvent.$invalid
      if (this.classEvent.privateMenu && !this.sku) {
        valid = valid && !this.$v.menu.$invalid
      }
      return valid
    },
    isCouples() {
      return (
        this.classEvent.reservationSize &&
        this.classEvent.reservationSize.key === 'couples'
      )
    },
    canCreateClass() {
      return this.$app.services.auth.hasPermission('classes.create')
    },
    canOverrideClass() {
      return this.$app.services.auth.hasPermission('classes.override')
    },
    capacity: function () {
      if (this.sku) return this.classEvent.capacity

      const { location, reservationSize, fulfillmentType } = this.classEvent
      if (!location || !reservationSize) return null

      if (reservationSize && reservationSize.key.includes('couples'))
        return location.couple_class_size
      if (
        fulfillmentType &&
        fulfillmentType.key === 'pre-assembled' &&
        location.pickup_class_size
      )
        return location.pickup_class_size
      return location.default_class_size
    },
  },

  methods: {
    initializeSelections() {
      return Array.from({ length: 10 }, () => ({ meal: null, delivery: false }))
    },
    emitClassEvent() {
      let emitData = {
        classEvent: this.classEvent,
        isValid: this.isValid,
      }

      if (this.classEvent.privateMenu) {
        emitData.menu = this.menu
      }

      this.$emit('input', emitData)
    },
    handleDateInput(date) {
      const isoDate = date instanceof Date ? date.toISOString() : date

      if (
        this.classEvent.fulfillmentType &&
        this.classEvent.fulfillmentType.key === 'delivery'
      ) {
        this.classEvent.date = isoDate.startOfDay()
      }

      try {
        if (!this.sku) {
          this.classEvent.menu = null
          if (!this.classEvent.privateMenu) {
            this.selectedMonth = DateTime.fromISO(isoDate).toFormat('yyyy-MM') + '-01'
            this.getMenus()
          }
        }
      } catch (error) {
        console.error('Invalid DateTime:', error)
      }
    },
    startOfDay(date) {
      const newDate = new Date(date)
      newDate.setHours(0, 0, 0, 0)
      return newDate
    },
    getMeals() {
      let params = {
        active: true,
      }

      if (this.menu.type.id > 1) {
        params.type = this.menu.type.id
      }

      this.meals.loading = true
      return this.$app.api.meals
        .getMeals(params)
        .then((response) => {
          this.meals.data = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (this.meals.loading = false))
    },
    getMenus() {
      this.menus.loading = true
      return this.$app.api.menus
        .getMenus({
          future: true,
          custom: false,
          month: this.selectedMonth,
        })
        .then((response) => {
          this.menus.data = response.data.data
          if (this.menus.data.length === 1) {
            this.classEvent.menu = this.menus.data[0]
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (this.menus.loading = false))
    },
    getLocations() {
      let params = {
        active: true,
        relations: 'address|calendar|calendar.events|calendar.events.status',
      }

      this.locations.loading = true
      return this.$app.api.locations
        .getLocations(params)
        .then((response) => {
          this.locations.data = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.locations.loading = false
        })
    },
    privateMenuChecked() {
      this.classEvent.menu = null
      if (!this.classEvent.privateMenu) {
        this.classEvent.classIsPublic = true
        this.classEvent.hostName = null
        this.classEvent.hostEmail = null
      } else {
        this.classEvent.classIsPublic = false
        this.classEvent.fulfillmentType = this.fulfillmentTypes.find(
          (type) => type.key === 'in-person'
        )
      }
    },
    reservationSizeSelected() {
      if (this.isCouples) {
        this.classEvent.fulfillmentType = this.fulfillmentTypes.find(
          (type) => type.key === 'in-person'
        )
      }
    },
    typeChanged() {
      this.getMeals()
    },
  },

  watch: {
    value: {
      deep: true,
      handler(newVal) {
        this.classEvent = newVal
        if (this.classEvent.customClassMeals) {
          for (let i = 0; i < this.classEvent.customClassMeals.length; i++) {
            this.menu.selections[i].meal = this.classEvent.customClassMeals[i]
          }
        }
      },
    },

    classEvent: {
      handler(value) {
        this.debouncedEmit()
      },
      deep: true,
    },

    menu: {
      handler(value) {
        this.debouncedEmit()
      },
      deep: true,
    },

    'classEvent.location': function () {
      this.classEvent.capacity = this.capacity
    },
    'classEvent.reservationSize': function () {
      this.classEvent.capacity = this.capacity
    },
    'classEvent.fulfillmentType': function () {
      this.classEvent.capacity = this.capacity
    },
  },

  async created() {
    this.debouncedEmit = _.debounce(this.emitClassEvent, 500)

    this.getMeals()
    this.getMenus()
    this.getLocations()

    if (this.value) this.classEvent = { ...this.value }
  },

  validations: {
    classEvent: {
      fulfillmentType: { required },
      menu: {
        privateMenu: requiredIf(function () {
          return !this.classEvent.privateMenu
        }),
      },
      location: { required },
      reservationSize: { required },
      capacity: { required },
      date: { required },
      classIsPublic: { required },
      privateMenu: { required },
      hostName: {
        privateMenu: requiredIf(function () {
          return this.classEvent.privateMenu
        }),
      },
      hostEmail: {
        privateMenu: requiredIf(function () {
          return this.classEvent.privateMenu
        }),
      },
      notes: {},
    },
    menu: {
      type: {
        required,
      },
      selections: {
        isValidMealsLength,
      },
    },
  },
}

function isValidMealsLength(value) {
  if (!Array.isArray(value) || value.length != 10) {
    return false
  }

  const mealCount = value.reduce((count, mealEntry) => {
    return mealEntry && mealEntry.meal != null ? count + 1 : count
  }, 0)

  return mealCount == 10
}
</script>
