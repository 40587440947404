<template>
    <div>
        <ContactForm :value="contact" @input="handleInput"/>
    </div>
</template>

<script>
import ContactForm from '@components/registrations/update/contact/ContactForm';

export default {
    name: "ContactInfo",

    components: {
        ContactForm
    },

    props: {
        contact: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            error: null,
        }
    },

    computed: {},

    methods: {
        handleInput(value) {
            if(!value.$invalid) {
                let payload = {
                    first_name: value.$model.first_name,
                    last_name: value.$model.last_name,
                    email: value.$model.email,
                    phone: value.$model.phone,
                }

                this.$app.store.commit('registration/setUpdateData', {contact: payload})
            }
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
