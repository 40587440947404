<template>
  <div>
    <div class="mb-10">
      <h2 class="mb-5">Users</h2>
      <div class="flex flex-wrap">
        <SearchInput
          class="mr-2"
          v-model="users.search"
          placeholder="Search by email, name"
          @search="runSearch"
        />
        <div class="relative mr-2">
          <div class="flex">
            <button
              class="btn bg-white text-gray-800 font-normal border border-gray-200 border-r-0 rounded-r-none capitalize py-2"
              @click.prevent="filters.showFilters = !filters.showFilters"
            >
              <span v-if="!filters.showFilters">Show Filters</span>
              <span v-else>Close Filters</span>
            </button>
            <button
              type="button"
              class="btn font-normal border border-green capitalize rounded-l-none"
              @click.prevent="applyFilters"
            >
              Apply
            </button>
          </div>
          <div
            v-show="filters.showFilters"
            class="absolute top-12 max-h-[400px] bg-white overflow-y-auto border border-gray-200 rounded-md px-4 py-2 z-50"
          >
            <div>
              <Checkbox
                v-model="filters.includeDeleted"
                label="Include Deleted"
              />
            </div>
            <div>Roles</div>
            <div>
              <div v-for="role in roles.data">
                <Checkbox
                  @input="handleRoleFilter(role)"
                  :value="roleSelected(role)"
                  :label="role.display_name"
                />
              </div>
            </div>
          </div>
        </div>
        <router-link
          class="btn green border border-green mr-2 mb-2 px-3 py-2"
          :to="`/users/create`"
        >
          <span><i class="fas fa-plus mr-2"></i>Create User</span>
        </router-link>
      </div>
    </div>
    <div>
      <template v-if="users.loading">
        <div class="flex items-center space-x-5">
          <Loader class="w-10 h-10 text-green" />
          <p>Loading users...</p>
        </div>
      </template>
      <template v-else-if="users.data.data && users.data.data.length">
        <ListView class="mb-10" :users="users.data.data" />
        <Pagination
          :page="users.page"
          :total-items="totalUsers"
          :per-page="users.perPage"
          @change="pageUpdated"
        />
      </template>
      <template v-else>
        <p>
          <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No
          users to show. Please refine your critera.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import Loader from "@components/global/Loader";
import ListView from "@components/users/ListView";
import SearchInput from "@components/global/SearchInput";
import Pagination from "@components/global/Pagination";
import Checkbox from "@components/global/forms/Checkbox";

export default {
  name: "Users",

  components: {
    Checkbox,
    Loader,
    ListView,
    SearchInput,
    Pagination
  },

  data() {
    return {
      users: {
        loading: false,
        search: this.$route.query.search ?? "",
        page: parseInt(this.$route.query.page) || 1,
        perPage: parseInt(this.$route.query.per_page) || 10,
        data: {}
      },
      roles: {
        loading: false,
        data: []
      },
      filters: {
        showFilters: false,
        includeDeleted: false,
        role_ids: []
      }
    };
  },

  computed: {
    totalUsers() {
      return this.users.data.meta?.total ?? 0;
    },
    routePath() {
      return this.$route.path;
    },
    routeName() {
      return this.$route.name;
    }
  },

  watch: {
    $route: function(route) {
      this.users.page = parseInt(route.query.page) || this.users.page;
      this.users.perPage = parseInt(route.query.per_page) || this.users.perPage;
      this.users.search = route.query.search ?? this.users.search;
      this.getUsers();
    }
  },

  methods: {
    pageUpdated(page) {
      this.users.page = page;
      this.getUsers();
    },
    runSearch() {
      this.users.page = 1;

      let query = { ...this.$route.query, search: this.users.search };
      delete query.page;

      if (!this.users.search) delete query.search;
      this.$router.replace({ path: this.$route.path, query });
      this.getUsers();
    },
    roleSelected(role) {
      return this.filters.role_ids.includes(role.id);
    },
    handleRoleFilter(role) {
      if (this.filters.role_ids.includes(role.role_id)) {
        this.filters.role_ids = this.filters.role_ids.filter(id => {
          return id !== role.role_id;
        });
      } else {
        this.filters.role_ids.push(role.role_id);
      }
    },
    applyFilters() {
      this.filters.showFilters = false;
      this.users.page = 1;
      let query = { ...this.$route.query };
      if (query.page) {
        delete query.page;
        this.$router.push({ path: this.$route.path, query });
      }

      this.getUsers();
    },
    getUsers() {
      let params = {
        page: this.$route.query.page || this.users.page,
        per_page: this.$route.query.per_page || this.users.perPage,
        include_deleted: this.filters.includeDeleted
      };

      if (this.filters.role_ids.length) {
        params.role_ids = this.filters.role_ids.join("|");
      }

      let search = this.$route.query.search || this.users.search;
      if (search) params.search = search;

      this.users.loading = true;
      return this.$app.api.users
        .getUsers(params)
        .then(response => {
          this.users.data = response.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.users.loading = false));
    },
    getRoles() {
      this.roles.loading = true;
      return this.$app.api.roles
        .getRoles()
        .then(res => {
          this.roles.data = res.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.roles.loading = false));
    }
  },

  created() {
    this.getUsers();
    this.getRoles();
  }
};
</script>

<style scoped></style>
