<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Confirm Delete</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div class="mb-4">
                <p class="text-red" v-for="error in errors">{{ error }}</p>
            </div>
            <div class="mb-10">
                <p class="mb-2">Are you sure you want to delete this event?</p>
                <p v-if="event"><span class="text-xs text-gray-400">Name:</span> {{ event.name }}</p>
            </div>
            <div class="flex space-x-5">
                <button class="btn gray px-6" @click="close">No</button>
                <button class="btn green px-6" @click="deleteEvent" :disabled="!canDeleteEvent || deleting || success">
                    <div v-if="deleting" class="flex items-center"><Loader class="w-5 mr-1"/><span>Deleting</span></div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Deleted!</span>
                    <span v-else>Yes</span>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@/components/global/Loader'

export default {
    name: "ConfirmEventDeleteModal",

    components: {
        Modal,
        Loader
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default: function () { return {} }
        }
    },

    data() {
        return {
            deleting: false,
            success: false,
            errors: [],
        }
    },

    computed: {
        canDeleteEvent() {
            return this.$app.services.auth.hasPermission('calendars.events.delete')
        },
    },

    methods: {
        reset() {
            this.deleting = false
            this.success = false
            this.errors = []
        },
        close() {
            this.$emit('close')
        },
        deleteEvent() {
            this.deleting = true
            this.$app.api.calendars.deleteEvent(this.event.id)
                .then((response) => {
                    this.success = true
                    setTimeout(() => {
                        this.success = false
                        this.close()
                        this.$emit('update', this.event)
                    }, 800)
                })
                .catch((error) => {
                    if(error.response?.data?.message) this.errors.push(error.response.data.message)
                    else this.errors.push('Oops, something went wrong.')
                })
                .finally(() => {
                    this.deleting = false
                })
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
