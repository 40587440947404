<template>
    <div>
        <div class="flex justify-between items-center mb-10">
            <h2>Vendors</h2>
            <button class="btn flex items-center mr-2" @click="syncVendors">
                <span v-if="vendors.syncing" class="mr-2">
                    <Loader class="w-5 h-5 text-white" />
                </span>
                Sync with Galley
            </button>
        </div>
        <div>
            <template v-if="vendors.loading">
                <div class="flex items-center space-x-5">
                    <Loader class="w-10 h-10 text-green" />
                    <p>Loading vendors...</p>
                </div>
            </template>
            <template v-else-if="vendors.data && vendors.data.length">
                <ListView class="mb-10" :vendors="vendors.data" />
            </template>
        </div>
    </div>
</template>

<script>
import Loader from '@components/global/Loader'
import ListView from '@components/locations/VendorsListView'

export default {
    components: {
        Loader,
        ListView,
    },

    data() {
        return {
            vendors: {
                loading: false,
                syncing: false,
                data: {},
            }
        }
    },

    methods: {
        getVendors() {
            this.vendors.loading = true
            this.$app.api.locations.getVendors()
                .then(response => {
                    this.vendors.data = response.data
                })
                .catch(error => {
                    this.$app.error.handle(error)
                })
                .finally(() => {
                    this.vendors.loading = false
                })
        },
        syncVendors() {
            this.vendors.syncing = true
            this.$app.api.locations.syncVendors()
                .then(response => {
                    this.getVendors()
                })
                .catch(error => {
                    this.$app.error.handle(error)
                })
                .finally(() => {
                    this.vendors.syncing = false
                })
        }
    },

    mounted() {
        this.getVendors()
    }
}
</script>