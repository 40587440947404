<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-visible">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Sync {{ meal.name }} with Galley?</h3>
                <button class="px-2 text-xl text-green" @click="close" :disabled="isProcessing"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="mb-6">
                <p class="mb-2">The process may take a few minutes to complete.</p>
                <div v-if="isProcessing && !error" class="flex items-center space-x-1">
                    <span class="mr-2">Syncing with Galley...</span>
                    <Loader class="w-5 h-5" />
                </div>
                <div v-if="error">
                    <p class="text-red">Error: {{ error }}</p>
                    <p v-if="invalidRecipe">If there is a corresponding Galley recipe, select it below and then try to
                        sync again.</p>
                    <multiselect v-if="invalidRecipe" class="max-w-xs mt-4 z-50" v-model="newMeal" :options="unsyncedGalleyMeals"
                        :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
                        :disabled="unsyncedGalleyMeals.length === 0" :loading="loading" label="name" track-by="id"
                        placeholder="Select galley meal">
                        <template slot="placeholder"><span class="text-gray-400">Select galley meal</span></template>
                        <template #caret="{ toggle }">
                            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                <span class="absolute right-3 top-3 select-none text-green"><i
                                        class="fas fa-chevron-down"></i></span>
                            </div>
                        </template>
                    </multiselect>
                </div>
                <div v-if="newMeal" class="mt-6">
                    <p class="mb-2">This will change <span>{{ meal.name }}</span> to have the following meal variants:</p>
                    <ul class="list-disc ml-6">
                        <li v-for="variant in mealVariants(newMeal)" :key="variant.id">{{ variant.name }}</li>
                    </ul>
                    <p class="mt-2">If you are ready to proceed, click sync. This process may take several minutes.
                    </p>
                </div>
            </div>
            <div class="flex space-x-5">
                <button class="btn px-6" @click="syncMeal" :disabled="isProcessing">Sync</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'

export default {
    name: "GalleySyncModal",

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        meal: {
            type: Object,
            required: true,
        }
    },

    components: {
        Modal,
        Loader
    },

    data() {
        return {
            isProcessing: false,
            error: null,
            invalidRecipe: false,
            newMeal: null,
            unsyncedGalleyMeals: [],
            allGalleyMeals: [],
            loading: false,
        };
    },

    methods: {
        close() {
            this.$emit('close')
        },
        fetchUnsyncedGalleyRecipes() {
            this.loading = true
            this.$app.api.meals.fetchUnsyncedGalleyRecipes().then((response) => {
                this.allGalleyMeals = Object.values(response.data.data)
                const regularMeals = this.allGalleyMeals.filter((meal) => !meal.name.includes('-') && !meal.name.toLowerCase().includes('testing'))
                const bulkMeals = this.allGalleyMeals.filter((meal) => meal.name.toLowerCase().includes('bulk'))
                this.unsyncedGalleyMeals = regularMeals.concat(bulkMeals.filter((meal) => !regularMeals.some((m) => meal.name.includes(m.name))))
            }).catch((error) => {
                this.error = error.response.data.message
            }).finally(() => {
                this.loading = false
            })
        },
        mealVariants(meal) {
            return this.allGalleyMeals.filter((m) => m.name.includes(meal.name))
        },
        syncMeal() {
            this.isProcessing = true;
            this.error = null;
            let data = {};

            if (this.newMeal) {
                data = {
                    galley_meals: this.mealVariants(this.newMeal)
                };
            }

            this.$app.api.meals.syncMeal(this.meal.id, data).then(response => {
                this.checkJobStatus('GalleySyncJob');
            }).catch(error => {
                console.error(error);
                this.isProcessing = false;
                this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
            });
        },
        checkJobStatus(jobType) {
            let uuid = null;
            const interval = setInterval(() => {
                this.$app.api.jobs.getMealJobId(this.meal.id, {
                    jobType: jobType
                }).then(response => {
                    if (response.data) {
                        uuid = response.data;
                    } else {
                        clearInterval(interval);
                        if (uuid) {
                            this.checkFailedJob(uuid);
                        } else {
                            console.error('Something went wrong: UUID is null.');
                            this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."

                            this.$app.api.meals.checkGalleyRecipe(this.meal.id).then(response => {
                                if (response.data) {
                                    this.error = response.data;
                                }
                            }).catch(error => {
                                console.error(error);
                                this.error = "Galley recipe not found."
                                this.invalidRecipe = true;
                            });

                            this.isProcessing = false;
                        }
                    }
                }).catch(error => {
                    console.error('Error:', error);
                    clearInterval(interval);
                    this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                });
            }, 5000);
        },
        checkFailedJob(uuid) {
            this.$app.api.jobs.checkFailedJob(uuid).then(response => {
                if (!response.data.failed) {
                    this.visible = false;
                    this.close();
                } else {
                    console.error('Something went wrong: Job failed.');
                    this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
                }
                this.isProcessing = false;
            }).catch(error => {
                console.error(error);
                this.isProcessing = false;
                this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
            });
        },
    },

    created() {
        this.fetchUnsyncedGalleyRecipes()
    }
}
</script>

<style scoped></style>
