<template>
    <span class="badge" :class="{
            'green': status == 'Active'
        }"
    >
        {{ status }}
    </span>
</template>

<script>
export default {
    props: {
        location: {
            type: Object,
            required: true
        }
    },

    computed: {
        status(){
            if(this.location.active) return 'Active'
            else return 'Inactive'
        }
    }
}
</script>

<style>

</style>
