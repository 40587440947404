<template>
    <div>
        <div class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="col-span-6 label">Nutrient</h6>
            <h6 class="col-span-6 label">Quantity</h6>
        </div>
        <div class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md">
            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Calories</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.calories_kcal }} kCal</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Total Fat</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.total_fat_g }} g</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Saturated Fat</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.saturated_fat_g }} g</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Trans Fat</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.trans_fat_g }} g</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Cholesterol</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.cholesterol_mg }} mg</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Sodium</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.sodium_mg }} mg</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Carbs</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.carbs_g }} g</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Fiber</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.fiber_g }} g</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Sugar</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.sugar_g }} g</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Protein</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.protein_g }} g</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Added Sugar</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.added_sugar_g }} g</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Calcium</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.calcium_mg }} mg</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Iron</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.iron_mg }} mg</p>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Nutrient</h6>
                        <p>Potassium</p>
                    </div>
                    <div class="xl:col-span-6">
                        <h6 class="label xl:hidden">Quantity</h6>
                        <p>{{ nutritionals.potassium_mg }} mg</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import StatusBadge from '@components/meals/StatusBadge'

export default {
    props: {
        nutritionals: {
            type: Object,
            required: true
        }
    },
    components: {
        StatusBadge,
    },

    created() { }
};
</script>
  
<style></style>
  