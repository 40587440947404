<template>
  <div>
    <div
      :class="headerSize"
      class="hidden p-2 bg-gray-100 rounded-t-md border border-gray-200"
    >
      <slot name="headers">
        <div v-for="header in headers">
          <span v-if="typeof header === 'string'">
            <h6 class="label">{{ header }}</h6>
          </span>
          <span class="flex flex-row items-center gap-x-2" v-else>
            <h6 class="label">{{ header.name }}</h6>
            <i
              v-if="header.sort"
              class="fas fa-sm text-gray-600 cursor-pointer"
              :class="header.sort === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"
              @click="changeSort(header)"
            />
          </span>
        </div>
      </slot>
    </div>
    <div
      class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md"
    >
      <slot name="list">
        <div v-for="item in items">
          <div v-for="[key, value] in item.entries()">
            <p class="text-xs text-gray-400 xl:hidden">{{ key }}</p>
            <p class="font-semibold">{{ value }}</p>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return []
      },
    },
    items: {
      type: Array,
      default: function () {
        return []
      },
    },
  },

  computed: {
    headerSize() {
      return this.headers.length
        ? `xl:grid xl:grid-cols-${this.headers.length} gap-2`
        : ''
    },
  },

  methods: {
    changeSort(header) {
      const sort = { name: header.name, orderBy: header.sort === 'asc' ? 'desc' : 'asc' }
      this.$emit('changeSort', sort)
    },
  },
}
</script>

<style lang="scss" scoped></style>
