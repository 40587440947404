<template>
  <div>
    <h2 class="mb-2">
      Reports<span v-if="selectedReport"> | {{ selectedReport }}</span>
    </h2>
    <div v-if="selectedReport" class="text-xs text-gray-400 mb-6">
      <router-link to="/reports"
        ><span class="underline">Back</span></router-link
      >
    </div>
    <div v-else>
      <ul>
        <li><router-link to="/reports/menu-costs">Projected Menu Costs</router-link></li>
        <li>
          <router-link to="/reports/delivery-addresses"
            >Delivery Addresses</router-link
          >
        </li>
        <li><router-link to="/reports/gift-cards">Gift Cards</router-link></li>
        <li><router-link to="/reports/management-payroll">Management Payroll</router-link></li>
      </ul>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Reports",

  data() {
    return {
      selectedReport: null
    };
  },

  computed: {
    routePath() {
      return this.$route.path;
    },
    routeName() {
      return this.$route.name;
    }
  },

  methods: {},

  created() {},

  mounted() {
    if (this.routePath === "/reports") this.selectedReport = null;
    else this.selectedReport = this.routeName;
  }
};
</script>

<style scoped></style>
