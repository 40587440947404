<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Confirm Delete</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div v-if="error" class="flex space-x-5 mb-4">
                <p class="text-red ml-1">{{ error }}</p>
            </div>
            <div class="mb-10">
                <p>Are you sure you want to delete this location? This cannot be undone!</p>
            </div>
            <div class="flex space-x-5">
                <button class="btn white px-6" @click="close" :disabled="loading">Cancel</button>
                <button class="btn button-green px-6" @click="deleteLocation" :disabled="loading || success || !canDelete">
                    <div v-if="loading" class="flex items-center space-x-1"><Loader class="w-5 h-5"/><span>Deleting</span></div>
                    <span v-else-if="success"><i class="fas fa-check-circle mr-1"></i>Deleted!</span>
                    <span v-else>Delete</span>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'


export default {
    name: "ConfirmDeleteModal",

    components: {
        Modal,
        Loader
    },

    props: {
        locationId: {
            type: Number,
            default: null
        },
        visible: {
            type: Boolean,
            default: false
        },
    },

    data(){
        return {
            error:  null,
            loading: false,
            success: false,
        }
    },

    methods: {
        close(){
            this.error = null
            this.$emit('close')
        },
        update() {
            this.$emit('update')
        },
        deleteLocation() {
            this.error = null
            this.loading = true
            return this.$app.api.locations.deleteLocation(this.locationId)
                .then((response) => {
                    this.success = true
                    setTimeout(() => {
                        this.success = false
                        this.close()
                        this.$emit('update')
                    }, 2000)
                })
                .catch((error) => {
                    this.error = error?.response?.data?.message
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },

    computed: {
        canDelete() {
            return this.$app.services.auth.hasPermission('locations.delete')
        },
    },
}
</script>

<style scoped>

</style>
