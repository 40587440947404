<template>
  <div>
    <div class="mb-10">
      <h2 class="mb-5">Gift Cards</h2>
      <div class="flex flex-wrap">
        <SearchInput
          class="mr-2"
          v-model="gift_cards.search"
          placeholder="Search by ID, email, number"
          @search="runSearch"
        />
        <DateRangePicker
          class="mr-2"
          :value="gift_cards.dateRange"
          :default="gift_cards.defaultDateRange"
          @input="dateRangeChanged"
        />
        <router-link
          v-if="canCreateGiftCard"
          class="btn green border border-green px-3 py-2"
          :to="`/gift-cards/create`"
          :disabled="!canCreateGiftCard"
        >
          <span><i class="fas fa-plus mr-2"></i>Create Gift Card</span>
        </router-link>
      </div>
    </div>
    <div>
      <template v-if="gift_cards.loading">
        <div class="flex items-center space-x-5">
          <Loader class="w-10 h-10 text-green" />
          <p>Loading cards...</p>
        </div>
      </template>
      <template v-else-if="gift_cards.data && gift_cards.data.length">
        <ListView class="mb-10" :gift_cards="gift_cards.data" />
        <Pagination
          :page="gift_cards.page"
          :total-items="totalCards"
          :per-page="gift_cards.perPage"
          @change="pageUpdated"
        />
      </template>
      <template v-else>
        <p>
          <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No gift cards
          to show. Please refine your criteria.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import Loader from '@components/global/Loader'
import SearchInput from '@components/global/SearchInput'
import Pagination from '@components/global/Pagination'
import DateRangePicker from '@components/global/dates/DateRangePicker'
import ListView from '@components/giftcards/ListView'

export default {
  name: 'GiftCards',

  components: {
    Loader,
    SearchInput,
    Pagination,
    DateRangePicker,
    ListView,
  },

  data() {
    return {
      gift_cards: {
        loading: false,
        search: this.$route.query.search ?? '',
        page: parseInt(this.$route.query.page) || 1,
        perPage: parseInt(this.$route.query.per_page) || 10,
        dateRange: this.getDateRange(),
        defaultDateRange: this.getDefaultDateRange(),
        meta: null,
        data: null,
      },
    }
  },

  computed: {
    canCreateGiftCard() {
      return this.$app.services.auth.hasPermission('giftcards.create')
    },
    title() {
      if (this.$route.query.search)
        return `Search: ${this.$route.query.search} | Gift Cards`
      return 'Gift Cards'
    },
    totalCards() {
      return this.gift_cards.meta?.total ?? 0
    },
  },

  methods: {
    getDefaultDateRange() {
      return {
        start: null,
        end: null,
      }
    },
    getDateRange() {
      let range = this.getDefaultDateRange()

      if (this.$route.query.after_date)
        range.start = DateTime.fromISO(this.$route.query.after_date).startOf('day')
      if (this.$route.query.before_date)
        range.end = DateTime.fromISO(this.$route.query.before_date).endOf('day')

      return range
    },
    dateRangeChanged(input) {
      const range = input.range
      this.gift_cards.dateRange = range
      this.gift_cards.page = 1

      let query = { ...this.$route.query }
      delete query.page

      if (
        +range.start === +this.gift_cards.defaultDateRange.start &&
        +range.end === +this.gift_cards.defaultDateRange.end
      ) {
        delete query.after_date
        delete query.before_date
      } else {
        query.after_date = range.start.toISODate()
        query.before_date = range.end.toISODate()
      }
      this.$router.replace({ path: this.$route.path, query })
      this.getGiftCards()
    },
    pageUpdated(page) {
      this.gift_cards.page = page
      this.getGiftCards()
    },
    runSearch() {
      this.gift_cards.page = 1

      let query = { ...this.$route.query, search: this.gift_cards.search }
      delete query.page

      if (!this.gift_cards.search) delete query.search
      this.$router.replace({ path: this.$route.path, query })
      this.getGiftCards()
    },
    getGiftCards() {
      let params = {
        page: this.$route.query.page || this.gift_cards.page,
        per_page: this.$route.query.per_page || this.gift_cards.perPage,
      }

      let search = this.$route.query.search || this.gift_cards.search
      if (search) params.search = search

      if (this.gift_cards.dateRange.start)
        params.after_date = this.gift_cards.dateRange.start.toUTC().toISO()
      if (this.gift_cards.dateRange.end)
        params.before_date = this.gift_cards.dateRange.end.toUTC().toISO()

      this.gift_cards.loading = true
      return this.$app.api.giftCards
        .getCards(params)
        .then((response) => {
          this.gift_cards.data = response.data.data
          this.gift_cards.meta = response.data.meta
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => (this.gift_cards.loading = false))
    },
  },

  created() {},

  mounted() {
    this.getGiftCards()
  },
}
</script>

<style lang="scss" scoped></style>
