import Resource from './resource'

export default class Accounts extends Resource {

    /**
     * @param {object} data account request data
     */
    createAccount(data) {
        return this.axios.post(`accounts`, data)
    }

    /**
     * 
     * @param {string|int} accountId account ID
     * @param {object} data account update data
     */
    updateAccount(accountId, data) {
        return this.axios.put(`accounts/${accountId}`, data)
    }

    /**
     * @param {string|int} accountId account ID
     * @param {object} data account request data
     */
    deleteAccount(accountId, data = {}) {
        return this.axios.delete(`accounts/${accountId}`, { data })
    }

    getEmployeeLinks() {
        return this.axios.get(`employee-links`)
    }

    deleteEmployeeLink(employeeLinkId) {
        return this.axios.delete(`employee-links/${employeeLinkId}`)
    }

    createEmployeeLink(data) {
        return this.axios.post(`employee-links`, data)
    }

}
