<template>
    <div>
        <UnauthorizedModal/>
    </div>
</template>

<script>
import UnauthorizedModal from '@components/global/modals/Unauthorized'

export default {
    components: {
        UnauthorizedModal
    },

    data(){
        return {}
    }
}
</script>

<style>

</style>
