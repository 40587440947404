<template>
    <div class="relative bg-gray-100 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
        <div v-if="saving" class="absolute top-0 left-0 w-full h-full flex justify-center items-center rounded-md xl:rounded-none bg-gray z-30">
            <div class="flex justify-center items-center bg-green rounded-md px-2.5 py-1">
                <Loader class="w-10 h-10 text-white"/>
                <p class="pl-4 text-white">Saving...</p>
            </div>
        </div>

        <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 gap-2 xl:px-3">
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Name</p>
                <p>{{ event.name }}</p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Description</p>
                <p class="text-hyphens">{{ event.description }}</p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Notes</p>
                <p class="text-hyphens">{{ event.notes }}</p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">All Day</p>
                <p>{{ event.all_day }}</p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Start</p>
                <p><Date :date="event.start_time" preset="DATE_FULL"/></p>
                <p><Date :date="event.start_time" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">End</p>
                <p><Date :date="event.end_time" preset="DATE_FULL"/></p>
                <p><Date :date="event.end_time" preset="TIME_SIMPLE_WITH_SHORT_OFFSET"/></p>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Status</p>
                <template v-if="permissionToEdit">
                    <multiselect
                        class=""
                        v-model="event.status"
                        :options="eventStatuses"
                        :multiple="false"
                        :close-on-select="true"
                        :allow-empty="false"
                        :searchable="false"
                        :taggable="false"
                        :show-labels="false"
                        placeholder="Select Status"
                        label="name"
                        track-by="name"
                        @input="onStatusSelect"
                    >
                        <template slot="placeholder"><span>Select Status</span></template>
                        <template slot="caret" slot-scope="{toggle}">
                            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                <img class="absolute top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] select-none" src="/img/icons/down-chevron.svg" alt="icon">
                            </div>
                        </template>
                    </multiselect>
                </template>
                <template v-else>
                    <p>{{ event.status.name }}</p>
                </template>
            </div>
            <div>
                <p class="text-xs text-gray-400 xl:hidden">Manage</p>
                <button v-if="permissionToEdit" class="btn xs green mr-2" @click="onEdit">Edit</button>
                <button v-else class="btn xs green mr-2" :disabled="!permissionToEdit">Edit</button>
                <button v-if="permissionToDelete" class="btn xs gray" @click="onDelete">Delete</button>
                <button v-else class="btn xs gray" :disabled="!permissionToDelete">Delete</button>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@components/global/Loader'
import Date from '@components/global/dates/Date'

export default {
    name: "CalendarEventCard",

    components: {
        Loader,
        Date,
    },

    props: {
        event: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            saving: false,
        }
    },

    computed: {
        permissionToEdit() {
            return this.$app.services.auth.hasPermission('calendars.events.update')
        },
        permissionToDelete() {
            return this.$app.services.auth.hasPermission('calendars.events.delete')
        },
        eventStatuses() {
            return this.$app.store.state.calendars.statuses.event
        },
    },

    methods: {
        onStatusSelect(status) {
            let data = {
                ...this.event,
                status_id: status.id
            }

            this.saving = true
            this.$app.api.calendars.updateEvent(this.event.id, data)
                .then((response) => {
                    // TODO: update the calendar and events
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.saving = false
                })
        },
        onEdit() {
            this.$app.events.$emit('calendar-event-edit', this.event)
        },
        onDelete() {
            this.$app.events.$emit('calendar-event-delete', this.event)
        },
    },

    created() {}
}
</script>

<style lang="scss" scoped>
.text-hyphens {
    //overflow-wrap: break-word;
    hyphens: auto;
}
</style>
