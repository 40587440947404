import Resource from "./resource";

export default class Menus extends Resource {
  /**
   *
   * @param {object} params
   */
  getMenus(params = {}) {
    return this.axios.get(`menus`, { params });
  }

  /**
 * 
 * @param {string|int} menuID menu ID
 * @param {object} params query params
 */
  getMenu(menuId, params = {}) {
    return this.axios.get(`menus/${menuId}`, { params });
  }

  /**
   *
   * @param {object} data menu data
   */
  createMenu(data) {
    return this.axios.post(`menus`, data)
  }

  /**
   *
   * @param {object} params
   */
  updateMenu(menuId, data = {}) {
    return this.axios.put(`menus/${menuId}`, data);
  }

  /**
   * @param {string|int} id class event ID
   */
  generateForecast(menuId, params = {}) {
    return this.axios.get(`menus/${menuId}/forecast`, { params });
  }
}
