<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-4 flex justify-between space-x-3">
                <h3>Edit Menu</h3>
                <button class="px-2 text-xl text-green" @click="close"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="mb-6">
                <p class="mb-2">Editing this menu will affect the registrations for {{ classEvents.length }} classes.
                </p>
                <p class="mb-2">Location managers will need to reach out to affected customers to determine new
                    selections.</p>
                <p class="mb-2">Are you sure you want to edit this menu?</p>
            </div>
            <div class="flex space-x-5">
                <button class="btn px-6"
                    @click="$router.push({ name: 'menu-edit', params: { id: menuId } });">Edit</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'

export default {
    name: "EditConfirmModal",

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        classEvents: {
            type: Array,
            default: () => [],
        },
        menuId: {
            type: Number,
            default: null,
        },
    },

    components: {
        Modal,
        Loader
    },

    data() {
        return {
            error: null,
        };
    },

    methods: {
        close() {
            this.$emit('close')
        },
    },

    computed: {
        classEventLocations() {
            return this.classEvents.reduce((acc, event) => {
                if (!acc.find(location => location.id === event.location.id)) {
                    acc.push(event.location)
                }
                return acc
            }, [])
        },
    }
}
</script>

<style scoped></style>
