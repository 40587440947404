
import axios from 'axios'

// api resources
import Announcements from './announcements'
import Accounts from './accounts'
import Carts from './carts'
import Classes from './classes'
import Fulfillments from './fulfillments'
import Jobs from './jobs'
import Locations from './locations'
import Shipping from './shipping'
import Meals from './meals'
import Users from './users'
import Allergies from './allergies'
import Marketing from './marketing'
import Orders from './orders'
import Registrations from './registrations'
import Promotions from './promotions'
import Payments from './payments'
import ContactUs from './contactUs'
import Blogs from './blogs'
import Calendars from './calendars'
import Newsroom from './newsroom'
import Tracking from './tracking'
import Reports from './reports'
import GiftCards from './giftCards'
import Roles from "./roles";
import Menus from "./menus";

export default class API {

    constructor(){
        const axiosInstance = axios.create({
            baseURL: process.env.VUE_APP_API_BASE_URL,
            headers: {
                accept: 'application/json',
            }
        })

        axiosInstance.interceptors.request.use(config => {
            if(!this._getAuthToken) return config
            return this._getAuthToken().then(token => {
                if(token) config.headers.Authorization = `Bearer ${token}`
                return config
            })
        })

        this.announcements = new Announcements(axiosInstance)
        this.accounts = new Accounts(axiosInstance)
        this.carts = new Carts(axiosInstance)
        this.classes = new Classes(axiosInstance)
        this.fulfillments = new Fulfillments(axiosInstance)
        this.jobs = new Jobs(axiosInstance)
        this.locations = new Locations(axiosInstance)
        this.shipping = new Shipping(axiosInstance)
        this.meals = new Meals(axiosInstance)
        this.users = new Users(axiosInstance)
        this.allergies = new Allergies(axiosInstance)
        this.marketing = new Marketing(axiosInstance)
        this.orders = new Orders(axiosInstance)
        this.registrations = new Registrations(axiosInstance)
        this.payments = new Payments(axiosInstance)
        this.promotions = new Promotions(axiosInstance)
        this.contactUs = new ContactUs(axiosInstance)
        this.blogs = new Blogs(axiosInstance)
        this.calendars = new Calendars(axiosInstance)
        this.newsroom = new Newsroom(axiosInstance)
        this.tracking = new Tracking(axiosInstance)
        this.reports = new Reports(axiosInstance)
        this.giftCards = new GiftCards(axiosInstance)
        this.roles = new Roles(axiosInstance);
        this.menus = new Menus(axiosInstance);
    }

    setAuthTokenCallback(callback){
        this._getAuthToken = callback
    }

}
