import Resource from './resource'

export default class Registrations extends Resource {

    /**
     * @param {string|int} id registration ID
     * @param {object} params Query params
     */
    getRegistration(id, params = {}) {
        return this.axios.get(`registrations/${id}`, { params })
    }

    /**
     * @param {string|int} id registration ID
     * @param {object} data
     */
    updateRegistration(id, data) {
        return this.axios.put(`registrations/${id}`, data)
    }

    /**
     * @param {string|int} id registration ID
     * @param {object} data
     */
    setStatus(id, data) {
        return this.axios.post(`registrations/${id}/status`, data)
    }

    /**
     * Get statuses
     */
    getStatuses() {
        return this.axios.get(`registrationStatuses`)
    }

    /**
     * @param {string|int} id registration ID
     * @param {object} data
     */
    getTotals(id, data) {
        return this.axios.post(`registrations/${id}/totals`, data)
    }

    getShippoOrderUrl(id) {
        return this.axios.get(`registrations/${id}/shippo-order-url`)
    }

    /**
     * Send confirmation email
     * @param {string|int} id registration ID
     * @param {object} data request data
     */
    sendConfirmation(id, data = {}) {
        return this.axios.post(`registrations/${id}/confirmation`, data)
    }

    /**
     * Send reminder email
     * @param {string|int} id registration ID
     * @param {object} data request data
     */
    sendReminder(id, data = {}) {
        return this.axios.post(`registrations/${id}/reminder`, data)
    }

    getUnconfirmedRegistrations(params = {}) {
        return this.axios.get(`registrations/unconfirmed`, { params });
    }

    transferPending(id, data) {
        return this.axios.post(`registrations/${id}/transfer-pending`, data);
    }
}
